import React, { useState } from 'react'
import CharacteristicsList from '@brainbay/components/components/characteristics-list'

import './expandable-characteristics-list.css'

export default function ExpandableCharacteristicsList({
  items,
  loading,
  heightLevel,
  headingLevel,
  title = null,
  valueRenderer,
  labelRenderer,
  modalRenderer,
}) {
  const [expanded, setExpanded] = useState(false)

  return (
    <div
      className={`expandable-list ${
        expanded ? 'expandable-list--expanded' : ''
      }`}
      style={{ '--height-level': heightLevel }}
    >
      <div className="expandable-list__list-wrapper">
        {items && (
          <CharacteristicsList
            headingLevel={headingLevel}
            data={items}
            loading={loading}
            title={title}
            valueRenderer={valueRenderer}
            labelRenderer={labelRenderer}
            modalRenderer={modalRenderer}
          />
        )}
      </div>
      {items?.[0].items?.length > 1 && (
        <footer className="expandable-list__footer">
          <button
            className="button button--x-small expandable-list__toggle print-excluded"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? 'Toon minder' : 'Toon meer'}
          </button>
        </footer>
      )}
    </div>
  )
}
