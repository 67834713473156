import { types, housing as defaultHousing, toolName } from './constants'

export default function formatUrl({ id, housingType, addressId }) {
  const urlParts = []
  const housingTypeFromArray = getHousingType(housingType)

  if (housingTypeFromArray && addressId) {
    urlParts.push(housingTypeFromArray)
    urlParts.push(addressId)
  }

  if (id) {
    urlParts.push(id)
  }

  if (urlParts.length > 0) {
    return `/${urlParts.join('/')}`
  }

  return ''
}

function getHousingType(housing) {
  let housingType = ''

  if (isExistingHousing(housing?.[housing?.length - 1])) {
    housingType = housing[housing.length - 1]
  } else if (types?.[housing?.[0]]) {
    housingType = housing[0]
  }

  return housingType.replace(/\s/g, '-')
}

function isExistingHousing(housingType) {
  return (
    defaultHousing.WOONHUIS_VARIANT.indexOf(housingType) !== -1 ||
    defaultHousing.APPARTEMENT_TYPE.indexOf(housingType) !== -1
  )
}

export const getOriginOfNavigatedUrl = (tool, pathName) => {
  const nodenv = window.deploy_env
  let originOfURL = ''
  switch (nodenv) {
    case '__DEPLOY_ENV__':
      switch (tool) {
        case toolName.INVERKOOPNAME:
          originOfURL = 'localhost:3000'
          break
        case toolName.AANKOOP:
          originOfURL = 'localhost:5002'
          break
        case toolName.WAARDEREN:
          originOfURL = 'localhost:7002'
          break
        case toolName.PRIJSONTWIKKELING:
          originOfURL = 'localhost:2000'
          break
        default:
          break
      }
      break
    case 'tst':
      switch (tool) {
        case toolName.INVERKOOPNAME:
          originOfURL = 'inverkoopname.tst.brainbay.nl'
          break
        case toolName.AANKOOP:
          originOfURL = 'aankoop.tst.brainbay.nl'
          break
        case toolName.WAARDEREN:
          originOfURL = 'waarderen.tst.brainbay.nl'
          break
        case toolName.PRIJSONTWIKKELING:
          originOfURL = 'prijsontwikkeling.tst.brainbay.nl'
          break
        default:
          break
      }
      break
    case 'acc':
      switch (tool) {
        case toolName.INVERKOOPNAME:
          originOfURL = 'inverkoopname.acc.brainbay.nl'
          break
        case toolName.AANKOOP:
          originOfURL = 'aankoop.acc.brainbay.nl'
          break
        case toolName.WAARDEREN:
          originOfURL = 'waarderen.acc.brainbay.nl'
          break
        case toolName.PRIJSONTWIKKELING:
          originOfURL = 'prijsontwikkeling.acc.brainbay.nl'
          break
        default:
          break
      }
      break
    case 'prd':
      switch (tool) {
        case toolName.INVERKOOPNAME:
          originOfURL = 'inverkoopname.brainbay.nl'
          break
        case toolName.AANKOOP:
          originOfURL = 'aankoop.brainbay.nl'
          break
        case toolName.WAARDEREN:
          originOfURL = 'waarderen.brainbay.nl'
          break
        case toolName.PRIJSONTWIKKELING:
          originOfURL = 'prijsontwikkeling.brainbay.nl'
          break
        default:
          break
      }
      break
    default:
      break
  }
  return `${window.location.protocol}//${originOfURL}${pathName}`
}
