import React, { Fragment } from 'react'
import {
  Circle,
  ClipPath,
  Defs,
  Ellipse,
  G,
  Line,
  LinearGradient,
  Path,
  Polygon,
  Polyline,
  RadialGradient,
  Rect,
  Stop,
  Svg,
  Text,
} from '@react-pdf/renderer'
import { v4 as uuid } from 'uuid'
import { attributeToNumber, cssToCamelCase } from '.'

const presentationAttributes = [
  {
    key: 'color',
    type: 'string',
  },
  {
    key: 'dominant-baseline',
    type: 'string',
  },
  { key: 'fill', type: 'string' },
  { key: 'fill-opacity', type: 'number' },
  { key: 'fill-rule', type: 'string' },
  { key: 'opacity', type: 'number' },
  { key: 'stroke', type: 'string' },
  { key: 'stroke-width', type: 'number' },
  { key: 'stroke-opacity', type: 'number' },
  { key: 'stroke-linecap', type: 'string' },
  { key: 'stroke-dasharray', type: 'string' },
  { key: 'transform', type: 'string' },
  { key: 'text-anchor', type: 'string' },
  { key: 'visibility', type: 'string' },
]

export const getAttributes = (item, attributes, props) => {
  attributes.forEach(attribute => {
    let value = item.getAttribute(attribute.key)

    if (attribute.key === 'stroke-dasharray' && value) {
      value = value.split(' ').join(', ')
    }
    if (value) {
      const key = cssToCamelCase(attribute.key)
      if (attribute.type === 'number') {
        props[key] = attributeToNumber(item, value)
      } else {
        props[key] = value
      }
    }
  })
  return Object.assign({}, props)
}

export const getPresentationAttributes = (item, props) => {
  getAttributes(item, presentationAttributes, props)
}

export const svgToSvg = item => {
  const props = { key: uuid() }
  getAttributes(
    item,
    [
      { key: 'width', type: 'number' },
      { key: 'height', type: 'number' },
      { key: 'viewBox', type: 'string' },
      { key: 'preserveAspectRatio', type: 'string' },
    ],
    props,
  )

  return <Svg {...props}>{getChildren(item)}</Svg>
}

export const lineToLine = item => {
  const props = {
    key: uuid(),
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0,
  }
  getPresentationAttributes(item, props)
  getAttributes(
    item,
    [
      { key: 'x1', type: 'number' },
      { key: 'x2', type: 'number' },
      { key: 'y1', type: 'number' },
      { key: 'y2', type: 'number' },
    ],
    props,
  )

  return <Line {...props}>{getChildren(item)}</Line>
}

export const polylineToPolyline = item => {
  const props = {
    key: uuid(),
    points: '',
  }
  getPresentationAttributes(item, props)
  getAttributes(item, [{ key: 'points', type: 'string' }], props)

  return <Polyline {...props}>{getChildren(item)}</Polyline>
}

export const polygonToPolygon = item => {
  const props = {
    key: uuid(),
    points: '',
  }
  getPresentationAttributes(item, props)
  getAttributes(item, [{ key: 'points', type: 'string' }], props)

  return <Polygon {...props}>{getChildren(item)}</Polygon>
}

export const pathToPath = item => {
  const props = { key: uuid(), d: '' }
  getPresentationAttributes(item, props)
  getAttributes(item, [{ key: 'd', type: 'string' }], props)

  if (!props.fill) props.fill = 'none'

  return <Path {...props}>{getChildren(item)}</Path>
}

export const rectToRect = item => {
  const props = {
    key: uuid(),
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  }
  getPresentationAttributes(item, props)
  getAttributes(
    item,
    [
      { key: 'x', type: 'number' },
      { key: 'y', type: 'number' },
      { key: 'width', type: 'number' },
      { key: 'height', type: 'number' },
      { key: 'rx', type: 'number' },
      { key: 'ry', type: 'number' },
    ],
    props,
  )

  return <Rect {...props}>{getChildren(item)}</Rect>
}

export const circleToCircle = item => {
  const props = {
    key: uuid(),
    cx: 0,
    cy: 0,
    r: 0,
  }
  getPresentationAttributes(item, props)
  getAttributes(
    item,
    [
      { key: 'cx', type: 'number' },
      { key: 'cy', type: 'number' },
      { key: 'r', type: 'number' },
    ],
    props,
  )

  return <Circle {...props}>{getChildren(item)}</Circle>
}
export const ellipseToEllipse = item => {
  const props = {
    key: uuid(),
    cx: 0,
    cy: 0,
    rx: 0,
    ry: 0,
  }
  getPresentationAttributes(item, props)
  getAttributes(
    item,
    [
      { key: 'cx', type: 'number' },
      { key: 'cy', type: 'number' },
      { key: 'rx', type: 'number' },
      { key: 'ry', type: 'number' },
    ],
    props,
  )

  return <Ellipse {...props}>{getChildren(item)}</Ellipse>
}

export const textToText = item => {
  const props = {
    key: uuid(),
    x: 0,
    y: 0,
  }
  getPresentationAttributes(item, props)
  getAttributes(
    item,
    [
      { key: 'x', type: 'number' },
      { key: 'y', type: 'number' },
      { key: 'width', type: 'number' },
      { key: 'height', type: 'number' },
    ],
    props,
  )

  const children = item.children
  if (children.length) {
    const texts = []
    for (const child of children) {
      texts.push(tspanToTspan(child, item))
    }
    return <Fragment key={uuid()}>{texts}</Fragment>
  } else {
    return (
      <Text {...props} fontSize={10}>
        {item.innerHTML}
      </Text>
    )
  }
}

export const tspanToTspan = (item, parent) => {
  const props = {
    x: 0,
    y: 0,
  }
  getPresentationAttributes(item, props)
  getAttributes(
    item,
    [
      { key: 'x', type: 'number' },
      { key: 'y', type: 'number' },
      { key: 'dx', type: 'number' },
      { key: 'dy', type: 'number' },
    ],
    props,
  )

  const parentProps = {
    key: uuid(),
    x: 0,
    y: 0,
  }
  getPresentationAttributes(parent, parentProps)
  getAttributes(
    parent,
    [
      { key: 'x', type: 'number' },
      { key: 'y', type: 'number' },
    ],
    parentProps,
  )

  parentProps.x = +parentProps.x + (props.dx ?? 0)
  parentProps.y = +parentProps.y + (props.dy ?? 0)

  return <Text {...parentProps}>{item.innerHTML}</Text>
}

export const gToG = item => {
  const props = { key: uuid() }
  getPresentationAttributes(item, props)

  return <G {...props}>{getChildren(item)}</G>
}

export const stopToStop = item => {
  const props = {
    key: uuid(),
    offset: 0,
    stopColor: '',
  }
  getAttributes(
    item,
    [
      { key: 'offset', type: 'number' },
      { key: 'stop-color', type: 'string' },
      { key: 'stop-opacity', type: 'number' },
    ],
    props,
  )

  return <Stop {...props}>{getChildren(item)}</Stop>
}

export const defsToDefs = item => {
  const props = { key: uuid() }

  return <Defs {...props}>{getChildren(item)}</Defs>
}

export const clipPathToClipPath = item => {
  const props = { key: uuid() }

  return <ClipPath {...props}>{getChildren(item)}</ClipPath>
}

export const linearGradientToLinearGradient = item => {
  const props = {
    key: uuid(),
    id: '',
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0,
  }
  getAttributes(
    item,
    [
      { key: 'id', type: 'string' },
      { key: 'x1', type: 'number' },
      { key: 'x2', type: 'number' },
      { key: 'y1', type: 'number' },
      { key: 'y2', type: 'number' },
    ],
    props,
  )

  return <LinearGradient {...props}>{getChildren(item)}</LinearGradient>
}

export const radialGradientToRadialGradient = item => {
  const props = {
    key: uuid(),
    id: '',
    cx: 0,
    cy: 0,
    fr: 0,
    fx: 0,
    fy: 0,
  }
  getPresentationAttributes(item, props)
  getAttributes(
    item,
    [
      { key: 'id', type: 'string' },
      { key: 'cx', type: 'number' },
      { key: 'cy', type: 'number' },
      { key: 'fr', type: 'number' },
      { key: 'fx', type: 'number' },
      { key: 'fy', type: 'number' },
    ],
    props,
  )

  return <RadialGradient {...props}>{getChildren(item)}</RadialGradient>
}

export const getChildren = parent => {
  const children = []

  for (let item of parent.children) {
    const type = item.tagName.toLowerCase()

    switch (type) {
      case 'svg':
        children.push(svgToSvg(item))
        break
      case 'line':
        children.push(lineToLine(item))
        break
      case 'polyline':
        children.push(polylineToPolyline(item))
        break
      case 'polygon':
        children.push(polygonToPolygon(item))
        break
      case 'path':
        children.push(pathToPath(item))
        break
      case 'rect':
        children.push(rectToRect(item))
        break
      case 'circle':
        children.push(circleToCircle(item))
        break
      case 'ellipse':
        children.push(ellipseToEllipse(item))
        break
      case 'text':
        children.push(textToText(item))
        break
      case 'tspan':
        children.push(tspanToTspan(item, parent))
        break
      case 'g':
        children.push(gToG(item))
        break
      case 'stop':
        children.push(stopToStop(item))
        break
      case 'defs':
        children.push(defsToDefs(item))
        break
      case 'clippath':
        children.push(clipPathToClipPath(item))
        break
      case 'lineargradient':
        children.push(linearGradientToLinearGradient(item))
        break
      case 'radialgradient':
        children.push(radialGradientToRadialGradient(item))
        break
      default:
        console.error('Skip item', item)
        break
    }
  }

  return children
}

export const convert = svg => {
  if (!svg || svg.tagName !== 'svg') throw new Error('Give a valid svg element')

  const props = {}
  getAttributes(
    svg,
    [
      { key: 'width', type: 'number' },
      { key: 'height', type: 'number' },
      { key: 'viewBox', type: 'string' },
      { key: 'preserveAspectRatio', type: 'number' },
    ],
    props,
  )

  return <Svg {...props}>{getChildren(svg)}</Svg>
}
