import React, { useMemo } from 'react'
import Panel from '@brainbay/components/components/panel'
import MapMarker from '@brainbay/components/components/map-marker'
import MapboxMap from '@brainbay/components/components/mapbox-map'
import formatAddressBusiness from '../../utils/format-address-business'

import './object-surroundings.css'

export default function ObjectSurroundings({ data = {} }) {
  const mapsAddress = useMemo(() => {
    const address = formatAddressBusiness({
      postalCode: data.postcode,
      street: data.straatnaam,
      houseNumber: data.huisnummer,
      houseNumberSuffix: data.huisnummertoevoeging,
      city: data.woonplaats,
      municipality: data.gemeente,
    })

    return encodeURIComponent(address)
  }, [
    data.postcode,
    data.huisnummer,
    data.huisnummertoevoeging,
    data.straatnaam,
    data.woonplaats,
    data.gemeente,
  ])

  const geowebAddress = useMemo(() => {
    return `${data.postcode}+${data.huisnummer}${
      data.huisnummertoevoeging || ''
    }`
  }, [data.huisnummer, data.huisnummertoevoeging, data.postcode])

  return (
    <Panel className="object-surroundings" title="Omgeving" tag="section">
      <h3 className="sr-only">Locatie</h3>
      {data.longitude && data.latitude && (
        <>
          <div className="object-surroundings__map-wrapper">
            <MapboxMap
              centeredLongitude={data.longitude}
              centeredLatitude={data.latitude}
              zoom={14}
            >
              {data.longitude && data.latitude && (
                <MapMarker
                  tabIndex="-1"
                  longitude={data.longitude}
                  latitude={data.latitude}
                  houseStatus={data.status}
                />
              )}
            </MapboxMap>
          </div>
          <a
            className="object-surroundings__maps-link"
            href={`https://www.google.com/maps/place/${mapsAddress}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Maps
          </a>
        </>
      )}
      <dl>
        <ObjectSurroundingsDefinition
          title="Ligging"
          items={data.liggingen}
          loading={data.loading}
        />
        <ObjectSurroundingsDefinition
          title="Liggingskenmerken"
          items={data.liggingskenmerken}
          loading={data.loading}
        />
        <ObjectSurroundingsDefinition
          title="Toelichting lokatie"
          items={data.toelichtingLocatie}
          loading={data.loading}
        />
        <ObjectSurroundingsDefinition
          title="Horeca concentratiegebied"
          items={
            typeof data.indicatieHorecaConcentratiegebied !== 'undefined'
              ? [data.indicatieHorecaConcentratiegebied ? 'Ja' : 'Nee']
              : []
          }
          loading={data.loading}
        />
        <ObjectSurroundingsDefinition
          title="Lokale voorzieningen"
          items={data.lokaleVoorzieningen}
          loading={data.loading}
        />
        <ObjectSurroundingsDefinition
          title="Bereikbaarheid"
          items={data.bereikbaarheid}
          loading={data.loading}
        />
      </dl>
      <a
        className="object-surroundings__geo-link"
        href={`https://geoweb-nvm.geowebonline.nl/GeoWeb56/Index.html?viewer=nvm&LayerTheme=Landelijk&adres=${geowebAddress}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Toon object in Geografische informatie
      </a>
    </Panel>
  )
}

const ObjectSurroundingsDefinition = ({ title, items, loading }) => {
  if (loading) {
    return (
      <>
        <dt className="body text">{title}</dt>
        <dd className="body loading-state"></dd>
      </>
    )
  }

  if (!items?.length) {
    return null
  }

  return (
    <>
      <dt className="body text">{title}</dt>
      {items.map((str, index) => (
        <dd key={index} className="body capitalize-first-letter">
          {str}
        </dd>
      ))}
    </>
  )
}
