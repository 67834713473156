import { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

export default function useDebouncedValue(
  value,
  callback,
  debounceDelay = 250,
) {
  /* the state which is immediately set and causes the ui to update */
  const [internalValue, setInternalValue] = useState(value)

  // synchronize the state with the props
  useEffect(() => {
    setInternalValue(value)
  }, [value])

  const debouncedCallback = useDebouncedCallback(callback, debounceDelay)

  return [
    internalValue,
    newValue => {
      setInternalValue(newValue)
      debouncedCallback(newValue)
    },
  ]
}
