import React, { useMemo, useState } from 'react'
import './consent-modal.css'
import Portal from '../portal'
import { marked } from 'marked'
import { useScrollWithShadow } from '../../hooks/useScrollWithShadows'

const DEFAULT_ERROR_TEXT =
  'U moet akkoord gaan met bovenstaande voorwaarden om verder te gaan'

export default function ConsentModal({
  id,
  isDisclaimer,
  hasDisclaimerContent,
  consentType,
  name,
  eulaMd,
  steps,
  acceptText,
  buttonText,
  errorText = DEFAULT_ERROR_TEXT,
  onAccept,
  onClose,
  readonly,
  headerImages,
}) {
  return (
    <Portal onClose={onClose} portalLayerId="consent">
      <ConsentModalContent
        key={id}
        id={id}
        isDisclaimer={isDisclaimer}
        hasDisclaimerContent={hasDisclaimerContent}
        consentType={consentType}
        name={name}
        eulaMd={eulaMd}
        steps={steps}
        acceptText={acceptText}
        buttonText={buttonText}
        errorText={errorText}
        onAccept={onAccept}
        readonly={readonly}
        headerImages={headerImages}
      />
    </Portal>
  )
}

function ConsentModalContent({
  id,
  isDisclaimer,
  hasDisclaimerContent,
  name,
  eulaMd,
  steps,
  acceptText,
  buttonText,
  errorText,
  onAccept,
  readonly,
  headerImages,
}) {
  const eulaHtml = useMarkdown(eulaMd)
  const [checked, setChecked] = useState(false)
  const [error, setError] = useState(false)
  const { boxShadow, onScrollHandler } = useScrollWithShadow()

  let srcSetJpg = ''
  if (headerImages?.jpg) {
    srcSetJpg = createSrcSet(headerImages.jpg)
  }

  let srcSetWebp = ''
  if (headerImages?.webp) {
    srcSetWebp = createSrcSet(headerImages.webp)
  }

  function createSrcSet(imageObject) {
    return Object.keys(imageObject).reduce((string, imageSize) => {
      string += `${imageObject[imageSize]} ${imageSize}w, `
      return string
    }, '')
  }

  function onSubmit(e) {
    e.preventDefault()

    if (!e.target.checkValidity()) {
      setError(true)
      return
    }

    onAccept?.(id, isDisclaimer, hasDisclaimerContent)
  }

  return (
    <div className="consent-modal">
      <header
        className={`${
          isDisclaimer
            ? 'consent-modal__disclaimer-header'
            : 'consent-modal__header'
        }`}
      >
        {isDisclaimer ? (
          <picture>
            {srcSetWebp && <source type="image/webp" srcSet={srcSetWebp} />}

            {srcSetJpg && <source type="image/jpeg" srcSet={srcSetJpg} />}

            <img
              className="app-hero__picture-img"
              src={headerImages?.jpg?.[Object.keys(headerImages?.jpg)[0]]}
              alt=""
            />
          </picture>
        ) : (
          <h1 className="consent-modal__title h5">{name}</h1>
        )}
        {steps?.total > 1 && !isDisclaimer && (
          <h6 className="consent-modal__sub-title">
            Stap {steps.current} van {steps.total}
          </h6>
        )}
      </header>

      <div
        className="consent-modal__eula"
        onScroll={onScrollHandler}
        style={{ boxShadow }}
        dangerouslySetInnerHTML={{ __html: eulaHtml }}
      />

      {!readonly && (
        <footer>
          <form onSubmit={onSubmit} className="consent-modal__form" noValidate>
            {acceptText && (
              <>
                <input
                  type="checkbox"
                  id="consent-tac"
                  checked={checked}
                  required
                  onChange={e => {
                    setChecked(e.target.checked)
                  }}
                />
                <label htmlFor="consent-tac">{acceptText}</label>
              </>
            )}

            {error && !checked && (
              <span
                role="alert"
                aria-live="assertive"
                className="consent-modal__error"
              >
                {errorText}
              </span>
            )}

            <button
              type="submit"
              className="consent-modal__accept button button--secondary"
            >
              {buttonText}
            </button>
          </form>
        </footer>
      )}
    </div>
  )
}

function useMarkdown(content) {
  return useMemo(() => {
    return marked.parse(content)
  }, [content])
}
