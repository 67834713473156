import React from 'react'
import { Image, Svg, Path } from '@react-pdf/renderer'
import { Box, Typography } from '../components'
import DefaultLayout from '../layouts/default'

export default function PandBodemLoketPage({
  debug,
  isGebouwpaspoort,
  userName,
  date,
  bodemloketMapImage,
  markerImage,
}) {
  const createPdfIconSoilInvestigation = color => (
    <Svg width="50" height="50">
      <Path
        transform="scale(0.75)"
        stroke="currentColor"
        fill={color}
        d="M31.22 1.84h-7.19V.65h-2v1.19H.8v2h21.23L22 31.29h2l.03-27.45h7.19v-2z"
      />
      <Path
        transform="scale(0.75)"
        stroke="currentColor"
        fill={color}
        d="M30 2v28H2V2h28m2-2H0v32h32V0Z"
      />
    </Svg>
  )

  const createPdfIconSoilInvestigationAngled = color => (
    <Svg width="50" height="50">
      <Path
        transform="scale(0.75)"
        stroke="currentColor"
        fill={color}
        d="M0 0v32h32V0H0Zm2 15.41L15.41 2h13.17L2 28.59V15.42Zm28-12v13.17L16.59 29.99H3.41L30 3.41ZM12.59 2 2 12.59V2h10.59Zm6.83 28 10.59-10.59V30H19.42Z"
      />
    </Svg>
  )
  const legend = [
    {
      title: 'Beschikbaarheid gegevens',
      items: [
        {
          icon: createPdfIconSoilInvestigationAngled('#668cff'),
          text: 'Geen gegevens in bodemloket, alternatief beschikbaar via bodemloket',
        },
        {
          icon: createPdfIconSoilInvestigationAngled('#f53a2f'),
          text: 'Geen gegevens in bodemloket',
        },
      ],
    },
    {
      title: 'Voortgang onderzoek',
      items: [
        {
          icon: createPdfIconSoilInvestigation('#668cff'),
          text: 'Gegevens aanwezig, status onbekend',
        },
        {
          icon: createPdfIconSoilInvestigation('#68ff68'),
          text: 'Saneringsactiviteit',
        },
        {
          icon: createPdfIconSoilInvestigation('#bf7aff'),
          text: 'Voldoende onderzocht/gesaneerd',
        },
        {
          icon: createPdfIconSoilInvestigation('#ff9c4f'),
          text: 'Onderzoek uitvoeren',
        },
        {
          icon: createPdfIconSoilInvestigation('#0001ffeb'),
          text: 'Historie bekend',
        },
      ],
    },
  ]
  return (
    <>
      <DefaultLayout
        pageName="Bodemloket"
        userName={userName}
        date={date}
        debug={debug}
        isGebouwpaspoort={isGebouwpaspoort}
      >
        <Box
          style={{
            width: '75%',
            marginRight: '2%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image
            style={{ height: 'auto', position: 'relative' }}
            src={bodemloketMapImage}
            allowDangerousPaths
            break
          ></Image>
        </Box>
        <Box style={{ display: 'flex' }}>
          {legend.map((section, sectionIndex) => (
            <Box key={sectionIndex}>
              <Typography
                style={{ fontWeight: 'bold', marginBottom: 6, marginTop: 4 }}
              >
                {section.title}
              </Typography>
              {section.items.map((item, itemIndex) => (
                <Box
                  key={itemIndex}
                  style={{ display: 'flex', flexDirection: 'row' }}
                >
                  <Box>{item.icon}</Box>
                  <Typography>{item.text}</Typography>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </DefaultLayout>
    </>
  )
}
