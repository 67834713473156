import React from 'react'
import DefaultLayout from '../layouts/default'
import {
  ObjectDetails,
  ObjectBroker,
  ObjectSurroundingMaps,
} from '../components'
import setTitleForObjectDetails from '../../set-title-object-details'

export default function BuildingDetailPage({
  item,
  userName,
  date,
  hasTransaction,
  debug,
  isKadasterPortfolioTransaction,
}) {
  return (
    <DefaultLayout
      pageName={setTitleForObjectDetails(item?.status)}
      userName={userName}
      date={date}
      debug={debug}
      hasTransaction={hasTransaction}
    >
      <ObjectDetails
        item={item}
        debug={debug}
        isKadasterPortfolioTransaction={isKadasterPortfolioTransaction}
      />
      <ObjectSurroundingMaps item={item} debug={debug} />
      <ObjectBroker item={item} debug={debug} />
    </DefaultLayout>
  )
}
