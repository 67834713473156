import { useState } from 'react'

export function useScrollWithShadow() {
  const [scrollTop, setScrollTop] = useState(0)
  const [scrollHeight, setScrollHeight] = useState(0)
  const [clientHeight, setClientHeight] = useState(0)

  const onScrollHandler = event => {
    setScrollTop(event.target.scrollTop)
    setScrollHeight(event.target.scrollHeight)
    setClientHeight(event.target.clientHeight)
  }

  function getBoxShadow() {
    const isBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 2
    const isTop = scrollTop === 0
    const isBetween = !isBottom && !isTop

    let boxShadow = 'none'
    const bottom = 'inset 0 -8px 5px -5px rgb(200 200 200 / 1)'

    if (isTop || isBetween) {
      boxShadow = bottom
    }
    return boxShadow
  }

  return { boxShadow: getBoxShadow(), onScrollHandler }
}
