import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useOnPopState from './use-on-pop-state'

export default function useExportEffect() {
  const [show, setShow] = useState(false)
  const history = useHistory()

  // Directly show modal when the location hash contains #export
  useOnPopState(() => {
    if (history.location.hash === '#export') {
      setShow(true)
    }
  }, true)

  // Listen for hash changes
  useEffect(() => {
    return history.listen(location => {
      if (location?.hash === '#export') {
        setShow(true)
      }
    })
  }, [history])

  return { show, setShow }
}
