import React from 'react'
import NotificationBar from '../notification-bar/notification-bar'
import './app-hero.css'

export default function AppHero({
  notification,
  userName,
  showLogout,
  headerImages,
  onLogout = () => {},
  searchbar,
  content = null,
  actions,
}) {
  let srcSetJpg = ''
  if (headerImages?.jpg) {
    srcSetJpg = createSrcSet(headerImages.jpg)
  }

  let srcSetWebp = ''
  if (headerImages?.webp) {
    srcSetWebp = createSrcSet(headerImages.webp)
  }

  let srcSetAvif = ''
  if (headerImages?.avif) {
    srcSetAvif = createSrcSet(headerImages.avif)
  }

  function createSrcSet(imageObject) {
    return Object.keys(imageObject).reduce((string, imageSize) => {
      string += `${imageObject[imageSize]} ${imageSize}w, `
      return string
    }, '')
  }

  return (
    <>
      {notification && <NotificationBar notification={notification} />}

      <header className="app-hero layout--grid">
        <div className="app-hero__search">
          <h2 className="h3 app-hero__content-title">
            Welkom{userName ? ` ${userName}!` : '!'}
          </h2>

          {searchbar && <div role="search">{searchbar}</div>}

          {content}

          {actions}

          {showLogout && (
            <button
              onClick={onLogout}
              className="app-hero__content-button body text-bold"
            >
              Uitloggen
            </button>
          )}
        </div>

        <picture className="app-hero__picture">
          {srcSetAvif && <source type="image/avif" srcSet={srcSetAvif} />}
          {srcSetWebp && <source type="image/webp" srcSet={srcSetWebp} />}
          {srcSetJpg && <source type="image/jpeg" srcSet={srcSetJpg} />}

          <img
            className="app-hero__picture-img"
            src={headerImages?.jpg?.[Object.keys(headerImages?.jpg)[0]]}
            alt=""
          />
        </picture>
      </header>
    </>
  )
}
