import { status } from '@brainbay/components/utils/constants'
import formatValue from '@brainbay/components/utils/format-value'

function getPriceDefinition(
  data,
  { voorvoegsel, confidential, price, condition },
) {
  // no price can be shown, because it's confidential..ssssshhhh
  if (data[confidential]) {
    return 'vertrouwelijk'
  }
  if (data?.transactie?.koopprijs?.kadasterPrijsRange) {
    return data?.transactie?.koopprijs?.kadasterPrijsRange
  }
  // price can not be shown. Voorvoegsel tells us why not
  if (typeof data[price] === 'undefined' && data[voorvoegsel]) {
    return data[voorvoegsel]
  }

  if (data[price.van] && data[price.tot]) {
    const priceFrom = formatValue(data[price.van], {
      format: 'costs',
      condition: data[condition],
    })

    return `${voorvoegsel} ${priceFrom}`
  }

  if (data.transactie) {
    return formatValue(data.transactie[price], {
      format: 'costs',
      condition: data.transactie[condition],
    })
  }

  return formatValue(data[price], {
    format: 'costs',
    condition: data[condition],
  })
}

function formattedPriceObject(data, props, term) {
  return {
    term,
    definition: getPriceDefinition(data, props),
    perM2: rentedOrSoldPrice(data, term.toLowerCase()),
  }
}

const saleProps = {
  confidential: 'koopprijsVertrouwelijk',
  price: 'koopprijs',
  condition: 'koopconditie',
  voorvoegsel: 'koopprijsVoorvoegsel',
}

const transactionProps = {
  confidential: 'transactieVertrouwelijk',
  price: 'transactiePrijs',
  condition: 'transactieConditie',
}

const rentProps = {
  confidential: 'huurprijsVertrouwelijk',
  price: 'huurprijs',
  condition: 'huurconditie',
  voorvoegsel: 'huurprijsVoorvoegsel',
}

const rentPropsFromAndTo = {
  confidential: 'huurprijsVertrouwelijk',
  price: {
    van: 'huurprijsVan',
    tot: 'huurprijsTot',
  },
  condition: 'huurconditie',
  voorvoegsel: 'vanaf',
}

const rentedOrSoldPrice = (data, term) => {
  switch (term) {
    case 'transactieprijs':
      if (data?.transactie?.koopprijs?.perM2) {
        return formatValue(data.transactie.koopprijs.perM2, 'per-meter-squared')
      }

      if (data?.transactie?.huurprijs?.perM2Jaar) {
        return formatValue(data.transactie.huurprijs.perM2Jaar, {
          format: 'costs',
          condition: 'per vierkante meter per jaar',
        })
      }
      break
    case 'vraagprijs':
      if (data?.koopprijsPerM2) {
        return formatValue(data.koopprijsPerM2, 'per-meter-squared')
      }
      break
    case 'huurprijs':
      if (data?.huurprijsPerM2Jaar) {
        return formatValue(data.huurprijsPerM2Jaar, {
          format: 'costs',
          condition: 'per vierkante meter per jaar',
        })
      }
      break
    default:
      return ''
  }
}

export default function useObjectPrices(data) {
  let prices = []

  switch (data?.status) {
    case status.VERKOCHT:
    case status.VERHUURD:
    case status.GEVEILD:
    case status.VERKOCHT_BIJ_INSCHRIJVING:
    case status.VERLENGING:
      prices.push(
        formattedPriceObject(data, transactionProps, 'Transactieprijs'),
      )
      break
    case status.TE_KOOP:
    case status.TE_HUUR:
    case status.VERKOCHT_ONDER_VOORBEHOUD:
    case status.VERHUURD_ONDER_VOORBEHOUD:
    case status.VEILING:
    case status.TE_KOOP_EN_TE_HUUR:
      if (
        [
          status.TE_KOOP,
          status.VERKOCHT_ONDER_VOORBEHOUD,
          status.VEILING,
          status.TE_KOOP_EN_TE_HUUR,
        ].includes(data.status)
      ) {
        prices.push(formattedPriceObject(data, saleProps, 'Vraagprijs'))
      }

      if (
        [
          status.TE_HUUR,
          status.VERHUURD_ONDER_VOORBEHOUD,
          status.TE_KOOP_EN_TE_HUUR,
        ].includes(data.status)
      ) {
        if (data.huurprijsVan && data.huurprijsTot) {
          prices.push(
            formattedPriceObject(data, rentPropsFromAndTo, 'Huurprijs'),
          )
        } else {
          prices.push(formattedPriceObject(data, rentProps, 'Huurprijs'))
        }
      }
      break

    case status.INGETROKKEN:
    case status.ONDER_OPTIE:
    case status.ONDER_BOD:
      if (data.isKoop) {
        prices.push(formattedPriceObject(data, saleProps, 'Vraagprijs'))
      }

      if (data.isHuur) {
        prices.push(formattedPriceObject(data, rentProps, 'Huurprijs'))
      }
      break

    default:
      break
  }
  return prices
}
