import React from 'react'
import DefaultLayout from '../layouts/default'
import { Typography } from '../components'

export default function SectorDescriptionPage({
  item,
  userName,
  date,
  debug,
  isGebouwpaspoort,
}) {
  const { pand, geselecteerdeAdres } = item || {}

  let verblijfsobject = pand?.verblijfsobjecten.find(element => {
    return (
      element?.hoofdAdres.nummeraanduidingId ===
        geselecteerdeAdres.nummeraanduidingId ||
      element?.nevenAdressen.find(
        address =>
          address.nummeraanduidingId === geselecteerdeAdres.nummeraanduidingId,
      )
    )
  })
  return (
    <>
      <DefaultLayout
        pageName="Sector Beschrijving"
        userName={userName}
        date={date}
        debug={debug}
        isGebouwpaspoort={isGebouwpaspoort}
      >
        <Typography
          style={{ fontSize: 11, lineHeight: 2, color: '#000', marginTop: 15 }}
          debug={debug}
        >
          {verblijfsobject?.sectorBeschrijving}
        </Typography>{' '}
      </DefaultLayout>
    </>
  )
}
