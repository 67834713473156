import React from 'react'
import ResponsiveImage from '../responsive-image'
import StatusLabel from '../status-label'
import { status as constantsStatus } from '../../utils/constants'
import { apiHost } from '../../utils/environment-vars'
import './object-list-item-thumb.css'

export default function ObjectListItemThumb({ address, status, mediaGuid }) {
  const hasStatus = status !== undefined && status !== constantsStatus.ONBEKEND
  const host = !mediaGuid && `${apiHost()}/streetview`
  const imageId = mediaGuid || address

  return (
    <div className="object-list-item-thumb">
      {hasStatus && <StatusLabel status={status} />}

      <ResponsiveImage
        className="object-list-item-thumb__image"
        imageId={imageId}
        host={host}
        alt="Hoofd foto van het object"
      />
      <p className="object-list-item-thumb__title body text-bold">{address}</p>
    </div>
  )
}
