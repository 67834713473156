import React, { useEffect, useMemo, useState } from 'react'
import ResponsiveImage from '@brainbay/components/components/responsive-image'
import StatusLabel from '@brainbay/components/components/status-label'
import formatAddressBusiness from '../../utils/format-address-business'
import { status as constantsStatus } from '@brainbay/components/utils/constants'
import { apiHost } from '@brainbay/components/utils/environment-vars'
import useApiCall from '../../utils/use-api-call'
import { Popup } from 'react-map-gl'

export default function MapboxPopup({ objectGuids, geometry, hide }) {
  const { get } = useApiCall()
  const [data, setData] = useState(null)

  useEffect(() => {
    if (objectGuids) {
      get(`objects/by-businessguid/${objectGuids}`).then(data => {
        setData(data)
      })
    }
    return () => {
      setData(null)
    }
  }, [get, objectGuids])

  const address = useMemo(() => {
    if (data) {
      return formatAddressBusiness({
        street: data.straatnaam,
        houseNumber: data.huisnummer,
        houseNumberSuffix: data.huisnummertoevoeging,
        postalCode: data.postcode,
        city: data.woonplaats,
        municipality: data.gemeente,
      })
    }

    return ''
  }, [data])

  const status = data?.status
  const host = !data?.mediaGuid && `${apiHost()}/streetview`
  const imageId = data?.mediaGuid || address

  return (
    <Popup
      longitude={geometry.coordinates[0]}
      latitude={geometry.coordinates[1]}
      closeButton={false}
      dynamicPosition
      className={hide ? 'mapbox__popup--hidden' : ''}
    >
      <div className="mapbox-popup">
        {status !== undefined && status !== constantsStatus.ONBEKEND && (
          <StatusLabel status={status} />
        )}

        <ResponsiveImage
          className="mapbox-popup__image"
          imageId={imageId}
          host={host}
          alt="Hoofd foto van het object"
          isLoading={!data}
        />
        <p className="mapbox-popup__title body text-bold">{address}</p>
      </div>
    </Popup>
  )
}
