import React, { forwardRef, useEffect, useState } from 'react'
import Panel from '@brainbay/components/components/panel'
import DataMap from '@brainbay/components/components/data-map'
import Icon from '@brainbay/components/components/icon'
import SourceLabel from '@brainbay/components/components/source-label'
import { ReactComponent as IconSoilInvestigationAngled } from '@brainbay/components/_assets/svg/icon-soil-investigation-angled.svg'
import { ReactComponent as IconSoilInvestigation } from '@brainbay/components/_assets/svg/icon-soil-investigation.svg'
import './building-soil-investigation.css'

const BuildingSoilInvestigationMoreInfo = () => (
  <span>
    Geen gegevens in bodemloket, alternatief beschikbaar{' '}
    <a
      href="https://www.bodemloket.nl/kaart"
      target="_blank"
      rel="noopener noreferrer"
    >
      via bodemloket
    </a>
  </span>
)

const BuildingSoilInvestigationLegend = () => {
  const legend = {
    availableData: {
      title: 'Beschikbaarheid gegevens',
      items: [
        {
          icon: <IconSoilInvestigationAngled />,
          text: <BuildingSoilInvestigationMoreInfo />,
          color: 'blue',
        },
        {
          icon: <IconSoilInvestigationAngled />,
          text: 'Geen gegevens in bodemloket',
          color: 'red',
        },
      ],
    },
    progressData: {
      title: 'Voortgang onderzoek',
      items: [
        {
          icon: <IconSoilInvestigation />,
          text: 'Gegevens aanwezig, status onbekend',
          color: 'blue',
        },
        {
          icon: <IconSoilInvestigation />,
          text: 'Saneringsactiviteit',
          color: 'green',
        },
        {
          icon: <IconSoilInvestigation />,
          text: 'Voldoende onderzocht/gesaneerd',
          color: 'purple',
        },
        {
          icon: <IconSoilInvestigation />,
          text: 'Onderzoek uitvoeren',
          color: 'orange',
        },
        {
          icon: <IconSoilInvestigation />,
          text: 'Historie bekend',
          color: 'cyan',
        },
      ],
    },
  }

  return (
    <div className="building-soil-investigation__legend" id="legendElement">
      {legend &&
        Object.keys(legend).map(key => (
          <div className="building-soil-investigation__legend-item" key={key}>
            <h3 className="building-soil-investigation__legend-title text-bold">
              {legend[key].title}
            </h3>
            {legend[key].items.map(item => (
              <div
                key={item.text}
                className="building-soil-investigation__legend-content"
              >
                <Icon
                  size="small"
                  className={`building-soil-investigation__icon building-soil-investigation__icon--${item.color}`}
                >
                  {item.icon}
                </Icon>
                {item.text}
              </div>
            ))}
          </div>
        ))}
    </div>
  )
}

const BuildingSoilInvestigation = forwardRef(function BuildingSoilInvestigation(
  { data },
  ref,
) {
  const [building, setBuilding] = useState({})
  const [address, setAddress] = useState({})
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    if (data && !data.loading) {
      const { pand, geselecteerdeAdres } = data || {}

      setBuilding(pand)
      setAddress(geselecteerdeAdres)
      setHasLoaded(true)
    }
  }, [data])

  const mapboxConfig = {
    scrollZoom: false,
  }

  return (
    <div ref={ref}>
      <Panel
        title="Bodemloket"
        tag="section"
        collapsable
        divider
        isOpenByDefault
        className="building-maps"
      >
        <div className="building-soil-investigation__content">
          {hasLoaded && (
            <>
              <BuildingSoilInvestigationLegend />
              <div id="bodemloketMapElement">
                <DataMap
                  longitude={address?.longitude}
                  latitude={address?.latitude}
                  mapboxConfig={mapboxConfig}
                  geoJson={building?.pandPolygon}
                  mapBoxStyle="mapbox://styles/mapbox/light-v11"
                  width="100%"
                  height="500px"
                  zoom={16}
                  showControls
                  showSoilInvestigation
                  showKadaster
                  showMarker
                />
              </div>
            </>
          )}
        </div>
        <div className="building-soil-investigation_source-label">
          <SourceLabel source="Bodemloket" prefixSource="Bron" />
        </div>
      </Panel>
    </div>
  )
})
export default BuildingSoilInvestigation
