import React from 'react'
import './expandable-footer.css'

const ExpandableFooter = ({
  showFooter,
  showNumberOfItems,
  itemsLength,
  onShowLess,
  onShowMore,
  showLessButtonCondition,
}) => {
  if (!showFooter) {
    return null
  }

  return (
    <footer className="expandable-footer__section">
      <div className="expandable-footer__section-buttons">
        {showNumberOfItems > showLessButtonCondition && (
          <button
            className="button button--x-small expandable-footer__section-buttons-toggle"
            onClick={onShowLess}
          >
            Toon minder
          </button>
        )}
        {showNumberOfItems < itemsLength && (
          <button
            className="button button--x-small expandable-footer__section-buttons-toggle"
            onClick={onShowMore}
          >
            Toon meer
          </button>
        )}
      </div>
    </footer>
  )
}

export default ExpandableFooter
