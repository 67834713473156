import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import fileSaver from 'file-saver'
import Modal from '@brainbay/components/components/modal'
import log from '@brainbay/components/utils/log'
import { trackEvent } from '@brainbay/components/utils/ga-events'
import { gaCategories } from '@brainbay/components/utils/constants'
import useExportEffect from '../../utils/use-export-effect'
import ExportButton from '../export-button'
import { post } from '../../utils/api-data'

import convertToCsv from './convert-to-csv'
import './export-search-results.css'

export default function ExportSearchResult({
  query,
  filterValues,
  parentLocation,
}) {
  const [exporting, setExporting] = useState(false)
  const [totalResults, setTotalResults] = useState('250')
  const [format, setFormat] = useState('csv-excel')
  const [errorMessage, setErrorMessage] = useState('')

  const history = useHistory()
  const location = useLocation()
  const { show, setShow } = useExportEffect()

  const hideModal = () => setShow(false)
  const isExporting = () => setExporting(true)
  const doneExporting = () => setExporting(false)
  const onChangeTotalResults = ({ target }) => setTotalResults(target.value)
  const updateValue = updater => event => updater(event.target.value)
  const removeExportHash = () => {
    history.replace({
      ...location,
      hash: '',
    })
  }

  const showModal = () => {
    history.replace({
      ...location,
      hash: '#export',
    })
    trackEvent(
      'clicked_open_modal_export',
      gaCategories.EXPORT,
      'Export modal opened',
    )
  }

  function cancel() {
    hideModal()
    doneExporting()
    removeExportHash()
    setFormat('csv-excel')
    setErrorMessage('')
    setTotalResults('250')
  }

  function fetchData() {
    return post({
      path: 'objects/by-search',
      body: {
        query,
        skip: 0,
        take: Number(totalResults),
        filter: filterValues,
        location: parentLocation,
      },
    })
  }

  function saveData(results) {
    let bom = ''
    let data
    let type
    let extension

    switch (format) {
      case 'csv-excel':
        bom = 'sep=, \n'
        data = convertToCsv(results.data)
        type = 'text/csv;charset=utf-8'
        extension = 'csv'
        break
      case 'csv':
        data = convertToCsv(results.data)
        type = 'text/csv;charset=utf-8'
        extension = 'csv'
        break
      default:
        throw new Error('Format unknown')
    }

    const blob = new Blob([bom, data], { type })
    fileSaver.saveAs(blob, `export.${extension}`)
  }

  function handleError(error) {
    if (error.message === 'Format unknown') {
      log.error('Could not save data', error.message)
      setErrorMessage(
        'We konden het gekozen formaat niet opslaan. Kies een ander formaat en probeer het opnieuw',
      )
    } else if (error.status >= 400) {
      log.error(`Could not process request (${error.status})`, error.message)
      setErrorMessage(
        'Er ging iets mis met het opvragen van de data. Probeert u het later nog eens',
      )
    } else {
      log.error('Unknown error')
      setErrorMessage('Er gaat iets mis, probeert u het later nog eens')
    }
  }

  async function handleOnExport() {
    isExporting()
    setErrorMessage('')

    trackEvent(
      'clicked_export_csv_button',
      gaCategories.EXPORT,
      'Export CSV from searchresults',
    )

    await fetchData().then(saveData).catch(handleError)

    doneExporting()
  }

  // Display error messages when totalResults have invalid numbers
  useEffect(() => {
    if (totalResults > 250) {
      setErrorMessage(
        'Er kunnen niet meer dan 250 resultaten opgevraagd worden',
      )
    } else if (totalResults < 1) {
      setErrorMessage('Er moet minimaal 1 resultaat opgevraagd worden')
    } else {
      setErrorMessage('')
    }
  }, [totalResults])

  return (
    <div className="export-search-results">
      <ExportButton onClick={showModal} />

      {show && (
        <Modal onClose={cancel} title="Zoekresultaten exporteren">
          {errorMessage !== '' && (
            <p className="export-search-results__error-message">
              {errorMessage}
            </p>
          )}
          <form className="export-search-results__form">
            <label htmlFor="export-search-results__input">
              Aantal zoekresultaten (Maximaal 250)
            </label>
            <input
              id="export-search-results__input"
              type="number"
              max="250"
              min="0"
              value={totalResults}
              onChange={onChangeTotalResults}
              disabled={exporting}
            />

            <label htmlFor="export-search-results__format">
              In welk formaat wilt u exporteren?
            </label>
            <select
              id="export-search-results__format"
              value={format}
              onChange={updateValue(setFormat)}
              disabled={exporting}
            >
              <option value="csv-excel">CSV (Excel)</option>
              <option value="csv">CSV</option>
            </select>
          </form>

          <div className="export-search-results__action-buttons">
            <button
              onClick={handleOnExport}
              className={`button ${
                exporting ? 'button--loading' : 'button--primary'
              }`.trim()}
              disabled={exporting || totalResults > 250 || totalResults < 1}
            >
              Exporteer
            </button>
            <button className="button" disabled={exporting} onClick={cancel}>
              Annuleren
            </button>
          </div>
        </Modal>
      )}
    </div>
  )
}
