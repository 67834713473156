import React, { useEffect, useMemo, useRef, useState } from 'react'
import Icon from '../../../icon'
import { ReactComponent as IconClose } from '../../../../_assets/svg/icon-close.svg'
import { useDebouncedCallback } from '../../../../utils/use-debounce'
import supportsInputDate from '../../../../utils/supports-input-date'
import log from '../../../../utils/log'
import Label from '../label'
import './date-input.css'

export default function DateInput({ control, filters, groupLabel, onChange }) {
  const inputRef = useRef(null)
  const [internalValue, setInternalValue] = useState('')
  const maxDate = new Date().toISOString().split('T')[0]

  const externalValue = useMemo(() => {
    return filters?.[control.name] || ''
  }, [control.name, filters])

  const onChangeDebounced = useDebouncedCallback(
    event => {
      let cursorLocation
      try {
        cursorLocation = inputRef?.current?.selectionStart
      } catch (error) {}
      const eventValue = event.target.value ?? ''
      setInternalValue(eventValue.replace(/\//g, '-'))
      setTimeout(() => {
        if (document.activeElement === inputRef.current) {
          try {
            inputRef.current.setSelectionRange(cursorLocation, cursorLocation)
          } catch (error) {}
        }
      }, 10)
      if (validInput(eventValue)) {
        onChange(event)
      }
    },
    supportsInputDate() ? 0 : 1000,
  )

  function validInput(value) {
    const [year = '', month = '', day = ''] = value
      .replace(/\//g, '-')
      .split('-')
      .map(item => item.replace(/^0+/, ''))

    if (
      (year.length === 4 &&
        (day.length === 1 || day.length === 2) &&
        (month.length === 1 || month.length === 2)) ||
      value === ''
    ) {
      return true
    }
  }

  useEffect(() => {
    setInternalValue(externalValue)
  }, [externalValue])

  function handleOnChange(event) {
    event.persist()
    setInternalValue(event?.target?.value || '')
    onChangeDebounced(event)
  }

  function handleOnBlur() {
    if (!validInput(internalValue)) {
      log.error('Invalid date format', internalValue)
    }
  }

  return (
    <>
      <Label
        htmlFor={`search-filters__control--${control.name}`}
        groupLabel={groupLabel}
        className="body-small"
        control={control}
      />
      <div className="date-input__wrapper">
        <input
          ref={inputRef}
          id={`search-filters__control--${control.name}`}
          className="date-input__input"
          name={control.name}
          type="date"
          value={internalValue}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          max={maxDate}
        />
        <button
          className="date-input__input-clear"
          onClick={handleOnChange}
          type="button"
        >
          <span className="sr-only">Maak {groupLabel} leeg</span>
          <Icon size="nano">
            <IconClose />
          </Icon>
        </button>
      </div>
    </>
  )
}
