import React, { useRef } from 'react'
import formatValue from '../../../../utils/format-value'
import EnergyLabel from '../../../energy-label'
import './checkbox.css'

const labelTextContextHelper = (content, label, value) => {
  if (typeof content === 'function') {
    return content(label || value)
  } else {
    return label || value
  }
}

const RecursiveCheckbox = ({
  control,
  option,
  labelContent,
  onChange,
  facet,
  filters,
  filterCatalog,
  subFilter,
  level = 0,
}) => {
  const checkboxRef = useRef()

  const facetValue = formatValue(
    facet?.[control.name]?.[option.value || option.label],
    'decimal',
  )

  const labelTextContent = labelTextContextHelper(
    labelContent,
    option.label,
    option.value,
  )

  const hasChildren =
    option?.subControl?.options && option?.subControl?.options?.length > 0

  const filterValueForControl = filters?.[control.name]
  const filterValues = (filters?.[control.name] || '').split(',')
  const subFilterValues = level > 0 && (filters?.[subFilter] || '').split(',')

  const controlName = control.name ? control.name : control
  const isSubLevel = level > 0

  const checked = (value, selected) => {
    if (level !== 0) {
      return Boolean(
        subFilterValues.includes(value) || (!filterValueForControl && selected),
      )
    }
    return Boolean(
      filterValues.includes(value) || (!filterValueForControl && selected),
    )
  }

  const onCheckboxCheck = e => {
    /*
      Also uncheck descendant checkboxes
    */
    if (!e.target.checked && checkboxRef.current) {
      const descendantCheckboxes = checkboxRef.current.querySelectorAll(
        'input[type=checkbox]',
      )
      descendantCheckboxes.forEach(checkbox => {
        checkbox.checked = false
      })
    }
    onChange()
  }

  return (
    <React.Fragment key={option.value}>
      <input
        id={`search-filters__control--${controlName}-${option.value}`}
        type="checkbox"
        value={option.value}
        onChange={onCheckboxCheck}
        name={controlName}
        checked={checked(option.value, option.selected)}
      />
      <label
        htmlFor={`search-filters__control--${controlName}-${option.value}`}
        className={isSubLevel ? `checkbox__control-sub-${level}` : ''}
        aria-label={option['sr-label']}
      >
        <span className="checkbox__label-text">
          {typeof labelContent === 'function' ? (
            labelTextContent
          ) : (
            <span
              className={`${
                facetValue === '0' || facetValue === '---'
                  ? 'checkbox__facet-0'
                  : ''
              }`}
            >
              {labelTextContent}
            </span>
          )}
          <span className="checkbox__facet">{facetValue}</span>
        </span>
      </label>
      {hasChildren && (
        <div ref={checkboxRef}>
          {option.subControl.options.map(subOption => (
            <div
              key={subOption.value}
              className={`checkbox__sub-option ${
                checked(option.value, option.selected)
                  ? 'checkbox__sub-option--selected'
                  : ''
              }`}
            >
              <RecursiveCheckbox
                key={subOption.value}
                option={subOption}
                control={option.subControl}
                subFilter={option.subControl.name}
                onChange={onChange}
                filters={filters}
                filterCatalog={filterCatalog}
                facet={facet}
                level={1}
              />
            </div>
          ))}
        </div>
      )}
    </React.Fragment>
  )
}

function CheckBox({
  control,
  filters,
  filterCatalog,
  facet,
  groupLabel,
  onChange,
  labelContent,
}) {
  return (
    <fieldset className="checkbox filters__fieldset">
      <legend className={`${groupLabel === control.label ? 'sr-only' : ''}`}>
        {control.label || groupLabel}
      </legend>
      {control?.options.map(option => (
        <RecursiveCheckbox
          key={option.value}
          option={option}
          control={control}
          filters={filters}
          onChange={onChange}
          facet={facet}
          labelContent={labelContent}
          filterCatalog={filterCatalog}
        />
      ))}
    </fieldset>
  )
}

export function getActiveFilterLabels(control, filters) {
  if (!filters[control.name]) {
    return []
  }

  const optionsByValue = new Map(
    control.options.map(option => [option.value, option]),
  )

  return filters[control.name].split(',').reduce((acc, optionValue) => {
    const option = optionsByValue.get(optionValue)

    acc = [...acc, { label: option.label, control, value: optionValue }]

    if (option.subControl) {
      acc = [...acc, ...getActiveFilterLabels(option.subControl, filters)]
    }

    return acc
  }, [])
}

export function removeFilterValues(params, control, optionValue) {
  let paramValue = ''

  if (optionValue) {
    paramValue = params[control.name]
      .split(',')
      .filter(item => item !== optionValue)
      .join(',')
  }

  if (paramValue) {
    params[control.name] = paramValue
  } else {
    delete params[control.name]
  }

  const option = control.options.find(option => option.value === optionValue)

  if (option && option.subControl) {
    params = removeFilterValues(params, option.subControl)
  }

  return params
}

export default function CheckBoxWrapper(props) {
  if (props.control.name === 'energielabel') {
    return (
      <CheckBox
        {...props}
        labelContent={value => <EnergyLabel isSmall label={value} />}
      />
    )
  } else {
    return <CheckBox {...props} />
  }
}
