import React from 'react'
import { Image, Path, Svg } from '@react-pdf/renderer'
import { Box } from './index'

/** ImageBox pdf component
 * Note: ImageBox placeholder image does not properly support "auto" keyword for width/height.
 *       Set a specific width/height for the best result.
 * @typedef {Object} ImageBoxProps
 * @param src
 * @param source
 * @param style {Object} - style object for the ImageBox (avoid use of "auto" keyword for width/height)
 * @param allowDangerousPaths
 * @param debug
 * @returns {JSX.Element}
 * @constructor
 */
export default function ImageBox({
  src,
  source,
  style,
  allowDangerousPaths,
  debug,
}) {
  style = { width: 300, height: 300, ...style }
  return (
    <Box
      style={{
        height: style.height,
        width: style.width,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f3f3f3',
      }}
      debug={debug}
    >
      <Svg
        style={{
          position: 'absolute',
          top: '15%',
          left: '15%',
          height: '70%',
          width: '70%',
        }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
      >
        <Path
          fill="#d1d1d1"
          d="M76 11a3 3 0 0 0-2.4 1.2l-52 72a3 3 0 1 0 4.8 3.5l5-6.7H94a3 3 0 0 0 3-3V22a3 3 0 0 0-3-3H76l2.4-3.3a3 3 0 0 0-2.3-4.8zM5.8 19A3 3 0 0 0 3 22v56a3 3 0 0 0 3 3h9a3 3 0 1 0 0-6H9v-6.8l20-13.6 2 1.4a3 3 0 0 0 3.4-5l-3.7-2.5a3 3 0 0 0-2-.5 3 3 0 0 0-1.4.5L9 61V25h46.1a3 3 0 1 0 0-6H6a3 3 0 0 0-.3 0zm66 6H91v29.3L75 40.7a3 3 0 0 0-2.3-.7 3 3 0 0 0-1.6.7L47.8 60.2l-1-.7 25-34.5zM30 28a8 8 0 0 0-8 8 8 8 0 0 0 8 8 8 8 0 0 0 8-8 8 8 0 0 0-8-8zm0 6a2 2 0 0 1 2 2 2 2 0 0 1-2 2 2 2 0 0 1-2-2c0-1.1.9-2 2-2zm43 13l18 15.2V75H35.7l7.6-10.6 3 2a3 3 0 0 0 3.6-.1L73 46.9z"
        />
      </Svg>
      <Image
        src={src || source}
        style={{
          objectFit: 'cover',
          ...style,
          height: '100%',
          width: '100%',
        }}
        allowDangerousPaths={allowDangerousPaths}
        debug={debug}
      ></Image>
    </Box>
  )
}
