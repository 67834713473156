import React from 'react'
import formatValue from '../../../../utils/format-value'
import useDebouncedValue from '../../../../utils/use-debounced-value'
import Label from '../label'
import './range-input.css'

export function hasValue(control, filters) {
  return (
    filters[control.min.name] !== undefined ||
    filters[control.max.name] !== undefined
  )
}

export function getActiveFilterLabels(control, filters) {
  const minValue = filters[control.min.name]
  const maxValue = filters[control.max.name]

  const formatter = value => formatValue(value, control.valueFormatter)

  if (maxValue && minValue) {
    return [
      {
        label: `${formatter(minValue)} - ${formatter(maxValue)} (${
          control.label
        })`,
        control,
      },
    ]
  }

  if (maxValue) {
    return [{ label: `tot ${formatter(maxValue)} (${control.label})`, control }]
  }

  if (minValue) {
    return [
      { label: `vanaf ${formatter(minValue)} (${control.label})`, control },
    ]
  }

  return []
}

export function removeFilterValues(params, control, _optionValue) {
  delete params[control.min.name]
  delete params[control.max.name]
}

export default function RangeInput({
  control,
  filters,
  groupLabel,
  onChange,
  debounceDelay = 500,
}) {
  const min = control.min
  const max = control.max
  const [minFilterValue, setMinFilterValue] = useDebouncedValue(
    filters?.[min.name] || '',
    onChange,
    debounceDelay,
  )
  const [maxFilterValue, setMaxFilterValue] = useDebouncedValue(
    filters?.[max.name] || '',
    onChange,
    debounceDelay,
  )

  return (
    <>
      <fieldset
        className="range-input__fieldset filters__fieldset"
        name={control.name}
      >
        <legend aria-label={control['sr-label'] || control.label}>
          {control.label !== groupLabel && control.label}
        </legend>
        <div className="range-input__wrapper">
          <div className="range-input__input-group">
            <Label
              className="body-small"
              htmlFor={`search-filters__control--${min.name}`}
              groupLabel={groupLabel}
              control={min}
            />
            <input
              id={`search-filters__control--${min.name}`}
              className="range-input__input"
              name={min.name}
              type="number"
              value={minFilterValue}
              onChange={e => setMinFilterValue(e.target.value)}
            />
          </div>
          <div className="range-input__input-group">
            <Label
              className="body-small"
              htmlFor={`search-filters__control--${max.name}`}
              groupLabel={groupLabel}
              control={max}
            />
            <input
              id={`search-filters__control--${max.name}`}
              className="range-input__input"
              name={max.name}
              type="number"
              value={maxFilterValue}
              onChange={e => setMaxFilterValue(e.target.value)}
            />
          </div>
        </div>
      </fieldset>
    </>
  )
}
