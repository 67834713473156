import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import CollapseButton from '../collapse-button'

import slugify from '../../utils/slugify'
import { trackEvent } from '../../utils/ga-events'
import { gaCategories } from '../../utils/constants'

import './panel.css'

export default function Panel({
  action,
  children,
  className,
  collapsable,
  isOpen,
  isOpenByDefault = true,
  divider = true,
  headerContent = null,
  fullBleed,
  hiddenTitle = false,
  links,
  moreInfoUrl,
  moreInfoLabel = 'Meer info',
  showOverflow = false,
  tag = 'section',
  title,
  titleTag = 'h2',
}) {
  const PanelTag = tag
  const TitleTag = titleTag
  const titleSlug = slugify(title || '')
  const [panelIsOpen, setpanelIsOpen] = useState(isOpenByDefault)

  useEffect(() => {
    if (typeof isOpen === 'boolean') {
      setpanelIsOpen(isOpen)
    }
  }, [isOpen])

  function togglePanel() {
    setpanelIsOpen(!panelIsOpen)
  }

  const panelHeaderClassnames = classNames({
    panel__header: true,
    'panel__header--two-columns': links || action || headerContent,
    'sr-only': hiddenTitle,
  })

  return (
    <PanelTag
      className={`panel${fullBleed ? ' panel--full-bleed' : ''} ${
        className ? ` ${className}` : ''
      } ${showOverflow ? 'panel--no-overflow' : ''}`.trim()}
      {...(titleSlug ? { 'aria-labelledby': titleSlug } : {})}
    >
      {title && (
        <header className={panelHeaderClassnames}>
          <div className="panel__header-column">
            <TitleTag className="body-big" id={titleSlug}>
              {title}
            </TitleTag>
            {moreInfoUrl && (
              <Link
                className="panel__header-link print-excluded"
                to={moreInfoUrl}
                onClick={() => {
                  trackEvent(
                    `visit_${title}_from_panel`,
                    gaCategories.PAGES,
                    `Opened ${title} for object from "Meer info"`,
                  )
                }}
              >
                {moreInfoLabel}
              </Link>
            )}
          </div>

          <div className="panel__header-column">
            {links && (
              <div className="panel__header-links print-excluded">{links}</div>
            )}
            {action && <div className="print-excluded">{action}</div>}

            {collapsable && (
              <div className="print-excluded">
                <CollapseButton
                  title={title}
                  isOpen={panelIsOpen}
                  onButtonClicked={togglePanel}
                />
              </div>
            )}

            {headerContent}
          </div>
        </header>
      )}

      {((divider && !collapsable) ||
        (divider && collapsable && panelIsOpen)) && (
        <hr className="panel__divider" />
      )}

      {(!collapsable || (collapsable && panelIsOpen)) && (
        <div className="panel__content body">{children}</div>
      )}
    </PanelTag>
  )
}
