import React, { useState } from 'react'
import Icon from '../icon'
import { ReactComponent as IconChevronBack } from '../../_assets/svg/icon-chevron-back.svg'
import { ReactComponent as IconInfo } from '../../_assets/svg/icon-info.svg'
import { trackEvent } from '../../utils/ga-events'
import { gaCategories } from '../../utils/constants'
import slugify from '../../utils/slugify'
import './print-setting-form.css'

export default function PrintSettingsForm({
  onFormSubmit,
  onDownloadDocx,
  onDownloadJson,
  onPrintPdf,
  disablePrintButton = false,
  disableExportPdfButton = false,
  hideRemarks = false,
  objectSections = [],
  excludedPrintItems = [],
  onChangeCheckbox = () => {},
  warningMessage,
  showSmallerSections = false,
}) {
  const [subSectionOpen, setSubSectionOpen] = useState(true)
  const [isLoadingPrint, setIsLoadingPrint] = useState(false)
  const [isLoadingPdf, setIsLoadingPdf] = useState(false)
  const [isLoadingDocx, setIsLoadingDocx] = useState(false)
  const [isLoadingJson, setIsLoadingJson] = useState(false)

  function handleFormSubmit(event) {
    event.preventDefault()

    trackEvent(
      'clicked_download_pdf_button',
      gaCategories.EXPORT,
      `Create pdf with excluded from export: ${excludedPrintItems}`,
    )

    setIsLoadingPdf(true)
    onFormSubmit(event).then(() => {
      setIsLoadingPdf(false)
    })
  }

  function downloadDocx() {
    trackEvent(
      'clicked_download_word_button',
      gaCategories.EXPORT,
      `Create docx word file with excluded from export: ${excludedPrintItems}`,
    )

    setIsLoadingDocx(true)
    onDownloadDocx().then(() => {
      setIsLoadingDocx(false)
    })
  }

  function downloadJson() {
    trackEvent(
      'clicked_download_json_button',
      gaCategories.EXPORT,
      `Create json file with excluded from export: ${excludedPrintItems}`,
    )

    setIsLoadingJson(true)
    onDownloadJson().then(() => {
      setIsLoadingJson(false)
    })
  }

  function printPage() {
    trackEvent(
      'clicked_print_page_button',
      gaCategories.EXPORT,
      `Print page with excluded from export: ${excludedPrintItems}`,
    )

    if (onPrintPdf.constructor.name === 'AsyncFunction') {
      setIsLoadingPrint(true)
      onPrintPdf().then(() => {
        setIsLoadingPrint(false)
      })
    } else {
      onPrintPdf()
    }
  }

  function onToggleSubSection(event) {
    event.preventDefault()

    setSubSectionOpen(!subSectionOpen)
  }

  return (
    <form className="print-settings-form__form" onSubmit={handleFormSubmit}>
      {!disableExportPdfButton &&
        objectSections.map(section => {
          const sectionSlug = slugify(section.name)
          return (
            <fieldset
              key={section.key}
              className={`print-settings-form__fieldset${
                excludedPrintItems.indexOf(section.key) >= 0
                  ? ' print-settings-form__fieldset--disabled'
                  : ''
              }${
                showSmallerSections
                  ? ' print-settings-form__fieldset--small-sections'
                  : ' print-settings-form__fieldset--big-sections'
              }`}
            >
              <legend
                id={`print-settings-form__section-name--${sectionSlug}`}
                className="sr-only"
              >
                {section.name}
              </legend>
              <div className="print-settings-form__checkboxes">
                <input
                  id={`input--${section.key}`}
                  type="checkbox"
                  defaultChecked={
                    excludedPrintItems.indexOf(section.key) === -1
                  }
                  onChange={event => onChangeCheckbox(event, section.key)}
                />
                <label
                  htmlFor={`input--${section.key}`}
                  className="text-bold print-settings-form__checkbox-label"
                >
                  <span className="capitalize-first-letter">
                    {section.name}
                  </span>
                  <span className="sr-only"> exporteren</span>
                </label>

                {!hideRemarks && (
                  <>
                    <input
                      id={`input-note--${section.key}`}
                      type="checkbox"
                      role="switch"
                      data-side-of-label="right"
                      defaultChecked={
                        !excludedPrintItems.includes(`${section.key}-note`)
                      }
                      disabled={excludedPrintItems.includes(section.key)}
                      onChange={event =>
                        onChangeCheckbox(event, `${section.key}-note`)
                      }
                    />
                    <label
                      className="print-settings-form__switch-label"
                      htmlFor={`input-note--${section.key}`}
                      aria-labelledby={`print-settings-form__section-name--${sectionSlug} print-settings-form__note-label--${sectionSlug}`}
                    >
                      <span
                        id={`print-settings-form__note-label--${sectionSlug}`}
                      >
                        Aantekeningen meenemen
                      </span>
                    </label>
                  </>
                )}

                {section.subSections && (
                  <button
                    className={`print-settings-form__subsection-button${
                      subSectionOpen
                        ? ' print-settings-form__subsection-button--open'
                        : ''
                    }`.trim()}
                    id="print-settings-form__subsection--button"
                    aria-expanded={subSectionOpen}
                    aria-controls="print-settings-form__subsection"
                    onClick={onToggleSubSection}
                  >
                    <Icon size="tiny">
                      <IconChevronBack />
                    </Icon>
                    <span className="sr-only">Open meer instellingen</span>
                  </button>
                )}
              </div>

              {section.subSections && (
                <div
                  className={`print-settings-form__checkboxes print-settings-form__subsection${
                    subSectionOpen
                      ? ' print-settings-form__subsection--open'
                      : ''
                  }`}
                  id="print-settings-form__subsection"
                  role="region"
                  aria-labelledby="print-settings-form__subsection--button"
                >
                  {section.subSections.map(subSection => (
                    <div
                      key={subSection.key}
                      className="print-settings-form__subsection-item"
                    >
                      <input
                        id={`input--${subSection.key}`}
                        role="switch"
                        data-side-of-label="right"
                        type="checkbox"
                        defaultChecked={
                          excludedPrintItems.indexOf(subSection.key) === -1
                        }
                        disabled={excludedPrintItems.indexOf(section.key) >= 0}
                        onChange={event =>
                          onChangeCheckbox(event, `${subSection.key}`)
                        }
                      />
                      <label htmlFor={`input--${subSection.key}`}>
                        <span className="capitalize-first-letter">
                          {subSection.name}
                        </span>
                        <span className="sr-only">exporteren</span>
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </fieldset>
          )
        })}
      {!disableExportPdfButton && warningMessage && (
        <p className="print-settings-form__warning-message body">
          <span className="print-settings-form__warning-message-icon">
            <Icon circle size="nano">
              <IconInfo />
            </Icon>
          </span>
          {warningMessage}
        </p>
      )}
      <div className="print-settings-form__buttons">
        {onDownloadDocx && (
          <button
            className={`button${isLoadingDocx ? ' button--loading' : ''}`}
            onClick={downloadDocx}
            type="button"
          >
            Exporteer naar Word
          </button>
        )}

        {onDownloadJson && (
          <button
            className={`button${isLoadingJson ? ' button--loading' : ''}`}
            onClick={downloadJson}
            type="button"
          >
            Exporteer naar JSON
          </button>
        )}

        {onPrintPdf && (
          <button
            className={`button${isLoadingPrint ? ' button--loading' : ''}`}
            onClick={printPage}
            type="button"
            disabled={disablePrintButton}
          >
            Print pagina
          </button>
        )}

        {onFormSubmit && (
          <button
            className={`button${
              !disableExportPdfButton ? ' button--secondary' : ''
            }${isLoadingPdf ? ' button--loading' : ''}`}
            type="submit"
            disabled={disableExportPdfButton}
          >
            Exporteer naar PDF
          </button>
        )}
      </div>
    </form>
  )
}
