// add for other browsers as well when needed

export const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === '[object SafariRemoteNotification]'
  })(
    !window['safari'] ||
      (typeof safari !== 'undefined' && window['safari'].pushNotification),
  )
