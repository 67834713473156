import React, { forwardRef } from 'react'
import Panel from '@brainbay/components/components/panel'
import './building-description.css'
import { convertNextLineToHtmlBreakEl } from '../../utils/string-helpers'
import SourceLabel from '@brainbay/components/components/source-label'

const SectorDescription = forwardRef(function BuildingDetail({ data }, ref) {
  const { pand, geselecteerdeAdres } = data || {}

  let verblijfsobject = pand?.verblijfsobjecten.find(element => {
    return (
      element?.hoofdAdres.nummeraanduidingId ===
        geselecteerdeAdres.nummeraanduidingId ||
      element?.nevenAdressen.find(
        address =>
          address.nummeraanduidingId === geselecteerdeAdres.nummeraanduidingId,
      )
    )
  })

  return (
    <div ref={ref}>
      <Panel title="Sectorbeschrijving" tag="article" divider>
        <div className="building-description__text-wrapper">
          <p className="building-description__text">
            {convertNextLineToHtmlBreakEl(verblijfsobject?.sectorBeschrijving)}
          </p>
          <div className="building-description__source-label">
            <SourceLabel source="Brainbay" prefixSource="Bron" />
          </div>
        </div>
      </Panel>
    </div>
  )
})
export default SectorDescription
