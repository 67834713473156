import React from 'react'
import { Font } from '@react-pdf/renderer'
import { Document, Page, ObjectCard } from './pdf/components'

import rubikRegular from '../_assets/fonts/pdf-fonts/Rubik-Regular.ttf'
import rubikMedium from '../_assets/fonts/pdf-fonts/Rubik-Medium.ttf'

Font.register({
  family: 'Rubik',
  format: 'truetype',
  fonts: [
    {
      src: rubikRegular,
    },
    {
      src: rubikMedium,
      fontWeight: 700,
    },
  ],
})

export default function ObjectsPDF({ items, debug = false }) {
  return (
    <Document>
      <Page smallPadding>
        {items &&
          items.map(item => (
            <ObjectCard key={item.objectGuid} item={item} debug={debug} />
          ))}
      </Page>
    </Document>
  )
}
