/*
  in this template the order of the csv export is determined
 */
export const csvOrderTemplate = {
  midasObjectId: null,
  tiaraObjectId: null,
  hoofdfunctie: null,
  specialisaties: null,
  alternatieveAanwendbaarheden: null,
  straatnaam: null,
  huisnummer: null,
  huisnummertoevoeging: null,
  postcode: null,
  woonplaats: null,
  bouwjaar: null,
  energielabel: null,
  aanmelddatum: null,
  afmelddatum: null,
  status: null,
  pandOppervlakte: null,
  unitsVanaf: null,
  perceelOppervlakte: null,
  huurprijsVoorvoegsel: null,
  huurprijs: null,
  huurconditie: null,
  koopprijsVoorvoegsel: null,
  koopprijs: null,
  koopconditie: null,
  brutoOpbrengstKaleHuur: null,
  brutoAanvangsrendementAanbieding: null,
  transactieDatum: null,
  transactieOppervlakte: null,
  transactiePrijs: null,
  transactieConditie: null,
  brutoAanvangsrendementTransactie: null,
  koperHuurderNaam: null,
  transactieVertrouwelijk: null,
  looptijd: null,
  hoofdAanbieder: null,
  medeAanbieders: null,
  bron: null,
  stamkaart: null,
  objectGuid: null,
}
