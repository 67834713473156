import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '../icon'
import { ReactComponent as IconPlus } from '../../_assets/svg/icon-plus.svg'
import { ReactComponent as IconClose } from '../../_assets/svg/icon-close.svg'
import './add-button.css'

export default function AddButton({
  className = '',
  isAdded,
  onClick,
  deleteIsDisabled,
  addIsDisabled,
  linkTo,
  labelAdd = 'Voeg toe',
  labelRemove = 'Verwijder',
  hideLabel = false,
  iconSize = 'tiny',
  isLoading,
  ...rest
}) {
  const ElementTag = linkTo ? Link : 'button'

  if (isAdded) {
    return (
      <ElementTag
        className={`add-button add-button--added ${className}`.trim()}
        onClick={onClick}
        disabled={deleteIsDisabled || isLoading}
        to={linkTo}
        {...rest}
      >
        {isLoading ? (
          <div className="add-button__spinner button--loading" />
        ) : (
          <>
            <Icon circle size={iconSize}>
              <IconClose />
            </Icon>
            <span
              className={`body text-bold ${hideLabel ? 'sr-only' : ''}`.trim()}
            >
              {labelRemove}
            </span>
          </>
        )}
      </ElementTag>
    )
  }

  return (
    <ElementTag
      className={`add-button ${className}`.trim()}
      onClick={onClick}
      disabled={addIsDisabled || isLoading}
      to={linkTo}
      {...rest}
    >
      {isLoading ? (
        <div className="add-button__spinner button--loading" />
      ) : (
        <>
          <Icon circle size={iconSize}>
            <IconPlus />
          </Icon>
          <span
            className={`body text-bold ${hideLabel ? 'sr-only' : ''}`.trim()}
          >
            {labelAdd}
          </span>
        </>
      )}
    </ElementTag>
  )
}
