import React from 'react'
import './export-button.css'

export default function ExportButton({ onClick }) {
  return (
    <button
      className="export-button button button--secondary"
      onClick={onClick}
    >
      Exporteren
    </button>
  )
}
