import React, { useEffect, useMemo, useRef, useState } from 'react'
import Icon from '../../../icon'
import { ReactComponent as IconPlus } from '../../../../_assets/svg/icon-plus.svg'
import { ReactComponent as IconClose } from '../../../../_assets/svg/icon-close.svg'
import slugify from '../../../../utils/slugify'
import TextInput from '../text-input'
import './text-list.css'

export function getActiveFilterLabels(control, filters) {
  if (!filters[control.name]) {
    return []
  }

  return [{ label: filters[control.name], control }]
}

export function removeFilterValues(params, control, _optionValue) {
  delete params[control.name]
}

export default function TextList({ control, filters, groupLabel, onChange }) {
  const rootRef = useRef(null)
  const [fakeFilters, setFakeFilters] = useState({
    [`ignore-${control.name}`]: '',
  })
  const [internalValue, setInternalValue] = useState([])

  const externalValue = useMemo(() => {
    return (filters?.[control.name] || '').split(',').filter(x => x)
  }, [control.name, filters])

  useEffect(() => {
    setInternalValue(externalValue)
  }, [externalValue])

  function handleOnChange(event) {
    event.persist()
    const { value } = rootRef.current.querySelector('input[type="text"]')
    const newList = [...internalValue, ...(value || '').split(',')]
      .filter(x => x)
      .map(item => item.trim())

    // We use modulo to alternate between "" and  " " so the <TextInput /> is
    // being cleared
    if (newList.length !== internalValue.length) {
      setFakeFilters({
        [`ignore-${control.name}`]: newList.length % 2 ? ' ' : '  ',
      })
    }

    setInternalValue([...new Set(newList)])
    setTimeout(() => {
      onChange(event)
    }, 10)
  }

  function handleRemove(item) {
    return function execute(event) {
      event.persist()

      const nextCloseButton =
        event?.currentTarget?.parentNode?.parentNode?.nextSibling?.querySelector?.(
          'button',
        )
      const previousCloseButton =
        event?.currentTarget?.parentNode?.parentNode?.previousSibling?.querySelector?.(
          'button',
        )

      if (nextCloseButton) {
        nextCloseButton.focus()
      } else if (previousCloseButton) {
        previousCloseButton.focus()
      }

      setInternalValue(internalValue.filter(listItem => listItem !== item))
      setTimeout(() => {
        onChange(event)
      }, 0)
    }
  }

  function handleEnterKeyTextInput(event) {
    if (event.key === 'Enter') {
      event.persist()
      event.preventDefault()
      event.stopPropagation()
      handleOnChange(event)
    }
  }

  return (
    <div className="text-list" ref={rootRef}>
      <div className="text-list__input-wrapper" role="group">
        <TextInput
          control={{ ...control, ...{ name: `ignore-${control.name}` } }}
          filters={fakeFilters}
          groupLabel={groupLabel}
          debounceDelay={1000}
          onChange={event => event.stopPropagation()}
          onKeyDown={handleEnterKeyTextInput}
        />
        <button
          type="button"
          className="text-list__add-button"
          onClick={handleOnChange}
        >
          <span className="sr-only">Voeg toe aan lijst</span>
          <Icon size="tiny">
            <IconPlus />
          </Icon>
        </button>
      </div>
      <input
        type="hidden"
        value={internalValue.toString()}
        name={control.name}
      />
      <ul className="text-list__list">
        {internalValue
          .map(item => item.trim())
          .filter(x => x)
          .map(item => {
            const id = `search-filters__text-list-item--${slugify(item)}`
            return (
              <li
                key={item}
                onClick={handleRemove(item)}
                className="text-list__item"
              >
                <span className="text-list__item-wrapper">
                  <span id={`${id}-label`}>{item}</span>
                  <button
                    onClick={handleRemove(item)}
                    className="text-list__remove-button highlight-focus"
                  >
                    <Icon size="nano">
                      <IconClose />
                    </Icon>
                    <span id={`${id}-remove-button`} className="sr-only">
                      verwijder uit filter {groupLabel}
                    </span>
                  </button>
                </span>
              </li>
            )
          })}
      </ul>
    </div>
  )
}
