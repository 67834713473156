/**
 * Hook to determine the formatted search query based on either the chosen autocomplete option or free text search
 * @returns
 */
export function getFormattedSearchQuery(autocompleteOption) {
  if (!autocompleteOption) {
    return ''
  }

  const { type, label, parent } = autocompleteOption

  if (type !== 'plaats') {
    const city = getCity(parent)
    return city ? `${label}, ${city}` : label
  }
  return label
}

export function getFormattedSearchQueryResultTitle(option) {
  if (!option) {
    return ''
  }

  const { type, label, parent } = option

  let prefix = type
  let formattedLabel = label

  if (parent) {
    prefix = null
    const city = getCity(parent)
    formattedLabel = city ? `${label}, ${city}` : label
  }

  return {
    label: formattedLabel,
    type: prefix,
  }
}

function getCity(parent) {
  while (parent && parent.type !== 'plaats') {
    parent = parent.parent
  }
  return parent?.label
}

export default getFormattedSearchQuery
