import React from 'react'
import { ReactComponent as IconChevronDown } from '../../_assets/svg/icon-chevron-down.svg'
import Icon from '../icon'
import './collapse-button.css'

export default function CollapseButton({ title, isOpen, onButtonClicked }) {
  return (
    <button
      className={`
        collapse-button
        ${isOpen ? 'collapse-button--is-open' : ''}
      `.trim()}
      onClick={onButtonClicked}
      aria-expanded={isOpen ? 'true' : 'false'}
    >
      <span className="sr-only">
        {isOpen ? `Verberg ${title}` : `Toon ${title}`}
      </span>
      <Icon circle>
        <IconChevronDown />
      </Icon>
    </button>
  )
}
