import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const fetchLocalAnnouncements = createAsyncThunk(
  'fetchLocalAnnouncements',
  async (address, thunkAPI) => {
    const { longitude, latitude } = address
    const queryParams = {
      where: '1=1',
      outFields: '*',
      geometry: `${longitude},${latitude}`,
      geometryType: 'esriGeometryPoint',
      inSR: 4326,
      spatialRel: 'esriSpatialRelIntersects',
      orderByFields: 'DatumBeschikbaar DESC',
      f: 'json',
      units: 'esriSRUnit_Kilometer',
      distance: 0.5,
    }
    const url = `https://services.arcgis.com/nSZVuSZjHpEZZbRo/ArcGIS/rest/services/Bekendmakingen_actueel/FeatureServer/0/query?${new URLSearchParams(
      queryParams,
    )}`

    try {
      const response = await fetch(url)
      const data = await response.json()

      const filteredLocalAnnouncementsData = data?.features?.map(feature => {
        const { DatumBeschikbaar, Maker, Rubriek, URL, Titel } =
          feature.attributes
        return { DatumBeschikbaar, Maker, Rubriek, URL, Titel }
      })

      return filteredLocalAnnouncementsData
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)

export const numberOfAnnouncementsToShowOnPage = 10

const localAnnouncements = createSlice({
  name: 'localAnnouncements',
  initialState: {
    announcements: [],
    total: 0,
    numberOfAnnouncementsToShow: numberOfAnnouncementsToShowOnPage,
    address: undefined,
    status: 'idle',
    error: null,
  },
  reducers: {
    handleShowMoreAnnouncements: state => {
      state.numberOfAnnouncementsToShow =
        state.numberOfAnnouncementsToShow + numberOfAnnouncementsToShowOnPage
    },
    handleShowLessAnnouncements: state => {
      state.numberOfAnnouncementsToShow = Math.max(
        state.numberOfAnnouncementsToShow - numberOfAnnouncementsToShowOnPage,
        numberOfAnnouncementsToShowOnPage,
      )
    },
    clearAnnouncements: state => {
      state.localAnnouncements = []
      state.total = 0
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchLocalAnnouncements.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchLocalAnnouncements.fulfilled, (state, action) => {
        const localAnnouncements = action?.payload

        state.status = 'succeeded'
        state.announcements = localAnnouncements
        state.total = localAnnouncements?.length || 0
      })
      .addCase(fetchLocalAnnouncements.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })
  },
})

export const selectLocalAnnouncements = state => state.localAnnouncements
export const {
  clearAnnouncements,
  handleShowMoreAnnouncements,
  handleShowLessAnnouncements,
} = localAnnouncements.actions

export default localAnnouncements.reducer
