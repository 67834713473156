import React from 'react'
import { Box, Typography } from './index'

export default function PageHeader({ pageName, debug, top }) {
  return (
    <Box
      style={{
        position: 'absolute',
        top: top || 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 596,
        height: 60,
        backgroundColor: '#0099bc',
      }}
      debug={debug}
      fixed
    >
      <Typography
        style={{
          fontSize: 20,
          color: '#fff',
          fontWeight: 700,
          textAlign: 'center',
          lineHeight: 1.2,
        }}
        debug={debug}
      >
        {pageName}
      </Typography>
    </Box>
  )
}
