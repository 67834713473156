import React, { useEffect, useState, forwardRef } from 'react'
import Panel from '@brainbay/components/components/panel'
import ModalImageViewer from '@brainbay/components/components/modal-image-viewer'
import ObjectDetails from '@brainbay/components/components/object-details'
import ObjectDetailsDefinitionList from '@brainbay/components/components/object-details-definition-list'
import EnergyLabel from '@brainbay/components/components/energy-label'
import formatAddressBusiness from '../../utils/format-address-business'
import formatValue from '@brainbay/components/utils/format-value'
import SourceLabel from '@brainbay/components/components/source-label'
import { Route, Switch } from 'react-router-dom'

import './building-detail.css'
const loadingItems = [
  null,
  null,
  null,
  null,
  <EnergyLabel isLoading={true} />,
  null,
]

const BuildingDetail = forwardRef(function BuildingDetail(
  { data, baseUrl, onCloseModal },
  ref,
) {
  const [formattedAddress, setFormattedAddress] = useState('')
  const [address, setAddress] = useState({})
  const [building, setBuilding] = useState({})
  const [accommodationObject, setAccommodationObject] = useState({})
  const [hasLoaded, setHasLoaded] = useState(false)
  const baseImagesUrl = `${baseUrl}/images`

  useEffect(() => {
    if (data && !data.loading) {
      const { pand, geselecteerdeAdres } = data || {}

      let verblijfsobject = pand?.verblijfsobjecten.find(element => {
        return (
          element?.hoofdAdres.nummeraanduidingId ===
            geselecteerdeAdres.nummeraanduidingId ||
          element?.nevenAdressen.find(
            address =>
              address.nummeraanduidingId ===
              geselecteerdeAdres.nummeraanduidingId,
          )
        )
      })

      setAccommodationObject(verblijfsobject)
      setAddress(geselecteerdeAdres)
      setBuilding(pand)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    const formattedAddressFromHelper = formatAddressBusiness({
      street: address?.straatnaam,
      houseNumber: address?.huisnummer,
      houseNumberSuffix: address?.huisnummertoevoeging,
      postalCode: address?.postcode,
      city: address?.woonplaats,
      municipality: address?.gemeente,
    })

    if (formattedAddressFromHelper !== '---') {
      setFormattedAddress(formattedAddressFromHelper)
    }

    if (data && !data.loading) {
      setHasLoaded(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, data])

  return (
    <div ref={ref}>
      <Panel title=" " tag="section" fullBleed divider={false}>
        <Switch>
          <Route path={`${baseImagesUrl}/:mediaGuid?`}>
            <ModalImageViewer
              onClose={onCloseModal}
              imagesBaseUrl={baseImagesUrl}
              mediaGuids={data?.mediaGuids}
              isLoading={data?.loading}
            />
          </Route>
        </Switch>

        <ObjectDetails
          objectGuid={data?.objectGuid}
          imagesBaseUrl={baseImagesUrl}
          address={formattedAddress}
          housingTypes={
            accommodationObject?.gebruiksdoelen
              ? [accommodationObject?.gebruiksdoelen]
              : []
          }
          mediaGuids={data?.mediaGuids}
          status={data?.status}
          isLoading={data?.loading}
          showStamkaartLink={data?.bron === 'NVM Midas'}
          stamkaartBaseUrl="https://stamkaart.nvm.nl/business/stamkaart/external"
          duration={data?.transactie?.looptijd ?? data?.looptijd}
          prefixSource="Bron"
          source={data?.bron}
        >
          <span
            className="sr-only"
            id="object-object-details__object-kenmerken-description"
          >
            Object kenmerken
          </span>

          {!hasLoaded && (
            <ObjectDetailsDefinitionList>
              <dl>
                {loadingItems.map((loadingItem, index) => (
                  <React.Fragment key={index}>
                    <dt></dt>
                    <dd>{loadingItem}</dd>
                  </React.Fragment>
                ))}
              </dl>
            </ObjectDetailsDefinitionList>
          )}

          {hasLoaded && (
            <ObjectDetailsDefinitionList>
              <dl aria-labelledby="object-object-details__object-kenmerken-description">
                <dt>Oppervlakte verblijfsobject</dt>
                <dd>
                  {formatValue(
                    accommodationObject?.oppervlakte,
                    'meter-squared',
                  )}
                </dd>

                <dt>Oppervlakte pand</dt>
                <dd>{formatValue(building?.oppervlakte, 'meter-squared')}</dd>

                <dt>Oppervlakte perceel</dt>
                <dd>
                  {formatValue(building?.perceeloppervlakte, 'meter-squared')}
                </dd>

                <dt>Bouwjaar</dt>
                <dd>{building?.bouwjaar || '---'}</dd>

                <dt>Energie label</dt>
                <dd>
                  <EnergyLabel
                    isLoading={!hasLoaded}
                    label={accommodationObject?.energieklasse}
                  />
                </dd>

                <dt>Pand status</dt>
                <dd>{building?.pandStatus || '---'}</dd>
              </dl>
            </ObjectDetailsDefinitionList>
          )}
          <div className="building-detail__geo-link-container">
            <a
              href={`https://geoweb-nvm.geowebonline.nl/GeoWeb56/Index.html?viewer=nvm&LayerTheme=Landelijk&adres=${
                address?.postcode
              }+${address?.huisnummer}${address?.huisnummertoevoeging || ''}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Toon object in Geografische informatie
            </a>
          </div>
          <span className="object-object-details_source-label">
            <SourceLabel source="BAG / RVO" prefixSource="Bron" />
          </span>
        </ObjectDetails>
      </Panel>
    </div>
  )
})
export default BuildingDetail
