import { configureStore, combineReducers } from '@reduxjs/toolkit'

import comparison from './comparison'
import displaySearchBar from './display-search-bar'
import filtercatalog from './filtercatalog'
import exportButton from './export-button'
import mapBig from './map-big'
import objects from './objects'
import buildings from './buildings'
import pageTitle from './page-title'
import routeHistory from './route-history'
import savedComparisons from './saved-comparisons'
import search from './search'
import searchResultMapMode from './search-result-map-mode'
import searchResultLatestSelected from './search-result-latest-selected'
import user from './user'
import printSettings from './print-settings'
import priceIndex from './price-index'
import searchResultMode from './search-result-mode'
import walkscore from './walkscore'
import betrokkenObject from './betrokken-object'
import businessRegistratiesHistories from './business-registraties-history'
import kvkUsers from './kvk-users'
import localAnnouncements from './lokale-bekendmakingen'
import fluxConnection from './flux-connection'
export default configureStore({
  reducer: combineReducers({
    comparison,
    displaySearchBar,
    filtercatalog,
    exportButton,
    mapBig,
    objects,
    buildings,
    pageTitle,
    routeHistory,
    savedComparisons,
    search,
    searchResultMapMode,
    searchResultMode,
    searchResultLatestSelected,
    user,
    printSettings,
    priceIndex,
    walkscore,
    kvkUsers,
    betrokkenObject,
    businessRegistratiesHistories,
    localAnnouncements,
    fluxConnection,
  }),
})
