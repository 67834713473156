import React, { useState, useEffect } from 'react'
import { Route, useLocation } from 'react-router-dom'
import isSSR from '@brainbay/components/utils/is-ssr'
import { useSelector } from 'react-redux'

import * as auth from '../../utils/auth-service'

export default function PrivateRoute({ children, ...rest }) {
  const location = useLocation()
  const accessToken = useSelector(state => state?.user?.accessToken)
  const expireTime = useSelector(state => state?.user?.user?.exp)
  const userRole = useSelector(state => state?.user?.user?.Role)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [validRole, setValidRole] = useState(false)
  const MESSAGE_LOADING = 'We controleren de login status'
  const MESSAGE_NOT_AUTHORISED = (
    <>
      <p>
        Helaas kunnen geen resultaten getoond worden. U hebt geen rechten tot
        deze applicatie.
      </p>
      <p>
        Voor meer informatie kunt u contact opnemen met{' '}
        <a href="mailto:support@brainbay.nl">support@brainbay.nl</a>
      </p>
    </>
  )

  useEffect(() => {
    async function getUser() {
      const hasValidRole = () =>
        userRole.indexOf('business_referentie_module') !== -1

      // We are not in production
      if (
        process.env.NODE_ENV !== 'production' &&
        process.env.REACT_APP_DEBUG_REQUIRE_LOGIN !== 'true'
      ) {
        setIsLoggedIn(true)
        setValidRole(true)
        return
      }

      // We don't have an accesstoken
      if (!accessToken) {
        auth.login()
      }

      // We do have an access token
      else {
        const now = Date.now() / 1000

        // Our token expire time is in the future
        if (now < expireTime) {
          setIsLoggedIn(true)

          if (hasValidRole()) {
            setValidRole(true)
          }
        }

        // Our token is expired
        else {
          auth.login()
        }
      }
    }

    if (isSSR === false) {
      getUser()
    }
  }, [accessToken, expireTime, userRole])

  useEffect(() => {
    const now = Date.now() / 1000

    // Our token is expired while browsing the site
    if (now >= expireTime - 60 && process.env.NODE_ENV === 'production') {
      auth.login()
    }
  }, [location, expireTime])

  const renderMessage = message => (
    <Route
      {...rest}
      render={() => (
        <main className="layout--grid">
          <p className="body">{message}</p>
        </main>
      )}
    />
  )

  if (isLoggedIn && validRole) {
    return <Route {...rest} render={() => children} />
  }

  if (isLoggedIn && !validRole) {
    return renderMessage(MESSAGE_NOT_AUTHORISED)
  }

  return renderMessage(MESSAGE_LOADING)
}
