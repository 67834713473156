import React from 'react'
import DefaultLayout from '../layouts/default'
import { Typography } from '../components'

export default function BuildingDetailPage({
  userName,
  date,
  hasTransaction,
  debug,
  isGebouwpaspoort,
}) {
  return (
    <DefaultLayout
      pageName="Disclaimer"
      userName={userName}
      date={date}
      hasTransaction={hasTransaction}
      debug={debug}
      isGebouwpaspoort={isGebouwpaspoort}
    >
      <Typography
        style={{
          marginTop: 10,
          fontWeight: '700',
        }}
        debug={debug}
      >
        Bron:
      </Typography>
      <Typography style={{ fontWeight: '500' }} debug={debug}>
        Brainbay database ({date}).
      </Typography>
      <Typography
        style={{
          marginTop: 10,
          fontWeight: '700',
        }}
        debug={debug}
      >
        Disclaimer:
      </Typography>
      <Typography style={{ fontWeight: '500' }} debug={debug}>
        Ondanks de voortdurende zorg en aandacht die Brainbay aan de in dit
        document opgenomen gegevens besteedt, geeft Brainbay geen garanties op
        de volledigheid, juistheid of voortdurende actualiteit van de gegevens.
      </Typography>
    </DefaultLayout>
  )
}
