import React from 'react'
import Icon from '../icon'
import { ReactComponent as IconChevronBack } from '../../_assets/svg/icon-chevron-back.svg'
import { ReactComponent as IconBouwjaar } from '../../_assets/svg/icon-bouwjaar.svg'
import { ReactComponent as IconPerceeloppervlak } from '../../_assets/svg/icon-perceeloppervlak.svg'
import { ReactComponent as IconWoonoppervlak } from '../../_assets/svg/icon-woonoppervlak.svg'
import { ReactComponent as IconPlattegrond } from '../../_assets/svg/icon-plattegrond.svg'
import { ReactComponent as IconCubic } from '../../_assets/svg/icon-cubic.svg'

import './icon-legend.css'

export default function IconLegend({
  align = 'right',
  open = false,
  className,
}) {
  return (
    <details className={`icon-legend ${className}`.trim()} open={open}>
      <summary className="body">
        iconen legenda{' '}
        <Icon size="tiny" className="icon-legend__chevron">
          <IconChevronBack />
        </Icon>
      </summary>
      <dl className={`icon-legend__list icon-legend__list--align-${align}`}>
        <dt>
          <Icon size="tiny">
            <IconBouwjaar />
          </Icon>
        </dt>
        <dd className="body">Bouwjaar</dd>
        <dt>
          <Icon size="tiny">
            <IconPerceeloppervlak />
          </Icon>
        </dt>
        <dd className="body">Perceel oppervlak</dd>
        <dt>
          <Icon size="tiny">
            <IconWoonoppervlak />
          </Icon>
        </dt>
        <dd className="body">Pand oppervlak / Woon oppervlak</dd>
        <dt>
          <Icon size="tiny">
            <IconPlattegrond />
          </Icon>
        </dt>
        <dd className="body">Aantal kamers</dd>
        <dt>
          <Icon size="tiny">
            <IconCubic />
          </Icon>
        </dt>
        <dd className="body">Bruto inhoud</dd>
      </dl>
    </details>
  )
}
