import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Modal from '@brainbay/components/components/modal'
import { trackEvent } from '@brainbay/components/utils/ga-events'
import { gaCategories } from '@brainbay/components/utils/constants'
import useExportEffect from '../../utils/use-export-effect'
import ExportButton from '../export-button'

import './export-object-details.css'

export default function ExportObjectDetails({ settings, onClose }) {
  const history = useHistory()
  const location = useLocation()
  const { show, setShow } = useExportEffect()

  const hideModal = () => setShow(false)
  const removeExportHash = () => {
    history.replace({
      ...location,
      hash: '',
    })
  }

  const showModal = () => {
    history.replace({
      ...location,
      hash: '#export',
    })
    trackEvent(
      'clicked_open_modal_export_object_details',
      gaCategories.EXPORT,
      'Export modal opened',
    )
  }

  function cancel() {
    onClose()
    hideModal()
    removeExportHash()
  }

  return (
    <div className="export-object-details">
      <ExportButton onClick={showModal} />

      {show && (
        <Modal
          onClose={cancel}
          title="Exporteren"
          subTitle="Welke onderdelen wil je exporteren?"
        >
          {settings}
        </Modal>
      )}
    </div>
  )
}
