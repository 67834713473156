import React from 'react'
import { Link } from 'react-router-dom'
import { trackEvent } from '../../utils/ga-events'
import { gaCategories } from '../../utils/constants'
import Icon from '../icon'
import { ReactComponent as IconInfo } from '../../_assets/svg/icon-info.svg'
import './market-value-item.css'

export default function MarketValueItem({
  className,
  value,
  description,
  icon,
  moreInfoUrl,
  linkTo,
  title,
  titleTag,
}) {
  let TitleTag = 'h3'

  if (titleTag) {
    TitleTag = titleTag
  } else if (linkTo) {
    TitleTag = Link
  }

  function onModalOpen() {
    trackEvent(
      'clicked_open_modal_avm',
      gaCategories.AVM,
      `AVM modal ${title} opened`,
    )
  }

  return (
    <section
      className={`market-value-item${className ? ` ${className}` : ''}`.trim()}
    >
      <Icon circle size="large">
        {icon}
      </Icon>

      <TitleTag
        className={`market-value-item__title text-bold body${
          moreInfoUrl ? ' market-value-item__title--with-icon' : ''
        }`.trim()}
        to={linkTo ? linkTo : ''}
        onClick={linkTo ? onModalOpen : null}
      >
        {title}

        {moreInfoUrl && (
          <Link to={moreInfoUrl} className="print-excluded">
            <span className="sr-only">Meer informatie over {title}</span>
            <Icon size="nano" circle>
              <IconInfo />
            </Icon>
          </Link>
        )}
      </TitleTag>

      <p className="market-value-item__value h4 text-bold loading-state">
        {value}
      </p>
      <p className="market-value-item__body body">
        <span className="sr-only">{title} is gebaseerd: </span>
        {description}
      </p>
    </section>
  )
}
