import React from 'react'

import * as RangeInput from './range-input'
import * as DateRangeInput from './date-range-input'
import * as CheckBox from './checkbox'
import * as TextInput from './text-input'
import * as TextList from './text-list'
import * as Select from './select'
import * as NumberInput from './number-input'
import * as OptionControl from './option-control'

export { default as FormGroup } from './form-group'

const controlsByType = {
  text: TextInput,
  'text-list': TextList,
  select: Select,
  number: NumberInput,
  range: RangeInput,
  'date-range': DateRangeInput,
  radio: OptionControl,
  'button-group-radio': OptionControl,
  'button-group-checkbox': OptionControl,
  checkbox: CheckBox,
}

/**
 * Control returns the React element for the given control type
 * @param {*} props
 * @returns
 */
export function Control(props) {
  const Component = controlsByType[props.control.type].default
  return <Component {...props} />
}

/**
 * Each control should implement getActiveFilterLabels.
 * The getActiveFilterLabels should return a list of objects where each object has the following format:
 * {
 *    label: "The label to show",
 *    control,
 *    optionValue: "" // checkboxes can delete one filter option without deleting the entire filter
 * }
 */
export function getActiveFilterLabels(control, filters) {
  if (!controlsByType[control.type].getActiveFilterLabels) {
    return { control, label: filters[control.name] }
  }

  return controlsByType[control.type].getActiveFilterLabels(control, filters)
}

/**
 * Each control should implement removeFilterValues
 * removeFilterValues should mutate the params object so that all the values for the given control (and optional optionValue) are removed
 */
export function removeFilterValues(params, control, optionValue) {
  if (!controlsByType[control.type].removeFilterValues) {
    delete params[control.name]
    return
  }

  return controlsByType[control.type].removeFilterValues(
    params,
    control,
    optionValue,
  )
}

/**
 * Returns whether a control has a value.
 * By default it just looks at the value in the url however some controls have sub values.
 * @param {*} control
 * @param {*} filters
 * @returns
 */
export function hasValue(control, filters) {
  if (!controlsByType[control.type].hasValue) {
    return !!filters[control.name]
  }

  return controlsByType[control.type].hasValue(control, filters)
}
