import React from 'react'
import Icon from '../icon'
import { ReactComponent as IconBusinessSecondary } from '../../_assets/svg/icon-business-secondary.svg'
import { ReactComponent as IconSecondarySelected } from '../../_assets/svg/icon-business-secondary-selected.svg'
import { ReactComponent as IconMap } from '../../_assets/svg/icon-map.svg'
import { Marker } from 'react-map-gl'
import './map-marker-pin.css'

export default function MapMarkerPin({
  longitude,
  latitude,
  comparisonAdded,
  isPinLocation,
}) {
  const src = isPinLocation ? (
    <IconMap />
  ) : comparisonAdded ? (
    <IconSecondarySelected />
  ) : (
    <IconBusinessSecondary />
  )

  return (
    <Marker
      className="map-marker-pin"
      captureDrag={false}
      captureClick={false}
      captureDoubleClick={false}
      longitude={longitude}
      latitude={latitude}
      offsetLeft={-16}
      offsetTop={-16}
    >
      <Icon className="map-marker-pin__image">{src}</Icon>
    </Marker>
  )
}
