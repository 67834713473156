import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '../icon'
import { ReactComponent as IconChevronBack } from '../../_assets/svg/icon-chevron-back.svg'
import formatValue from '../../utils/format-value'
import './pagination.css'

export default function Pagination({
  rootUri,
  page,
  count,
  pageSize,
  queryParams = '',
  isCompactMode = false,
}) {
  const totalPages = Math.ceil(count / pageSize)
  const prevPage =
    isNaN(totalPages) === false && page > 1 ? page - 1 : undefined
  const nextPage =
    isNaN(totalPages) === false && page < totalPages ? page + 1 : undefined
  const pages = []

  if (isNaN(totalPages) === false) {
    for (let i = 0; i < totalPages; i++) {
      if (isCompactMode) {
        pages.push({
          uri: `${rootUri}/compact-lijst/${i + 1}`,
          pageNumber: i + 1,
        })
      } else {
        pages.push({ uri: `${rootUri}/${i + 1}`, pageNumber: i + 1 })
      }
    }
  }

  const indexOfFirstPage = page < 7 || totalPages <= 10 ? 0 : page - 6
  const indexOfLastPage = page < 7 ? 10 : page + 4

  const slizedPagesArray = pages.slice(indexOfFirstPage, indexOfLastPage)

  return (
    isNaN(totalPages) === false && (
      <nav className="pagination body">
        {prevPage && (
          <Link
            className="pagination__first"
            to={
              isCompactMode
                ? `${rootUri}/compact-lijst/${prevPage}${queryParams}`
                : `${rootUri}/${prevPage}${queryParams}`
            }
          >
            <Icon size="tiny">
              <IconChevronBack />
              <span className="sr-only">
                Ga naar pagina {formatValue(prevPage, { format: 'decimal' })}
              </span>
            </Icon>
            <span className="pagination__text">Vorige</span>
          </Link>
        )}
        {indexOfFirstPage > 0 && (
          <>
            <Link
              className="pagination__item"
              to={
                isCompactMode
                  ? `${rootUri}/compact-lijst/1${queryParams}`
                  : `${rootUri}/1${queryParams}`
              }
            >
              1
            </Link>
            {indexOfFirstPage > 1 && <span>...</span>}
          </>
        )}
        {slizedPagesArray.map(pageObject => {
          const isLink = pageObject.pageNumber !== page
          return isLink ? (
            <Link
              className="pagination__item"
              key={pageObject.uri}
              to={`${pageObject.uri}${queryParams}`}
            >
              {formatValue(pageObject.pageNumber, { format: 'decimal' })}
            </Link>
          ) : (
            <span className="pagination__item" key={pageObject.uri}>
              {formatValue(pageObject.pageNumber, { format: 'decimal' })}
            </span>
          )
        })}
        {totalPages > 10 && indexOfLastPage < totalPages && (
          <>
            {indexOfLastPage + 1 < totalPages && <span>...</span>}
            <Link
              className="pagination__item"
              to={
                isCompactMode
                  ? `${rootUri}/compact-lijst/${totalPages}${queryParams}`
                  : `${rootUri}/${totalPages}${queryParams}`
              }
            >
              {formatValue(totalPages, { format: 'decimal' })}
            </Link>
          </>
        )}
        {nextPage && (
          <Link
            className="pagination__last"
            to={
              isCompactMode
                ? `${rootUri}/compact-lijst/${nextPage}${queryParams}`
                : `${rootUri}/${nextPage}${queryParams}`
            }
          >
            <span className="pagination__text">Volgende</span>
            <Icon size="tiny">
              <IconChevronBack />
              <span className="sr-only">
                Ga naar pagina {formatValue(nextPage, { format: 'decimal' })}
              </span>
            </Icon>
          </Link>
        )}
      </nav>
    )
  )
}
