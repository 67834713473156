import { useEffect, useState } from 'react'
import { useDebouncedCallback } from '@brainbay/components/utils/use-debounce'
import useApiCall from './use-api-call'
import { gebouwpaspoortApiHost } from './business-environment-vars'

export default function useAutocompleteOptions(query, isGebouwpaspoort) {
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const { post, cancel } = useApiCall()

  let pathPrefix = 'suggestion/search'

  const doRequest = useDebouncedCallback(query => {
    post({
      path: pathPrefix,
      apiHostUrl: isGebouwpaspoort && gebouwpaspoortApiHost(),
      body: { query },
    })
      .then(({ data }) => {
        setOptions(data)
        setLoading(false)
      })
      .catch(e => {
        if (e.name !== 'AbortError') {
          /* 
            Don't set loading to false on abort error.
            Abort is async and will override the setLoading(true) when triggering a new request
          */
          setLoading(false)
          throw e
        }
      })
  }, 500)

  useEffect(() => {
    cancel()
    if (query.length >= 2) {
      setLoading(true)
      doRequest(query)
    } else {
      setLoading(false)
      setOptions([])
      doRequest.cancel()
    }
  }, [query, post, doRequest, cancel])

  return {
    autocompleteOptions: options,
    autocompleteOptionsAreLoading: loading,
  }
}
