import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setSavedComparisons as setPageTitle } from '../store/page-title'
import { resetSearchBar, hideSearchBar } from '../store/display-search-bar'
import {
  setSavedComparisons,
  removeComparison,
} from '../store/saved-comparisons'
import { load, addObject, enableComparePanel } from '../store/comparison'
import Panel from '@brainbay/components/components/panel'
import Modal from '@brainbay/components/components/modal'

import './saved-comparisons.css'

function sortComparisonsByUpdatedAt(comparisons) {
  const list = Object.entries(comparisons).map(([key, value]) => ({
    ...value,
    key,
  }))
  list.sort(
    (a, b) => (b.updatedAt || b.createdAt) - (a.updatedAt || a.createdAt),
  )
  return list
}

export default function Landing() {
  const history = useHistory()
  const dispatch = useDispatch()
  const currentLastSavedHashHash = useSelector(
    state => state.comparison.lastSavedHash,
  )
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [comparisonToBeRemoved, setComparsonToBeRemoved] = useState(undefined)
  const comparisons = sortComparisonsByUpdatedAt(
    useSelector(state => state.savedComparisons),
  )

  const navigateToComparison = comparison => {
    const base = '/search-results'
    const query = comparison.query ? `/${comparison.query}` : ''
    const filter = comparison.filter ? `?${comparison.filter}` : ''
    history.push(`${base}${query}${filter}`)
  }

  const loadComparison = async comparison => {
    const clone = Object.assign({}, comparison)
    delete clone.key

    if (clone.compareList.length) {
      await Promise.all(
        clone.compareList.map(guid => dispatch(addObject(guid))),
      )
    }

    await dispatch(load(clone))
    navigateToComparison(comparison)
  }
  const openDeleteModal = key => () => {
    setComparsonToBeRemoved(comparisons.find(item => item.key === key))
    setShowDeleteModal(true)
  }
  const closeDeleteModal = () => {
    setComparsonToBeRemoved(undefined)
    setShowDeleteModal(false)
  }

  useEffect(() => {
    const comparisons = JSON.parse(localStorage.getItem('comparisons') || '{}')

    dispatch(setPageTitle())
    dispatch(hideSearchBar())
    dispatch(setSavedComparisons(comparisons))
    dispatch(enableComparePanel())

    return () => resetSearchBar()
  }, [dispatch])

  function deleteComparison() {
    if (comparisonToBeRemoved?.key) {
      dispatch(removeComparison(comparisonToBeRemoved.key))
    }
    closeDeleteModal()
  }

  return (
    <main className="saved-comparisons layout__grid">
      <Panel title="Opgeslagen vergelijkingen">
        <ul className="saved-comparisons__list">
          {comparisons.map(comparison => {
            return (
              <li key={comparison.key} className="saved-comparisons__list-item">
                <button
                  className="saved-comparisons__open-button text-bold"
                  onClick={() =>
                    comparison.lastSavedHash === currentLastSavedHashHash
                      ? navigateToComparison(comparison)
                      : loadComparison(comparison)
                  }
                  {...(comparison.lastSavedHash === currentLastSavedHashHash
                    ? { 'aria-current': 'true' }
                    : {})}
                >
                  {comparison.lastSavedHash === currentLastSavedHashHash && (
                    <span role="img" aria-label="Deze vergelijking is actief">
                      ✅
                    </span>
                  )}
                  <span className="sr-only">Open de vergelijking:</span>
                  <span>{comparison.title}</span>
                </button>
                <dl className="saved-comparisons__summary body-small">
                  <dd>Laatste aanpassing:</dd>
                  <dt>vandaag</dt>
                  {comparison.query && (
                    <>
                      <dd>Zoekterm:</dd>
                      <dt>{comparison.query}</dt>
                    </>
                  )}
                  {(comparison?.filter?.split(',')?.length || null) && (
                    <>
                      <dd>Aantal filters:</dd>
                      <dt>{comparison.filter.split(',').length}</dt>
                    </>
                  )}
                  {(comparison?.compareList?.length || null) && (
                    <>
                      <dd>Aantal objecten in vergelijking:</dd>
                      <dt>{comparison?.compareList?.length}</dt>
                    </>
                  )}
                </dl>
                <button
                  className="saved-comparisons__delete-button"
                  onClick={openDeleteModal(comparison.key)}
                >
                  Verwijderen
                  <span className="sr-only">: {comparison.title}</span>
                </button>
              </li>
            )
          })}
        </ul>
      </Panel>
      {showDeleteModal && (
        <Modal
          onClose={closeDeleteModal}
          title={`Verwijderen: ${comparisonToBeRemoved?.title}`}
        >
          <p>
            Weet je zeker dat je{' '}
            <strong className="text-bold">
              {comparisonToBeRemoved?.title}
            </strong>{' '}
            wilt verwijderen?
          </p>
          <div className="saved-comparisons__modal-buttons">
            <button
              className="button button--destructive"
              onClick={deleteComparison}
            >
              Ja
            </button>
            <button className="button" onClick={closeDeleteModal}>
              Nee
            </button>
          </div>
        </Modal>
      )}
    </main>
  )
}
