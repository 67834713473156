import React from 'react'
import { Typography, Box, EnergyLabel } from '../components'
import formatValue from '@brainbay/components/utils/format-value'
import { StyleSheet, Link } from '@react-pdf/renderer'

export default function PandCharacteristics({ item, debug, address }) {
  const { kenmerken } = item || {}

  const styles = StyleSheet.create({
    table: {
      display: 'flex',
      width: 'auto',
    },
    tableHeader: {
      fontWeight: 'bold',
    },
    tableRow: {
      flexDirection: 'row',
    },
    tableCol: {
      width: '25%',
    },
    tableCell: {
      marginTop: 5,
      fontSize: 10,
    },
    textCapitilize: {
      marginTop: 5,
      fontSize: 10,
      textTransform: 'capitalize',
    },
  })
  const BuildingSurfaceTablePDF = ({ item }) => {
    return (
      <>
        <Box style={styles.table}>
          <Box style={styles.tableRow}>
            <Box style={styles.tableCol}>
              <Typography style={styles.tableHeader}>Gebruiksdoel</Typography>
            </Box>
            <Box style={styles.tableCol}>
              <Typography style={styles.tableHeader}>Oppervlakte</Typography>
            </Box>
            <Box style={styles.tableCol}>
              <Typography style={styles.tableHeader}>
                Verblijfsobjecten
              </Typography>
            </Box>
          </Box>

          {Object.entries(item?.gebruiksdoelen)
            .sort(([key]) => (key === 'totaal' ? 1 : -1))
            .map(([key, value]) => (
              <Box
                key={key}
                style={[
                  styles.tableRow,
                  key === 'totaal' && { fontWeight: 'bold' },
                ]}
              >
                <Box style={styles.tableCol}>
                  <Typography style={styles.textCapitilize}>{key}</Typography>
                </Box>
                <Box style={styles.tableCol}>
                  <Typography style={styles.tableCell}>
                    {formatValue(value.oppervlakte, 'meter-squared')}
                  </Typography>
                </Box>
                <Box style={styles.tableCol}>
                  <Typography style={styles.tableCell}>
                    {value.count}
                  </Typography>
                </Box>
              </Box>
            ))}
        </Box>
      </>
    )
  }

  const gebruiksoppervlakteCharacteristic = kenmerken
    .find(x => x.title === 'BAG Pand')
    .items.find(x => x.type === 'gebruikersoppervlakte')

  return (
    <>
      {kenmerken?.map((characteristic, index) => {
        return (
          <Box
            key={index}
            style={{
              paddingBottom: 10,
              marginTop: 15,
            }}
            debug={debug}
          >
            <Typography
              style={{
                fontSize: 13,
                fontWeight: '700',
                paddingTop: 12,
                marginBottom: 2,
                borderBottom: '1px solid #dddddd',
              }}
              debug={debug}
            >
              {characteristic.title}
            </Typography>
            {characteristic.items.map((characteristicItem, index) => {
              let value = characteristicItem.value

              return (
                <Box
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: 10,
                    paddingTop: 6,
                    paddingBottom: 4,
                    borderBottom: '1px solid #dddddd',
                  }}
                  debug={debug}
                >
                  <Typography
                    style={{
                      width: 180,
                      fontSize: 11,
                      paddingRight: 10,
                    }}
                    debug={debug}
                  >
                    {characteristicItem.label}
                  </Typography>
                  {characteristicItem.type === 'energielabel' ? (
                    <EnergyLabel label={value} fontSize={11} />
                  ) : characteristicItem.type === 'url' ? (
                    <Link
                      style={{
                        width: '60%',
                        fontSize: 11,
                      }}
                      src={value}
                      debug={debug}
                    >
                      {characteristicItem.hrefLabel}
                    </Link>
                  ) : (
                    <Typography
                      style={{
                        width: '60%',
                        fontSize: 11,
                      }}
                      debug={debug}
                    >
                      {value}
                    </Typography>
                  )}
                </Box>
              )
            })}
          </Box>
        )
      })}
      <Box
        style={{
          marginLeft: '34%',
          width: '100%',
        }}
      >
        (<BuildingSurfaceTablePDF item={gebruiksoppervlakteCharacteristic} />)
      </Box>
    </>
  )
}
