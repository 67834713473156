import React, { useState, useEffect, forwardRef } from 'react'
import Panel from '@brainbay/components/components/panel'
import StatusLabel from '@brainbay/components/components/status-label'
import SourceLabel from '@brainbay/components/components/source-label'
import formatValue from '@brainbay/components/utils/format-value'
import ObjectDetailLink from '@brainbay/components/components/object-detail-link'
import formatAddressBusiness from '../../utils/format-address-business'
import getPriceType from '../../utils/use-object-history-prices'
import ExpandableFooter from '../expandable-footer/expandable-footer'

import './building-history.css'

const BuildingHistory = forwardRef(function BuildingHistory({ building }, ref) {
  const [hasLoaded, setHasLoaded] = useState(false)
  const buildingHistoryCount =
    building?.businessRegistratieHistories?.length ?? 0
  const [showRows, setShowRows] = useState(5)
  const [showFooter, setShowFooter] = useState(false)

  // show the footer based on the number of rows
  useEffect(() => {
    if (building && !building.loading) {
      setHasLoaded(true)
      if (hasLoaded && buildingHistoryCount > 5) {
        setShowFooter(true)
      }
    }
  }, [hasLoaded, building, buildingHistoryCount])

  // sort the table based on the date
  const sortDataOnDate =
    building?.businessRegistratieHistories?.length > 0
      ? building?.businessRegistratieHistories
          .slice()
          .sort((a, b) => new Date(b.datum) - new Date(a.datum))
      : []

  const handleShowMore = () => {
    setShowRows(showRows + 5)
  }
  const handleShowLess = () => {
    setShowRows(Math.max(showRows - 5, 5))
  }

  return (
    <div ref={ref}>
      <Panel
        title={`Historie in Brainbay Business database${
          hasLoaded || buildingHistoryCount === 0
            ? ` (${buildingHistoryCount})`
            : '(0)'
        }`}
        tag="section"
        fullBleed
        isOpenByDefault
        {...(buildingHistoryCount === 0
          ? {}
          : { collapsable: true, divider: true })}
      >
        {hasLoaded ? (
          <div className="building-history">
            <table className="building-history__table">
              <caption className="building-history__table-caption sr-only">
                Historie in Brainbay Business database
              </caption>
              {building?.businessRegistratieHistories?.length > 0 && (
                <thead>
                  <tr className="text-bold">
                    <th scope="col" className="building-history__table-header">
                      Status
                    </th>
                    <th scope="col" className="building-history__table-header">
                      Type
                    </th>
                    <th scope="col" className="building-history__table-header">
                      Adres
                    </th>
                    <th scope="col" className="building-history__table-header">
                      Datum
                    </th>
                    <th scope="col" className="building-history__table-header">
                      Oppervlakte
                    </th>
                    <th scope="col" className="building-history__table-header">
                      Prijs
                    </th>
                    <th scope="col" className="building-history__table-header">
                      Geïndexeerde prijs
                    </th>

                    <th scope="col" className="building-history__table-header">
                      Bron
                    </th>
                  </tr>
                </thead>
              )}
              <tbody>
                {building?.businessRegistratieHistories &&
                  sortDataOnDate.slice(0, showRows).map(object => {
                    const bron =
                      /^(bak\s*property\s*research(\s*\(.*\))?)/i.test(
                        object.bron,
                      )
                        ? 'Bak'
                        : object.bron
                    return (
                      <tr key={object.objectGuid}>
                        <td className="building-history__table-data">
                          <StatusLabel
                            className="building-history__status-label text-bold"
                            status={object.status}
                          />
                        </td>
                        <td className="building-history__table-data">
                          {object.hoofdfunctie}
                        </td>
                        <td className="building-history__table-data">
                          {formatAddressBusiness({
                            street: object.straatnaam,
                            houseNumber: object.huisnummer,
                            houseNumberSuffix: object.huisnummertoevoeging,
                          })}
                        </td>
                        <td className="building-history__table-data">
                          {formatValue(object.datum, {
                            format: 'date',
                          })}
                        </td>
                        <td className="building-history__table-data">
                          {formatValue(object.oppervlakte, {
                            format: 'meter-squared',
                          })}
                        </td>
                        <>
                          <td className="building-history__table-data">
                            {getPriceType(object)}
                          </td>
                        </>

                        <td className="building-history__table-data">
                          {object.indexedPrice
                            ? formatValue(object.indexedPrice, {
                                format: 'costs',
                                dynamicRound: true,
                                condition: object.prijsConditie,
                              })
                            : '---'}
                        </td>

                        <td className="building-history__table-data">{bron}</td>
                        <td className="building-history__table-data">
                          <ObjectDetailLink
                            guid={object?.objectGuid}
                            url={`/${object.objectGuid}`}
                            type="object_details"
                            page="gebouwpaspoort"
                          >
                            Details
                          </ObjectDetailLink>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
            <ExpandableFooter
              showFooter={showFooter}
              showNumberOfItems={showRows}
              itemsLength={sortDataOnDate?.length}
              onShowLess={handleShowLess}
              onShowMore={handleShowMore}
              showLessButtonCondition={5}
            />
          </div>
        ) : (
          <div className="loading-state"></div>
        )}
        <div className="building-history__source-label">
          <SourceLabel source="Brainbay" prefixSource="Bron" />
        </div>
      </Panel>
    </div>
  )
})
export default BuildingHistory
