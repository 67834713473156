import { status } from '@brainbay/components/utils/constants'
import formatValue from '@brainbay/components/utils/format-value'

function formattedPriceObject(object, { confidential, price, condition }) {
  //if object has confidential price return 'vertrouwelijk'
  if (object[confidential]) {
    return 'vertrouwelijk'
  }
  if (object?.kadasterPrijsRange) {
    return object.kadasterPrijsRange
  }
  if (object[price]) {
    return formatValue(object[price], {
      format: 'costs',
      condition: object[condition],
    })
  }
  //check to see if price is 0 or object doesn't have price
  if (!object[price]) {
    return '---'
  }
}
const saleProps = {
  confidential: 'koopprijsVertrouwelijk',
  price: 'vraagprijsKoop',
  condition: 'koopconditie',
}

const transactionProps = {
  confidential: 'transactieVertrouwelijk',
  price: 'prijs',
  condition: 'prijsConditie',
}

const rentProps = {
  confidential: 'huurprijsVertrouwelijk',
  price: 'vraagprijsHuur',
  condition: 'huurconditie',
}

export default function getPriceType(object) {
  switch (object?.status) {
    case status.VERKOCHT:
    case status.VERHUURD:
    case status.GEVEILD:
    case status.VERKOCHT_BIJ_INSCHRIJVING:
    case status.VERLENGING:
      return formattedPriceObject(object, transactionProps)
    case status.TE_KOOP:
    case status.TE_HUUR:
    case status.VERKOCHT_ONDER_VOORBEHOUD:
    case status.VERHUURD_ONDER_VOORBEHOUD:
    case status.VEILING:
    case status.TE_KOOP_EN_TE_HUUR:
      if (
        [
          status.TE_KOOP,
          status.VERKOCHT_ONDER_VOORBEHOUD,
          status.VEILING,
          status.TE_KOOP_EN_TE_HUUR,
        ].includes(object.status)
      ) {
        return formattedPriceObject(object, saleProps)
      } else if (
        [status.TE_HUUR, status.VERHUURD_ONDER_VOORBEHOUD].includes(
          object.status,
        )
      ) {
        return formattedPriceObject(object, rentProps)
      }
      break
    case status.INGETROKKEN:
      //check if the object has vraagprijsHuur or vraagprijsKoop key.
      if (
        (object?.vraagprijsKoop === undefined ||
          object?.vraagprijsHuur === undefined) &&
        object?.vraagprijsKoop === undefined &&
        object?.vraagprijsHuur === undefined
      ) {
        return '---'
      }
      //some objects have both koop and huur pirce
      if (object?.vraagprijsKoop && object?.vraagprijsHuur) {
        return `${formatValue(object.vraagprijsKoop, {
          format: 'costs',
          condition: object.koopconditie,
        })}/${formatValue(object.vraagprijsHuur, {
          format: 'costs',
          condition: object.huurconditie,
        })}`
        //check if the object has huur or koop price
      } else {
        if (object?.vraagprijsKoop) {
          return formattedPriceObject(object, saleProps)
        }
        if (object?.vraagprijsHuur) {
          return formattedPriceObject(object, rentProps)
        }
      }
      break
    default:
      break
  }
}
