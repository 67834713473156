import React, { forwardRef } from 'react'
import Panel from '@brainbay/components/components/panel'
import './building-marktanalyse-reports.css'

const reports = [
  {
    name: 'Kwartaalrapportage Commercieel Vastgoed 2024 Q2',
    url: 'https://www.nvm.nl/media/qachpd0l/kwartaalrapportage_2024q2.pdf',
    isNewReport: true,
  },
  {
    name: 'NVM Business Bedrijfsruimtemarkt rapport 2024',
    url: 'https://www.nvm.nl/media/qh3nyhxf/nvm-business-bedrijfsruimtemarkt-rapport-2024.pdf',
    isNewReport: true,
  },
  {
    name: 'Kwartaalrapportage Commercieel Vastgoed 2024 Q1',
    url: 'https://www.nvm.nl/media/vogn4otx/kwartaalrapportage_business_2024q1.pdf',
  },
  {
    name: 'NVM Business Kantorenmarkt rapport 2024',
    url: 'https://www.nvm.nl/media/egpjskih/nvm-business-kantoren-2024-schets-08.pdf',
  },
  {
    name: 'Jaarrapport Agrarisch en Landelijk, 2023 in feiten en cijfers',
    url: 'https://www.nvm.nl/media/zeepefde/nvm-rapport-vastgoedmarkt-in-beeld-agrarisch-landelijk-2023-in-feiten-en-cijfers.pdf',
  },
  {
    name: 'NVM Business Winkelmarkt rapport 2023',
    url: 'https://www.nvm.nl/media/qovdugh0/nvm-business-winkelmarktrapport-2023.pdf',
  },
]
const BuildingMarktAnalyse = forwardRef(
  function BuildingMarktAnalyse(props, ref) {
    return (
      <div ref={ref}>
        <Panel
          title="Marktanalyse"
          tag="section"
          collapsable
          divider
          isOpenByDefault
          className="building-marktanalyse"
        >
          {reports.map((report, index) => (
            <div key={index} className="report-item">
              <a
                href={report.url}
                target="_blank"
                rel="noopener noreferrer"
                className="report-link"
              >
                {report.name}
              </a>
              {report.isNewReport && (
                <span className="report-item__new-report-sign">Nieuw!</span>
              )}
            </div>
          ))}
        </Panel>
      </div>
    )
  },
)

export default BuildingMarktAnalyse
