import React from 'react'
import { Font } from '@react-pdf/renderer'

import rubikRegular from '../../../_assets/fonts/pdf-fonts/Rubik-Regular.ttf'
import rubikMedium from '../../../_assets/fonts/pdf-fonts/Rubik-Medium.ttf'
import { Page, PageHeader, PageFooter } from '../components'

Font.register({
  family: 'Rubik',
  format: 'truetype',
  fonts: [
    {
      src: rubikRegular,
    },
    {
      src: rubikMedium,
      fontWeight: 700,
    },
  ],
})

export default function DefaultLayout({
  children,
  pageName,
  userName,
  date,
  hideHeader = false,
  hideFooter = false,
  hasTransaction = false,
  debug = false,
  isGebouwpaspoort = false,
}) {
  return (
    <Page debug={debug}>
      {!hideHeader && <PageHeader pageName={pageName} debug={debug} />}
      {children}
      {!hideFooter && (
        <PageFooter
          userName={userName}
          date={date}
          hasTransaction={hasTransaction}
          debug={debug}
          isGebouwpaspoort={isGebouwpaspoort}
        />
      )}
    </Page>
  )
}
