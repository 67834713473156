import React from 'react'
import { Canvas, Image } from '@react-pdf/renderer'
import {
  mapboxStyle,
  mapboxToken,
} from '@brainbay/components/utils/environment-vars'
import { Box, Typography } from './index'
import { mapDefaults } from '../../../components/mapbox/mapbox'
import markerImage from '@brainbay/components/_assets/svg/icon-business-primary.png'

const ObjectSurroundingsDefinition = ({ title, items }) => {
  if (!items?.length) {
    return null
  }

  return (
    <Box style={{ marginTop: 10 }}>
      <Typography style={{ fontSize: 10, color: '#000', fontWeight: 700 }}>
        {title}
      </Typography>
      {items.map((str, index) => (
        <Typography
          style={{ fontSize: 9, color: '#000', textTransform: 'capitalize' }}
          key={index}
        >
          {str}
        </Typography>
      ))}
    </Box>
  )
}

export default function ObjectSurroundingMaps({ item, debug }) {
  const mapboxStyleToken = mapboxStyle.replace('mapbox://styles/', '')
  const mapWidth = 525
  const mapHeight = 400

  const longitude = item?.longitude || mapDefaults.LONGITUDE
  const latitude = item?.latitude || mapDefaults.LATITUDE

  const zoomedOut = mapDefaults.ZOOM_LEVEL_ZOOM_WIDE
  const zoomedIn = 14

  const mapBoxImageZoomedIn = `https://api.mapbox.com/styles/v1/${mapboxStyleToken}/static/${longitude},${latitude},${zoomedIn},0/${mapWidth}x${mapHeight}?access_token=${mapboxToken}`
  const mapBoxImageZoomedOut = `https://api.mapbox.com/styles/v1/${mapboxStyleToken}/static/${longitude},${latitude},${zoomedOut},0/${mapWidth}x${mapHeight}?access_token=${mapboxToken}`

  return (
    <>
      <Typography
        style={{
          fontSize: 18,
          color: '#000',
          fontWeight: 700,
          marginBottom: 10,
        }}
        debug={debug}
      >
        Omgevingskaarten
      </Typography>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
        debug={debug}
      >
        <Box
          style={{
            flex: '1 1 48%',
            width: '48%',
            marginRight: '2%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          debug={debug}
        >
          {mapBoxImageZoomedIn ? (
            <>
              <Image
                src={mapBoxImageZoomedIn}
                style={{ width: '100%', height: 'auto', position: 'relative' }}
                allowDangerousPaths
                break
              />
              <Image
                src={markerImage}
                style={{ width: 25, height: 'auto', position: 'absolute' }}
                allowDangerousPaths
              />
            </>
          ) : (
            <Box
              style={{
                width: '100%',
                height: 200,
                backgroundColor: '#888888',
              }}
              debug={debug}
            />
          )}
        </Box>
        <Box
          style={{
            flex: '1 1 48%',
            width: '48%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          debug={debug}
        >
          {mapBoxImageZoomedOut ? (
            <>
              <Image
                src={mapBoxImageZoomedOut}
                style={{ width: '100%', height: 'auto', position: 'relative' }}
                allowDangerousPaths
                break
              />
              <Image
                src={markerImage}
                style={{ width: 25, height: 'auto', position: 'absolute' }}
                allowDangerousPaths
              />
            </>
          ) : (
            <Box
              style={{
                width: '100%',
                height: 200,
                backgroundColor: '#888888',
              }}
              debug={debug}
            />
          )}
        </Box>
      </Box>
      <ObjectSurroundingsDefinition title="Ligging" items={item.liggingen} />
      <ObjectSurroundingsDefinition
        title="Liggingskenmerken"
        items={item.liggingskenmerken}
      />
      <ObjectSurroundingsDefinition
        title="Toelichting lokatie"
        items={item.toelichtingLocatie}
      />
      <ObjectSurroundingsDefinition
        title="Horeca concentratiegebied"
        items={
          typeof item.indicatieHorecaConcentratiegebied !== 'undefined'
            ? [item.indicatieHorecaConcentratiegebied ? 'Ja' : 'Nee']
            : []
        }
      />
      <ObjectSurroundingsDefinition
        title="Lokale voorzieningen"
        items={item.lokaleVoorzieningen}
      />
      <ObjectSurroundingsDefinition
        title="Bereikbaarheid"
        items={item.bereikbaarheid}
      />
      <Canvas
        paint={painter =>
          painter.moveTo(0, 0).lineTo(525, 0).lineWidth(1).stroke('#000')
        }
        style={{ marginVertical: 10 }}
      />
    </>
  )
}
