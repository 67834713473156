import React from 'react'
import StatusLabel from '../status-label'
import { status as constantsStatus } from '../../utils/constants'
import formatValue from '../../utils/format-value'
import './object-label.css'

export default function ObjectLabel({
  children,
  className = '',
  match,
  status,
  duration,
  isSmall,
  showStatusOnly,
}) {
  const isSold = status === constantsStatus.VERKOCHT
  return (
    <div className={`object-label ${className}`.trim()}>
      {children}

      {match !== undefined && (
        <div
          className={`object-label__match object-label__focus${
            isSmall ? ' body-small' : ' body'
          }`}
        >
          <span className="text-bold">
            {formatValue(match, { format: 'percent' })}
          </span>{' '}
          Match
        </div>
      )}

      <div
        className={`${isSmall ? 'body-small' : 'body'}${
          status || duration ? ' object-label__availablility' : ''
        }`.trim()}
      >
        {status && status !== constantsStatus.ONBEKEND && (
          <StatusLabel
            status={status}
            className={
              showStatusOnly
                ? 'object-label__label body-small text-bold capitalize-first-letter'
                : 'text-bold capitalize-first-letter'
            }
          />
        )}

        {duration !== undefined && (
          <div className="object-label__availablility-value">
            <span>{isSold ? 'Verkooptijd: ' : 'Looptijd: '}</span>
            <span className="text-bold">
              {formatValue(duration, { format: 'days' })}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}
