import React, { useState, useEffect } from 'react'
import { useDebouncedCallback as debounce } from 'use-debounce'

/**
 * This component is meant to announce information about the loading state and
 * amount of options available.
 *
 * The content can be debounced so that the users is not overflooded with
 * messages
 *
 * The messages are rendered alternated in different slots so each message is
 * spoken
 */

export default function Status({
  query = '',
  minQueryLength = 2,
  isInFocus,
  optionsAreLoading = false,
  visibleOptions = [],
  options = [],
  selectedIndex,
}) {
  const [bump, setBump] = useState(false)
  const [queryIsValidOption, setQueryIsValidOption] = useState(false)
  const [content, setContent] = useState('')
  const setContentDebounced = debounce(updateContent(), 500)
  const setContentNow = debounce(updateContent(true), 10, { leading: true })

  function updateContent(shouldCancelWaitingMessages) {
    return function update(value) {
      shouldCancelWaitingMessages && setContentDebounced.cancel()
      if (value !== content) {
        setBump(!bump)
        setContent(value)
      }
    }
  }

  useEffect(() => {
    const foundItem = options
      .map(({ label }) => label.toLowerCase())
      .find(label => label === query.toLocaleLowerCase())

    setQueryIsValidOption(!!foundItem)
  }, [options, query])

  useEffect(() => {
    // Options are visible
    if (visibleOptions.length > 0) {
      setContentDebounced(`${visibleOptions.length} opties beschikbaar.`)

      // We are loading options
    } else if (
      visibleOptions.length === 0 &&
      query.length >= minQueryLength &&
      queryIsValidOption === false &&
      optionsAreLoading === true
    ) {
      setContentNow(`Opties worden geladen`)

      // Options are loaded, but none does match query
    } else if (
      visibleOptions.length === 0 &&
      queryIsValidOption === false &&
      optionsAreLoading === false
    ) {
      const text = query.length
        ? `Geen resultaten gevonden voor: ${query}`
        : 'Geen resultaten'
      setContentDebounced(text)

      // All other cases, clear out
    } else {
      setContentNow('')
    }
  }, [
    query,
    isInFocus,
    visibleOptions,
    selectedIndex,
    setContentNow,
    setContentDebounced,
    minQueryLength,
    queryIsValidOption,
    optionsAreLoading,
  ])

  return (
    <div className="sr-only">
      <div
        role="status"
        aria-atomic="true"
        aria-live="polite"
        style={{ backgroundColor: 'red' }}
      >
        {isInFocus && !bump && content}
      </div>
      <div
        role="status"
        aria-atomic="true"
        aria-live="polite"
        style={{ backgroundColor: 'green' }}
      >
        {isInFocus && bump && content}
      </div>
    </div>
  )
}
