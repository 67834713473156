import React from 'react'
import useDebouncedValue from '../../../../utils/use-debounced-value'
import Label from '../label'
import './number-input.css'

export default function NumberInput({
  control,
  filters,
  groupLabel,
  onChange,
  debounceDelay = 500,
}) {
  const [filterValue, setFilterValue] = useDebouncedValue(
    filters?.[control.name] || '',
    onChange,
    debounceDelay,
  )

  return (
    <>
      <Label
        htmlFor={`search-filters__control--${control.name}`}
        groupLabel={groupLabel}
        control={control}
      />
      <input
        id={`search-filters__control--${control.name}`}
        className="number-input__input"
        name={control.name}
        type="number"
        value={filterValue}
        onChange={setFilterValue}
        min={control?.min}
        max={control?.max}
        step={control?.step}
      />
    </>
  )
}
