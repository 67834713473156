import React from 'react'
import { Canvas } from '@react-pdf/renderer'
import formatValue from '@brainbay/components/utils/format-value'
import DefaultLayout from '../layouts/default'
import { Typography, Box } from '../components'
import { convert } from '../helpers/convert-svg-to-react-pdf-svg'
export default function priceIndexPage({
  item,
  priceIndex,
  userName,
  date,
  debug,
  hasTransaction,
  graphSVG,
}) {
  const description =
    item?.transactie?.transactieDatum &&
    `Op basis van prijsontwikkeling (${
      priceIndex?.indexLevel?.toLowerCase() ?? 'onbekend'
    }) sinds laatste keer ${
      item?.transactie?.koopprijs?.prijs ? 'verkocht' : 'verhuurd'
    } (${formatValue(item?.transactie?.transactieDatum, 'quarterly-date')})`
  const lastPriceIndexItem =
    priceIndex?.prijsOntwikkelingsGrafiek &&
    priceIndex?.prijsOntwikkelingsGrafiek[
      priceIndex?.prijsOntwikkelingsGrafiek.length - 1
    ]
  const convertedChart = graphSVG.current
    ? convert(graphSVG.current)
    : 'Geen grafiek beschikbaar'
  return (
    <DefaultLayout
      pageName={`Geïndexeerde ${
        item?.transactie?.koopprijs?.prijs ? 'koopsom' : 'huurprijs'
      }`}
      userName={userName}
      date={date}
      hasTransaction={hasTransaction}
      debug={debug}
    >
      <Typography
        style={{
          fontSize: 18,
          color: '#000',
          fontWeight: 700,
          marginBottom: 10,
        }}
        debug={debug}
      >
        Indicatie actuele waarde{' '}
        {lastPriceIndexItem &&
          formatValue(lastPriceIndexItem.periode, 'quarterly-date')}
      </Typography>
      <Typography
        style={{
          fontSize: 22,
          color: '#000',
          fontWeight: 700,
        }}
        debug={debug}
      >
        {formatValue(priceIndex?.prijsOntwikkeling, {
          format: 'costs',
          condition: item?.transactie?.transactieConditie,
          dynamicRound: true,
        })}
      </Typography>
      <Typography style={{ marginTop: 5 }} debug={debug}>
        {description}
      </Typography>
      <Typography
        style={{
          fontSize: 18,
          color: '#000',
          fontWeight: 700,
          marginTop: 40,
        }}
        debug={debug}
      >
        Prijsontwikkeling
      </Typography>
      <Canvas
        paint={painter =>
          painter.moveTo(0, 0).lineTo(525, 0).lineWidth(2).stroke('#000')
        }
        style={{ marginTop: 10 }}
        debug={debug}
      />
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          marginTop: 20,
        }}
        debug={debug}
      >
        {convertedChart}
      </Box>
    </DefaultLayout>
  )
}
