import React from 'react'
import './autocomplete-item.css'

export default function AutosuggestItem({ label, type, parent }) {
  return (
    <div className="autocomplete__list-item autocomplete__list-item--with-details body">
      {label}
      <AutocompleteItemDetails type={type} parent={parent} />
    </div>
  )
}

const AutocompleteItemDetails = ({ type, parent }) => {
  if (parent?.parent?.label) {
    return (
      <span className="autocomplete-item__details">
        adres in {parent.parent.label}
      </span>
    )
  }

  if (parent?.label) {
    return (
      <span className="autocomplete-item__details">
        straat in {parent.label}
      </span>
    )
  }

  return <span className="autocomplete-item__details">{type}</span>
}
