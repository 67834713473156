import React from 'react'
import Modal from '../portal'
import './modal.css'

export default function Info({
  onClose,
  title,
  className,
  titleTag,
  subTitle = '',
  children,
}) {
  const TitleTag = titleTag ? titleTag : 'h3'

  return (
    <Modal className={`modal-child ${className}`} onClose={onClose}>
      <div className="modal-child__header">
        <TitleTag className="modal-child__title h5">{title}</TitleTag>
        {subTitle && <p className="body">{subTitle}</p>}
      </div>

      <div className="body">{children}</div>
    </Modal>
  )
}
