import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get } from '../utils/api-data'
import { gebouwpaspoortApiHost } from '../utils/business-environment-vars'

export const resultPerPage = 10

export const fetchKvkUsers = createAsyncThunk(
  'kvk-search/fetchKvkUsers',
  async (object, thunkAPI) => {
    const { address, page } = object
    if (address && address.huisnummer && address.postcode) {
      const apiPath = address.huisnummertoevoeging
        ? `proxies/kvk-search?postcode=${address.postcode}&huisnummer=${address.huisnummer}&huisletter=${address.huisnummertoevoeging}&pagina=${page}&resultatenPerPagina=${resultPerPage}`
        : `proxies/kvk-search?postcode=${address.postcode}&huisnummer=${address.huisnummer}&pagina=${page}&resultatenPerPagina=${resultPerPage}`

      try {
        const response = await get(apiPath, {
          apiHostUrl: gebouwpaspoortApiHost(),
        })
        const data = JSON.parse(response)
        return data
      } catch (error) {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  },
)

const kvkUsers = createSlice({
  name: 'kvkUsers',
  initialState: {
    kvkUsers: [],
    total: 0,
    address: undefined,
    status: 'idle',
    error: null,
  },
  reducers: {
    clearKvkUsers: state => {
      state.kvkUsers = []
      state.total = 0
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchKvkUsers.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchKvkUsers.fulfilled, (state, action) => {
        const fetchedKvkUsers = action?.payload?.resultaten

        state.status = 'succeeded'
        state.kvkUsers = fetchedKvkUsers
          ? action.meta.arg.page === 1
            ? [...fetchedKvkUsers]
            : [...state.kvkUsers, ...fetchedKvkUsers]
          : []
        state.total = action.payload?.totaal || 0
      })
      .addCase(fetchKvkUsers.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })
  },
})

export const selectKvkUsers = state => state.kvkUsers
export const { clearKvkUsers } = kvkUsers.actions

export default kvkUsers.reducer
