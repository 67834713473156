import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from './api-data'
import { loadBulkObjectsByObjectGuid } from '../store/objects'
import { gebouwpaspoortApiHost } from './business-environment-vars'

export default function useReferences(searchValue) {
  const dispatch = useDispatch()
  const objects = useSelector(state => state.objects || [])
  const { sortReferencesBy, sortReferencesDirection } = useSelector(
    state => state.comparison,
  )
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  // Reset initial data, then load reference data when searchValue changes
  useEffect(() => {
    setLoading(true)
    setData([])
    setError(false)

    const apiHost = isNaN(searchValue) ? '' : gebouwpaspoortApiHost()

    get(`referenties/${searchValue}?top=10`, { apiHostUrl: apiHost })
      .then(response => setData(response[sortReferencesBy]['References']))
      .catch(error => setError(error))
      .finally(() => setLoading(false))
  }, [searchValue, sortReferencesDirection, sortReferencesBy])

  // Call loadBulkObjectsByObjectGuid when reference data is fetched
  useEffect(() => {
    const ids = data.map(({ BusinessGuid }) => BusinessGuid)
    ids.length > 0 && dispatch(loadBulkObjectsByObjectGuid(ids))
  }, [data, dispatch])

  const referencesData = useMemo(() => {
    return data
      .map(item => ({
        objectGuid: item.BusinessGuid,
        match: item.MatchingPercentage,
        ...(objects[item.BusinessGuid] || {}),
      }))
      .filter(object => object.straatnaam)
  }, [data, objects])

  return {
    referencesData,
    referencesAreLoading: loading,
    referencesError: error,
  }
}
