import { useEffect, useState } from 'react'

/**
 * This hook allows you to execute JS based on whether a media query is being matched.
 * @param {String} query
 * @returns
 */
export default function useMediaQuery(query) {
  const [matches, setMatches] = useState(() => window.matchMedia(query).matches)
  useEffect(() => {
    const mq = window.matchMedia(query)
    const callback = e => {
      setMatches(e.matches)
    }
    mq.addEventListener('change', callback)
    return () => {
      mq.removeEventListener('change', callback)
    }
  }, [query])
  return matches
}
