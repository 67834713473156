import { createSlice } from '@reduxjs/toolkit'
import log from '@brainbay/components/utils/log'

const initialState = {}

function removeComparisonReducer(state, { payload: key }) {
  try {
    const clone = Object.assign({}, state)
    delete clone[key]
    const newJson = JSON.stringify(clone)
    localStorage.setItem('comparisons', newJson)
  } catch (error) {
    log.error(`Could not delete comparison: ${key}`)
    return state
  }

  delete state[key]
  log.info(`Deleted comparison: ${key}`)
}

const savedComparisons = createSlice({
  name: 'savedComparisons',
  initialState,
  reducers: {
    setSavedComparisons: (_, { payload }) => payload,
    removeComparison: removeComparisonReducer,
  },
})

export const { setSavedComparisons, removeComparison } =
  savedComparisons.actions

export default savedComparisons.reducer
