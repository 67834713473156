import React, { forwardRef, useEffect, useState } from 'react'
import Panel from '@brainbay/components/components/panel'
import titleCase from '../../utils/title-case'
import ExpandableFooter from '../expandable-footer/expandable-footer'
import formatValue from '@brainbay/components/utils/format-value'

import './building-bekendmakingen.css'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchLocalAnnouncements,
  handleShowLessAnnouncements,
  handleShowMoreAnnouncements,
  numberOfAnnouncementsToShowOnPage,
  selectLocalAnnouncements,
} from '../../store/lokale-bekendmakingen'

const BuildingBekendmakingen = forwardRef(function BuildingBekendmakingen(
  { data },
  ref,
) {
  const [address, setAddress] = useState({})
  const [hasLoaded, setHasLoaded] = useState(false)

  const localAnnouncements = useSelector(selectLocalAnnouncements)
  const showNumberOfAnnouncements =
    localAnnouncements.numberOfAnnouncementsToShow

  const dispatch = useDispatch()

  useEffect(() => {
    if (data && !data.loading) {
      setAddress(data?.geselecteerdeAdres)
      setHasLoaded(true)
    }
  }, [data])

  useEffect(() => {
    dispatch(fetchLocalAnnouncements(address))
  }, [dispatch, address])

  return (
    <div ref={ref}>
      <Panel
        title={`Lokale bekendmakingen (${localAnnouncements?.total ?? 0})`}
        tag="section"
        collapsable
        divider
        isOpenByDefault
        className="building-bekendmakingen"
      >
        <div>
          <p className="building-bekendmakingen__description body">
            Hieronder vindt u een overzicht van de officiële bekendmakingen
            binnen een straal van 500 meter van het object. Klik op de
            bekendmaking voor meer informatie.
          </p>
          {hasLoaded && localAnnouncements?.total > 0 ? (
            <>
              <table className="building-bekendmakingen__table">
                <thead>
                  <tr>
                    <th className="building-bekendmakingen__table-header">
                      <span className="sr-only">Index</span>
                    </th>
                    <th className="building-bekendmakingen__table-header">
                      Datum
                    </th>
                    <th className="building-bekendmakingen__table-header">
                      Overheid
                    </th>
                    <th className="building-bekendmakingen__table-header">
                      Onderwerp
                    </th>
                    <th className="building-bekendmakingen__table-header">
                      Titel
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {localAnnouncements?.announcements
                    ?.slice(0, showNumberOfAnnouncements)
                    .map((announcement, index) => (
                      <tr key={index}>
                        <td className="building-bekendmakingen__table-data">
                          {index + 1}.
                        </td>
                        <td className="building-bekendmakingen__table-data building-bekendmakingen__table-data-date">
                          {formatValue(announcement?.DatumBeschikbaar / 1000, {
                            format: 'date',
                          })}
                        </td>
                        <td className="building-bekendmakingen__table-data">
                          {announcement?.Maker}
                        </td>
                        <td className="building-bekendmakingen__table-data">
                          {titleCase(announcement?.Rubriek)}
                        </td>
                        <td className="building-bekendmakingen__table-data">
                          <a
                            className="link_to_bekendmakingen-official"
                            href={announcement?.URL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {announcement?.Titel}
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          ) : (
            <p className="body">
              Geen officiële bekendmakingen aanwezig binnen een straal van 500
              meter van het object.
            </p>
          )}
          <ExpandableFooter
            showFooter={
              localAnnouncements?.total > numberOfAnnouncementsToShowOnPage
            }
            showNumberOfItems={showNumberOfAnnouncements}
            itemsLength={localAnnouncements?.total}
            onShowLess={() => dispatch(handleShowLessAnnouncements())}
            onShowMore={() => dispatch(handleShowMoreAnnouncements())}
            showLessButtonCondition={numberOfAnnouncementsToShowOnPage}
          />
        </div>
        <div className="building-bekendmakingen__source-label">
          <span className="text-dim">Bron: </span>
          <a
            href="https://www.overheid.nl/"
            target="_blank"
            rel="noopener noreferrer"
            className="building-bekendmakingen__source-label-link"
          >
            Overheid.nl
          </a>
        </div>
      </Panel>
    </div>
  )
})
export default BuildingBekendmakingen
