import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'

import Icon from '../icon'
import isSSR from '../../utils/is-ssr'
import { ReactComponent as IconClose } from '../../_assets/svg/icon-close.svg'

import './modal.css'

const focusableElementSelector =
  'a[href], area[href], input:not([disabled]):not([type="hidden"]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable], audio[controls], video[controls], summary, [tabindex^="0"], [tabindex^="1"], [tabindex^="2"], [tabindex^="3"], [tabindex^="4"], [tabindex^="5"], [tabindex^="6"], [tabindex^="7"], [tabindex^="8"], [tabindex^="9"]'

function getFocusableElements(el) {
  return [...el.querySelectorAll(focusableElementSelector)].filter(
    element => element.disabled === false,
  )
}

const mount = document.getElementById('portal-root')
mount.classList.add('print-excluded')

function createOrGetPortalLayer(id) {
  const prefixedId = 'portalLayer-' + id

  let el = document.getElementById(prefixedId)
  if (el) {
    return el
  }

  el = document.createElement('aside')
  el.id = prefixedId

  const onMutation = function (mutationsList, observer) {
    for (let mutation of mutationsList) {
      if (mutation.type === 'childList') {
        const focusableElements = getFocusableElements(el)

        // If: there are focusable child elements, focus the first one
        if (focusableElements[0]) {
          focusableElements[0].focus()
        }
      }
    }
  }
  if (isSSR === false && window.MutationObserver) {
    const observer = new MutationObserver(onMutation)
    observer.observe(el, { childList: true })
  }

  return el
}

export default function Modal({
  children,
  onClose,
  fullPage,
  className = '',
  hideCloseButton,
  buttonText,
  portalLayerId = 'default',
}) {
  const el = useMemo(
    () => createOrGetPortalLayer(portalLayerId),
    [portalLayerId],
  )
  const closeButton = useRef(null)
  const wrapper = useRef(null)
  const [mounted, setMounted] = useState(false)
  const [prevElementWithFocus, setPrevElementWithFocus] = useState(undefined)
  const handleClose = useCallback(() => {
    onClose()
    prevElementWithFocus.focus()
  }, [prevElementWithFocus, onClose])
  el.classList.add('modal')

  const contentClassNames = classNames({
    modal__content: true,
    [className]: className,
  })

  const buttonClassNames = classNames({
    'modal__close-button': true,
    'modal__close-button--with-text': Boolean(buttonText),
    'text-bold': Boolean(buttonText),
  })

  useEffect(() => {
    mount.appendChild(el)

    if (fullPage) {
      el.classList.add('modal--full-page')
    } else {
      el.classList.remove('modal--full-page')
    }

    setPrevElementWithFocus(document.activeElement)

    return () => {
      mount.removeChild(el)
    }
  }, [fullPage, el])

  useEffect(() => {
    function handleKeyDown(event) {
      const focusableElements = getFocusableElements(el)
      const firstFocusableElement = focusableElements[0]
      const currentCloseBtn = closeButton.current
      const activeElement = document.activeElement
      if (event.key === 'Escape') {
        handleClose()
        prevElementWithFocus.focus()
      }

      if (
        activeElement === currentCloseBtn &&
        event.key === 'Tab' &&
        event.shiftKey === false &&
        firstFocusableElement
      ) {
        event.preventDefault()
        firstFocusableElement.focus()
      }

      if (
        activeElement === firstFocusableElement &&
        event.key === 'Tab' &&
        event.shiftKey === true
      ) {
        event.preventDefault()
        currentCloseBtn.focus()
      }
    }
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleClose, prevElementWithFocus, el])

  useEffect(() => {
    const scrollY = window.scrollY
    const body = document.body
    body.classList.add('body--fixed')
    body.style.top = `-${scrollY}px`

    // For using vh in the modal we can optimise it with this so it will also be the visible vh.
    // Thanks to https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    window.addEventListener('resize', setVh)

    function setVh() {
      let vh = window.innerHeight * 0.01
      body.style.setProperty('--vh', `${vh}px`)
    }

    setVh()
    setMounted(true)

    return () => {
      body.classList.remove('body--fixed')
      body.style.top = ''
      window.scrollTo(0, parseInt(scrollY || 0))

      window.removeEventListener('resize', setVh)
      body.style.removeProperty('--vh')
    }
  }, [])

  return (
    mounted &&
    createPortal(
      <div ref={wrapper} className={contentClassNames}>
        {children}

        {!hideCloseButton && (
          <button
            ref={closeButton}
            className={buttonClassNames}
            onClick={handleClose}
          >
            <span className="sr-only">Sluiten</span>
            {buttonText && (
              <span className="modal__close-button-text">{buttonText}</span>
            )}
            <Icon>
              <IconClose />
            </Icon>
          </button>
        )}
      </div>,
      el,
    )
  )
}
