import React from 'react'
import DefaultLayout from '../layouts/default'
import { Typography, Box } from '../components'
import { StyleSheet } from '@react-pdf/renderer'
import { getObjectAddress } from '../helpers'

export default function KvkUsersPage({
  item,
  users,
  userName,
  date,
  isGebouwpaspoort,
}) {
  const kvkUsers = users.kvkUsers
  const totalKvkUsers = users.total
  const formattedAddress = getObjectAddress(item, isGebouwpaspoort)

  const styles = StyleSheet.create({
    tableContainer: {
      display: 'table',
      width: '100%',
    },
    tableHeader: {
      fontWeight: 'bold',
      fontSize: 12,
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderColor: '#0099bc',
    },
    tableRow: {
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottom: '1px solid #dddddd',
    },
    tableCell: {
      flex: 1,
      padding: 6,
      textAlign: 'left',
      fontSize: 10,
    },
  })

  return (
    <DefaultLayout
      pageName="Actuele gebruikers"
      userName={userName}
      date={date}
      isGebouwpaspoort={isGebouwpaspoort}
    >
      <Typography
        style={{
          fontSize: 18,
          color: '#000',
          fontWeight: 700,
          marginBottom: 10,
        }}
      >
        {`Actuele gebruikers (KvK) (${totalKvkUsers})`}
      </Typography>

      <Typography
        style={{
          fontSize: 11,
          color: '#000',
          marginBottom: 10,
        }}
      >
        Adres: {formattedAddress}
      </Typography>

      <Box style={styles.tableContainer}>
        {kvkUsers?.length > 0 && (
          <Box style={styles.tableHeader}>
            <Typography style={{ ...styles.tableCell, flex: 0.5 }}></Typography>

            <Typography style={styles.tableCell}>KVK nummer</Typography>

            <Typography style={{ ...styles.tableCell, flex: 1.5 }}>
              Handelsnaam
            </Typography>

            <Typography style={styles.tableCell}>Adres type</Typography>

            <Typography style={styles.tableCell}>Type</Typography>
          </Box>
        )}
        {kvkUsers?.map((objectData, rowIndex) => (
          <Box style={styles.tableRow} key={rowIndex} wrap={false}>
            <Typography style={{ ...styles.tableCell, flex: 0.5 }}>
              {rowIndex + 1}.
            </Typography>

            <Typography style={styles.tableCell}>
              {objectData?.kvkNummer}
            </Typography>

            <Typography style={{ ...styles.tableCell, flex: 1.5 }}>
              {objectData?.naam || '---'}
            </Typography>

            <Typography style={styles.tableCell}>
              {objectData?.adres?.binnenlandsAdres?.type}
            </Typography>

            <Typography style={styles.tableCell}>{objectData?.type}</Typography>
          </Box>
        ))}
      </Box>
    </DefaultLayout>
  )
}
