import { createSlice } from '@reduxjs/toolkit'

const pageTitle = createSlice({
  name: 'pageTitle',
  initialState: '',
  reducers: {
    setLanding: _ => `Home`,
    setSearchResult: (_, { payload }) =>
      `Zoekresultaten voor ${payload.query}. Pagina ${payload.page} van ${payload.totalPages}`,
    setObjectDetails: (_, { payload }) =>
      payload ? payload : 'Object details',
    setBuildingDetails: (_, { payload }) =>
      payload ? payload : 'Building details',
    setCompare: _ => 'Vergelijken',
    setSavedComparisons: _ => 'Opgeslagen vergelijkingen',
  },
})

export const {
  setLanding,
  setSearchResult,
  setObjectDetails,
  setBuildingDetails,
  setCompare,
  setSavedComparisons,
} = pageTitle.actions

export default pageTitle.reducer
