import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import Icon from '../icon'
import ResponsiveImage from '../responsive-image'

import { ReactComponent as IconArrow } from '../../_assets/svg/icon-chevron-back.svg'

import { apiHost } from '../../utils/environment-vars'
import {
  calculateImageWidth,
  createImageSrc,
} from '../../utils/responsive-image-helpers'

import './image-viewer.css'

export default function ImageViewer({
  customWidth,
  disableLazyLoad,
  id,
  isLoading = false,
  mediaGuids = [],
  mediaSourceId,
  selectedMediaGuidIndex,
  smallButtons,
  onMediaGuidChange = () => {},
  onClick = () => {},
}) {
  const [activeMediaGuidIndex, setActiveMediaGuidIndex] = useState(
    selectedMediaGuidIndex ? selectedMediaGuidIndex : 0,
  )
  const [imageRef, setImageRef] = useState(null)
  const [touchStartX, setTouchStartX] = useState(0)
  const [touchEndX, setTouchEndX] = useState(0)
  const [nextIndex, setNextIndex] = useState(0)

  useEffect(() => {
    if (!mediaGuids.length) {
      return
    }

    setNextIndex((activeMediaGuidIndex + 1) % mediaGuids.length)
  }, [activeMediaGuidIndex, mediaGuids])

  useEffect(() => {
    if (!imageRef || nextIndex === -1 || !mediaGuids.length) {
      return
    }
    const img = new Image()
    const width = calculateImageWidth({ ref: imageRef })
    img.src = createImageSrc({ id: mediaGuids[nextIndex], width })
  }, [nextIndex, imageRef, mediaGuids])

  const buttonWrapperClasses = classNames({
    'image-viewer__buttons': true,
    'image-viewer__buttons--small': smallButtons,
    'print-excluded': true,
  })

  const prevButtonClasses = classNames({
    'image-viewer__button': true,
    'image-viewer__button--small': smallButtons,
    'image-viewer__icon-previous': true,
  })

  const nextButtonClasses = classNames({
    'image-viewer__button': true,
    'image-viewer__button--small': smallButtons,
    'image-viewer__icon-next': true,
  })

  const prevButtonId = `image-viewer-${id}__vorige-foto`

  const nextButtonId = `image-viewer-${id}__volgende-foto`

  const loadingStrategy = disableLazyLoad ? 'eager' : 'lazy'

  function handleTouchStart(e) {
    setTouchStartX(e.targetTouches[0].clientX)
  }

  function handleTouchMove(e) {
    setTouchEndX(e.targetTouches[0].clientX)
  }

  function handleTouchEnd() {
    if (touchStartX - touchEndX > 25) {
      nextMediaGuid()
    }

    if (touchStartX - touchEndX < -25) {
      previousMediaGuid()
    }
  }

  function handleKeyDown(e) {
    if (e.keyCode === 37) {
      previousMediaGuid()
    }

    if (e.keyCode === 39) {
      nextMediaGuid()
    }
  }

  function nextMediaGuid() {
    let newActiveMediaGuidIndex = activeMediaGuidIndex + 1
    const totalMediaGuids = mediaGuids.length - 1

    if (newActiveMediaGuidIndex > totalMediaGuids) {
      newActiveMediaGuidIndex = 0
    }

    onMediaGuidChange(mediaGuids[newActiveMediaGuidIndex])
    setActiveMediaGuidIndex(newActiveMediaGuidIndex)
  }

  function previousMediaGuid() {
    let newActiveMediaGuidIndex = activeMediaGuidIndex - 1
    const totalMediaGuids = mediaGuids.length - 1

    if (activeMediaGuidIndex <= 0) {
      newActiveMediaGuidIndex = totalMediaGuids
    }

    onMediaGuidChange(mediaGuids[newActiveMediaGuidIndex])
    setActiveMediaGuidIndex(newActiveMediaGuidIndex)
  }

  return (
    <>
      <div
        className="image-viewer__wrapper"
        tabIndex="0"
        aria-label="object foto"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        onKeyDown={handleKeyDown}
        onClick={onClick}
        ref={setImageRef}
      >
        {mediaGuids.length > 0 ? (
          <ResponsiveImage
            className="image-viewer__image"
            customWidth={customWidth}
            imageId={mediaGuids?.[activeMediaGuidIndex]}
            loading={loadingStrategy}
            isLoading={isLoading}
          />
        ) : (
          <ResponsiveImage
            className="image-viewer__image"
            customWidth={customWidth}
            host={`${apiHost()}/streetview`}
            imageId={mediaSourceId}
            loading={loadingStrategy}
            isLoading={isLoading}
          />
        )}
      </div>
      {!isLoading && mediaGuids.length > 1 && (
        <div className={buttonWrapperClasses}>
          <button
            type="button"
            className={prevButtonClasses}
            aria-labelledby={prevButtonId}
            onClick={previousMediaGuid}
          >
            <span id={prevButtonId} className="sr-only">
              Vorige foto
            </span>
            <Icon className="image-viewer__icon-arrow">
              <IconArrow />
            </Icon>
          </button>
          <button
            type="button"
            className={nextButtonClasses}
            aria-labelledby={nextButtonId}
            onClick={nextMediaGuid}
          >
            <span id={nextButtonId} className="sr-only">
              Volgende foto
            </span>
            <Icon>
              <IconArrow />
            </Icon>
          </button>
        </div>
      )}
    </>
  )
}
