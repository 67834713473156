import React from 'react'
import './building-walkscore-badge.css'

const WalkScoreBadge = ({ address }) => {
  const { straatnaam, huisnummer, woonplaats, postcode } = address
  const badgeCode = `
    <div style="padding: 0; margin: 0; border: 0; outline: 0; width: 300px;">
      <div style="border:0;outline:0;padding:0; margin: 11px 9px 0;">
        <h2 style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; padding: 0; margin: 0; border: 0; outline: 0; text-decoration: none; vertical-align: baseline; font-style: normal; background: none; text-align: left; font:15px/19px 'Arial Black', Gadget, sans-serif; font-weight: normal; color: #222;">
          ${huisnummer} ${straatnaam}
        </h2>
        <h3 style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; padding: 0; margin: 0; border: 0; outline: 0; text-decoration: none; vertical-align: baseline; font-style: normal; background: none; text-align: left; font:12px/14px Verdana, Arial, Helvetica, sans-serif; font-weight: bold; color: #666; margin: 2px 0 4px 0;">
          ${woonplaats}
        </h3>
        <div style="padding: 0; margin: 0; border: 0; outline: 0; position: relative; width: 100%; padding-bottom: 30%">
          <div style="padding: 0; margin: 0; border: 0; outline: 0; position: absolute; top: 0; bottom: 0; left: 0; right: 0;">
            <a rel="nofollow" href="https://www.walkscore.com/score/${huisnummer}-${straatnaam.replace(
              ' ',
              '-',
            )}-${woonplaats.replace(' ', '-')}-${postcode.replace(
              ' ',
              '',
            )}?utm_source=badge&utm_medium=responsive&utm_campaign=badge">
              <!--[if lte IE 8]><img src="//pp.walk.sc/badge/walk/Hoofdweg-10-amsterdam.png" style='border-radius: 0; box-shadow: none; outline: 0; color: transparent; margin: 0 0 0 2.000000%; float: left; padding: 0; width: 30.666667%; background: none; border: 0;' alt="Walk Score of 10 Hoofdweg Amsterdam NH Netherlands"><![endif]-->
              <!--[if gt IE 8]><img src="//pp.walk.sc/badge/walk/Hoofdweg-10-amsterdam.svg" style='border-radius: 0; box-shadow: none; outline: 0; color: transparent; margin: 0 0 0 2.000000%; float: left; padding: 0; width: 30.666667%; background: none; border: 0;' alt="Walk Score of 10 Hoofdweg Amsterdam NH Netherlands"><![endif]-->
              <!--[if !IE]> <!-- -->
              <img src="//pp.walk.sc/badge/walk/${huisnummer}-${straatnaam.replace(
                ' ',
                '-',
              )}-${woonplaats.replace(' ', '-')}-${postcode.replace(
                ' ',
                '',
              )}.svg" style='border-radius: 0; box-shadow: none; outline: 0; color: transparent; margin: 0 0 0 2.000000%; float: left; padding: 0; width: 30.666667%; background: none; border: 0;' alt="Walk Score of 10 ${huisnummer} ${straatnaam} ${woonplaats} ${postcode}">
              <!-- <![endif]-->
            </a>
          </div>
        </div>
        <p style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; padding: 0; margin: 0; border: 0; outline: 0; color: #999; font-size: 11px; font-family: Verdana, Arial, Helvetica, sans-serif; line-height: 13px; clear:both; padding: 8px 0;">
          <a rel="nofollow" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; padding: 0; margin: 0; border: 0; outline: 0; text-decoration: none; vertical-align: baseline; font-style: normal; background: none; text-align: left; color: #999; font-size: 11px; font-family: Verdana, Arial, Helvetica, sans-serif; line-height: 13px;" target="_blank" href="https://www.walkscore.com/score/${huisnummer}-${straatnaam.replace(
            ' ',
            '-',
          )}-${woonplaats.replace(' ', '-')}-${postcode.replace(
            ' ',
            '',
          )}?utm_source=badge&utm_medium=responsive&utm_campaign=badge">Meer informatie over Walk Score</a>
          </p>
      </div>
    </div>
`

  return (
    <>
      <div>
        <h2 className="walkscore__heading">Bereikbaarheidsscore (0-100)</h2>
        <div dangerouslySetInnerHTML={{ __html: badgeCode }} />
        <div className="building-walkscore-badge">
          <p className="building-walkscore-badge__definition">
            De Walk Score meet de beloopbaarheid van elk adres op basis van de
            afstand tot nabijgelegen voorzieningen en de
            voetgangersvriendelijkheid. Hier vindt u een duiding van de score:
          </p>
          <table className="building-walkscore-badge__content">
            <tbody>
              <tr>
                <td className="building-walkscore-badge__content__data">
                  90-100
                </td>
                <td className="building-walkscore-badge__content__text">
                  <strong>Zeer goed beloopbaar</strong>
                  <br />
                  Alle noodzakelijke voorzieningen zijn nabijgelegen.
                </td>
              </tr>
              <tr>
                <td className="building-walkscore-badge__content__data">
                  70-89
                </td>
                <td className="building-walkscore-badge__content__text">
                  <strong>Goed beloopbaar</strong>
                  <br />
                  De meeste noodzakelijke voorzieningen zijn nabijgelegen.
                </td>
              </tr>
              <tr>
                <td className="building-walkscore-badge__content__data">
                  50-69
                </td>
                <td className="building-walkscore-badge__content__text">
                  <strong>Redelijk beloopbaar</strong>
                  <br />
                  Sommige voorzieningen zijn nabijgelegen.
                </td>
              </tr>
              <tr>
                <td className="building-walkscore-badge__content__data">
                  25-49
                </td>
                <td className="building-walkscore-badge__content__text">
                  <strong>Meestal afhankelijk van de fiets of auto</strong>
                  <br />
                  De meeste voorzieningen zijn niet nabijgelegen en vereisen een
                  fiets of auto.
                </td>
              </tr>
              <tr>
                <td className="building-walkscore-badge__content__data">
                  0-24
                </td>
                <td className="building-walkscore-badge__content__text">
                  <strong>Altijd afhankelijk van de fiets of auto</strong>
                  <br />
                  Bijna alle voorzieningen zijn niet nabijgelegen en vereisen
                  een fiets of auto.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default WalkScoreBadge
