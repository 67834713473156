import React, { useState, useEffect, Suspense, lazy, useRef } from 'react'
import { Layer, Source, NavigationControl } from 'react-map-gl'
import { bbox, featureCollection } from '@turf/turf'
import Icon from '../icon'
import { ReactComponent as IconMapLayerSvg } from '../../_assets/svg/icon-map-layer.svg'
import MapMarkerPin from '../map-marker-pin/map-marker-pin'
import {
  mapboxStyle as mapboxStyleDefault,
  mapboxToken,
} from '../../utils/environment-vars'
import 'mapbox-gl/dist/mapbox-gl.css'
import './data-map.css'

const MapGL = lazy(() => import('react-map-gl'))

export const mapDefaults = {
  LATITUDE: 52.1326,
  LONGITUDE: 5.2913,
  ZOOM_LEVEL_ZOOM_IN: 16,
  ZOOM_LEVEL_ZOOM_WIDE: 7,
}

export default function DataMap({
  children,
  longitude,
  latitude,
  zoom = mapDefaults.ZOOM_LEVEL_ZOOM_IN,
  width = '100%',
  height = '300px',
  mapboxConfig,
  mapboxStyle = mapboxStyleDefault,
  geoJson,
  title,
  showKadaster,
  showSoilInvestigation,
  showMarker,
  showPolygon,
  showRedPolygon,
  showControls,
  showAerial,
  showWalkScore,
  showRuimtelijkePlannenMap,
  showBAGPand,
  mapId = '',
}) {
  const mapRef = useRef()

  const [viewport, setViewport] = useState({
    bearing: 0,
    pitch: 0,
    longitude: mapDefaults.LONGITUDE,
    latitude: mapDefaults.LATITUDE,
    minZoom: mapDefaults.ZOOM_LEVEL_ZOOM_WIDE,
  })

  const [showBestemming, setShowBestemming] = useState(false)
  const [showAerialInBestemmingSection, setShowAerialInBestemmingSection] =
    useState(false)
  const [showLayerOpacityControls, setshowLayerOpacityControls] =
    useState(false)
  const [kadastralekaartOpacity, setKadastralekaartOpacity] = useState(1.0)
  const [bagPandOpacity, setBagPandOpacity] = useState(0.8)

  useEffect(() => {
    if (longitude && latitude) {
      async function centerMarker() {
        const { FlyToInterpolator } = await import('react-map-gl')
        try {
          setViewport(oldViewport => ({
            ...oldViewport,
            latitude: latitude,
            longitude: longitude,
            zoom: zoom,
            transitionDuration: 500,
            transitionInterpolator: new FlyToInterpolator(),
          }))
        } catch {
          setViewport(oldViewport => ({
            ...oldViewport,
            latitude: latitude,
            longitude: longitude,
            zoom: zoom,
          }))
        }
      }

      centerMarker()
    }
  }, [latitude, longitude, zoom])

  useEffect(() => {
    if (geoJson && longitude && latitude) {
      async function setBoundingBox() {
        const { WebMercatorViewport } = await import('react-map-gl')

        const newFeatureCollection = featureCollection(geoJson)
        const boundingBox = bbox(newFeatureCollection.features)

        const combinedBoundingBox = [
          [boundingBox[0], boundingBox[1]],
          [boundingBox[2], boundingBox[3]],
        ]

        try {
          const currentMapWidth = mapRef.current.getMap()._containerWidth || 500
          const currentMapHeight =
            mapRef.current.getMap()._containerHeight || 350

          const newViewPort = new WebMercatorViewport({
            width: currentMapWidth,
            height: currentMapHeight,
          }).fitBounds(combinedBoundingBox, { padding: 40 })

          const { longitude: long, latitude: lat, zoom: zo } = newViewPort

          setViewport(oldViewport => ({
            ...oldViewport,
            longitude: long,
            latitude: lat,
            zoom: zo,
          }))
        } catch {
          setViewport(oldViewport => ({
            ...oldViewport,
            longitude: longitude,
            latitude: latitude,
            zoom: zoom,
          }))
        }
      }

      setBoundingBox()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoJson, latitude, longitude, zoom, mapRef.current])

  const toggleBestemmingToAerial = () => {
    setShowBestemming(!showBestemming)
    setShowAerialInBestemmingSection(!showBestemming)
  }

  const handleShowLayersOpacityControls = () => {
    setshowLayerOpacityControls(!showLayerOpacityControls)
  }

  const handleOpacitySliderChange = (setOpacity, event) => {
    setOpacity(event.target.value / 100)
  }

  return (
    <Suspense fallback={<></>}>
      <div className="data-map">
        {showRuimtelijkePlannenMap && (
          <div className="data-map__header">
            {title && <h3 className="text-bold">{title}</h3>}

            <div className="data-map__toggle">
              <input
                id="areal-toggle"
                type="checkbox"
                role="switch"
                checked={showAerialInBestemmingSection}
                onChange={toggleBestemmingToAerial}
              />
              <label htmlFor="areal-toggle">Luchtfoto</label>
            </div>
          </div>
        )}
        <div id={mapId}>
          {!showRuimtelijkePlannenMap && title && (
            <div className="data-map__header">
              <h3 className="text-bold">{title}</h3>
            </div>
          )}

          {showAerialInBestemmingSection && (
            <div className="data-map__layers">
              <button
                className="data-map__layers-button body text-dim"
                onClick={handleShowLayersOpacityControls}
              >
                <Icon className="data-map__layers-icon">
                  <IconMapLayerSvg />
                </Icon>
                Layers
              </button>

              {showLayerOpacityControls && (
                <div className="layers-container">
                  {[
                    {
                      label: 'Kadastrale kaart',
                      value: kadastralekaartOpacity,
                      setOpacity: setKadastralekaartOpacity,
                    },
                    {
                      label: 'BAG Pand',
                      value: bagPandOpacity,
                      setOpacity: setBagPandOpacity,
                    },
                  ].map((layer, index) => (
                    <div key={index} className="data-map__layers-option">
                      <input
                        id={`layer-${index}`}
                        type="range"
                        min="0"
                        max="100"
                        value={layer.value * 100}
                        onChange={event =>
                          handleOpacitySliderChange(layer.setOpacity, event)
                        }
                        className="data-map__layers-input"
                      />
                      <label
                        htmlFor={`layer-${index}`}
                        className="data-map__layers-label"
                      >
                        {layer.label}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          <MapGL
            ref={mapRef}
            {...viewport}
            {...mapboxConfig}
            width={width}
            height={height}
            mapStyle={mapboxStyle}
            onViewportChange={nextViewport => setViewport(nextViewport)}
            mapboxApiAccessToken={mapboxToken}
            preserveDrawingBuffer={true}
          >
            {children}

            {showMarker && (
              <MapMarkerPin
                tabIndex="-1"
                longitude={longitude}
                latitude={latitude}
                isPinLocation={true}
              />
            )}

            {showKadaster && (
              <Source
                id="percelen"
                type="raster"
                tiles={[
                  'https://service.pdok.nl/kadaster/kadastralekaart/wmts/v5_0?layer=Kadastralekaart&style=default&tilematrixset=EPSG:3857&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TileMatrix=EPSG:3857:{z}&TileCol={x}&TileRow={y}',
                ]}
                tileSize={256}
                maxzoom={19}
              >
                <Layer
                  id="percelen"
                  type="raster"
                  source="percelen"
                  source-layer="kadastralekaart"
                  layout={{
                    visibility:
                      showRuimtelijkePlannenMap &&
                      !showAerialInBestemmingSection
                        ? 'none'
                        : 'visible',
                  }}
                  paint={{ 'raster-opacity': kadastralekaartOpacity }}
                />
              </Source>
            )}

            {geoJson && showRedPolygon && (
              <Source id="red-geojson" type="geojson" data={geoJson}>
                <Layer
                  id="red-geojson"
                  type="fill"
                  source="red-geojson"
                  paint={{
                    'fill-color': '#B80000',
                    'fill-opacity': 0.8,
                  }}
                />
                <Layer
                  id="red-geojson-line"
                  type="line"
                  source="red-geojson"
                  paint={{
                    'line-color': '#00748E',
                    'line-width': 2,
                  }}
                />
              </Source>
            )}

            {geoJson && showPolygon && (
              <Source id="geojson" type="geojson" data={geoJson}>
                <Layer
                  id="geojson"
                  type="fill"
                  source="geojson"
                  paint={{
                    'fill-color': '#0099bc',
                    'fill-opacity': 0.8,
                  }}
                />
                <Layer
                  id="geojson-line"
                  type="line"
                  source="geojson"
                  paint={{
                    'line-color': '#00748e',
                    'line-width': 2,
                  }}
                />
                <Layer
                  id="geojson-point"
                  type="circle"
                  source="geojson"
                  paint={{
                    'circle-radius': 5,
                    'circle-color': '#00748e',
                  }}
                />
              </Source>
            )}

            {geoJson && showSoilInvestigation && (
              <Source
                id="bodem"
                type="raster"
                tiles={[
                  'https://www.gdngeoservices.nl/arcgis/rest/services/blk/lks_blk_rd/MapServer/export?bbox={bbox-epsg-3857}&bboxSR=3857&imageSR=3857&size=512,512&dpi=96&format=png32&transparent=true&layers=show:0,1&f=image',
                ]}
                maxzoom={19}
                attribution='<a href="https://www.bodemloket.nl/kaart" target="_blank" rel="noopener noreferrer">Bodemloket</a>'
              >
                <Layer
                  id="bodem"
                  type="raster"
                  source="bodem"
                  layout={{ visibility: 'visible' }}
                  paint={{ 'raster-opacity': 0.4 }}
                />
              </Source>
            )}

            {(showAerial || showAerialInBestemmingSection) && (
              <Source
                id="aerial"
                type="raster"
                tiles={[
                  ' https://service.pdok.nl/hwh/luchtfotorgb/wms/v1_0?service=WMS&version=1.1.1&request=GetMap&layers=Actueel_orthoHR&styles=default&srs=EPSG%3A3857&bbox={bbox-epsg-3857}&width=256&height=256&format=image/jpeg&transparent=true&',
                ]}
                tileSize={256}
              >
                <Layer
                  id="aerial"
                  type="raster"
                  source="aerial"
                  source-layer="Actueel_orthoHR"
                  layout={{ visibility: 'visible' }}
                  beforeId={showAerialInBestemmingSection ? 'percelen' : ''}
                />
              </Source>
            )}

            {showWalkScore && (
              <MapMarkerPin
                latitude={latitude}
                longitude={longitude}
                isPinLocation={true}
              />
            )}

            {showRuimtelijkePlannenMap && !showAerialInBestemmingSection && (
              <>
                <img
                  src="https://service.pdok.nl/kadaster/plu/wms/v1_0/legend/enkelbestemming/enkelbestemming.png"
                  alt="enkelbestemming"
                ></img>
                <Source
                  id="enkelbestemming"
                  type="raster"
                  tiles={[
                    'https://service.pdok.nl/kadaster/plu/wms/v1_0?request=GetMap&service=wms&version=1.3.0&layers=Enkelbestemming&styles=&crs=epsg:3857&bbox={bbox-epsg-3857}&width=512&height=512&format=image/png&transparent=true',
                  ]}
                  tileSize={256}
                >
                  <Layer
                    id="enkelbestemming"
                    type="raster"
                    source="enkelbestemming"
                    source-layer="Ruimtelijke plannen"
                    layout={{ visibility: 'visible' }}
                  />
                </Source>
              </>
            )}

            {showBAGPand && showAerialInBestemmingSection && (
              <>
                <Source
                  id="BAGPand"
                  type="raster"
                  tiles={[
                    'https://service.pdok.nl/lv/bag/wms/v2_0?request=GetMap&service=WMS&version=1.3.0&layers=pand&styles=&crs=epsg:3857&bbox={bbox-epsg-3857}&width=512&height=512&format=image/png&transparent=true',
                  ]}
                  tileSize={256}
                >
                  <Layer
                    id="BAGPand"
                    type="raster"
                    source="BAGPand"
                    source-layer="pand"
                    layout={{ visibility: 'visible' }}
                    paint={{ 'raster-opacity': bagPandOpacity }}
                    beforeId="percelen"
                  />
                </Source>
              </>
            )}

            {showControls && (
              <NavigationControl className="data-map__navigation-control" />
            )}
          </MapGL>
        </div>
      </div>
    </Suspense>
  )
}
