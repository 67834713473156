import { gaCategories } from './constants'

export function trackEvent(
  event_action,
  event_category = null,
  event_label = null,
) {
  window.gtag &&
    window.gtag('event', event_action, {
      event_category,
      event_label,
    })
}

export function trackPageView(event_action, event_label = null) {
  window.gtag &&
    window.gtag('event', event_action, {
      event_category: gaCategories.PAGES,
      event_label,
    })
}
