// https://davidwalsh.name/javascript-debounce-function
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
  var timeout
  return function () {
    var context = this,
      args = arguments
    var later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

// https://dev.to/admitkard/mobile-issue-with-100vh-height-100-100vh-3-solutions-3nae
function setViewportValue() {
  const viewportHeight = window.innerHeight / 100 + 'px'
  const scrollbarWidth =
    window.innerWidth - document.documentElement.clientWidth + 'px'

  document.querySelector(':root').style.setProperty('--vh', viewportHeight)
  document.querySelector(':root').style.setProperty('--sb', scrollbarWidth)
}

export default function viewportHack() {
  setViewportValue()
  window.addEventListener('resize', debounce(setViewportValue, 100))
}
