import React from 'react'
import DefaultLayout from '../layouts/default'
import { Typography, Box, PdfLink } from '../components'
import { Image, Link } from '@react-pdf/renderer'
import formatValue from '@brainbay/components/utils/format-value'

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export default function RuimtelijkePlannenPage({
  item,
  userName,
  date,
  debug,
  isGebouwpaspoort,
  ruimtelijkePlannenImage,
}) {
  const ruimtelijkePlannenFromStore = item?.pand?.ruimtelijkePlannen
  const ruimtelijkePlannen =
    ruimtelijkePlannenFromStore?.length > 0
      ? ruimtelijkePlannenFromStore.map((ruimtelijkePlan, index) => ({
          items: [
            {
              label: 'Bestemmings hoofdgroep',
              type: 'hoofdgroep',
              value: capitalize(ruimtelijkePlan.bestemmingshoofdgroep),
            },
            {
              label: 'Plan status',
              value: capitalize(ruimtelijkePlan.planstatus),
            },
            {
              label: 'Type plan',
              value: capitalize(ruimtelijkePlan.typeplan),
            },
            {
              label: 'Datum',
              value: formatValue(ruimtelijkePlan.datum, {
                format: 'date',
              }),
            },
            {
              label: 'Planregels',
              type: 'url',
              hrefLabel: 'Bekijk bestemmingsplan',
              value: ruimtelijkePlan.url,
            },
          ],
          title: `< ${index + 1} van ${ruimtelijkePlannenFromStore?.length} >`,
        }))
      : undefined

  return (
    <DefaultLayout
      pageName="Ruimtelijke Plannen"
      userName={userName}
      date={date}
      isGebouwpaspoort={isGebouwpaspoort}
    >
      <Typography
        style={{
          color: '#000',
          marginBottom: 10,
          backgroundColor: '#eff7f9',
          paddingHorizontal: 5,
          paddingTop: 6,
          paddingBottom: 3,
          fontSize: 11,
          borderRadius: 4,
        }}
      >
        <Typography>
          Let op, de informatie van Ruimtelijke Plannen is mogelijk niet
          volledig en verouderd. Maak gebruik van het{' '}
        </Typography>
        <PdfLink
          style={{
            color: '#0099bc',
            fontWeight: 'bold',
          }}
          src={
            'https://omgevingswet.overheid.nl/regels-op-de-kaart/viewer/locatie?regelsandere=regels'
          }
        >
          {'Omgevingsloket'}
        </PdfLink>
        <Typography>
          {' '}
          voor een volledig beeld van de geldende regels.
        </Typography>
      </Typography>
      <Typography
        style={{
          fontSize: 16,
          color: '#000',
          fontWeight: 700,
          marginBottom: 5,
        }}
      >
        Enkelbestemming overzichtskaart
      </Typography>
      <Box
        style={{
          width: '100%',
          position: 'relative',
        }}
      >
        <Image
          style={{
            width: '100%',
            height: 'auto',
            position: 'relative',
            padding: 6,
          }}
          src={ruimtelijkePlannenImage}
          allowDangerousPaths
          break
        ></Image>
      </Box>
      {ruimtelijkePlannen?.length && (
        <Typography
          style={{
            fontSize: 16,
            color: '#000',
            fontWeight: 700,
            marginTop: 5,
          }}
        >
          Geldende bestemmingplannen
        </Typography>
      )}
      {ruimtelijkePlannen?.map((ruimtelijkePlan, index) => {
        return (
          <Box
            key={index}
            style={{
              paddingBottom: 5,
              paddingTop: 5,
            }}
            debug={debug}
          >
            <Typography
              style={{
                fontSize: 13,
                fontWeight: '700',
                paddingTop: 5,
                paddingBottom: 2,
                borderBottom: '1px solid #dddddd',
              }}
              debug={debug}
            >
              {ruimtelijkePlan.title}
            </Typography>

            {ruimtelijkePlan.items.map(item => (
              <Box
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontSize: 10,
                  paddingTop: 6,
                  paddingBottom: 4,
                  borderBottom: '1px solid #dddddd',
                }}
                debug={debug}
              >
                <Typography
                  style={{
                    width: 180,
                    fontSize: 11,
                    paddingRight: 10,
                  }}
                  debug={debug}
                >
                  {item.label}
                </Typography>
                {item.type === 'url' ? (
                  <Link
                    style={{
                      width: '60%',
                      fontSize: 11,
                      color: '#0099bc',
                      fontWeight: 600,
                    }}
                    src={item.value}
                    debug={debug}
                  >
                    {item.hrefLabel}
                  </Link>
                ) : (
                  <Typography
                    style={{
                      width: 180,
                      fontSize: 11,
                      paddingRight: 10,
                    }}
                    debug={debug}
                  >
                    {item.value}
                  </Typography>
                )}
              </Box>
            ))}
          </Box>
        )
      })}
    </DefaultLayout>
  )
}
