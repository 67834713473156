import React from 'react'
import { Box, EnergyLabel, ImageBox, Typography } from './index'
import formatValue from '@brainbay/components/utils/format-value'

import {
  getImageUrl,
  getObjectAddress,
  usePandDetailsSlotOrder,
} from '../helpers'

export default function PandDetails({
  item,
  debug,
  gebouwpaspoortImage,
  isGebouwpaspoort,
}) {
  const address = getObjectAddress(item, isGebouwpaspoort)
  const slotElements = usePandDetailsSlotOrder(item)
  const imageDataUrl = getImageUrl(item, gebouwpaspoortImage)
  const { pand, geselecteerdeAdres } = item || {}

  let verblijfsobject = pand?.verblijfsobjecten.find(element => {
    return (
      element?.hoofdAdres.nummeraanduidingId ===
        geselecteerdeAdres.nummeraanduidingId ||
      element?.nevenAdressen.find(
        address =>
          address.nummeraanduidingId === geselecteerdeAdres.nummeraanduidingId,
      )
    )
  })

  const objectFunctions = verblijfsobject?.gebruiksdoelen
    ? [verblijfsobject?.gebruiksdoelen]
    : []

  return (
    <>
      <Typography
        style={{
          fontSize: 18,
          color: '#000',
          fontWeight: 700,
        }}
        debug={debug}
      >
        Details
      </Typography>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
        }}
        debug={debug}
        break
      >
        <Box
          style={{
            width: '37%',
            display: 'flex',
            position: 'relative',
            marginTop: 4,
          }}
        >
          <ImageBox
            style={{ width: '100%', height: 100 }}
            source={imageDataUrl}
            allowDangerousPaths
            break
          />
        </Box>
        {/* Object address and source */}
        <Box
          style={{
            width: '100%',
            height: '100%',
            marginLeft: '10px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              style={{ fontSize: 12, fontWeight: 700, color: '#0099bc' }}
              debug={debug}
            >
              {address}
            </Typography>
          </Box>

          {/* Object main function and sub functions */}
          <Box style={{ width: '100%' }}>
            <Typography style={{ fontSize: 10, color: '#000' }} debug={debug}>
              {formatValue(objectFunctions, 'comma-separated-list')}
            </Typography>
          </Box>

          {/* Object item details */}
          <Box
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {Array.isArray(slotElements) &&
              slotElements.map(({ term, definition, perM2 }, index) => {
                return (
                  <Box
                    style={{
                      flex: '1 1 33%',
                      width: '33%',
                      marginTop: '4px',
                    }}
                    key={index}
                  >
                    <Typography
                      style={{ fontSize: 9, color: '#888888' }}
                      debug={debug}
                    >
                      {term}
                    </Typography>
                    {term === 'Energielabel' ? (
                      <EnergyLabel label={definition} fontSize={7} />
                    ) : (
                      <Typography
                        style={{ fontSize: 11, color: '#000', fontWeight: 700 }}
                        debug={debug}
                      >
                        {definition}
                      </Typography>
                    )}
                    {perM2 && (
                      <Typography
                        style={{ fontSize: 9, color: '#888888' }}
                        debug={debug}
                      >
                        {perM2}
                      </Typography>
                    )}
                  </Box>
                )
              })}
          </Box>
        </Box>
      </Box>
    </>
  )
}
