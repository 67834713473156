import React, { forwardRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import AddButton from '@brainbay/components/components/add-button'
import Panel from '@brainbay/components/components/panel'
import Table from '@brainbay/components/components/table'
import Tooltip from '@brainbay/components/components/tooltip'
import Icon from '@brainbay/components/components/icon'
import ObjectLabel from '@brainbay/components/components/object-label'
import ImageViewer from '@brainbay/components/components/image-viewer'
import EnergyLabel from '@brainbay/components/components/energy-label'
import formatAddressBusiness from '../../utils/format-address-business'
import formatValue from '@brainbay/components/utils/format-value'
import { ReactComponent as IconPerceeloppervlak } from '@brainbay/components/_assets/svg/icon-perceeloppervlak.svg'
import { ReactComponent as IconPandoppervlak } from '@brainbay/components/_assets/svg/icon-woonoppervlak.svg'
import { ReactComponent as IconBouwjaar } from '@brainbay/components/_assets/svg/icon-bouwjaar.svg'
import { setSortReferencesBy } from '../../store/comparison'

import useObjectPrices from '../../utils/use-object-prices'
import useObjectDate from '../../utils/use-object-date'
import useObjectSurface from '../../utils/use-object-surface'
import formatObjectFunction from '../../utils/format-object-function'

import './object-references.css'

const SortReferencesSelect = ({
  sortOptions = {},
  sortBy = '',
  onChangeSort = () => {},
}) => {
  function setSelect(e) {
    onChangeSort(e.target.value)
  }

  return (
    <>
      <label htmlFor="object-references__select" className="sr-only">
        Sorteer op referentie status
      </label>
      <select
        id="object-references__select"
        className="references__select select--small"
        onChange={setSelect}
        value={sortBy}
      >
        {sortOptions.map?.(option => {
          return (
            <option key={option.key} value={option.key}>
              {option.label}
            </option>
          )
        })}
      </select>
    </>
  )
}
const ObjectReferences = forwardRef(function ObjectReferences(
  {
    nummeraanduidingId,
    objectGuid,
    isLoading = true,
    data = [],
    error = undefined,
    compareList,
    onAddToggle,
    className = '',
  },
  ref,
) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { sortReferencesBy } = useSelector(state => state.comparison)

  const sortOptions = [
    { key: 'All', label: 'Alles' },
    { key: 'Koop', label: 'Verkocht' },
    { key: 'Huur', label: 'Verhuurd' },
    { key: 'Belegging', label: 'Belegging' },
  ]

  function handleSortChange({ sortBy } = {}) {
    const params = new URLSearchParams(window.location.search)

    if (sortBy) {
      dispatch(setSortReferencesBy(sortBy))
      params.set('sortReferenceBy', sortBy)
    }
    const idType = nummeraanduidingId || objectGuid
    history.push(`${idType}?${params}`)
  }

  return (
    <div ref={ref}>
      <Panel
        title="Referenties"
        className={`object-references ${className}`.trim()}
        action={
          <SortReferencesSelect
            sortBy={sortReferencesBy}
            sortOptions={sortOptions}
            onChangeSort={sortBy => handleSortChange({ sortBy })}
          />
        }
      >
        {isLoading && <p className="body">De referenties worden geladen</p>}

        {error && (
          <p className="body">
            Er kunnen geen referenties opgehaald worden op dit moment
          </p>
        )}

        {data.length > 0 && (
          <Table
            className="object-references__table"
            columns={data}
            caption="Referenties"
            hideCaption
            hideColumnHeaders
            hideRowHeaders
            columnHeaders={data.map(item => {
              const address = formatAddressBusiness({
                postalCode: item?.postcode,
                street: item?.straatnaam,
                houseNumber: item?.huisnummer,
                houseNumberSuffix: item?.huisnummertoevoeging,
                municipality: item?.gemeente,
              })

              return address
            })}
            rows={[
              {
                columnProperty: 'comparison',
                header: 'Comparison',
                render: item => (
                  <div className="object-references__add-button">
                    <AddButton
                      hideLabel
                      labelAdd="Voeg toe aan vergelijking"
                      labelRemove="Verwijder uit vergelijking"
                      onClick={() => onAddToggle(item.objectGuid)}
                      isAdded={compareList.includes(item.objectGuid)}
                    />
                  </div>
                ),
              },
              {
                columnProperty: 'media',
                header: 'Media',
                render: item => {
                  const hasUrl = (item?.objectGuid || item?.id) !== objectGuid
                  let address = ''

                  if (!isLoading) {
                    address = formatAddressBusiness({
                      street: item?.straatnaam,
                      houseNumber: item?.huisnummer,
                      houseNumberSuffix: item?.huisnummertoevoeging,
                      city: item?.woonplaats,
                      postalCode: item?.postcode,
                      municipality: item?.gemeente,
                    })
                  }
                  return (
                    <div
                      className={
                        hasUrl ? ' object-references__image--has-url' : ''
                      }
                    >
                      <ObjectLabel
                        className="object-references__image-sizer"
                        status={item?.status}
                        duration={item?.looptijd || item?.verkoopTijd}
                        isSmall
                        match={item.match}
                      >
                        <ImageViewer
                          mediaGuids={item?.mediaGuids}
                          mediaSourceId={address}
                          isLoading={isLoading}
                          onClick={() => {
                            history.push(`/${item.objectGuid}`)
                          }}
                        />
                      </ObjectLabel>
                    </div>
                  )
                },
              },
              {
                columnProperty: 'adres',
                header: 'Compleet adres',
                render: item => {
                  const address = formatAddressBusiness({
                    street: item?.straatnaam,
                    houseNumber: item?.huisnummer,
                    houseNumberSuffix: item?.huisnummertoevoeging,
                    city: item?.woonplaats,
                    postalCode: item?.postcode,
                    municipality: item?.gemeente,
                  })

                  if ((item?.objectGuid || item?.id) !== objectGuid) {
                    return (
                      <Link
                        className="object-references__header body text-bold loading-state"
                        to={`/${item.objectGuid}`}
                      >
                        {!isLoading && address}
                      </Link>
                    )
                  } else {
                    return (
                      <p className="object-references__header body text-bold loading-state">
                        {!isLoading && address}
                      </p>
                    )
                  }
                },
              },
              {
                columnProperty: 'hoofdfunctie',
                header: 'Hoofdfunctie',
                render: item => {
                  return formatObjectFunction(item)
                },
              },
              {
                columnProperty: 'date',
                header: 'Datum',
                render: item => {
                  return <ObjectReferenceDate data={item} />
                },
              },
              {
                columnProperty: 'prijs',
                header: 'Prijs',
                render: item => {
                  return (
                    <ObjectReferencePrice isLoading={isLoading} data={item} />
                  )
                },
              },

              {
                columnProperty: 'pandOppervlakte',
                header: 'Pandoppervlakte',
                render: item => (
                  <ObjectReferenceSurface data={item} isLoading={isLoading} />
                ),
              },
              {
                columnProperty: 'perceelOppervlakte',
                header: 'Perceeloppervlakte',
                render: item => (
                  <div
                    className="object-references__td-icon text-dim loading-state"
                    tabIndex="0"
                    aria-describedby="perceeloppervlak-tooltip"
                  >
                    {!isLoading && (
                      <>
                        <Tooltip
                          id="perceeloppervlak-tooltip"
                          className="object-references__tooltip"
                        >
                          <p className="body-small">Perceeloppervlak</p>
                        </Tooltip>

                        <Icon size="tiny">
                          <IconPerceeloppervlak />
                        </Icon>

                        <p className="body text-dim">
                          {formatValue(item?.perceelOppervlakte, {
                            format: 'meter-squared',
                          })}
                        </p>
                      </>
                    )}
                  </div>
                ),
              },
              {
                columnProperty: 'bouwperiode',
                header: 'Bouwperiode',
                render: item => {
                  return (
                    <div
                      className="object-references__td-icon text-dim loading-state"
                      tabIndex="0"
                      aria-describedby="bouwjaar-tooltip"
                    >
                      {!isLoading && (
                        <>
                          <Tooltip
                            id="bouwjaar-tooltip"
                            className="object-references__tooltip"
                          >
                            <p className="body-small">Bouwperiode</p>
                          </Tooltip>

                          <Icon size="tiny">
                            <IconBouwjaar />
                          </Icon>

                          <p className="body text-dim">{item.bouwjaar}</p>
                        </>
                      )}
                    </div>
                  )
                },
              },
              {
                columnProperty: 'ligging',
                header: 'Ligging',
                render: item => {
                  return (
                    <>
                      <p className="body-small text-dim">Ligging:</p>
                      <p className="body">
                        {formatValue(item.liggingen, 'comma-separated-list')}
                      </p>
                    </>
                  )
                },
              },
              {
                columnProperty: 'energielabel',
                header: 'Energielabel',
                render: item => (
                  <>
                    <p
                      className="object-comparison__body body-small text-dim loading-state"
                      aria-hidden="true"
                    >
                      {!isLoading && <>Energielabel:</>}
                    </p>
                    <EnergyLabel
                      isLoading={isLoading}
                      label={item?.energielabel}
                      isSmall
                    />
                  </>
                ),
              },
              {
                columnProperty: 'bron',
                header: 'Bron',
                render: item => {
                  return (
                    <>
                      <p className="body-small text-dim">Bron:</p>
                      <p className="body">{item?.bron ?? '---'}</p>
                    </>
                  )
                },
              },
            ]}
          />
        )}
      </Panel>
    </div>
  )
})

function ObjectReferenceSurface({ data, isLoading }) {
  const surface = useObjectSurface(data)
  return (
    <div
      className="object-references__td-icon text-dim loading-state"
      tabIndex="0"
      aria-describedby="pandoppervlakte-tooltip"
    >
      {!isLoading && (
        <>
          <Tooltip
            id="pandoppervlakte-tooltip"
            className="object-references__tooltip"
          >
            <p className="body-small">{surface.term}</p>
          </Tooltip>

          <Icon size="tiny">
            <IconPandoppervlak />
          </Icon>

          <p className="body text-dim">{surface.definition}</p>
        </>
      )}
    </div>
  )
}

function ObjectReferenceDate({ data }) {
  const date = useObjectDate(data)
  return (
    <>
      <p className="body-small text-dim">{date.term}:</p>
      <time className="object-references__time">{date.definition}</time>
    </>
  )
}

function ObjectReferencePrice({ isLoading, data }) {
  const [primaryPrice, secondaryPrice] = useObjectPrices(data)
  return (
    <>
      <p className="body-small text-dim">{!isLoading && primaryPrice?.term}:</p>
      <p className="object-references__body body text-bold loading-state">
        {!isLoading &&
          [primaryPrice, secondaryPrice].filter(Boolean).map((price, index) => {
            return (
              <span className="object-references__prices" key={index}>
                <span className="object-references__price">
                  {price.definition}
                </span>
                <span className="object-references__pricem2 body-small text-dim">
                  {price.perM2}
                </span>
              </span>
            )
          })}
      </p>
    </>
  )
}
export default ObjectReferences
