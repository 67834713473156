import React from 'react'
import './toast-bar.css'

export default function ToastBar({ title, children }) {
  return (
    <aside
      className="toast-bar layout--grid print-excluded"
      aria-labelledby={title ? 'toast-bar-title' : null}
    >
      {title && (
        <h1 id="toast-bar-title" className="sr-only">
          {title}
        </h1>
      )}
      {children}
    </aside>
  )
}
