import React, { useState } from 'react'
import kebabCase from 'lodash/kebabCase'
import Icon from '../icon'
import { ReactComponent as IconPlattegrond } from '../../_assets/svg/icon-plattegrond.svg'
import { ReactComponent as IconPerceeloppervlak } from '../../_assets/svg/icon-perceeloppervlak.svg'
import { ReactComponent as IconWoonoppervlak } from '../../_assets/svg/icon-woonoppervlak.svg'
import { ReactComponent as IconBouwjaar } from '../../_assets/svg/icon-bouwjaar.svg'
import { trackEvent } from '../../utils/ga-events'
import { gaCategories } from '../../utils/constants'
import './object-details-definition-list.css'
import { labelClass } from '../energy-label/energy-label'

export default function ObjectDetailsDefinitionList({
  isBAGObject,
  children,
  canEdit,
  termClassName = ' body text-dim',
  definitionClassName = ' body-big text-bold',
  iconSize = 'small',
  className = '',
}) {
  if (!children) {
    return null
  }

  const _children = children.length ? children : [children]
  const iconMap = {
    'units-vanaf': IconPlattegrond,
    'aantal-kamers': IconPlattegrond,
    woonoppervlak: IconWoonoppervlak,
    'aangeboden-oppervlakte': IconWoonoppervlak,
    perceeloppervlak: IconPerceeloppervlak,
    buitenruimte: IconPerceeloppervlak,
    bouwjaar: IconBouwjaar,
  }

  const [firstChild] = _children ? _children : []

  if (!firstChild || firstChild.type !== 'dl') return children

  const { className: rootClassName = '', ...rootProps } = firstChild.props

  const wrappedChildren = firstChild.props.children
    .reduce(
      (collection, child) =>
        child?.type === React.Fragment
          ? [...collection, ...child.props.children]
          : [...collection, child],
      [],
    )
    .filter(child => child?.type === 'dt' || child?.type === 'dd')
    .reduce((list, child) => {
      if (child.type === 'dt') {
        list.push([])
      }
      list[list.length - 1].push(child)
      return list
    }, [])
    .map((childList, index) => {
      const term = childList.find(child => child.type === 'dt')
      const definition = childList.find(child => child.type === 'dd')
      const termText = term?.props?.children ? term.props.children : undefined
      const kebabTerm = kebabCase(termText)
      const DtIcon = iconMap[kebabTerm]

      return (
        <div
          className={`object-details-list__item${
            definition?.props?.inputValue !== undefined
              ? ' object-details-list__item--user-edit'
              : ''
          } object-details-list__item--${kebabTerm}`.trim()}
          key={index}
        >
          <dt
            className={`loading-state object-details-list__term ${termClassName} ${
              term?.props?.className ? term?.props?.className : ''
            }`.trim()}
          >
            {term?.props?.children}
          </dt>
          <dd
            className={`loading-state object-details-list__definition ${definitionClassName} ${
              definition?.props?.className ? definition?.props?.className : ''
            }`.trim()}
            style={{ '--icon-size': `var(--icon-${iconSize})` }}
          >
            {definition?.props?.children && DtIcon && (
              <Icon size={iconSize}>
                <DtIcon />
              </Icon>
            )}
            {definition?.props?.children && (
              <>
                {(canEdit && definition?.props?.inputValue !== undefined) || (
                  <span className="object-details-list__definition-text capitalize-first-letter">
                    {definition?.props?.children}
                  </span>
                )}

                {canEdit &&
                  definition?.props?.type === undefined &&
                  definition?.props?.inputValue !== undefined && (
                    <InputField
                      key={definition?.props?.name}
                      autoFocus={!isBAGObject && index === 0}
                      name={definition?.props?.name}
                      value={definition?.props?.inputValue}
                    />
                  )}

                {canEdit &&
                  definition?.props?.type === 'select' &&
                  definition?.props?.inputValue !== undefined && (
                    <SelectField
                      key={definition?.props?.name}
                      autoFocus={!isBAGObject && index === 0}
                      name={definition?.props?.name}
                      value={definition?.props?.inputValue}
                      options={definition?.props?.options}
                    />
                  )}
                {canEdit &&
                  definition?.props?.type === 'select-energy-label' && (
                    <EnergyLabelSelectField
                      key={definition?.props?.name}
                      autoFocus={!isBAGObject && index === 0}
                      name={definition?.props?.name}
                      value={definition?.props?.inputValue}
                      options={definition?.props?.options}
                    />
                  )}
              </>
            )}
          </dd>
        </div>
      )
    })
  return (
    <dl
      {...rootProps}
      className={`object-details-definition-list ${className} ${rootClassName}`.trim()}
    >
      {wrappedChildren}
    </dl>
  )
}

const InputField = ({ name, value, autoFocus }) => {
  const [inputValue, setInputValue] = useState(value)

  function handleInputChange(e) {
    trackEvent(
      `changed_${e.target.name}`,
      gaCategories.EDIT,
      `Changed ${e.target.name} to ${e.target.value}`,
    )

    setInputValue(e.target.value)
  }

  return (
    <input
      className="object-details-list__definition-text object-details-list__definition-text--input"
      autoFocus={autoFocus}
      type="number"
      min="1"
      max={name === 'bouwjaar' ? new Date().getFullYear() : undefined}
      name={name}
      value={inputValue}
      onChange={handleInputChange}
    />
  )
}

const SelectField = ({ name, value, options, autoFocus }) => {
  const [inputValue, setInputValue] = useState(value)

  function handleInputChange(e) {
    trackEvent(
      `changed_${e.target.name}`,
      gaCategories.EDIT,
      `Changed ${e.target.name} to ${e.target.value}`,
    )

    setInputValue(e.target.value)
  }

  return (
    <select
      className="object-details-list__definition-text object-details-list__definition-text--input"
      autoFocus={autoFocus}
      value={inputValue}
      name={name}
      onChange={handleInputChange}
    >
      {options?.map(option => {
        return (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        )
      })}
    </select>
  )
}

const EnergyLabelSelectField = ({ name, value, options, autoFocus }) => {
  const [inputValue, setInputValue] = useState(value)

  function handleInputChange(e) {
    trackEvent(
      `changed_${e.target.name}`,
      gaCategories.EDIT,
      `Changed ${e.target.name} to ${e.target.value}`,
    )

    setInputValue(e.target.value)
  }

  return (
    <div
      className={`energy-label-select-wrapper energy-label--${labelClass(
        inputValue,
      )}`}
    >
      <select
        className="object-details-list__definition-text object-details-list__definition-text--input"
        autoFocus={autoFocus}
        value={inputValue}
        name={name}
        onChange={handleInputChange}
      >
        {options?.map(option => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          )
        })}
      </select>
      <span
        className={`energy-label-select-arrow energy-label--${labelClass(
          inputValue,
        )}`}
      ></span>
    </div>
  )
}
