const ua = navigator.userAgent
const browsers = [
  [/Edge\/(\d+)\.(\d+)/, 'Edge'],
  [/Edg\/(\d+)\.(\d+)/, 'Edge'],
  [/Chrome\/(\d+)\.(\d+)/, 'Chrome'],
  [/(?:iPod|iPod touch|iPhone|iPad).*OS (\d+)_(\d+)/, 'Mobile Safari'],
  [/Version\/(\d+)\.(\d+).*Safari/, 'Safari'],
  [/Firefox\/(\d+)\.(\d+)/, 'Firefox'],
  [/Trident.*rv.(\d+)\.(\d+)/, 'IE'],
  [/MSIE (\d+)\.(\d+)/, 'IE'],
]

export const browser = browsers
  .map(([regex, browser]) => ({ result: regex.exec(ua), browser }))
  .filter(({ result }) => result)
  .filter((_, index) => index === 0)
  .map(({ result, browser }) => {
    document.documentElement.dataset.browser = browser.toLocaleLowerCase()
    result[1] && (document.documentElement.dataset.browserMarjor = result[1])
    result[2] && (document.documentElement.dataset.browserMinor = result[2])

    return {
      vendor: browser,
      major: result?.[1],
      minor: result?.[2],
    }
  })
  .find(x => x)
