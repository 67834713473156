import React from 'react'
import formatValue from '@brainbay/components/utils/format-value'
import { status as constantsStatus } from '@brainbay/components/utils/constants'
import { Box, Typography, ImageBox, EnergyLabel } from './index'
import formatObjectFunction from '../../format-object-function'
import {
  useSlotOrder,
  statusColor,
  getObjectAddress,
  getImageUrl,
} from '../helpers'

export default function ObjectCard({ item, debug }) {
  const address = getObjectAddress(item)
  const slotElements = useSlotOrder(item)
  const imageDataUrl = getImageUrl(item)
  const objectFunctions = formatObjectFunction(item)

  const { naam, plaats } = item.hoofdAanbieder ?? {}

  const isSold = item.status === constantsStatus.VERKOCHT
  const duration = item.transactie?.looptijd ?? item.looptijd

  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '16px',
        border: '1px solid #0099bc',
        padding: '8px',
      }}
      debug={debug}
      break
    >
      {/* Object Image */}
      <Box
        style={{
          width: '37%',
          display: 'flex',
          position: 'relative',
        }}
      >
        <ImageBox
          style={{ width: '100%', height: 90 }}
          source={imageDataUrl}
          allowDangerousPaths
          break
        />
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'flex-end',
            position: 'absolute',
            bottom: '10px',
            right: 0,
            textAlign: 'center',
          }}
        >
          {item.status && (
            <Typography
              style={{
                fontSize: 7,
                fontWeight: 700,
                lineHeight: 1.2,
                paddingHorizontal: '5px',
                paddingVertical: '5px',
                backgroundColor: statusColor(item.status).backgroundColor,
                color: statusColor(item.status).color,
              }}
            >
              {item.status}
            </Typography>
          )}
          {duration !== undefined && (
            <Typography
              style={{
                fontSize: 7,
                lineHeight: 1.2,
                paddingHorizontal: '5px',
                paddingVertical: '5px',
                backgroundColor: '#ffffff',
                color: '#000000',
              }}
            >
              {isSold ? 'Verkooptijd: ' : 'Looptijd: '}&nbsp;
              <Typography
                style={{
                  fontSize: 7,
                  fontWeight: 700,
                  lineHeight: 1.2,
                  color: '#000000',
                }}
              >
                {formatValue(duration, { format: 'days' })}
              </Typography>
            </Typography>
          )}
        </Box>
      </Box>

      {/* Object address and source */}
      <Box
        style={{
          width: '100%',
          height: '100%',
          marginLeft: '10px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{ fontSize: 9, fontWeight: 700, color: '#0099bc' }}
            debug={debug}
          >
            {address}
          </Typography>
          <Typography style={{ fontSize: 7, color: '#888888' }} debug={debug}>
            {item.bron}
          </Typography>
        </Box>

        {/* Object main function and sub functions */}
        <Box style={{ width: '100%' }}>
          <Typography style={{ fontSize: 6, color: '#000' }} debug={debug}>
            {objectFunctions}
          </Typography>
        </Box>

        {/* Object item details */}
        <Box
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          {Array.isArray(slotElements) &&
            slotElements.map(({ term, definition, perM2 }, index) => {
              return (
                <Box
                  style={{
                    flex: '1 1 33%',
                    width: '33%',
                    marginTop: '4px',
                  }}
                  key={index}
                >
                  <Typography
                    style={{ fontSize: 7, color: '#888888' }}
                    debug={debug}
                  >
                    {term}
                  </Typography>
                  {term === 'Energielabel' ? (
                    <EnergyLabel label={definition} fontSize={7} />
                  ) : (
                    <Typography
                      style={{ fontSize: 7, color: '#000', fontWeight: 700 }}
                      debug={debug}
                    >
                      {definition}
                    </Typography>
                  )}
                  {perM2 && (
                    <Typography
                      style={{ fontSize: 7, color: '#888888' }}
                      debug={debug}
                    >
                      {perM2}
                    </Typography>
                  )}
                </Box>
              )
            })}
        </Box>

        {/* Object broker and broker place */}
        <Box style={{ width: '100%', marginTop: 'auto' }}>
          <Typography style={{ fontSize: 8, color: '#000' }} debug={debug}>
            {naam} {plaats ?? ''}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
