import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import SearchBar from '@brainbay/components/components/search-bar'
import AutocompleteItem from '../autocomplete-item/autocomplete-item'
import useAutocompleteOptions from '../../utils/use-autocomplete-options'
import { defaultSearchOption } from '../../utils/constants'
import getFormattedSearchQuery, {
  getFormattedSearchQueryResultTitle,
} from '../../utils/get-formatted-search-query'
import { retriggerInitialBounds } from '../../store/map-big'
import { setSearchQuery } from '../../store/search'
import {
  retriggerInitialState,
  setLatestSelectedResult,
  setLatestSelectedResultGeometry,
} from '../../store/search-result-latest-selected'
import {
  setAutoCompleteOption,
  setNewSearchValue,
  setRadius,
} from '../../store/comparison'

export default function AppSearchBarReferences({ isLanding }) {
  const [inputQuery, setInputQuery] = useState('')
  const [option, setOption] = useState({})
  const [subLabel, setSubLabel] = useState('')

  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const isMapMode = useSelector(state => state.searchResultMode.isMapMode)
  const isCompactMode = useSelector(
    state => state.searchResultMode.isCompactMode,
  )
  const radiusValue = useSelector(state => state.comparison.radius)
  const searchQuery = useSelector(state => state.search.query)
  const existingAutocompleteOption = useSelector(
    state => state.comparison.autocompleteOption,
  )
  const { id } = defaultSearchOption

  const { autocompleteOptions, autocompleteOptionsAreLoading } =
    useAutocompleteOptions(inputQuery)

  const itemRenderer = (isSelected, item) => {
    return <AutocompleteItem isSelected={isSelected} {...item} />
  }

  const triggerInitialBounds = () => {
    dispatch(retriggerInitialBounds())
  }
  const resetLastestSelectedResult = () => {
    dispatch(retriggerInitialState())
    dispatch(setLatestSelectedResult(undefined))
    dispatch(
      setLatestSelectedResultGeometry({
        longitude: undefined,
        latitude: undefined,
        zoom: undefined,
      }),
    )
  }

  const getOfferParam = url => {
    resetLastestSelectedResult()
    return url
  }

  /* makes sure the input query is set to the correct value on load */
  useEffect(() => {
    setInputQuery(getFormattedSearchQuery(existingAutocompleteOption))
  }, [existingAutocompleteOption])

  useEffect(() => {
    if (inputQuery === '') {
      setOption({})
    }

    if (inputQuery === existingAutocompleteOption?.label) {
      setOption(existingAutocompleteOption)
    }
  }, [inputQuery, existingAutocompleteOption])

  useEffect(() => {
    if (option) {
      const { type } = getFormattedSearchQueryResultTitle(option)
      setSubLabel(type)
    }
  }, [option])

  /*
    Happens when the user presses 'enter' or clicks the search button.
    The content in the text field might not directly map to something in the autocomplete list so
    we have to do some magic here to lookup the option that the user might want to select
  */

  function handleOnSearchSubmit(event, inputValue) {
    if (event) {
      event.preventDefault()
    }

    // this might happen when you press 'enter' too soon. The api call is still in-flight
    // we might want to refine this a bit, but for now this seems ok.
    if (autocompleteOptionsAreLoading) {
      return
    }

    if (inputValue === '') {
      let url = `/search-results/${id}`

      if (isMapMode) {
        url += '/map'
        triggerInitialBounds()
      } else if (isCompactMode) {
        url += '/compact-lijst'
      }

      const offerParam = getOfferParam(url)
      dispatch(setSearchQuery(offerParam))
      history.push(offerParam)
    } else {
      const label = option.parent?.parent?.label
        ? `${option.label}, ${option.parent.parent.label}`
        : option.label

      if (label !== inputValue) {
        if (autocompleteOptions) {
          const [firstOption] = autocompleteOptions
          dispatch(setAutoCompleteOption(firstOption))
          const { url } = selectOption(firstOption)
          history.push(url)
          triggerInitialBounds()
        }
      } else if (searchQuery && Object.keys(option).length !== 0) {
        history.push(searchQuery)
        triggerInitialBounds()
      }
    }
  }
  function selectOption(autocompleteOption) {
    const mapUrlPart = isMapMode
      ? '/map'
      : isCompactMode
      ? '/compact-lijst'
      : ''
    const query = autocompleteOption?.id

    setOption(autocompleteOption)

    dispatch(setNewSearchValue(autocompleteOption?.label))
    setInputQuery(getFormattedSearchQuery(autocompleteOption))

    /* bit of hack to retrigger a zoom */
    if (existingAutocompleteOption?.id === autocompleteOption.id) {
      dispatch(retriggerInitialBounds())
    }

    const url = `/search-results/${query}${mapUrlPart}${location.search}`
    dispatch(setSearchQuery(getOfferParam(url)))

    if (!isLanding) {
      dispatch(setAutoCompleteOption(autocompleteOption))
      history.push(getOfferParam(url))
    }

    return {
      url: getOfferParam(url),
    }
  }

  function onChangeRadius(e) {
    let value = e.target.value
    if (value === '0') {
      value = ''
    }

    const params = new URLSearchParams(location.search)
    if (value) {
      params.set('radius', value)
    } else {
      params.delete('radius')
    }

    dispatch(setRadius(value))
    history.push(`${location.pathname}?${params.toString()}`)
  }
  return (
    <SearchBar
      autocomplete
      autocompleteOptions={autocompleteOptions}
      autocompleteOptionsAreLoading={autocompleteOptionsAreLoading}
      autocompleteDefaultSelectedOption={0}
      onSubmit={handleOnSearchSubmit}
      itemRenderer={itemRenderer}
      onChange={setInputQuery}
      radiusOptions={[0, 1, 2, 5, 10, 15, 30, 50, 100]}
      onChangeRadius={onChangeRadius}
      radiusValue={radiusValue}
      placeholder="Zoek op adres, postcode, plaats, gemeente of provincie"
      noResultsMessage="Geen resultaten, geef een andere zoekterm in."
      onClick={selectOption}
      searchValue={inputQuery}
      primaryAction={isLanding}
      separated={isLanding}
      subLabel={subLabel}
      isMapMode={isMapMode}
    />
  )
}
