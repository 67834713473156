import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ObjectLabel from '@brainbay/components/components/object-label'
import ResponsiveImage from '@brainbay/components/components/responsive-image'
import { apiHost } from '@brainbay/components/utils/environment-vars'
import formatValue from '@brainbay/components/utils/format-value'
import StatusLabel from '@brainbay/components/components/status-label'
import AddButton from '@brainbay/components/components/add-button'
import Icon from '@brainbay/components/components/icon'
import { ReactComponent as IconBusinessBuilding } from '@brainbay/components/_assets/svg/icon-business-building.svg'
import useObjectDate from '../../utils/use-object-date'
import useObjectSurface from '../../utils/use-object-surface'
import useObjectPrices from '../../utils/use-object-prices'
import formatAddressBusiness from '../../utils/format-address-business'
import {
  addObject,
  removeObject,
  expandComparePanel,
} from '../../store/comparison'

import '../search-result-compact-list/search-result-compact-list.css'
import { fluxParams } from '../../utils/flux-params'

export default function SearchResultsCompact({ data, isLoading }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const compareList = useSelector(state => state.comparison.compareList)
  function onAddToggle(guid) {
    dispatch(expandComparePanel())
    if (compareList.includes(guid)) {
      dispatch(removeObject(guid))
    } else {
      dispatch(addObject(guid))
    }
  }
  const address = formatAddressBusiness({
    street: data?.straatnaam,
    houseNumber: data?.huisnummer,
    houseNumberSuffix: data?.huisnummertoevoeging,
    postalCode: data?.postcode,
    city: data?.woonplaats,
    municipality: data?.gemeente,
  })
  const GetDateTypeFromObject = item => {
    const datum = useObjectDate(item)
    return datum
  }
  const GetSurfaceTypeFromObject = item => {
    const oppervlakte = useObjectSurface(item)
    return oppervlakte
  }
  const GetPriceTypeFromObject = item => {
    const [prijs] = useObjectPrices(item)
    return prijs
  }

  function formatPrimaryPriceValue(item) {
    const isHuurprijs = item?.isHuur
    const isKoop = item?.isKoop

    const conditie = isHuurprijs ? 'huurconditie' : isKoop ? 'koopconditie' : ''

    if (isHuurprijs || isKoop) {
      if (item?.huurprijsVan && item?.huurprijsTot) {
        return `vanaf ${formatValue(item.huurprijsVan, {
          format: 'costs',
          condition: item[conditie],
        })}`
      } else {
        return formatValue(isHuurprijs ? item?.huurprijs : item?.koopprijs, {
          format: 'costs',
          condition: item[conditie],
        })
      }
    }

    return '---'
  }

  function formatSecondaryPriceValue(item) {
    if (item.isHuur && item.huurprijsPerM2Jaar) {
      return formatValue(item?.huurprijsPerM2Jaar, {
        format: 'costs',
        condition: 'per vierkante meter per jaar',
      })
    } else if (item.isKoop && item.koopprijsPerM2) {
      return formatValue(item?.koopprijsPerM2, 'per-meter-squared')
    }

    return '---'
  }

  return (
    <div className="search-results-compact-table">
      <table className="search-results-compact-table__content">
        <thead>
          <tr className="search-results-compact-table__row">
            <th
              scope="col"
              className="search-results-compact-table__column-header"
            ></th>
            <th
              scope="col"
              className="search-results-compact-table__column-header"
            >
              Functie
            </th>
            <th
              scope="col"
              className="search-results-compact-table__column-header"
            >
              Adres
            </th>
            <th
              scope="col"
              className="search-results-compact-table__column-header"
            >
              Postcode
            </th>
            <th
              scope="col"
              className="search-results-compact-table__column-header"
            >
              Woonplaats
            </th>
            <th
              scope="col"
              className="search-results-compact-table__column-header"
            >
              Datum
            </th>
            <th
              scope="col"
              className="search-results-compact-table__column-header"
            >
              Oppervlakte
            </th>
            <th
              scope="col"
              className="search-results-compact-table__column-header"
            >
              Vraagprijs
            </th>
            <th
              scope="col"
              className="search-results-compact-table__column-header"
            >
              Transactieprijs
            </th>
          </tr>
        </thead>
        <tbody className="search-results-compact__body">
          {data.map(item => {
            const date = GetDateTypeFromObject(item)
            const surface = GetSurfaceTypeFromObject(item)
            const price = GetPriceTypeFromObject(item)
            const isAdded = compareList.includes(item.objectGuid)
            const host = !item?.mediaGuid && `${apiHost()}/streetview`
            const imageId = item?.mediaGuid || address

            const {
              isAllFluxParamsExist,
              fluxId,
              fluxSessionContext,
              fluxAddress,
            } = fluxParams()

            const redirectUrl = !isAllFluxParamsExist
              ? `/${item.objectGuid}`
              : `/${item.objectGuid}?sessionContext=${fluxSessionContext}&address=${fluxAddress}&fluxid=${fluxId}`

            return (
              <tr
                key={item.id}
                className="search-results-compact__row"
                onClick={() => history.push(redirectUrl)}
              >
                <td className="search-results-compact__data loading-state">
                  <div className="search-results-compact__data__image-wrapper">
                    <ObjectLabel
                      isSmall
                      className={`search-result-item-business-references__image-label 
                      ${
                        item.mediaGuid
                          ? 'search-result-item-business-references__image-label--with-image'
                          : ''
                      }`}
                      status={
                        <StatusLabel
                          status={item?.status}
                          className={
                            item?.status === 'verkocht bij inschrijving' ||
                            item?.status === 'verkoop bij inschrijving'
                              ? 'compact__custom-status-label_long-text text-bold capitalize-first-letter '
                              : 'compact__custom-status-label capitalize-first-letter body-small text-bold'
                          }
                        />
                      }
                    >
                      {item?.mediaGuid ? (
                        <ResponsiveImage
                          imageId={imageId}
                          host={host}
                          isLoading={isLoading}
                        />
                      ) : (
                        <div className="search-result-compact-business-references__image-placeholder">
                          <Icon className="search-result-compact-business-references__icon">
                            <IconBusinessBuilding />
                          </Icon>
                        </div>
                      )}
                    </ObjectLabel>
                  </div>
                </td>
                <td className="search-results-compact__data loading-state">
                  {item?.hoofdfunctie}
                </td>
                <td className="search-results-compact__data loading-state">
                  {!isLoading &&
                    formatAddressBusiness({
                      street: item?.straatnaam,
                      houseNumber: item?.huisnummer,
                      houseNumberSuffix: item?.huisnummertoevoeging,
                    })}
                </td>
                <td className="search-results-compact__data loading-state">
                  {item?.postcode}
                </td>
                <td className="search-results-compact__data loading-state">
                  {item?.woonplaats}
                </td>
                <td className="search-results-compact__data loading-state">
                  {date?.definition}
                </td>
                <td className="search-results-compact__data loading-state">
                  {surface?.definition}
                </td>
                <td className="search-results-compact__data loading-state">
                  {!isLoading && (
                    <>
                      {price?.term === 'Vraagprijs' ||
                      price?.term === 'Huurprijs'
                        ? price?.definition
                        : formatPrimaryPriceValue(item)}
                      <br />
                      <span className="body-small text-normal text-dim">
                        {price?.term === 'Vraagprijs' ||
                        price?.term === 'Huurprijs'
                          ? price?.perM2
                          : formatSecondaryPriceValue(item)}
                      </span>
                    </>
                  )}
                </td>
                <td className="search-results-compact__data loading-state">
                  {!isLoading && (
                    <>
                      {price?.term === 'Transactieprijs'
                        ? price?.definition
                        : '---'}
                      <br />
                      <span className="body-small text-normal text-dim">
                        {price?.term === 'Transactieprijs' ? price?.perM2 : ''}
                      </span>
                    </>
                  )}
                </td>
                <td className="search-results-compact__data-add-button">
                  <AddButton
                    hideLabel
                    isAdded={isAdded}
                    disabled={isLoading}
                    onClick={e => {
                      e.stopPropagation()
                      onAddToggle(item?.objectGuid)
                    }}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
