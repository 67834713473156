import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  Suspense,
} from 'react'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import NotificationBar from '../notification-bar/notification-bar'
import Icon from '../icon'

import { ReactComponent as IconExport } from '../../_assets/svg/icon-export.svg'
import { ReactComponent as IconSearch } from '../../_assets/svg/icon-search.svg'

import isSSR from '../../utils/is-ssr'

import './app-header.css'

const Logo = React.lazy(() => import('./logo'))

export default function AppHeader({
  notification,
  hasExport,
  onExport,
  searchbar,
  actions,
}) {
  const [searchBarIsOpen, setSearchBarIsOpen] = useState(false)
  const searchElement = useRef()
  const searchToggle = useRef()
  const location = useLocation()
  const handleClickOutside = useCallback(
    event => {
      if (searchBarIsOpen && !searchElement.current.contains(event.target)) {
        setSearchBarIsOpen(false)
      }
    },
    [searchBarIsOpen],
  )
  const handleEscape = useCallback(
    event => {
      if (searchBarIsOpen && event.key === 'Escape') {
        setSearchBarIsOpen(false)
        searchToggle.current.focus()
      }
    },
    [searchBarIsOpen],
  )

  useEffect(() => {
    if (searchBarIsOpen) {
      searchElement.current.querySelector('input').select()
      document.addEventListener('mousedown', handleClickOutside)
      document.addEventListener('keydown', handleEscape)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleEscape)
    }
  }, [handleClickOutside, handleEscape, searchBarIsOpen])

  useEffect(() => {
    setSearchBarIsOpen(false)
  }, [location])

  function onSearchBarOpen() {
    setSearchBarIsOpen(true)
  }

  const headerClasses = classNames({
    'app-header': true,
    'app-header--no-export-button': !hasExport,
    'app-header--search-open': searchBarIsOpen,
    'print-excluded': true,
  })

  return (
    <>
      {notification && <NotificationBar notification={notification} />}

      <header className={headerClasses}>
        <Link to="/" className="app-header__home-link">
          {!isSSR ? (
            <Suspense fallback={<span>Home</span>}>
              <span className="sr-only">Terug naar home</span>
              <Logo />
            </Suspense>
          ) : (
            <span>Home</span>
          )}
        </Link>

        {searchbar && (
          <>
            <button
              ref={searchToggle}
              className="app-header__search-toggle"
              onClick={onSearchBarOpen}
            >
              <Icon aria-hidden="true">
                <IconSearch />
              </Icon>
              <span className="sr-only">Zoeken</span>
            </button>

            <div
              className="app-header__search"
              role="search"
              ref={searchElement}
            >
              {searchbar}
            </div>
          </>
        )}

        {actions}

        {hasExport && (
          <button
            className="app-header__export-button button button--secondary"
            onClick={onExport}
          >
            <Icon aria-hidden="true">
              <IconExport />
            </Icon>
            <span className="app-header__export-button-text">Exporteren</span>
          </button>
        )}
      </header>
    </>
  )
}
