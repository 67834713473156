import React from 'react'
import { useSelector } from 'react-redux'
import { logout } from '../../utils/auth-service'

import './app-nav.css'

const getUrlOfDienstenApp = () => {
  const nodenv = window.deploy_env
  let url = ''

  switch (nodenv) {
    case '__DEPLOY_ENV__':
      url = 'https://diensten.acc.brainbay.nl/'
      break
    case 'tst':
      url = 'https://diensten.tst.brainbay.nl/'
      break
    case 'acc':
      url = 'https://diensten.acc.brainbay.nl/'
      break
    case 'prd':
      url = 'https://diensten.brainbay.nl/'
      break
    default:
      break
  }

  return url
}

export default function AppNav({ className = '' }) {
  const user = useSelector(state => state?.user)

  return (
    <div
      className={`app-nav layout__double-sidebar print-excluded ${className}`.trim()}
    >
      <nav>
        <ul className="app-nav__nav">
          {
            <li>
              <a
                href={getUrlOfDienstenApp()}
                rel="noopener noreferrer"
                target="_blank"
              >
                Voorwaarden
              </a>
            </li>
          }
          {user.user && user.accessToken && user.idToken && (
            <li>
              <button onClick={logout} className="body text-bold">
                Uitloggen
              </button>
            </li>
          )}
        </ul>
      </nav>
    </div>
  )
}
