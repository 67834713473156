import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  addObject,
  removeObject,
  objectsToCompareSelector,
} from '../../store/comparison'
import CompareListItem from '../compare-list-item'
import Table from '@brainbay/components/components/table'
import Icon from '@brainbay/components/components/icon'
import EnergyLabel from '@brainbay/components/components/energy-label'
import formatValue from '@brainbay/components/utils/format-value'
import { ReactComponent as IconBouwjaar } from '@brainbay/components/_assets/svg/icon-bouwjaar.svg'
import { ReactComponent as IconWoonoppervlak } from '@brainbay/components/_assets/svg/icon-woonoppervlak.svg'
import { ReactComponent as IconPerceeloppervlak } from '@brainbay/components/_assets/svg/icon-perceeloppervlak.svg'

import './compare-table.css'
import useObjectDate from '../../utils/use-object-date'
import useObjectPrices from '../../utils/use-object-prices'
import useObjectSurface from '../../utils/use-object-surface'
import formatObjectFunction from '../../utils/format-object-function'

const CellWithLabel = ({
  label,
  value,
  className = '',
  isLoading,
  hideLabel = false,
  ariaLabel,
  icon,
}) => (
  <div className={`compare-table__table-cell-wrapper ${className}`.trim()}>
    <span
      className={`compare-table__table-cell-label body-small text-bold loading-state ${
        hideLabel ? 'sr-only' : ''
      }`.trim()}
      aria-label={ariaLabel}
    >
      {label}:
    </span>
    <span className="compare-table__table-cell-value body loading-state">
      {isLoading
        ? null
        : (
            <>
              {icon && <Icon size="tiny">{icon}</Icon>} {value}
            </>
          ) || <span>{icon && <Icon size="tiny">{icon}</Icon>}&nbsp;</span>}
    </span>
  </div>
)

const DateCell = ({ data, ...otherProps }) => {
  const { term, definition } = useObjectDate(data)
  return <CellWithLabel label={term} value={definition} {...otherProps} />
}

const PriceCell = ({ data, ...otherProps }) => {
  const [primaryPrice, secondaryPrice] = useObjectPrices(data)

  const value = [primaryPrice, secondaryPrice]
    .filter(Boolean)
    .map((price, index) => {
      return (
        <div className="compare-table__table-cell-prices" key={index}>
          <span className="compare-table__table-cell-price text-bold">
            {price.definition}
          </span>
          <span className="body-small text-dim">{price.perM2}</span>
        </div>
      )
    })

  return (
    <CellWithLabel
      className="compare-table__table-cell--prices"
      label={primaryPrice?.term}
      value={value}
      {...otherProps}
    />
  )
}

const SurfaceCell = ({ data, ...otherProps }) => {
  const { term, definition } = useObjectSurface(data)
  return (
    <CellWithLabel
      ariaLabel={term}
      label={term}
      icon={<IconWoonoppervlak />}
      value={definition}
      {...otherProps}
    />
  )
}

const ConditionCell = ({ data, ...otherProps }) => {
  const [condition] = data
    .map(kenmerk => {
      if (kenmerk.title === 'Staat van onderhoud') {
        const [binnen, buiten] = kenmerk.items

        return {
          title: kenmerk.title,
          value: (
            <span className="compare-table__table-cell-condition">
              {binnen && <span>{`${binnen.label}: ${binnen.value}`}</span>}
              {buiten && <span>{`${buiten.label}: ${buiten.value}`}</span>}
            </span>
          ),
        }
      }
      return null
    })
    .filter(Boolean)

  if (!condition?.title || !condition?.value) return null

  return (
    <CellWithLabel
      label={condition?.title}
      value={condition?.value}
      {...otherProps}
    />
  )
}

export default function CompareTable() {
  const dispatch = useDispatch()
  const compareList = useSelector(state => state.comparison.compareList)
  const objectsToCompare = useSelector(objectsToCompareSelector)

  function handleAddButton(guid) {
    if (compareList.includes(guid)) {
      dispatch(removeObject(guid))
    } else {
      dispatch(addObject(guid))
    }
  }

  return (
    <div className="compare-table__table-wrapper">
      <Table
        className="compare-table__table"
        caption="Vergelijk objecten"
        hideCaption
        columns={objectsToCompare}
        columnHeaders={objectsToCompare.map(
          (item, index) => `Referentie #${index + 1}`,
        )}
        rows={[
          {
            columnProperty: 'summary',
            render: (object, index, array) => (
              <CompareListItem
                className="compare-table__table-header-image"
                style={{ zIndex: array.length - index }}
                key={`${object.objectGuid}`}
                isLoading={object.loading}
                city={object.woonplaats}
                houseNumber={object.huisnummer}
                houseNumberSuffix={object.huisnummertoevoeging}
                mediaGuid={object?.mediaGuid}
                objectGuid={object.objectGuid}
                status={object.status}
                duration={object.looptijd}
                street={object.straatnaam}
                municipality={object.gemeente}
                removeHandler={() => handleAddButton(object.objectGuid)}
              />
            ),
          },
          {
            columnProperty: 'hoofdfunctie',
            render: object => (
              <CellWithLabel
                isLoading={object.loading}
                label="Hoofdfunctie"
                value={formatObjectFunction(object)}
              />
            ),
          },
          {
            columnProperty: 'date',
            render: object => (
              <DateCell data={object} isLoading={object.loading} />
            ),
          },
          {
            columnProperty: 'price',
            render: object => (
              <PriceCell data={object} isLoading={object.loading} />
            ),
          },
          {
            columnProperty: 'incentives',
            render: object => (
              <CellWithLabel
                isLoading={object.loading}
                label="Huurkorting"
                value={formatValue(
                  object.transactie?.huurovereenkomst?.aantalMaandenIncentives,
                  {
                    format: 'months',
                  },
                )}
              />
            ),
          },
          {
            columnProperty: 'term-contract',
            render: object => (
              <CellWithLabel
                isLoading={object.loading}
                label="Duur contract"
                value={formatValue(
                  object.transactie?.huurovereenkomst?.duurcontractMaanden,
                  {
                    format: 'months',
                  },
                )}
              />
            ),
          },
          {
            columnProperty: 'pand-oppervlakte',
            render: object => (
              <SurfaceCell data={object} isLoading={object.loading} />
            ),
          },
          {
            columnProperty: 'terrein-oppervlakte',
            render: object => (
              <CellWithLabel
                isLoading={object.loading}
                hideLabel
                label="Terrein oppervlakte"
                icon={<IconPerceeloppervlak />}
                value={
                  object.perceelOppervlakte && `${object.perceelOppervlakte}m2`
                }
              />
            ),
          },
          {
            columnProperty: 'bouwjaar',
            render: object => (
              <CellWithLabel
                isLoading={object.loading}
                label="Bouwjaar"
                value={object.bouwjaar}
                icon={<IconBouwjaar />}
              />
            ),
          },
          {
            columnProperty: 'kenmerken',
            render: object => <ConditionCell data={object.kenmerken} />,
          },
          {
            columnProperty: 'ligging',
            render: object => (
              <CellWithLabel
                isLoading={object.loading}
                label="Ligging"
                value={formatValue(object.liggingen, 'comma-separated-list')}
              />
            ),
          },
          {
            columnProperty: 'energielabel',
            render: object => (
              <CellWithLabel
                label="Energielabel"
                value={
                  <EnergyLabel
                    isLoading={object.loading}
                    label={object.energielabel}
                  />
                }
              />
            ),
          },
        ]}
      />
    </div>
  )
}
