/**
 * @example
 * titleCase("some string") // "Some string"
 *
 * @param {String} string String to be transformed
 * @returns Transformed string
 */
export default function titleCase(string) {
  const [firstLetter, ...rest] = string
  return `${firstLetter?.toUpperCase?.() || ''}${
    rest?.join('')?.toLowerCase?.() || ''
  }`
}
