import formatValue from '@brainbay/components/utils/format-value'
import titleCase from './title-case'

/**
 * Format object function subfunctions
 * @param hoofdfunctie
 * @param specialisaties
 * @param alternatieveAanwendbaarheden
 * @returns {string}
 */
export default function formatObjectFunction({
  hoofdfunctie,
  specialisaties,
  alternatieveAanwendbaarheden,
} = {}) {
  let hoofdFunctieString = hoofdfunctie ? titleCase(hoofdfunctie) : ''
  hoofdFunctieString +=
    specialisaties?.length > 0
      ? ` - ${formatValue(specialisaties, 'comma-separated-list')}`
      : ''

  let alternatieveAanwendbaarhedenString =
    alternatieveAanwendbaarheden?.length > 0
      ? `${alternatieveAanwendbaarheden.filter(item => item).join(' | ')}`
      : ''

  if (alternatieveAanwendbaarhedenString === '') return hoofdFunctieString

  if (hoofdFunctieString === '') return alternatieveAanwendbaarhedenString

  return hoofdFunctieString + ' | ' + alternatieveAanwendbaarhedenString
}
