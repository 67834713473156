import React, { useEffect, useState } from 'react'
import formatValue from '../../utils/format-value'
import useResizeObserver from '../../utils/use-resize-observer'
import './search-result-list.css'

const Error = ({ query }) => (
  <p className="body-big">
    Geen resultaten gevonden voor <q>{query}</q>
  </p>
)

export default function SearchResultList({
  children,
  query,
  type,
  radius,
  error,
  hasResults,
  isLoading,
  titleTag = 'p',
  noResultsText = 'In de Midas-database zijn geen resultaten gevonden voor',
  explanation,
  totalResults,
  sizeThreshold = 478,
  size: providedSize,
  filterOptions = null,
  filterDescription = null,
  autoSizedRows,
}) {
  const TitleTag = titleTag
  const [size, setSize] = useState('large')

  useEffect(() => {
    if (providedSize) {
      setSize(providedSize)
    }
  }, [providedSize])

  const { ref } = useResizeObserver({
    onResize: ({ width }) => {
      if (providedSize) return
      window.requestAnimationFrame(() => {
        if (width >= sizeThreshold) {
          setSize('large')
        } else {
          setSize('small')
        }
      })
    },
  })

  if (error) {
    return <Error query={query} />
  } else {
    let introText = ''
    if (isLoading) {
      introText = `Resultaten laden voor ${type ? type : ''} `
    } else if (hasResults) {
      introText = `Zoekresultaten voor ${type ? type : ''} `
    } else {
      introText = noResultsText
    }

    return (
      <>
        <div className="search-result-list__header">
          <TitleTag
            className={`body-big search-result-list__title ${
              filterDescription ? 'search-result-list__title--spacing' : null
            }`}
            aria-live="polite"
          >
            {introText} <q>{query}</q>
            {!isLoading && !hasResults && explanation
              ? `. ${explanation}`
              : ''}{' '}
            {radius > 0 &&
              ` + ${formatValue(radius, {
                format: 'linear-measure',
                condition: 'km',
              })}`}{' '}
            {totalResults && `(${formatValue(totalResults, 'decimal')})`}
          </TitleTag>
          {(filterOptions || filterDescription) && (
            <div className="search-result-list__options">
              {filterOptions}
              {filterDescription}
            </div>
          )}
        </div>
        <ul
          ref={ref}
          className={`search-result-list__list body ${
            autoSizedRows ? `search-result-list__list--auto-sized-rows` : ''
          }`}
          data-size={size}
        >
          {children &&
            children.map((child, index) => (
              <li key={index} className="search-result-list__item">
                {child}
              </li>
            ))}
        </ul>
      </>
    )
  }
}
