import React from 'react'
import { Link } from 'react-router-dom'

import ResponsiveImage from '../responsive-image'

import formatValue from '../../utils/format-value'
import formatUrl from '../../utils/format-url'
import { apiHost } from '../../utils/environment-vars'

import './object-list-item-small.css'

export default function ObjectListItemSmall({
  addressId,
  id,
  mediaGuid,
  address,
  date,
  housing,
  linkTo = '',
  dateText = 'Laatst bekeken op:',
}) {
  const host = !mediaGuid && `${apiHost()}/streetview`
  const imageId = mediaGuid || address
  const url = linkTo || formatUrl({ housingType: housing, addressId, id })

  return (
    <Link to={url} className="object-list-item-small">
      <div className="object-list-item-small__visual">
        <ResponsiveImage
          className="object-list-item-small__image"
          imageId={imageId}
          host={host}
        />
      </div>
      <h3 className="object-list-item-small__title body text-bold">
        {address}
      </h3>
      <p className="body text-dim">
        {dateText} {formatValue(Date.parse(date) / 1000, { format: 'date' })}
      </p>
    </Link>
  )
}
