export default function formatAddressBusiness({
  street,
  houseNumber,
  houseLetter,
  houseNumberSuffix,
  city,
  postalCode,
  municipality,
  noStreetReturnValue,
}) {
  if (!street && !municipality) {
    return '---'
  }
  if (!street) {
    return noStreetReturnValue ? noStreetReturnValue : municipality
  }

  let address = street

  if (houseNumber) address += ` ${houseNumber}`

  if (houseLetter) {
    address += ` ${houseLetter}`
  }

  if (houseNumberSuffix) {
    address += ` ${houseNumberSuffix}`
  }

  if (postalCode) {
    address += `, ${postalCode}`
  } else if (city) {
    address += ','
  }

  if (city) {
    address += ` ${city}`
  }

  return address
}
