import { useCallback, useContext, useState } from 'react'
import ConsentModal from '@brainbay/components/components/consent-modal'
import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { consentApi } from './consent-api'
import hero3000Webp from '../../_assets/images/hero-image-3000.webp'
import hero2000Webp from '../../_assets/images/hero-image-2000.webp'
import heroWebp from '../../_assets/images/hero-image.webp'
import hero1280Webp from '../../_assets/images/hero-image-1280.webp'
import hero920Webp from '../../_assets/images/hero-image-920.webp'
import hero640Webp from '../../_assets/images/hero-image-640.webp'
import hero320Webp from '../../_assets/images/hero-image-320.webp'
import hero3000Jpg from '../../_assets/images/hero-image-3000.jpg'
import hero2000Jpg from '../../_assets/images/hero-image-2000.jpg'
import heroJpg from '../../_assets/images/hero-image.jpg'
import hero1280Jpg from '../../_assets/images/hero-image-1280.jpg'
import hero920Jpg from '../../_assets/images/hero-image-920.jpg'
import hero640Jpg from '../../_assets/images/hero-image-640.jpg'
import hero320Jpg from '../../_assets/images/hero-image-320.jpg'

export const ConsentContext = React.createContext({
  checkConsent: async contextName => {
    console.log('Did you forget to implement the consent provider?')
  },
})

export function useConsent() {
  return useContext(ConsentContext)
}

function defaultRenderConsentDialog({
  id,
  isDisclaimer,
  hasDisclaimerContent,
  consentType,
  name,
  eulaMd,
  acceptText,
  buttonText,
  steps,
  onAccept,
  onClose,
  headerImages,
}) {
  return (
    <ConsentModal
      id={id}
      isDisclaimer={isDisclaimer}
      hasDisclaimerContent={hasDisclaimerContent}
      consentType={consentType}
      name={name}
      eulaMd={eulaMd}
      acceptText={acceptText}
      buttonText={buttonText}
      steps={steps}
      onAccept={onAccept}
      onClose={onClose}
      headerImages={headerImages}
    />
  )
}

export class RejectError extends Error {
  constructor() {
    super('Rejected consent')
  }
}

export function ConsentProvider({
  children,
  renderConsentDialog = defaultRenderConsentDialog,
}) {
  const username = useSelector(state => state.user.user?.sub)

  const [consents, setConsents] = useState([])
  const [steps, setSteps] = useState(() => ({ current: 1, total: 1 }))
  const consentPromiseResolve = useRef(null)
  const consentPromiseReject = useRef(null)

  const currentConsent = consents[0] ?? null

  async function onAcceptConsent(id, isDisclaimer) {
    if (isDisclaimer) {
      const updatedConsents = consents.filter(
        consent => consent.consentId !== id,
      )
      setConsents(updatedConsents)
      return
    }
    await consentApi.acceptConsents({
      username,
      ids: [id],
    })

    // filter out the given consent as it is accepted
    const updatedConsents = consents.filter(consent => consent.consentId !== id)
    setConsents(updatedConsents)

    setSteps({
      current: steps.current + 1,
      total: steps.total,
    })

    if (!updatedConsents.length) {
      const callback = consentPromiseResolve.current
      consentPromiseResolve.current = null
      callback()
    }
  }

  function onRejectConsent() {
    const callback = consentPromiseReject.current
    consentPromiseReject.current = null
    reset()
    callback(new RejectError())
  }

  function reset() {
    setSteps({ current: 1, total: 1 })
    setConsents([])
  }

  const checkConsent = useCallback(
    async contextName => {
      if (!username) {
        throw new Error('User is not authenticated yet')
      }

      const { allAccepted, missingConsents } = await consentApi.checkConsents({
        username,
        contextName,
      })

      if (allAccepted) {
        return
      }

      const missingConsentsContent = await consentApi.getConsents({
        ids: missingConsents,
      })

      const filteredOutConsentContents = missingConsentsContent.filter(
        consent => {
          return missingConsents.includes(consent.consentId)
        },
      )
      const consentsWithDisclaimer = filteredOutConsentContents.reduce(
        (acc, consent, index) => {
          consent.disclaimerContent
            ? acc.push(
                {
                  isDisclaimer: true,
                  content: consent.disclaimerContent,
                  consentId: index,
                  consentType: { isDisclaimer: true },
                },
                { ...consent, hasDisclaimerContent: true },
              )
            : acc.push(consent)
          return acc
        },
        [],
      )

      setConsents(consentsWithDisclaimer)
      setSteps({ current: 1, total: missingConsents.length })

      return new Promise((resolve, reject) => {
        consentPromiseResolve.current = resolve
        consentPromiseReject.current = reject
      })
    },
    [username],
  )

  const headerImages = {
    jpg: {
      3000: hero3000Jpg,
      2000: hero2000Jpg,
      1440: heroJpg,
      1280: hero1280Jpg,
      920: hero920Jpg,
      640: hero640Jpg,
      320: hero320Jpg,
    },
    webp: {
      3000: hero3000Webp,
      2000: hero2000Webp,
      1440: heroWebp,
      1280: hero1280Webp,
      920: hero920Webp,
      640: hero640Webp,
      320: hero320Webp,
    },
  }

  return (
    <ConsentContext.Provider value={{ checkConsent }}>
      {children}
      {currentConsent &&
        renderConsentDialog({
          id: currentConsent.consentId,
          isDisclaimer: currentConsent.isDisclaimer,
          hasDisclaimerContent: currentConsent.hasDisclaimerContent,
          name: currentConsent.consentType?.isDisclaimer
            ? 'Disclaimer'
            : currentConsent.name,
          eulaMd: currentConsent.content,
          acceptText: currentConsent.consentType?.isBedrijf
            ? 'Ik ben me ervan bewust dat ik een betaalde relatie aanga met brainbay'
            : currentConsent.consentType?.isDisclaimer
            ? ''
            : 'Ik heb het gelezen',
          steps,
          buttonText: currentConsent.consentType?.isDisclaimer
            ? 'Naar de gebruiksvoorwaarden'
            : 'Accepteren',
          onAccept: onAcceptConsent,
          onClose: onRejectConsent,
          headerImages: headerImages,
        })}
    </ConsentContext.Provider>
  )
}
