import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AppSearchBarReferences from '../app-search-bar-references/app-search-bar-references'
import AppSearchBarGebouwpaspoort from '../app-search-bar-gebouwpaspoort/app-search-bar-gebouwpaspoort'
import { setSearchMode } from '../../store/search'
import { useHistory } from 'react-router-dom'

import './app-search-bar.css'

export default function AppSearchBar({ isLanding, defaultSearchMode = '' }) {
  const history = useHistory()
  let searchType = useSelector(state => state.search.mode)
  searchType = defaultSearchMode || searchType
  const dispatch = useDispatch()

  function switchSearchType(event) {
    dispatch(setSearchMode(event.target.value))
    if (event.target.value === 'switch-gebouwpaspoort') {
      history.push('/gebouwpaspoort')
    } else if (event.target.value === 'switch-referenties') {
      history.push('/')
    }
  }

  return (
    <>
      {isLanding && (
        <form
          className={`app-search__switch ${
            searchType === 'switch-gebouwpaspoort'
              ? 'app-search__switch--gebouwpaspoort'
              : ''
          }`}
        >
          <input
            onChange={e => switchSearchType(e)}
            type="radio"
            name="search"
            value="switch-referenties"
            id="switch-referenties"
            checked={searchType === 'switch-referenties'}
          />
          <label
            htmlFor="switch-referenties"
            className="app-search__switch__label"
          >
            Vrij zoeken naar referenties
          </label>
          <input
            onChange={e => switchSearchType(e)}
            type="radio"
            name="search"
            value="switch-gebouwpaspoort"
            id="switch-gebouwpaspoort"
            checked={searchType === 'switch-gebouwpaspoort'}
          />
          <label
            htmlFor="switch-gebouwpaspoort"
            className="app-search__switch__label"
          >
            Zoeken naar gebouwpaspoort
          </label>
        </form>
      )}
      {searchType === 'switch-gebouwpaspoort' ? (
        <AppSearchBarGebouwpaspoort isLanding={isLanding} />
      ) : (
        <AppSearchBarReferences isLanding={isLanding} />
      )}
    </>
  )
}
