import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import ObjectLabel from '@brainbay/components/components/object-label'
import SourceLabel from '@brainbay/components/components/source-label'
import ResponsiveImage from '@brainbay/components/components/responsive-image'
import AddButton from '@brainbay/components/components/add-button'
import useResizeObserver from '@brainbay/components/utils/use-resize-observer'
import formatAddressBusiness from '../../utils/format-address-business'
import { apiHost } from '@brainbay/components/utils/environment-vars'
import Icon from '@brainbay/components/components/icon'
import { ReactComponent as IconBusinessBuilding } from '@brainbay/components/_assets/svg/icon-business-building.svg'
import SearchResultItemDetails from '../search-result-item-details'
import ObjectPartialTransactions from '../object/object-partial-transactions'
import formatObjectFunction from '../../utils/format-object-function'
import StatusRegistration from '../status-registration/status-registration'

import './search-result-item.css'
import { fluxParams } from '../../utils/flux-params'

export default function SearchResultItem({
  item,
  isAdded,
  isLoading,
  onAddToggle,
  standalone = false,
}) {
  const history = useHistory()
  const sizeThreshold = 700
  const [size, setSize] = useState('large')
  const address = formatAddressBusiness({
    street: item?.straatnaam,
    houseNumber: item?.huisnummer,
    houseNumberSuffix: item?.huisnummertoevoeging,
    postalCode: item?.postcode,
    city: item?.woonplaats,
    municipality: item?.gemeente,
    noStreetReturnValue:
      item?.bron === 'Kadaster'
        ? `Een perceel of appartementsrecht in de gemeente ${item?.gemeente}`
        : '',
  })

  const { ref } = useResizeObserver({
    onResize: ({ width }) => {
      window.requestAnimationFrame(() =>
        width >= sizeThreshold ? setSize('large') : setSize('small'),
      )
    },
  })

  const duration = item.transactie?.looptijd ?? item.looptijd
  const objectFunctions = formatObjectFunction(item)
  const host = !item?.mediaGuid && `${apiHost()}/streetview`
  const imageId = item?.mediaGuid || address

  const { isAllFluxParamsExist, fluxId, fluxSessionContext, fluxAddress } =
    fluxParams()

  const redirectUrl = !isAllFluxParamsExist
    ? `/${item.objectGuid}`
    : `/${item.objectGuid}?sessionContext=${fluxSessionContext}&address=${fluxAddress}&fluxid=${fluxId}`

  return (
    <>
      <div
        ref={ref}
        data-size={size}
        className={`search-result-item-business-references ${
          isAdded ? `search-result-item-business-references--added` : ''
        } ${
          standalone ? 'search-result-item-business-references--standalone' : ''
        }`}
      >
        <div
          className="search-result-item-business-references__image-wrapper"
          onClick={() => history.push(redirectUrl)}
        >
          <ObjectLabel
            isSmall
            className={`search-result-item-business-references__image-label ${
              item.mediaGuid
                ? 'search-result-item-business-references__image-label--with-image'
                : ''
            }`}
            duration={duration}
            match={item.match}
            status={item.status}
          >
            {item?.mediaGuid ? (
              <ResponsiveImage
                imageId={imageId}
                host={host}
                isLoading={isLoading}
              />
            ) : (
              <div className="search-result-item-business-references__image-placeholder">
                <Icon className="search-result-item-business-references__icon">
                  <IconBusinessBuilding />
                </Icon>
              </div>
            )}
          </ObjectLabel>
        </div>

        <div className="search-result-item-business-references__address">
          <h3 className="search-result-item-business-references__title">
            <Link className="loading-state" to={redirectUrl}>
              {address !== '---' ? address : null}
            </Link>
            {item.bron && <SourceLabel source={item.bron} isSmall />}
          </h3>
          <span className={`body-small ${isLoading ? 'loading-state' : ''}`}>
            {objectFunctions}
          </span>
        </div>

        <SearchResultItemDetails
          className="search-result-item-business-references__details"
          isLoading={isLoading}
          data={item}
        />

        <SearchResultItemBrokerAndStatusRegistration
          data={item}
          isLoading={isLoading}
        />

        <AddButton
          className="search-result-item-business-references__add-button"
          isAdded={isAdded}
          disabled={isLoading}
          aria-label={`Voeg ${item.straatnaam}${item.huisnummer}${item.huisnummertoevoeging} toe aan referenties`}
          onClick={() => onAddToggle(item.objectGuid)}
        />
        {item?.deelTransacties?.length > 0 && (
          <ObjectPartialTransactions
            data={item}
            className="search-result-item-business-references__transactions"
            fullBleed
            hideFooter
          />
        )}
      </div>
    </>
  )
}

const SearchResultItemBrokerAndStatusRegistration = ({ data, isLoading }) => {
  const { naam, plaats } = data.hoofdAanbieder ?? {}

  return (
    <div className="search-result-item-business-references__broker-and-status-registration">
      {data?.bron === 'Kadaster' &&
        data?.isPortefeuille &&
        'Let op: meer onroerend goed betrokken, zie detailpagina.'}

      <div className={`${isLoading ? 'loading-state' : ''}`}>
        {naam}
        {plaats ? `, ${plaats}` : ''}
      </div>

      {data?.controleStatus && (
        <StatusRegistration
          controleStatus={data.controleStatus}
          prefixControleStatus="Controlestatus"
          isSmall
        />
      )}
    </div>
  )
}
