import React, { useEffect, useState } from 'react'
import { useConsent } from './consent-provider'
import { useHistory, useLocation } from 'react-router-dom'
import { setSearchMode } from '../../store/search'
import { useDispatch } from 'react-redux'

/**
 * RequireConsent can be wrapped around a React element like this: <RequireConsent contextName="export">your content here</RequireConsent>
 * It will first check consent before rendering its contents.
 * If the user has not given consent then it will either return to the previous route or display a standard message.
 */
export function RequireConsent({ children, contextName }) {
  const { checkConsent } = useConsent()
  const [hasConsent, setHasConsent] = useState(null)
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const MESSAGE_LOADING = 'We controleren of u toegang heeft'
  const MESSAGE_NOT_AUTHORISED =
    'U bent niet akkoord gegaan met de algemene voorwaarden en daarom kunt u niet doorgaan.'

  useEffect(() => {
    checkConsent(contextName)
      .then(() => {
        setHasConsent(true)
      })
      .catch(() => {
        if (location.pathname !== '/') {
          // set URL to root path and search mode to referenties
          dispatch(setSearchMode('switch-referenties'))
          history.push('/')
        } else {
          // Just display a message when we are at the root of the application
          setHasConsent(false)
        }
      })
  }, [checkConsent, contextName, history, location.pathname, dispatch])

  const renderMessage = message => (
    <main className="layout--grid">
      <p className="body">{message}</p>
    </main>
  )

  if (hasConsent) {
    return children
  }

  if (hasConsent === false) {
    return renderMessage(MESSAGE_NOT_AUTHORISED)
  }

  return renderMessage(MESSAGE_LOADING)
}
