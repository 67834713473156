import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import ObjectLabel from '@brainbay/components/components/object-label'
import ResponsiveImage from '@brainbay/components/components/responsive-image'
import useResizeObserver from '@brainbay/components/utils/use-resize-observer'
import formatAddressBusiness from '../../utils/format-address-business'
import { apiHost } from '@brainbay/components/utils/environment-vars'
import Icon from '@brainbay/components/components/icon'
import { ReactComponent as IconBusinessBuilding } from '@brainbay/components/_assets/svg/icon-business-building.svg'
import SearchResultItemDetails from '../search-result-item-details'

import './search-result-item-gebouwpaspoort.css'

export default function SearchResultItemGebouwpaspoort({ item, isLoading }) {
  const history = useHistory()
  const sizeThreshold = 700
  const [size, setSize] = useState('large')

  const { pand, geselecteerdeAdres, verblijfsobjectOverview } = item || {}
  const {
    gemeente,
    huisnummer,
    huisnummertoevoeging,
    nummeraanduidingId,
    postcode,
    straatnaam,
    woonplaats,
  } = geselecteerdeAdres || {}

  const address = formatAddressBusiness({
    street: straatnaam,
    houseNumber: huisnummer,
    houseNumberSuffix: huisnummertoevoeging,
    postalCode: postcode,
    city: woonplaats,
    municipality: gemeente,
  })
  const host = address && `${apiHost()}/streetview`

  const { ref } = useResizeObserver({
    onResize: ({ width }) => {
      window.requestAnimationFrame(() =>
        width >= sizeThreshold ? setSize('large') : setSize('small'),
      )
    },
  })

  return (
    <>
      <div
        ref={ref}
        data-size={size}
        className="search-result-item__gebouwpaspoort"
      >
        <div
          className="search-result-item__gebouwpaspoort__image-wrapper"
          onClick={() => history.push(`/gebouwpaspoort/${nummeraanduidingId}`)}
        >
          <ObjectLabel
            isSmall
            className={`search-result-item__gebouwpaspoort__image-label ${
              item.mediaGuid
                ? 'search-result-item__gebouwpaspoort__image-label--with-image'
                : ''
            }`}
          >
            {item ? (
              <ResponsiveImage
                imageId={address}
                host={host}
                isLoading={isLoading}
              />
            ) : (
              <div className="search-result-item__gebouwpaspoort__image-placeholder">
                <Icon className="search-result-item__gebouwpaspoort__icon">
                  <IconBusinessBuilding />
                </Icon>
              </div>
            )}
          </ObjectLabel>
        </div>

        <div className="search-result-item__gebouwpaspoort__address">
          <h3 className="search-result-item__gebouwpaspoort__title">
            <Link
              className="loading-state"
              to={`/gebouwpaspoort/${nummeraanduidingId}`}
            >
              {address !== '---' ? address : null}
            </Link>
          </h3>
          <span className="body-small">
            {verblijfsobjectOverview?.gebruiksdoelen?.join(' | ')}
          </span>
        </div>

        <SearchResultItemDetails
          className="search-result-item__gebouwpaspoort__details"
          isLoading={isLoading}
          data={pand}
          verblijfsobject={verblijfsobjectOverview}
          isGebouwpaspoort
        />
      </div>
    </>
  )
}
