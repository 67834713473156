import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit'
import { loadObjectByObjectGuid } from './objects'
import hashString from '../utils/hash-string'
import { defaultSearchOption } from '../utils/constants'
import slugify from '@brainbay/components/utils/slugify'
import log from '@brainbay/components/utils/log'
import formatAddressBusiness from '../utils/format-address-business'

function saveState(reducer) {
  return function execute(...args) {
    const result = reducer(...args)

    if (result.title) {
      let comparisons
      const titleSlug = slugify(result.title)
      try {
        const comparisonsJSON = localStorage.getItem('comparisons') || '{}'
        comparisons = JSON.parse(comparisonsJSON)
      } catch (error) {
        log.error(
          `Failed to save ${result.title}`,
          'Could not load comparisons from localStorage',
          error,
        )
        return result
      }
      try {
        comparisons[titleSlug]
          ? (result.updatedAt = Date.now())
          : (result.createdAt = Date.now())

        if (result.hash) {
          result.lastSavedHash = result.hash
        }

        comparisons[titleSlug] = result
        localStorage.setItem('comparisons', JSON.stringify(comparisons))
        log.info(`Saved ${result.title}`)
      } catch (error) {
        log.error(`Failed to save ${result.title}`, error)
      }
    }

    return result
  }
}

function withUpdateHash(reducer) {
  return function exec(...args) {
    const { createdAt, updatedAt, hash, lastSavedHash, ...result } = reducer(
      ...args,
    )
    const json = JSON.stringify(result)
    const newHash = hashString(json)

    return {
      ...result,
      ...(lastSavedHash ? { lastSavedHash } : {}),
      ...(newHash ? { hash: newHash } : {}),
      ...(createdAt ? { createdAt } : {}),
      ...(updatedAt ? { updatedAt } : {}),
    }
  }
}

const initialSearch = {
  query: '',
  autocompleteOption: undefined,
  filter: '',
  sortDirection: defaultSearchOption.sortDirection,
  sortBy: defaultSearchOption.sortBy,
  radius: '',
}

const initialCompareList = {
  displayComparison: false,
  comparePanel: 'hide',
  compareList: [],
}

const initialProject = {
  title: '',
  createdAt: undefined,
  updatedAt: undefined,
}

const initialReferenceOptions = {
  sortReferencesBy: 'All',
}

const initialState = {
  ...initialSearch,
  ...initialCompareList,
  ...initialProject,
  ...initialReferenceOptions,
}

const addObjectThunk = createAsyncThunk(
  'comparison/addObject',
  (guid, thunkAPI) => {
    thunkAPI.dispatch(loadObjectByObjectGuid(guid))
    return guid
  },
)

const searchValue = createSlice({
  name: 'searchValue',
  initialState,
  reducers: {
    save: saveState(state => state),
    setTitle: withUpdateHash((state, { payload }) => ({
      ...state,
      title: payload,
    })),
    setNewSearchValue: withUpdateHash((state, { payload }) => ({
      ...state,
      query: payload,
    })),
    setAutoCompleteOption: withUpdateHash((state, { payload }) => ({
      ...state,
      autocompleteOption: payload,
    })),
    setNewFilterValue: withUpdateHash((state, { payload }) => ({
      ...state,
      filter: payload,
    })),
    setSortBy: withUpdateHash((state, { payload }) => ({
      ...state,
      sortBy: payload,
    })),
    setSortDirection: withUpdateHash((state, { payload }) => ({
      ...state,
      sortDirection: payload,
    })),
    setRadius: withUpdateHash((state, { payload }) => ({
      ...state,
      radius: payload,
    })),
    setSortReferencesBy: withUpdateHash((state, { payload }) => ({
      ...state,
      sortReferencesBy: payload,
    })),
    clearSearch: withUpdateHash(state => ({ ...state, ...initialSearch })),
    clearComparison: withUpdateHash(_ => initialState),
    removeObject: withUpdateHash((state, { payload }) => ({
      ...state,
      compareList: state.compareList.filter(item => item !== payload),
    })),
    load: (_, { payload }) => payload,
    enableComparePanel: withUpdateHash(state => ({
      ...state,
      displayComparison: true,
    })),
    disableComparePanel: withUpdateHash(state => ({
      ...state,
      displayComparison: false,
    })),
    hideComparePanel: withUpdateHash(state => ({
      ...state,
      comparePanel: 'hide',
    })),
    expandComparePanel: withUpdateHash(state => ({
      ...state,
      comparePanel: 'expand',
    })),
    fullComparePanel: withUpdateHash(state => ({
      ...state,
      comparePanel: 'full',
    })),
  },
  extraReducers: {
    [addObjectThunk.fulfilled]: withUpdateHash((state, { payload }) => ({
      ...state,
      compareList: Array.from(new Set([...state.compareList, payload])),
    })),
  },
})

export const objectsToCompareSelector = createSelector(
  state => state.comparison.compareList,
  state => state.objects,
  (compareList, objects) =>
    compareList.map(guid => {
      const formattedAddress = formatAddressBusiness({
        postalCode: objects[guid]?.postcode,
        street: objects[guid]?.straatnaam,
        houseNumber: objects[guid]?.huisnummer,
        houseNumberSuffix: objects[guid]?.huisnummertoevoeging,
        municipality: objects[guid]?.gemeente,
        city: objects[guid]?.woonplaats,
      })
      return { ...objects[guid], formattedAddress }
    }),
)

export const addObject = addObjectThunk
export const {
  setTitle,
  setNewSearchValue,
  setNewFilterValue,
  setSortBy,
  setSortDirection,
  setRadius,
  setAutoCompleteOption,
  setSortReferencesBy,
  clearSearch,
  clearComparison,
  removeObject,
  save,
  load,
  enableComparePanel,
  disableComparePanel,
  hideComparePanel,
  expandComparePanel,
  fullComparePanel,
} = searchValue.actions

export default searchValue.reducer
