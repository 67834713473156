import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import log from '@brainbay/components/utils/log'
import { setAccessToken, setIdToken, setUser } from '../store/user'
import { useHistory } from 'react-router'
import 'url-search-params-polyfill'

export default function AuthCallback() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    try {
      var hashParams = new URLSearchParams(
        window.location.hash.replace('#', ''),
      )
      const accessToken = hashParams.get('access_token')
      const idToken = hashParams.get('id_token')

      if (!accessToken) throw new Error('No Access token')
      if (!idToken) throw new Error('No ID token')

      dispatch(setAccessToken(accessToken))
      dispatch(setIdToken(idToken))
      dispatch(setUser(idToken))

      const pathWeCameFrom =
        window.sessionStorage.getItem('redirectAfterAuth') || '/'

      setIsLoading(false)
      history.push(pathWeCameFrom)
      window.sessionStorage.removeItem('redirectAfterAuth')
    } catch (error) {
      log.error('Getting user failed', error)
      setIsLoading(false)
      setHasError(true)
    }
  }, [dispatch, history])

  return (
    <main className="layout--grid">
      <h2 className="h3 text-bold">Inloggen</h2>
      {isLoading && (
        <p className="body">
          We valideren uw inlog gegevens.
          <br />U wordt zometeen door gestuurd...
        </p>
      )}
      {hasError === true && (
        <div>
          <p className="body">Er gaat iets mis met uw inlog poging.</p>
          <a className="body" href="/">
            Probeer opnieuw in te loggen
          </a>
        </div>
      )}
    </main>
  )
}
