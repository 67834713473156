import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '@brainbay/components/components/modal'
import { setTitle, save as saveComparison } from '../../store/comparison'

import './save-button.css'

const now = new Date()
const day = `${now.getDate()}`?.padStart(2, '0') ?? now.getDate()
const month = `${now.getMonth() + 1}`?.padStart(2, '0') ?? now.getMonth() + 1
const year = now.getFullYear()

const today = `${day}-${month}-${year}`

export default function SaveButton({ className = '' }) {
  const dispatch = useDispatch()
  const query = useSelector(state => state.comparison.query)
  const storedTitle = useSelector(state => state.comparison.title)
  const isDirty = useSelector(
    state => state.comparison?.hash !== state.comparison?.lastSavedHash,
  )
  const [internalTitle, setInternalTitle] = useState('')
  const [showModal, setShowModal] = useState(false)

  const updateTitle = event => setInternalTitle(event.target.value)
  const closeModal = () => setShowModal(false)
  const openModal = () => {
    const titleQuery = query
      .split('')
      .map((char, index) => (index === 0 ? char.toUpperCase() : char))
      .join('')
    const newTitle = query ? `${titleQuery} (${today})` : today
    setInternalTitle(storedTitle || newTitle)
    setShowModal(true)
  }
  const cancel = () => {
    closeModal()
    if (!storedTitle) {
      setInternalTitle('')
    }
  }
  const save = async event => {
    // eslint-disable-next-line no-unused-expressions
    event?.preventDefault?.()
    if (internalTitle !== '') {
      await dispatch(setTitle(internalTitle))
      await dispatch(saveComparison())
    }
    closeModal()
  }

  return (
    <>
      <div className={`save-button ${className}`.trim()}>
        <button
          className={`button button--small button--secondary save-button__save save-button__save--${
            isDirty ? 'show' : 'hide'
          }`}
          onClick={() =>
            storedTitle ? dispatch(saveComparison()) : openModal()
          }
          tabIndex={isDirty ? '0' : '-1'}
        >
          {storedTitle && (
            <span className="save-button__title text-normal body-small">
              {storedTitle}
            </span>
          )}
          <span className="save-button__text">Opslaan</span>
        </button>
      </div>
      {showModal && (
        <Modal onClose={cancel} title="Vergelijking Opslaan">
          <form className="save-button__modal-form" onSubmit={save}>
            <label>Titel</label>
            <input type="text" onChange={updateTitle} value={internalTitle} />

            <div className="save-button__modal-buttons">
              <button
                type="reset"
                className="save-button__modal-button button"
                onClick={cancel}
              >
                Annuleren
              </button>
              <button
                type="submit"
                className="save-button__modal-button button button--primary"
              >
                Opslaan
              </button>
            </div>
          </form>
        </Modal>
      )}
    </>
  )
}
