import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Icon from '@brainbay/components/components/icon'
import { ReactComponent as IconClose } from '@brainbay/components/_assets/svg/icon-close.svg'
import { ReactComponent as IconScale } from '@brainbay/components/_assets/svg/icon-scale.svg'
import { trackEvent } from '@brainbay/components/utils/ga-events'
import { gaCategories } from '@brainbay/components/utils/constants'
import { SkiplinkTarget } from '../skiplinks'
import CompareTable from '../compare-table'
import CompareList from '../compare-list'
import CompareListItem from '../compare-list-item'
import {
  addObject,
  removeObject,
  objectsToCompareSelector,
  hideComparePanel,
  expandComparePanel,
  fullComparePanel,
} from '../../store/comparison'
import { showFluxModal } from '../../store/flux-connection'

import './comparison-panel.css'
import useFeatureFlags from '../../utils/use-feature-flags'
import { generateObjectsPDFDocument } from '../../utils/generate-pdf-document'
import { fluxParams } from '../../utils/flux-params'

const REFERENCES = 'references'
const COMPARISON = 'comparison'
const SAVED = 'saved'

export default function ComparisonPanel() {
  const [panel, setPanel] = useState(REFERENCES)
  const [isLoadingPdf, setIsLoadingPdf] = useState(false)
  const { isEnabled } = useFeatureFlags()
  const objectsToCompare = useSelector(objectsToCompareSelector)
  const compareList = useSelector(state => state.comparison.compareList)
  const state = useSelector(state => state.comparison.comparePanel)
  const displayComparison = useSelector(
    state => state.comparison.displayComparison,
  )
  const dispatch = useDispatch()
  const { isAllFluxParamsExist: isShowSendFluxButton } = fluxParams()

  const referencesSelected = panel === REFERENCES
  const comparisonSelected = panel === COMPARISON
  const savedSelected = panel === SAVED

  const showReferences = referencesSelected && objectsToCompare.length > 0
  const showComparison = comparisonSelected && objectsToCompare.length > 0

  function handleAddButton(guid) {
    if (compareList.includes(guid)) {
      dispatch(removeObject(guid))
    } else {
      dispatch(addObject(guid))
    }
  }

  function handleToggleButton() {
    if (state === 'expand') {
      dispatch(hideComparePanel())
    } else {
      dispatch(expandComparePanel())
    }
  }

  function handleCompareClick() {
    dispatch(fullComparePanel())
    setPanel(COMPARISON)
  }

  function handleSavedClick() {
    dispatch(fullComparePanel())
    setPanel(SAVED)
  }

  function handleCloseButton() {
    setPanel(REFERENCES)

    if (state === 'full') {
      dispatch(expandComparePanel())
    } else if (state === 'expand') {
      dispatch(hideComparePanel())
    }
  }

  function handleToFullToggleButton() {
    if (state === 'expand') {
      dispatch(fullComparePanel())
      setPanel(COMPARISON)
    } else if (state === 'full') {
      dispatch(expandComparePanel())
      setPanel(REFERENCES)
    }
  }

  async function handleDownloadPDF(objects) {
    trackEvent(
      'clicked_download_pdf_selected_references',
      gaCategories.EXPORT,
      'Download PDF selected references',
    )
    await generateObjectsPDFDocument(
      objects,
      `Geselecteerde-referenties-${new Date().toISOString()}.pdf`,
    )
  }

  // make sure that the 'expanded' view returns to the references panel.
  useEffect(() => {
    if (state === 'expand') {
      setPanel(REFERENCES)
    }
  }, [state])

  useEffect(() => {
    const loading =
      objectsToCompare.length > 0 &&
      objectsToCompare.some(({ loading }) => loading)
    if (objectsToCompare.length > 0 && !loading) {
      setIsLoadingPdf(false)
    } else {
      setIsLoadingPdf(true)
    }
  }, [objectsToCompare])

  return (
    <aside
      className={`comparison-panel print-excluded comparison-panel--${state}`}
    >
      {state === 'hide' && displayComparison && (
        <button
          onClick={handleToggleButton}
          className="button button--small comparison-panel__toggle print-excluded"
        >
          Vergelijking
        </button>
      )}
      <section className="comparison-panel__body">
        <SkiplinkTarget
          id="search-results__referenties"
          skiplinkLabel="Snel naar referenties"
        >
          {state !== 'hide' && (
            <button
              className="comparison-panel__to-full"
              onClick={handleToFullToggleButton}
            >
              <Icon size="tiny">
                <IconScale />
              </Icon>
              {state === 'expand' && (
                <span className="sr-only">Toon uitgebreide vergelijking</span>
              )}
              {state === 'full' && (
                <span className="sr-only">
                  Toon compacte lijst met vergelijkings objecten
                </span>
              )}
            </button>
          )}

          {state === 'expand' && (
            <h2 className="comparison-panel__expand-title body">
              <span className="text-bold">Geselecteerde referenties&nbsp;</span>
              <span>({objectsToCompare.length})</span>
            </h2>
          )}

          {state === 'expand' && (
            <button
              className="comparison-panel__close"
              onClick={handleCloseButton}
            >
              <Icon size="tiny">
                <IconClose />
              </Icon>
              <span className="sr-only">Vergelijking sluiten</span>
            </button>
          )}
          <div className="comparison-panel__content">
            {state === 'full' && (
              <fieldset data-button-group>
                <button
                  onClick={handleCompareClick}
                  {...(comparisonSelected ? { 'aria-selected': 'true' } : {})}
                >
                  Vergelijking
                </button>
                {isEnabled('myComparisons') && (
                  <button
                    onClick={handleSavedClick}
                    {...(savedSelected ? { 'aria-selected': 'true' } : {})}
                  >
                    Mijn vergelijkingen
                  </button>
                )}
              </fieldset>
            )}
            {objectsToCompare.length === 0 && (
              <div className="comparison-panel__empty-list-message">
                <p className="body">
                  U heeft nog geen objecten geselecteerd om te vergelijken.
                </p>
                <p className="body">
                  Gebruik de blauwe knop bij een object om deze toe te voegen
                  aan de vergelijking
                </p>
              </div>
            )}
            <div className="comparison-panel__buttons">
              <button
                className="button button--x-small button--secondary comparison-panel__print"
                onClick={() => handleDownloadPDF(objectsToCompare)}
                disabled={isLoadingPdf || objectsToCompare.length <= 0}
              >
                Download PDF
              </button>
              {isShowSendFluxButton && (
                <button
                  className="button button--x-small button--primary comparison-panel__print"
                  onClick={() => dispatch(showFluxModal())}
                  disabled={isLoadingPdf || objectsToCompare.length <= 0}
                >
                  Verzend naar Flux
                </button>
              )}
            </div>
            {showReferences && (
              <CompareList>
                {objectsToCompare.map((object, index) => (
                  <CompareListItem
                    key={`${object.objectGuid}-${index}`}
                    isLoading={object.loading}
                    city={object.woonplaats}
                    houseNumber={object.huisnummer}
                    postalCode={object.postcode}
                    houseNumberSuffix={object.huisnummertoevoeging}
                    mediaGuid={object?.mediaGuid}
                    objectGuid={object.objectGuid}
                    status={object.status}
                    duration={object.looptijd}
                    street={object.straatnaam}
                    municipality={object.gemeente}
                    removeHandler={() => handleAddButton(object.objectGuid)}
                    hoofdfunctie={object.hoofdfunctie}
                  />
                ))}
              </CompareList>
            )}
            {showComparison && <CompareTable />}
          </div>
        </SkiplinkTarget>
      </section>
    </aside>
  )
}
