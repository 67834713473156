import { status } from '@brainbay/components/utils/constants'
import formatValue from '@brainbay/components/utils/format-value'

export default function useObjectSurface(data) {
  switch (data?.status) {
    case status.VERKOCHT:
    case status.VERHUURD:
    case status.GEVEILD:
    case status.VERLENGING:
    case status.VERKOCHT_BIJ_INSCHRIJVING:
      return {
        term: 'Transactieoppervlakte',
        definition: data.transactie.transactieVertrouwelijk
          ? 'vertrouwelijk'
          : formatValue(data.transactie.transactieOppervlakte, 'meter-squared'),
      }
    default:
      if (typeof data?.pandOppervlakte !== 'undefined') {
        return {
          term: 'Aangeboden oppervlakte',
          definition:
            data.pandOppervlakteStatus ??
            formatValue(data.pandOppervlakte, 'meter-squared'),
        }
      }
      return {
        term: '',
        definition: '',
      }
  }
}
