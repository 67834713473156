import React, { forwardRef, useEffect, useState } from 'react'
import Panel from '@brainbay/components/components/panel'
import formatAddressBusiness from '../../utils/format-address-business'
import sortByProperties from '../../utils/sort-by-properties'
import formatValue from '@brainbay/components/utils/format-value'
import EnergyLabel from '@brainbay/components/components/energy-label'
import SourceLabel from '@brainbay/components/components/source-label'

import './building-accommodation-objects.css'

const BuildingAccommodationObjects = forwardRef(
  function BuildingAccommodationObjects({ data }, ref) {
    const [building, setBuilding] = useState({})
    const [hasLoaded, setHasLoaded] = useState(false)

    useEffect(() => {
      if (data && !data.loading) {
        const { pand } = data || {}
        setBuilding(pand)
        setHasLoaded(true)
      }
    }, [data])

    const sortedAccommodationObjects = building?.verblijfsobjecten
      ? [...building.verblijfsobjecten].sort((a, b) =>
          sortByProperties(a, b, [
            'hoofdAdres.straatnaam',
            'hoofdAdres.huisnummer',
            'hoofdAdres.huisnummertoevoeging',
          ]),
        )
      : []
    const accommodationObjectsCount = building?.verblijfsobjecten?.length || 0

    return (
      <div ref={ref}>
        <Panel
          title={`Verblijfsobjecten ${
            hasLoaded ? `(${accommodationObjectsCount})` : ''
          }`}
          tag="section"
          fullBleed
          collapsable
          divider
          isOpenByDefault
        >
          <div className="building-accommodation-objects">
            {hasLoaded ? (
              <div className="building-accommodation-objects__content">
                <table className="building-accommodation-objects-table">
                  <caption className="building-accommodation-objects-table__caption sr-only">
                    Verblijfsobjecten
                  </caption>

                  <thead className="building-accommodation-objects-table__head">
                    <tr className="building-accommodation-objects-table__row">
                      <th
                        scope="col"
                        className="building-accommodation-objects-table__column-header"
                      >
                        Adres
                      </th>
                      <th
                        scope="col"
                        className="building-accommodation-objects-table__column-header"
                      >
                        Energie label
                      </th>
                      <th
                        scope="col"
                        className="building-accommodation-objects-table__column-header"
                      >
                        Oppervlakte
                      </th>
                      <th
                        scope="col"
                        className="building-accommodation-objects-table__column-header"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="building-accommodation-objects-table__column-header"
                      >
                        Gebruiksdoel(en)
                      </th>
                    </tr>
                  </thead>

                  <tbody className="building-accommodation-objects-table__body">
                    {sortedAccommodationObjects.map(accommodationObject => (
                      <tr
                        className="building-accommodation-objects-table__row"
                        key={accommodationObject.verblijfsobjectId}
                      >
                        <td className="building-accommodation-objects-table__cell">
                          <div
                            key={
                              accommodationObject.hoofdAdres.nummeraanduidingId
                            }
                            className="building-accommodation-objects-table__address"
                          >
                            {formatAddressBusiness({
                              street: accommodationObject.hoofdAdres.straatnaam,
                              houseNumber:
                                accommodationObject.hoofdAdres.huisnummer,
                              houseNumberSuffix:
                                accommodationObject.hoofdAdres
                                  .huisnummertoevoeging,
                              postalCode:
                                accommodationObject.hoofdAdres.postcode,
                              city: accommodationObject.hoofdAdres.woonplaats,
                              municipality:
                                accommodationObject.hoofdAdres.gemeente,
                            })}
                          </div>
                        </td>
                        <td className="building-accommodation-objects-table__cell">
                          <EnergyLabel
                            isLoading={!hasLoaded}
                            label={accommodationObject.energieklasse}
                            isSmall
                          />
                        </td>
                        <td className="building-accommodation-objects-table__cell">
                          {formatValue(
                            accommodationObject.oppervlakte,
                            'meter-squared',
                          )}
                        </td>
                        <td className="building-accommodation-objects-table__cell">
                          {accommodationObject.verblijfsobjectStatus}
                        </td>
                        <td className="building-accommodation-objects-table__cell-purposes">
                          {formatValue(
                            accommodationObject.gebruiksdoelen,
                            'comma-separated-list',
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>
                Er kunnen geen verblijfsobjecten opgehaald worden op dit moment.
              </p>
            )}
          </div>
          <div className="building-accommodation-objects__source-label">
            <SourceLabel source="BAG" prefixSource="Bron" />
          </div>
        </Panel>
      </div>
    )
  },
)
export default BuildingAccommodationObjects
