import React from 'react'
import './energy-label.css'

export const labelClass = label => {
  if (label.indexOf('+') > -1) {
    return label.replace(/\+/g, 'p')
  }

  if (label.indexOf('---') > -1) {
    return 'no-label'
  }

  return label
}

export default function EnergyLabel({
  isLoading,
  label = '---',
  isSmall,
  className,
}) {
  return (
    <p
      className={`energy-label energy-label--${labelClass(label)} ${className}${
        isSmall ? ' energy-label--small body-small' : ' body text-bold'
      }${isLoading ? ' energy-label--loading' : ''}`}
    >
      {!isLoading && (
        <span className="sr-only">
          Energie label: {label === '---' ? 'onbekend' : label}
        </span>
      )}
      <span className="energy-label__value" aria-hidden="true">
        {!isLoading ? label : '\u00A0'}
      </span>
      <span className="energy-label__track">&#8288;</span>
      <span className="energy-label__arrow"></span>
    </p>
  )
}
