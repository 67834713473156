let config = { type: 'image/jpeg' }

export default function toDataUrl(url, options) {
  config = Object.assign(config, options)
  return fetchUrl(url).then(blob => makeDataURL(blob, config))
}

export function fetchUrl(url) {
  return fetch(url).then(response => {
    return new Promise((resolve, reject) => {
      if (!response.ok) {
        reject(response)
      }
      resolve(response.blob())
    })
  })
}

export function makeDataURL(blob, config) {
  const newBlob = new Blob([blob], { type: config.type })
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(newBlob)
  })
}
