import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { post } from '../utils/api-data'

export const sentRefIdsToFlux = createAsyncThunk(
  'flux',
  async ({ ids, sessionContext }, thunkAPI) => {
    try {
      await post({
        path: `flux`,
        body: { ids, sessionContext },
      })
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)

const exportButton = createSlice({
  name: 'fluxConnection',
  initialState: {
    isFluxModalOpen: false,
    statusOfSentRefs: 'idle',
  },
  reducers: {
    showFluxModal: state => {
      state.isFluxModalOpen = true
    },
    removeFluxModal: state => {
      state.isFluxModalOpen = false
    },
    resetFluxConnectionState: state => {
      state.isFluxModalOpen = false
      state.statusOfSentRefs = 'idle'
    },
  },
  extraReducers: {
    [sentRefIdsToFlux.pending]: state => {
      state.statusOfSentRefs = 'loading'
    },
    [sentRefIdsToFlux.fulfilled]: state => {
      state.statusOfSentRefs = 'succeeded'
    },
    [sentRefIdsToFlux.rejected]: state => {
      state.statusOfSentRefs = 'failed'
    },
  },
})

export const { showFluxModal, removeFluxModal, resetFluxConnectionState } =
  exportButton.actions

export default exportButton.reducer
