import React, { useEffect, useState, useCallback } from 'react'
import formatValue from '../../utils/format-value'
import './graph.css'

const DEFAULTS = {
  color: '#0099bc',
  width: 400,
  height: 200,
  strokeWidth: 1.5,
  circleSize: 3,
  graphPadding: 8,
  transitionDuration: 1000,
}

export default function Graph({
  className = '',
  valuationData = [],
  graphOnly,
  innerRef,
  options = {},
  granularity = '1',
}) {
  const [marginHeight, setMarginHeight] = useState(25)
  const [marginWidth, setMarginWidth] = useState(52)

  const config = { ...DEFAULTS, ...options }

  useEffect(() => {
    if (!graphOnly) {
      const yMaxValue = Math.max(...valuationData.map(d => d.prijs))

      setMarginHeight(40)
      if (yMaxValue >= 1000000) {
        setMarginWidth(65)
      } else if (yMaxValue >= 100000) {
        setMarginWidth(60)
      }
    } else {
      setMarginWidth(10)
    }
  }, [valuationData, graphOnly])

  if (valuationData.length <= 1) {
    return <></>
  }

  return (
    <svg
      ref={innerRef}
      className={`graph__d3 ${className}`.trim()}
      viewBox={`-${marginWidth} 0 ${config.width + marginWidth * 1.5} ${
        config.height + marginHeight
      }`}
    >
      {!graphOnly && (
        <GraphAxis
          className={`${className}--axis`}
          valuationData={valuationData}
          granularity={granularity}
          options={config}
        />
      )}
      <GraphWithoutAxis
        className={`${className}--line`}
        valuationData={valuationData}
        showCircles={!graphOnly}
        granularity={granularity}
        options={config}
      />
    </svg>
  )
}

export function GraphWithoutAxis({
  className = '',
  valuationData = [],
  showCircles,
  options = {},
  granularity,
}) {
  const config = { ...DEFAULTS, ...options }

  const buildGraphWithoutAxis = useCallback(
    async data => {
      const d3 = await import(/* webpackChunkName: 'd3' */ 'd3')

      const graphData = data.map(item => ({
        ...item,
        periode: d3.timeParse('%Y-%m-%dT%H:%M:%S')(item.periode),
      }))

      const lineGroup = d3.select(
        className ? `.${className}` : '.graph__d3--line',
      )

      const easeTransition = d3
        .transition()
        .duration(config.transitionDuration)
        .ease(d3.easeQuadInOut)

      lineGroup.selectAll('g').remove()

      const { yMinValue, yMaxValue } = getYAxisMinMaxValues(d3, graphData)

      const line = d3
        .line()
        .x(d => xScale(d.periode))
        .y(d => yScale(d.prijs))
        .curve(d3.curveMonotoneX)

      const xScale = d3
        .scaleTime()
        .range([config.graphPadding, config.width - config.graphPadding])
        .domain(d3.extent(graphData, d => d.periode))

      const yScale = d3
        .scaleLinear()
        .range([config.height - config.graphPadding, config.graphPadding])
        .domain([Math.ceil(yMinValue), Math.ceil(yMaxValue)])

      // Render the line of the graph with corresponding data
      const linePath = lineGroup
        .append('g')
        .attr('class', 'graph__line')
        .attr('fill', 'none')
        .attr('stroke-width', config.strokeWidth)
        .attr('stroke', config.color)
        .append('path')
        .datum(graphData)
        .attr('class', 'graph__line-path')
        .attr('d', line)

      linePath.transition(easeTransition).attrTween('stroke-dasharray', () => {
        const length = linePath.node().getTotalLength()
        return d3.interpolate(`0,${length}`, `${length},${length}`)
      })

      if (showCircles) {
        // Render for each data point a circle corresponding the rendered line
        const circleGroups = lineGroup
          .append('g')
          .attr('class', 'graph__circles')
          .selectAll('.graph__circles')
          .data(graphData)
          .enter()
          .append('g')
          .attr('class', 'graph__circle-group')

        const allCircles = circleGroups
          .append('circle')
          .attr('class', 'graph__circles-circle')
          .attr('opacity', 0)
          .attr('fill', config.color)
          .attr('r', config.circleSize)
          .attr('cx', d => xScale(d.periode))
          .attr('cy', d => yScale(d.prijs))

        // On mouseover we show a tooltip
        let hasClicked = false
        allCircles
          .on('mouseover', (e, d) => {
            if (hasClicked) {
              hasClicked = false
              lineGroup.selectAll('.graph__tooltip').remove()
              allCircles.attr('r', config.circleSize)
            }

            // Render tooltip
            const tooltipGroup = lineGroup
              .append('g')
              .attr('class', 'graph__tooltip')
              .attr(
                'transform',
                `translate(${xScale(d.periode)}, ${yScale(d.prijs) + 38})`,
              )

            const width = 65
            const height = 35
            tooltipGroup
              .append('rect')
              .attr('x', width / -2)
              .attr('y', height / -1.25)
              .attr('width', `${width}px`)
              .attr('height', `${height}px`)
              .attr('fill', '#fff')
              .attr('filter', 'drop-shadow(0 0 1px rgba(0, 0, 0, .2))')

            tooltipGroup
              .append('text')
              .attr('class', 'body-x-small text-bold')
              .attr('text-anchor', 'middle')
              .attr('y', 0)
              .text(formatValue(d.prijs, { format: 'currency' }))

            tooltipGroup
              .append('text')
              .attr('class', 'body-x-small')
              .attr('text-anchor', 'middle')
              .attr('y', -14)
              .text(
                granularity === '1'
                  ? formatValue(
                      d3.timeFormat('%Y-%m-%d')(d.periode),
                      'quarterly-date',
                    )
                  : formatValue(
                      d3.timeFormat('%Y-%m-%d')(d.periode),
                      'short-date',
                    ),
              )
          })
          .on('mouseout', () => {
            if (hasClicked) return
            lineGroup.selectAll('.graph__tooltip').remove()
            allCircles.attr('r', config.circleSize)
          })
          .on('click', () => {
            if (hasClicked) {
              hasClicked = false
              lineGroup.selectAll('.graph__tooltip').remove()
              allCircles.attr('r', config.circleSize)
            } else {
              hasClicked = true
            }
          })
      }
    },
    // We don't want the side effects of `config` triggering this `useCallback`.
    // We only want to trigger this `useEffect` one time, when mounting this page.
    // eslint-disable-next-line
    [],
  )

  useEffect(() => {
    if (valuationData.length > 0) {
      buildGraphWithoutAxis(valuationData)
    }
  }, [valuationData, buildGraphWithoutAxis])

  return <g className={`graph__d3--line ${className}`.trim()} />
}

export function GraphAxis({
  className = '',
  valuationData = [],
  options = {},
  granularity,
}) {
  const config = { ...DEFAULTS, ...options }

  const buildAxis = useCallback(
    async data => {
      const d3 = await import(/* webpackChunkName: 'd3' */ 'd3')
      const axisGroup = d3.select(
        className ? `.${className}` : '.graph__d3--axis',
      )

      axisGroup.selectAll('g').remove()

      const numberOfXTicks = Math.min(15, data.length)
      const numberOfYTicks = 8

      const { yMinValue, yMaxValue } = getYAxisMinMaxValues(d3, data)

      const dateProcessedData = data.map(d => {
        return {
          prijs: d.prijs,
          periode: d3.timeParse('%Y-%m-%dT%H:%M:%S')(d.periode),
        }
      })

      const xScale = d3
        .scaleTime()
        .range([config.graphPadding, config.width - config.graphPadding])
        .domain(d3.extent(dateProcessedData, d => d.periode))

      const yScale = d3
        .scaleLinear()
        .range([config.height - config.graphPadding, config.graphPadding])
        .domain([Math.ceil(yMinValue), Math.ceil(yMaxValue)])

      // Render horizontal lines as grid on the x-axis
      axisGroup
        .append('g')
        .attr('class', 'graph__grid')
        .attr('stroke-dasharray', 2)
        .attr('opacity', 0.25)
        .call(
          d3
            .axisLeft(yScale)
            .ticks(numberOfYTicks)
            .tickSize(-config.width)
            .tickFormat(''),
        )

      // Render the x-axis
      const xAxis = axisGroup
        .append('g')
        .datum(data)
        .attr('class', 'graph__x-axis')
        .attr('transform', `translate(0, ${config.height})`)

      xAxis
        .append('line')
        .attr('class', 'graph__y-axis--line')
        .attr('x1', 0)
        .attr('x2', config.width)
        .attr('y1', 0)
        .attr('y2', 0)
        .attr('stroke-width', 1)
        .attr('stroke', 'currentColor')

      xAxis.call(
        d3
          .axisBottom(xScale)
          .ticks(numberOfXTicks)
          .tickFormat(d => {
            const date = d3.timeFormat('%Y-%m-%d')(d)
            return granularity === '1'
              ? formatValue(date, 'quarterly-date')
              : formatValue(date, 'short-date')
          }),
      )

      // Render the y-axis with the highest and lowest currencies
      const yAxis = axisGroup.append('g').attr('class', 'graph__y-axis')
      yAxis
        .append('line')
        .attr('class', 'graph__y-axis--line')
        .attr('x1', 0)
        .attr('x2', 0)
        .attr('y1', 0)
        .attr('y2', config.height)
        .attr('stroke-width', 1)
        .attr('stroke', 'currentColor')

      yAxis.call(
        d3
          .axisLeft(yScale)
          .ticks(numberOfYTicks)
          .tickFormat(d => formatValue(d, { format: 'currency' })),
      )

      // Make all text in the graph `body-x-small`
      axisGroup.selectAll('text').attr('class', 'body-x-small')
      axisGroup
        .selectAll('g.graph__x-axis text')
        .attr('transform', 'rotate(-45) translate(-27,  -5)')
      axisGroup.select('g.graph__grid .domain').remove()
      axisGroup.select('g.graph__y-axis .domain').remove()
      axisGroup.select('g.graph__x-axis .domain').remove()
    },
    // We don't want the side effects of `config` triggering this `useCallback`.
    // We only want to trigger this `useEffect` one time, when mounting this page.
    // eslint-disable-next-line
    [],
  )

  useEffect(() => {
    if (valuationData.length > 0) {
      buildAxis(valuationData)
    }
  }, [valuationData, buildAxis])

  return <g className={`graph__d3--axis ${className}`.trim()} />
}

function getYAxisMinMaxValues(d3, data) {
  let yMinValue = d3.min(data, d => d.prijs)
  let yMaxValue = d3.max(data, d => d.prijs)

  const yValueRatio = yMinValue / yMaxValue

  if (yValueRatio > 0.8) {
    yMaxValue = yMaxValue + yMaxValue * (1 - yValueRatio)
    yMinValue = yMinValue - yMinValue * (1 - yValueRatio)
  }

  return { yMinValue, yMaxValue }
}
