import React from 'react'
import DefaultLayout from '../layouts/default'
import { StyleSheet } from '@react-pdf/renderer'
import { Box, Typography } from '../components'
import formatValue from '@brainbay/components/utils/format-value'
import formatAddressBusiness from '../../format-address-business'
import getPriceType from '../../../utils/use-object-history-prices'

export default function PandHistoryPage({
  businessRegistratieHistory,
  userName,
  date,
  debug,
  isGebouwpaspoort,
}) {
  const styles = StyleSheet.create({
    tableContainer: {
      display: 'flex',
      width: '100%',
    },
    tableHeader: {
      fontWeight: 'bold',
      borderBottomWidth: 1,
      borderColor: '#0099bc',
      flex: 1,
      padding: 6,
      textAlign: 'left',
      fontSize: 10,
    },
    tableRow: {
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottom: '1px solid #dddddd',
    },

    tableCell: {
      flex: 1,
      padding: 6,
      textAlign: 'left',
      fontSize: 9,
      wordWrap: 'break-word',
    },
  })

  const sortedDataOnDate =
    businessRegistratieHistory?.length > 0
      ? businessRegistratieHistory
          .slice()
          .sort((a, b) => new Date(b.datum) - new Date(a.datum))
      : []

  function breakLongPriceRange(priceRange) {
    if (priceRange !== undefined && priceRange !== null) {
      const parts = priceRange.split('-')
      if (parts.length === 2) {
        return parts.join('-\n')
      }
      return priceRange
    }
  }

  const formattedBronArray = sortedDataOnDate?.map(objectData =>
    /^(bak\s*property\s*research(\s*\(.*\))?)/i.test(objectData?.bron)
      ? 'Bak'
      : objectData?.bron,
  )
  return (
    <DefaultLayout
      pageName="Historie in Brainbay Business database"
      userName={userName}
      date={date}
      debug={debug}
      isGebouwpaspoort={isGebouwpaspoort}
    >
      <Box style={styles.tableContainer}>
        {businessRegistratieHistory?.length > 0 && (
          <Box style={styles.tableRow}>
            <Typography style={styles.tableHeader} debug={debug}>
              Status
            </Typography>

            <Typography
              style={{ ...styles.tableHeader, flex: 1.25 }}
              debug={debug}
            >
              Type
            </Typography>

            <Typography
              style={{ ...styles.tableHeader, flex: 2.5 }}
              debug={debug}
            >
              Adres
            </Typography>

            <Typography style={styles.tableHeader} debug={debug}>
              Datum
            </Typography>

            <Typography
              style={{ ...styles.tableHeader, flex: 1.25 }}
              debug={debug}
            >
              Oppervlakte
            </Typography>

            <Typography
              style={{ ...styles.tableHeader, flex: 1.5 }}
              debug={debug}
            >
              Prijs
            </Typography>

            <Typography style={styles.tableHeader} debug={debug}>
              Bron
            </Typography>
          </Box>
        )}
        {sortedDataOnDate?.map((objectData, rowIndex) => (
          <Box style={styles.tableRow} key={rowIndex} wrap={false}>
            <Typography style={styles.tableCell} debug={debug}>
              {objectData?.status}
            </Typography>

            <Typography
              style={{ ...styles.tableCell, flex: 1.25 }}
              debug={debug}
            >
              {objectData?.hoofdfunctie}
            </Typography>

            <Typography
              style={{ ...styles.tableCell, flex: 2.5 }}
              debug={debug}
            >
              {formatAddressBusiness({
                street: objectData?.straatnaam,
                houseNumber: objectData?.huisnummer,
                houseNumberSuffix: objectData?.huisnummertoevoeging,
              })}
            </Typography>

            <Typography style={styles.tableCell} debug={debug}>
              {formatValue(objectData?.datum, {
                format: 'date',
              })}
            </Typography>

            <Typography
              style={{ ...styles.tableCell, flex: 1.25 }}
              debug={debug}
            >
              {formatValue(objectData?.oppervlakte, {
                format: 'meter-squared',
              })}
            </Typography>

            <Typography
              style={{ ...styles.tableCell, flex: 1.5 }}
              debug={debug}
            >
              {breakLongPriceRange(getPriceType(objectData))}
            </Typography>

            <Typography style={styles.tableCell} debug={debug}>
              {formattedBronArray[rowIndex]}
            </Typography>
          </Box>
        ))}
      </Box>
    </DefaultLayout>
  )
}
