import React from 'react'
import DefaultLayout from '../layouts/default'
import { Box, Typography, ImageBox } from '../components'
import { arrayChunk, getImageUrl, getObjectAddress } from '../helpers'

export default function MediaPage({
  item,
  userName,
  date,
  hasTransaction,
  debug,
}) {
  const address = getObjectAddress(item)
  const streetViewUrl = getImageUrl(item)
  const mediaGuidsCount = item?.mediaGuids?.length

  return (
    <DefaultLayout
      pageName="Media"
      userName={userName}
      date={date}
      debug={debug}
      hasTransaction={hasTransaction}
    >
      <Typography
        style={{
          fontSize: 18,
          color: '#000',
          fontWeight: 700,
          textAlign: 'center',
        }}
        debug={debug}
      >
        {address}
      </Typography>

      <Typography
        style={{ fontSize: 11, color: '#000', marginTop: 10 }}
        debug={debug}
      >
        {mediaGuidsCount === 1 ? `Foto` : `Foto's`} {mediaGuidsCount}
      </Typography>

      {item?.mediaGuids ? (
        arrayChunk(item.mediaGuids, 3).map((row, index) => {
          return (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                height: 145,
                marginTop: 10,
              }}
              key={index}
              debug={debug}
            >
              {row.map((mediaGuid, index) => {
                const imageDataUrl = getImageUrl({ mediaGuid })

                return (
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '31%',
                      marginRight: 10,
                    }}
                    key={index}
                    debug={debug}
                  >
                    <ImageBox
                      src={imageDataUrl}
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                      allowDangerousPaths
                    />
                  </Box>
                )
              })}
            </Box>
          )
        })
      ) : (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '31%',
            marginTop: 10,
          }}
          debug={debug}
        >
          <ImageBox
            src={streetViewUrl}
            style={{
              width: '100%',
              height: 145,
            }}
            allowDangerousPaths
          />
        </Box>
      )}
    </DefaultLayout>
  )
}
