import React, { useEffect, useState } from 'react'
import Panel from '@brainbay/components/components/panel'
import useResizeObserver from '@brainbay/components/utils/use-resize-observer'

import './object-description.css'

const buttonTexts = {
  expanded: ['Toon minder', 'Verberg deel van de aanbiedingstekst'],
  collapsed: ['Toon meer', 'Toon hele aanbiedingstekst'],
}

const srIntroText = {
  expanded: (
    <p className="sr-only">
      De aanbiedingstekst is deels visueel verborgen. Gebruik de knop:
      <i>Toon hele aanbiedingstekst</i>, om een deel van de tekst te verbergen.
    </p>
  ),
  collapsed: (
    <p className="sr-only">
      De aanbiedingstekst is volledig beschikbaar maar deels visueel verborgen.
      Gebruik de knop: <i>Verberg deel van de aanbiedingstekst</i>, om de gehele
      tekst te tonen.
    </p>
  ),
}

const loadingLines = [null, null, null, null]

export default function ObjectDescription({ data } = {}) {
  const { ref: textRef, height: textHeight } = useResizeObserver()
  const [hasLoaded, setHasLoaded] = useState(false)
  const [showFooter, setShowFooter] = useState(true)
  const [collapsedHeight, setCollapsedHeight] = useState(200)
  const [expanded, setExpanded] = useState(undefined)
  const [buttonText, setButtonText] = useState(buttonTexts.collapsed)
  const [lines, setLines] = useState([])
  const rawAanbiedingsTekst = data?.aanbiedingstekst || ''

  useEffect(() => {
    if (hasLoaded) {
      const newLines = rawAanbiedingsTekst.split('\n').filter(x => x)
      if (newLines.length === 0) {
        setShowFooter(false)
        setCollapsedHeight(20)
        newLines.push('Er is geen aanbiedingstekst voor dit object')
      }
      setLines(newLines)
    }
  }, [hasLoaded, rawAanbiedingsTekst])

  useEffect(() => {
    if (data && !data.loading) {
      setTimeout(() => {
        setHasLoaded(true)
      }, 10)
    }
  }, [data])

  useEffect(() => {
    if (
      // We want to run this code ONCE. So:
      hasLoaded && // It should be done loading
      textHeight && // The text should be rendered
      expanded === undefined // We did not do anthing with expanded yet
    ) {
      if (textHeight <= collapsedHeight) {
        setExpanded(true)
        setCollapsedHeight(textHeight)
        setShowFooter(false)
      } else {
        setExpanded(false)
      }
    }
  }, [collapsedHeight, expanded, hasLoaded, textHeight])

  function toggle() {
    const isExpanded = !expanded
    setExpanded(isExpanded)
    setButtonText(isExpanded ? buttonTexts.expanded : buttonTexts.collapsed)
  }

  return (
    <Panel
      title="Aanbiedingstekst"
      tag="article"
      className={`object-description object-description--${
        expanded ? 'expanded' : 'collapsed'
      }`}
    >
      <div
        style={{ '--collapsed-height': `${collapsedHeight}px` }}
        className={`object-description__text-wrapper`}
      >
        {hasLoaded ? (
          <div ref={textRef} className={`object-description__text`}>
            {expanded ? srIntroText.expanded : srIntroText.collapsed}
            {lines.map((line, index) => (
              <p key={index} className="loading-state">
                {line}
              </p>
            ))}
          </div>
        ) : (
          <div className={`object-description__text`}>
            {loadingLines.map((line, index) => (
              <p key={index} className="loading-state">
                {line}
              </p>
            ))}
          </div>
        )}
      </div>

      {showFooter && (
        <footer
          className={`object-description__footer ${
            data?.loading ? 'object-description__footer--loading' : ''
          }`}
        >
          <button
            aria-expanded={expanded ? 'true' : 'false'}
            className="object-description__toggle button button--x-small print-excluded"
            onClick={toggle}
            disabled={data?.loading}
          >
            <span aria-hidden="true">{buttonText[0]}</span>
            <span className="sr-only">{buttonText[1]}</span>
          </button>
        </footer>
      )}
    </Panel>
  )
}
