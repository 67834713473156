import toDataUrl from '@brainbay/components/utils/to-data-url'
import formatAddressBusiness from '../../format-address-business'
import formatValue from '@brainbay/components/utils/format-value'
import { apiHost } from '@brainbay/components/utils/environment-vars'

import useObjectDate from '../../use-object-date'
import useObjectSurface from '../../use-object-surface'
import useObjectPrices from '../../use-object-prices'

/**
 * Return correct PDF backgroundColor and Color based on status
 * @param status
 * @returns {{backgroundColor: string, color: string}}
 */
export const statusColor = status => {
  switch (status) {
    case 'te huur':
    case 'te koop':
    case 'te koop / huur':
    case 'verkoop bij inschrijving':
    case 'onder optie':
    case 'onder bod':
    case 'veiling':
    case 'beschikbaar':
      return {
        backgroundColor: '#ff4f4f',
        color: '#fff',
      }
    case 'verhuurd':
    case 'verkocht':
    case 'geveild':
      return {
        backgroundColor: '#0099bc',
        color: '#fff',
      }
    case 'verhuurd o.v.':
    case 'verkocht o.v.':
    case 'verkocht bij inschrijving':
      return {
        backgroundColor: '#00c085',
        color: '#fff',
      }
    case 'ingetrokken':
      return {
        backgroundColor: '#000',
        color: '#fff',
      }
    case 'bag':
    default:
      return {
        backgroundColor: '#b4b4b4',
        color: '#fff',
      }
  }
}

/**
 * Return base64 data url based on url
 * @param url
 * @returns {*}
 */
export const getDataUrl = url => {
  return toDataUrl(url)
    .then(dataUrl => {
      return dataUrl
    })
    .catch(error => {
      if (process.env.NODE_ENV !== 'production') {
        console.error(error)
      }

      return null
    })
}

/**
 * Return address based on item
 * @param item
 * @returns {*}
 */
export const getObjectAddress = (item, isGebouwpaspoort = false) => {
  const { geselecteerdeAdres } = item || {}

  if (isGebouwpaspoort) {
    return formatAddressBusiness({
      street: geselecteerdeAdres?.straatnaam,
      houseNumber: geselecteerdeAdres?.huisnummer,
      houseNumberSuffix: geselecteerdeAdres?.huisnummertoevoeging,
      postalCode: geselecteerdeAdres?.postcode,
      city: geselecteerdeAdres?.woonplaats,
      municipality: geselecteerdeAdres?.gemeente,
    })
  } else {
    return formatAddressBusiness({
      street: item?.straatnaam,
      houseNumber: item?.huisnummer,
      houseNumberSuffix: item?.huisnummertoevoeging,
      city: item?.woonplaats,
      postalCode: item?.postcode,
      municipality: item?.gemeente,
    })
  }
}

/**
 * Return slots based on slot order from the item
 * @param item
 * @returns {*[]}
 */
export const useSlotOrder = (item, isKadasterPortfolioTransaction = false) => {
  const [primaryPrice, secondaryPrice] = useObjectPrices(item)
  const objectDate = useObjectDate(item)
  const surface = useObjectSurface(item)

  const slots = {
    prijs: primaryPrice,
    prijsAlt: secondaryPrice,
    oppervlakte: surface,
    perceelOppervlakte: {
      term: 'Perceeloppervlakte',
      definition: formatValue(item.perceelOppervlakte, 'meter-squared'),
    },
    aanmelddatum: objectDate,
    bouwjaar: {
      term: isKadasterPortfolioTransaction ? 'Bouwjaar adres' : 'Bouwjaar',
      definition: item.bouwjaar || '---',
    },
    energielabel: {
      term: isKadasterPortfolioTransaction
        ? 'Energielabel adres'
        : 'Energielabel',
      definition: item.energielabel || '---',
    },
  }

  const slotOrder = [
    'prijs',
    'prijsAlt',
    'oppervlakte',
    'perceelOppervlakte',
    'aanmelddatum',
    'bouwjaar',
    'energielabel',
  ]

  return slotOrder.map(name => slots[name]).filter(Boolean)
}
export const usePandDetailsSlotOrder = item => {
  const { pand, geselecteerdeAdres } = item || {}
  let verblijfsobject = pand?.verblijfsobjecten.find(element => {
    return (
      element?.hoofdAdres.nummeraanduidingId ===
        geselecteerdeAdres.nummeraanduidingId ||
      element?.nevenAdressen.find(
        address =>
          address.nummeraanduidingId === geselecteerdeAdres.nummeraanduidingId,
      )
    )
  })

  const slots = {
    oppervlakteVerblijfsobject: {
      term: 'Oppervlakte verblijfsobject',
      definition: formatValue(verblijfsobject?.oppervlakte, 'meter-squared'),
    },
    oppervlaktePand: {
      term: 'Oppervlakte pand',
      definition: formatValue(pand?.oppervlakte, 'meter-squared'),
    },
    oppervlaktePerceel: {
      term: 'Oppervlakte perceel',
      definition: formatValue(pand?.perceeloppervlakte, 'meter-squared'),
    },
    bouwjaar: {
      term: 'Bouwjaar',
      definition: pand?.bouwjaar || '---',
    },
    energielabel: {
      term: 'Energielabel',
      definition: verblijfsobject?.energieklasse || '---',
    },
    pandStatus: {
      term: 'Pand status',
      definition: pand?.pandStatus || '---',
    },
  }
  const slotOrder = [
    'oppervlakteVerblijfsobject',
    'oppervlaktePand',
    'oppervlaktePerceel',
    'bouwjaar',
    'energielabel',
    'pandStatus',
  ]

  return slotOrder.map(name => slots[name]).filter(Boolean)
}
export const getImageUrl = (item, gebouwpaspoortImage = false) => {
  const address = getObjectAddress(item, gebouwpaspoortImage)
  let dataUrl

  if (item.mediaGuid) {
    dataUrl = getDataUrl(`${apiHost()}/media/500x/${item.mediaGuid}`)
  } else if (address) {
    dataUrl = getDataUrl(
      `${apiHost()}/streetview/500x/${encodeURIComponent(address)}`,
    )
  }

  return dataUrl
}

/**
 * Slice an array for creating rows in a mapping
 * @param arr
 * @param n
 * @returns {*[]}
 */
export const arrayChunk = (arr, n) => {
  const array = arr.slice()
  const chunks = []
  while (array.length) chunks.push(array.splice(0, n))
  return chunks
}

/**
 * Css to camelCase
 * @param str Css
 * @returns camelCase
 */
export const cssToCamelCase = str => {
  const splitted = str.split('-')
  if (splitted.length === 1) return splitted[0]

  return (
    splitted[0] +
    splitted
      .slice(1)
      .map(word => word[0].toUpperCase() + word.slice(1))
      .join('')
  )
}

/**
 * Attribute to number
 * @param item Item
 * @param value Value
 * @returns Number
 */
export const attributeToNumber = (item, value) => {
  if (value.includes('px')) return value

  if (value.includes('rem')) throw new Error('rem size not implemented')

  if (value.includes('em')) {
    const fontSize = 10

    const num = +value.replace('em', '')
    const px = Math.round(num * fontSize)

    return px
  }

  return Number(value)
}
