import React from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import { ReactComponent as IconProjectionChart } from '@brainbay/components/_assets/svg/icon-projection-chart.svg'
import Panel from '@brainbay/components/components/panel'
import MarketValueItem from '@brainbay/components/components/market-value-item'
import formatValue from '@brainbay/components/utils/format-value'
import Modal from '@brainbay/components/components/modal'
import Graph from '@brainbay/components/components/graph'

import './object-price-index.css'

export default function ObjectPriceIndex({
  data,
  onCloseModal,
  baseUrl,
  priceIndex = {},
}) {
  const { indexLevel, prijsOntwikkelingsGrafiek, prijsOntwikkeling } =
    priceIndex
  const ClosableInfo = props => <Modal onClose={onCloseModal} {...props} />

  const description =
    data?.transactie?.transactieDatum &&
    `Op basis van prijsontwikkeling (${
      indexLevel?.toLowerCase() ?? 'onbekend'
    }) sinds laatste keer ${
      data?.transactie?.koopprijs?.prijs ? 'verkocht' : 'verhuurd'
    } (${formatValue(data?.transactie?.transactieDatum, 'quarterly-date')})`

  const lastPriceIndexItem =
    prijsOntwikkelingsGrafiek &&
    prijsOntwikkelingsGrafiek[prijsOntwikkelingsGrafiek.length - 1]

  const priceLevel = lastPriceIndexItem
    ? `(${formatValue(lastPriceIndexItem.periode, 'quarterly-date')})`
    : ''

  // last element of prijsontwikkeling graph data is being rounded to be identical with the price index value that is shown on the tool which is also rounded with the same formatting function
  const roundedPrijsOntwikkelingGraphData = prijsOntwikkelingsGrafiek?.map(
    (data, index) => ({
      ...data,
      prijs:
        prijsOntwikkelingsGrafiek.length - 1 === index
          ? formatValue(data.prijs, { dynamicRound: true })
          : data.prijs,
    }),
  )

  const waardeOrHuurwaarde = data?.transactie?.koopprijs?.prijs
    ? 'waarde'
    : 'huurwaarde'

  return (
    <Panel
      title={`Geïndexeerde ${
        data?.transactie?.koopprijs?.prijs ? 'koopsom' : 'huurprijs'
      } ${priceLevel}`}
      tag="section"
      moreInfoUrl={`${baseUrl}/info/price-index/price-development-info`}
    >
      {priceIndex && (
        <>
          <Switch>
            <Route path={`${baseUrl}/info/price-index/price-development`}>
              <ClosableInfo title="Prijsontwikkeling">
                <p>
                  Deze berekenmethodiek neemt de transactieprijs van dit object
                  als basis en indexeert deze prijs naar de huidige waarde,
                  gebruikmakend van de prijsontwikkeling van het specifieke
                  marktsegment waartoe dit object behoort.
                </p>
                <Graph
                  className="valuation-modal__graph"
                  valuationData={roundedPrijsOntwikkelingGraphData}
                />
                <p className="object-valuation__source text-color--dim-text">
                  Bron: Prijsontwikkeling brainbay
                </p>
              </ClosableInfo>
            </Route>

            <Route path={`${baseUrl}/info/price-index/price-development-info`}>
              <ClosableInfo title="Prijsontwikkeling informatie">
                <p>
                  Het inschatten van de actuele {waardeOrHuurwaarde} van het
                  object gebeurt door middel van het doorrekenen van de
                  historische transactieprijs naar de huidige{' '}
                  {waardeOrHuurwaarde}. Hiervoor wordt de gemiddelde
                  prijsontwikkeling binnen de betreffende vastgoedcategorie
                  (winkelruimte, bedrijfsruimte of kantoorruimte) gehanteerd.
                  Afhankelijk van het aantal beschikbare transacties wordt het
                  laagst betrouwbare schaalniveau toegepast (gemeente, corop,
                  provincie of landelijk).
                </p>
              </ClosableInfo>
            </Route>
          </Switch>

          <MarketValueItem
            id="valuation-price-development"
            value={formatValue(prijsOntwikkeling, {
              format: 'costs',
              condition: data?.transactie?.transactieConditie,
              dynamicRound: true,
            })}
            title="Prijsontwikkeling"
            description={description}
            linkTo={
              prijsOntwikkelingsGrafiek &&
              `${baseUrl}/info/price-index/price-development`
            }
            icon={
              !prijsOntwikkelingsGrafiek ? (
                <IconProjectionChart />
              ) : (
                <Link
                  className="valuation-price-development__link"
                  to={`${baseUrl}/info/price-index/price-development`}
                >
                  <Graph
                    className="market-value-list__graph"
                    valuationData={roundedPrijsOntwikkelingGraphData}
                    graphOnly
                    options={{ strokeWidth: 10, color: '#000' }}
                  />
                </Link>
              )
            }
          />
        </>
      )}
    </Panel>
  )
}
