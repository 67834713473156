import React from 'react'
import { statusText, statusSubClass } from '../../utils/constants'

import './status-label.css'

export default function StatusLabel({
  status,
  tag = 'span',
  className = 'body text-bold',
}) {
  const Tag = tag
  return (
    <Tag
      className={`status-label ${className} ${
        statusSubClass?.[status] ? `status-label${statusSubClass[status]}` : ''
      }`.trim()}
    >
      {statusText?.[status] || status}
    </Tag>
  )
}
