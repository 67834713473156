import React from 'react'
import { Link } from 'react-router-dom'
import { trackEvent } from '../../utils/ga-events'
import { gaCategories } from '../../utils/constants'

/**
 * Renders a link to an external page or a link to an internal page
 * @param url
 * @param guid
 * @param isExternal
 * @param type
 * @param {string} page - page has to be passed with an underscore.
 * @param className
 * @param children
 * @param target
 * @param rel
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function ObjectDetailLink({
  url,
  guid,
  isExternal,
  type,
  page = 'object_details',
  className,
  children,
  target = '_blank',
  rel = 'noopener noreferrer',
}) {
  if (!guid && !url && !type) {
    return null
  }

  if (isExternal) {
    return (
      <a
        className={`object-details__external-link print-excluded ${className}`.trim()}
        href={url}
        rel={rel}
        target={target}
        onClick={() => {
          trackEvent(
            `visit_${type}_from_${page}`,
            gaCategories.EXTERNAL_PAGES,
            `Opened ${type.toUpperCase()} from ${page.replace(
              '_',
              ' ',
            )} for object: ${guid}`,
          )
        }}
      >
        {children}
      </a>
    )
  } else {
    return (
      <Link
        className={`object-details__internal-link print-excluded ${className}`.trim()}
        to={url}
        rel={rel}
        target={target}
        onClick={() => {
          trackEvent(
            `visit_${type}_from_${page}`,
            gaCategories.EXTERNAL_PAGES,
            `Opened ${
              type.charAt(0).toUpperCase() + type.slice(1)
            } from ${page.replace('_', ' ')} for object: ${guid}`,
          )
        }}
      >
        {children}
      </Link>
    )
  }
}
