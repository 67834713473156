import React from 'react'
import DefaultLayout from '../layouts/default'
import { Typography, Box, PdfLink } from '../components'
import formatValue from '@brainbay/components/utils/format-value'
import { StyleSheet } from '@react-pdf/renderer'
import titleCase from '../../title-case'

export default function LokaleBekendmakingen({
  userName,
  date,
  isGebouwpaspoort,
  localAnnouncements,
}) {
  const styles = StyleSheet.create({
    tableContainer: {
      display: 'table',
      width: '100%',
    },
    tableHeader: {
      fontWeight: 'bold',
      fontSize: 12,
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderColor: '#0099bc',
    },
    tableRow: {
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottom: '1px solid #dddddd',
    },
    tableCell: {
      flex: 1,
      padding: 6,
      textAlign: 'left',
      fontSize: 10,
    },
  })
  return (
    <DefaultLayout
      pageName={`Lokale Bekendmakingen (${localAnnouncements?.total ?? 0})`}
      userName={userName}
      date={date}
      isGebouwpaspoort={isGebouwpaspoort}
    >
      <Typography
        style={{
          fontSize: 11,
          color: '#000',
          marginBottom: 15,
        }}
      >
        Hieronder vindt u een overzicht van de officiële bekendmakingen binnen
        een straal van 500 meter van het object. Klik op de bekendmaking voor
        meer informatie.
      </Typography>
      <Box style={styles.tableContainer}>
        {
          <Box style={styles.tableHeader}>
            <Typography style={{ ...styles.tableCell, flex: 0.1 }}></Typography>
            <Typography style={{ ...styles.tableCell, flex: 0.5 }}>
              Datum
            </Typography>
            <Typography style={{ ...styles.tableCell, flex: 0.5 }}>
              Overheid
            </Typography>
            <Typography style={{ ...styles.tableCell, flex: 0.8 }}>
              Onderwerp
            </Typography>
            <Typography style={{ ...styles.tableCell, flex: 1.5 }}>
              URL
            </Typography>
          </Box>
        }
        {localAnnouncements?.announcements?.map(
          (localAnnouncement, rowIndex) => (
            <Box style={styles.tableRow} key={rowIndex} wrap={false}>
              <Typography style={{ ...styles.tableCell, flex: 0.1 }}>
                {rowIndex + 1}.
              </Typography>
              <Typography style={{ ...styles.tableCell, flex: 0.5 }}>
                {' '}
                {formatValue(localAnnouncement?.DatumBeschikbaar / 1000, {
                  format: 'date',
                })}
              </Typography>
              <Typography style={{ ...styles.tableCell, flex: 0.5 }}>
                {localAnnouncement?.Maker}
              </Typography>
              <Typography style={{ ...styles.tableCell, flex: 0.8 }}>
                {titleCase(localAnnouncement?.Rubriek)}
              </Typography>
              <Typography style={{ ...styles.tableCell, flex: 1.5 }}>
                <PdfLink
                  style={{
                    color: '#0099bc',
                    fontWeight: 'bold',
                  }}
                  src={localAnnouncement?.URL}
                >
                  {localAnnouncement?.Titel}
                </PdfLink>
              </Typography>
            </Box>
          ),
        )}
      </Box>
    </DefaultLayout>
  )
}
