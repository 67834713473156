import React, { forwardRef, useEffect, useState } from 'react'
import Panel from '@brainbay/components/components/panel'
import DataMap from '@brainbay/components/components/data-map'
import SourceLabel from '@brainbay/components/components/source-label'
import './building-maps.css'

const BuildingMaps = forwardRef(function BuildingMaps({ data }, ref) {
  const [building, setBuilding] = useState({})
  const [address, setAddress] = useState({})
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    if (data && !data.loading) {
      const { pand, geselecteerdeAdres } = data || {}

      setBuilding(pand)
      setAddress(geselecteerdeAdres)
      setHasLoaded(true)
    }
  }, [data])

  const mapboxConfig = {
    scrollZoom: false,
  }

  const percelen = building?.percelen

  let geoJsonData = null
  if (percelen && percelen.length > 0) {
    geoJsonData = {
      type: 'FeatureCollection',
      features: percelen.map(perceel => ({
        type: 'Feature',
        properties: {
          kadastraleAanduiding: perceel?.kadastraleAanduiding,
        },
        geometry: {
          type: 'Polygon',
          coordinates: perceel?.begrenzing?.coordinates,
        },
      })),
    }
  }
  return (
    <div ref={ref}>
      <Panel
        title="Omgevingskaarten"
        tag="section"
        collapsable
        divider
        isOpenByDefault
        className="building-maps"
      >
        <div className="building-maps__wrapper" id="omgevingskaartenMapElement">
          {hasLoaded && (
            <>
              <div className="map-and-title-block">
                <h4 className="text-bold">Overzichtskaart</h4>
                <DataMap
                  longitude={address?.longitude}
                  latitude={address?.latitude}
                  mapboxConfig={mapboxConfig}
                  width="100%"
                  height="350px"
                  zoom={10}
                  showMarker
                  showControls
                />
              </div>
              <div className="map-and-title-block">
                <h4 className="text-bold">Detail kaart</h4>
                <DataMap
                  longitude={address?.longitude}
                  latitude={address?.latitude}
                  mapboxConfig={mapboxConfig}
                  width="100%"
                  height="350px"
                  zoom={13}
                  showMarker
                  showControls
                />
              </div>
              <div className="map-and-title-block">
                <h4 className="text-bold">Pand contour</h4>
                <DataMap
                  longitude={address?.longitude}
                  latitude={address?.latitude}
                  mapboxConfig={mapboxConfig}
                  width="100%"
                  height="350px"
                  zoom={16}
                  geoJson={building?.pandPolygon}
                  showKadaster
                  showControls
                  showRedPolygon
                />
              </div>
              <div className="map-and-title-block">
                <h4 className="text-bold">Kadastraal perceel</h4>
                <DataMap
                  longitude={address?.longitude}
                  latitude={address?.latitude}
                  mapboxConfig={mapboxConfig}
                  width="100%"
                  height="350px"
                  zoom={16}
                  geoJson={geoJsonData}
                  showKadaster
                  showPolygon
                  showControls
                />
              </div>
            </>
          )}
        </div>
        <div className="building-maps_source-label">
          <SourceLabel source="Kadaster" prefixSource="Bron" />
        </div>
      </Panel>
    </div>
  )
})
export default BuildingMaps
