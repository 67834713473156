import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '../icon'
import { ReactComponent as IconPlus } from '../../_assets/svg/icon-plus.svg'
import { ReactComponent as IconClose } from '../../_assets/svg/icon-close.svg'
import { trackEvent } from '../../utils/ga-events'
import { gaCategories } from '../../utils/constants'
import isTruthy from '../../utils/is-truthy'
import formatValue from '../../utils/format-value'
import './object-list.css'

export default function ObjectList({
  children,
  isLoading,
  small,
  numberOfColumns = 3,
  referencesLink,
  downloadReferencesLink,
  objectDeleteButton,
  onObjectDelete,
  onResetReferences,
  referencesUpdatedAt,
  deletedObjectId,
}) {
  const filteredChildren = children?.filter(child => isTruthy(child))

  function onReferencesModalOpen() {
    trackEvent(
      'clicked_open_modal_references',
      gaCategories.REFERENCES,
      'References modal opened',
    )
  }

  return (
    <ul
      className={`object-list${small ? ' object-list--small' : ''}`.trim()}
      style={{ '--number-of-columns': numberOfColumns }}
    >
      {filteredChildren &&
        filteredChildren.map(child => {
          const showSpinner = child?.props?.id === deletedObjectId

          return (
            <li className="object-list__item" key={child?.props?.id}>
              {isLoading ? (
                child
              ) : (
                <>
                  {child}

                  {objectDeleteButton && (
                    <button
                      className="object-list__delete-reference print-excluded"
                      disabled={filteredChildren.length === 1 || showSpinner}
                      onClick={() => onObjectDelete(child.props.id)}
                    >
                      <span className="sr-only">
                        Verwijderen uit referenties: {child.props.address}
                      </span>

                      {showSpinner ? (
                        <div className="object-list__delete-reference-spinner button--loading" />
                      ) : (
                        <Icon size="tiny">
                          <IconClose />
                        </Icon>
                      )}
                    </button>
                  )}
                </>
              )}

              {!small && <hr className="object-list__item-divider" />}
            </li>
          )
        })}
      {!isLoading && referencesLink && (
        <li className="object-list__item">
          <Link
            className="object-list__item-link object-list__add-reference print-excluded"
            to={referencesLink}
            onClick={onReferencesModalOpen}
          >
            <Icon className="object-list__plus-icon" circle>
              <IconPlus />
            </Icon>
            <p className="body-big text-dim">Wijzig referenties</p>
          </Link>

          {onResetReferences && (
            <>
              <button
                className="object-list__reset-button button print-excluded"
                onClick={onResetReferences}
              >
                Vernieuw referenties
              </button>

              {referencesUpdatedAt && (
                <p className="object-list__reset-button--meta body-small text-dim print-excluded">
                  Laatst gewijzigd op{' '}
                  {formatValue(referencesUpdatedAt, {
                    format: 'date',
                  })}
                </p>
              )}
            </>
          )}

          {!isLoading && filteredChildren && downloadReferencesLink && (
            <Link
              className="object-list__export-button button button--secondary print-excluded"
              to={downloadReferencesLink}
            >
              Exporteer referenties
            </Link>
          )}

          {!isLoading && filteredChildren && (
            <p className="object-list__source text-color--dim-text">
              *Bron geïndexeerde koopsom: Prijsontwikkeling brainbay
            </p>
          )}
        </li>
      )}
    </ul>
  )
}
