import React from 'react'
import { pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'

import ObjectsPDF from './objects-pdf'
import ObjectDetailPDF from './object-detail-pdf'
import PandDetailPDF from './pand-detail-pdf'

/**
 * Generate a PDF document from a list of objects.
 * @param documentData
 * @param filename
 * @returns {Promise<void>}
 */
export const generateObjectsPDFDocument = async (documentData, filename) => {
  const blob = await pdf(<ObjectsPDF items={documentData} />).toBlob()
  saveAs(blob, filename)
}

/**
 * Generate a PDF document from a single object detail page.
 * @param user
 * @param documentData
 * @param exportSettings
 * @param filename
 * @param priceIndex
 * @param graphSVG
 * @returns {Promise<void>}
 */
export const generateObjectDetailPDFDocument = async (
  user,
  documentData,
  exportSettings,
  filename,
  priceIndex,
  graphSVG,
  betrokkenObjectData,
) => {
  const blob = await pdf(
    <ObjectDetailPDF
      user={user}
      object={documentData}
      exportSettings={exportSettings}
      priceIndex={priceIndex}
      graphSVG={graphSVG}
      betrokkenObjectData={betrokkenObjectData}
    />,
  ).toBlob()
  saveAs(blob, filename)
}

export const generatePandDetailPDFDocument = async (
  user,
  documentData,
  businessRegistratieHistory,
  exportSettings,
  filename,
  walkscore,
  kvkUsers,
  omgevingskaartenMapImage,
  luchtfotoMapImage,
  bodemloketMapImage,
  ruimtelijkePlannenImage,
  localAnnouncements,
) => {
  const blob = await pdf(
    <PandDetailPDF
      user={user}
      building={documentData}
      businessRegistratieHistory={businessRegistratieHistory}
      exportSettings={exportSettings}
      walkscore={walkscore}
      kvkUsers={kvkUsers}
      omgevingskaartenMapImage={omgevingskaartenMapImage}
      luchtfotoMapImage={luchtfotoMapImage}
      bodemloketMapImage={bodemloketMapImage}
      ruimtelijkePlannenImage={ruimtelijkePlannenImage}
      localAnnouncements={localAnnouncements}
    />,
  ).toBlob()
  saveAs(blob, filename)
}
