import React from 'react'
import Icon from '../icon'
import { ReactComponent as IconSearch } from '../../_assets/svg/icon-search.svg'
import Autocomplete from '../autocomplete'

import './search-bar.css'

export default function SearchBar({
  isMapMode = false,
  className,
  separated = false,
  primaryAction = false,
  onSubmit = () => {},
  onChange = () => {},
  onClick = () => {},
  onChangeAutocompleteIndex = () => {},
  onChangeRadius = () => {},
  searchValue = '',
  autocomplete = false,
  autocompleteOptions = [],
  autocompleteOptionsAreLoading = false,
  autocompleteDefaultSelectedOption = -1,
  placeholder = 'Zoek naar adres',
  radiusOptions = [],
  radiusValue = 0,
  noResultsMessage,
  itemRenderer,
  subLabel,
  hideSearchButton = false,
} = {}) {
  function onFormSubmit(event) {
    onSubmit(event, searchValue)
  }

  return (
    <form
      className={`
        search-bar${className ? ` ${className}` : ''}${
          separated === false ? ' search-bar--connected' : ''
        }${separated === true ? ' search-bar--separated' : ''}${
          primaryAction ? ' search-bar--primary-action' : ''
        }
      `.trim()}
      onSubmit={onFormSubmit}
    >
      <label className="search-bar__label">
        <IconSearch className="search-bar__icon--search" />

        {autocomplete ? (
          <Autocomplete
            id="search-bar-autocomplete"
            className="search-bar__autocomplete"
            value={searchValue}
            onChange={onChange}
            onClick={onClick}
            options={autocompleteOptions}
            optionsAreLoading={autocompleteOptionsAreLoading}
            itemRenderer={itemRenderer}
            defaultSelectedOption={autocompleteDefaultSelectedOption}
            onChangeSelectedIndex={onChangeAutocompleteIndex}
            noResultsMessage={noResultsMessage}
            subLabel={subLabel}
            slugifyWithCount
          />
        ) : (
          <input
            className="search-bar__input highlight-focus body"
            type="text"
            value={searchValue}
            onChange={event => onChange(event.target.value)}
          />
        )}

        <span
          className="search-bar__label-text body"
          hidden={searchValue.length ? true : undefined}
        >
          {placeholder}
        </span>
      </label>

      {!isMapMode && radiusOptions.length > 0 && (
        <label className="search-bar__radius">
          <span className="sr-only">Zoek in radius</span>
          <select value={radiusValue} onChange={onChangeRadius}>
            {radiusOptions.map(option => {
              return (
                <option value={option} key={option}>
                  + {option}km
                </option>
              )
            })}
          </select>
        </label>
      )}

      {!hideSearchButton && (
        <button className="search-bar__submit highlight-focus">
          <Icon>
            <IconSearch />
          </Icon>
          <span className="search-bar__submit-text--mobile sr-only">
            Zoeken
          </span>
          <span className="search-bar__submit-text--desktop body text-bold">
            Zoeken
          </span>
        </button>
      )}
    </form>
  )
}
