import React from 'react'
import Icon from '@brainbay/components/components/icon'
import { ReactComponent as IconList } from '@brainbay/components/_assets/svg/icon-list.svg'
import { ReactComponent as IconMap } from '@brainbay/components/_assets/svg/icon-map.svg'
import { ReactComponent as IconTiles } from '@brainbay/components/_assets/svg/icon-tiles.svg'

import './map-list-switch.css'

export default function MapListSwitch({
  current = 'list',
  onChange = () => {},
  className = '',
}) {
  const isMap = current === 'map'
  const isList = current === 'list'
  const isCompact = current === 'compact'

  return (
    <nav
      className={`map-list-switch map-list-switch--${current} ${className}`.trim()}
    >
      <div className="map-list-switch__button-wrapper">
        <button
          className="body text-bold map-list-switch__button--list"
          aria-current={isList ? 'true' : 'false'}
          onClick={() => onChange('list')}
        >
          <Icon size="small">
            <IconTiles />
          </Icon>
          Lijst
        </button>
        <button
          className="body text-bold map-list-switch__button--compact"
          aria-current={isCompact ? 'true' : 'false'}
          onClick={() => onChange('compact')}
        >
          <Icon size="small">
            <IconList />
          </Icon>
          Compact
        </button>
        <button
          className="body text-bold map-list-switch__button--map"
          aria-current={isMap ? 'true' : 'false'}
          onClick={() => onChange('map')}
        >
          <Icon size="small">
            <IconMap />
          </Icon>
          Kaart
        </button>
      </div>
    </nav>
  )
}
