import React from 'react'

export default function Label({
  control,
  groupLabel,
  htmlFor,
  className = '',
  children,
}) {
  const ariaLabel = control['sr-label']
    ? { 'aria-label': control['sr-label'] }
    : {}
  return (
    <label
      htmlFor={htmlFor}
      className={`label ${control.label ? '' : 'sr-only'} ${className}`.trim()}
      {...ariaLabel}
    >
      {control.label || groupLabel}
      {children}
    </label>
  )
}
