import React from 'react'
import './icon.css'

export default function Icon({
  children,
  circle,
  size = 'small',
  debug = false,
  className = '',
  ...props
}) {
  return (
    <span
      className={`icon icon--${size} ${circle ? 'icon--circle' : ''} ${
        debug ? 'icon--debug' : ''
      } ${className}`.trim()}
      {...props}
    >
      {children}
    </span>
  )
}
