import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLanding as setPageTitle } from '../store/page-title'
import { enableComparePanel, clearComparison } from '../store/comparison'
import { resetSearchBar, showSearchBar } from '../store/display-search-bar'
import { list as setListMode } from '../store/search-result-map-mode'
import useUserData from '../utils/use-user-data'
import LaunchText from '../components/gebouwpaspoort-launch-text/gebouwpaspoort-launch-text'

import AppHero from '@brainbay/components/components/app-hero'
import ObjectList from '@brainbay/components/components/object-list'
import ObjectListItemSmall from '@brainbay/components/components/object-list-item-small'
import Icon from '@brainbay/components/components/icon'
import { ReactComponent as IconInfo } from '@brainbay/components/_assets/svg/icon-info.svg'

import hero3000Webp from '../_assets/images/hero-image-3000.webp'
import hero2000Webp from '../_assets/images/hero-image-2000.webp'
import heroWebp from '../_assets/images/hero-image.webp'
import hero1280Webp from '../_assets/images/hero-image-1280.webp'
import hero920Webp from '../_assets/images/hero-image-920.webp'
import hero640Webp from '../_assets/images/hero-image-640.webp'
import hero320Webp from '../_assets/images/hero-image-320.webp'
import hero3000Jpg from '../_assets/images/hero-image-3000.jpg'
import hero2000Jpg from '../_assets/images/hero-image-2000.jpg'
import heroJpg from '../_assets/images/hero-image.jpg'
import hero1280Jpg from '../_assets/images/hero-image-1280.jpg'
import hero920Jpg from '../_assets/images/hero-image-920.jpg'
import hero640Jpg from '../_assets/images/hero-image-640.jpg'
import hero320Jpg from '../_assets/images/hero-image-320.jpg'

import './landing.css'

export default function Landing({ searchBarComponent }) {
  const dispatch = useDispatch()
  const userName = useSelector(
    state => state?.user?.user?.name || state?.user?.user?.sub,
  )
  const { getUserData } = useUserData()
  const recentVisits =
    useSelector(state => state.user?.data?.recentVisits) || []

  const headerImages = {
    jpg: {
      3000: hero3000Jpg,
      2000: hero2000Jpg,
      1440: heroJpg,
      1280: hero1280Jpg,
      920: hero920Jpg,
      640: hero640Jpg,
      320: hero320Jpg,
    },
    webp: {
      3000: hero3000Webp,
      2000: hero2000Webp,
      1440: heroWebp,
      1280: hero1280Webp,
      920: hero920Webp,
      640: hero640Webp,
      320: hero320Webp,
    },
  }

  useEffect(() => {
    if (!recentVisits.length) {
      getUserData('recent-visits')
    }

    // We don't want the side effects of `getUserData` triggering this `useEffect`.
    // We only want to trigger this `useEffect` one time, when mounting this page.
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(setPageTitle())
    dispatch(clearComparison())
    dispatch(showSearchBar())
    dispatch(enableComparePanel())
    dispatch(setListMode())

    return () => {
      resetSearchBar()
    }
  }, [dispatch])

  const scrollRef = useRef(null)

  //to avoid scrolling when the page loads initially
  useEffect(() => {
    const url = new URL(window.location.href)
    const hash = url.hash
    if (hash && scrollRef.current) {
      const offset = 150 // Adjust the offset value as needed
      const scrollPosition = scrollRef.current.offsetTop - offset
      window.scrollTo({ top: scrollPosition, behavior: 'smooth' })
      window.history.replaceState(null, null, url.pathname)
    }
  }, [])

  //function to handle the scrolling behaviour when clicked on link

  const handleClick = event => {
    event.preventDefault()
    const targetSection = event.target.getAttribute('href')
    if (scrollRef.current) {
      const offset = 150 // Adjust the offset value as needed
      const scrollPosition = scrollRef.current.offsetTop - offset
      window.scrollTo({ top: scrollPosition, behavior: 'smooth' })
      window.history.pushState(null, null, targetSection)
    }
  }

  return (
    <div className="landing">
      <div className="notification-panel__container">
        <div className="notification-panel__content">
          <Icon circle size="nano" className="notification-panel__icon">
            <IconInfo />
          </Icon>
          <div>
            <div>
              <h2 className="notification-panel__heading">
                Nieuw! Abonneer je op het Gebouwpaspoort
              </h2>
            </div>
            <div className="notification-panel__text">
              Was je voorheen veel tijd kwijt met het verzamelen van alle
              gebouwdata en het vormen van een dossier, met het Gebouwpaspoort
              is dat verleden tijd. Sluit vanaf mei een abonnement af voor het
              gebruik van het Gebouwpaspoort. Voor meer informatie over de
              voordelen, kosten en de voorwaarden{' '}
              <a href="#section" className="scroll-link" onClick={handleClick}>
                klik hier
              </a>
            </div>
          </div>
        </div>
      </div>
      <AppHero
        userName={userName}
        headerImages={headerImages}
        searchbar={searchBarComponent}
      />
      <main className="landing layout--grid">
        <h2 className="object-list-item-small__header-text h4 text-bold">
          Recent bekeken objecten
        </h2>
        {recentVisits?.length ? (
          <ObjectList small>
            {recentVisits.map(item => {
              return (
                <ObjectListItemSmall
                  key={item.objectGuid || item.id}
                  id={item.objectGuid || item.id}
                  mediaGuid={item.mediaGuid}
                  addressId={item.addressId}
                  housing={item.housing}
                  address={item.address}
                  date={new Date(item.date)}
                />
              )
            })}
          </ObjectList>
        ) : (
          <p className="object-list-item-small__header-text body">
            Begin met zoeken om recent bekeken adressen te zien.
          </p>
        )}
        <h2
          className="object-list-item-small__header-text h4 text-bold"
          id="section"
          ref={scrollRef}
        >
          Nieuw! Abonneer je op het Gebouwpaspoort
          <p style={{ fontSize: '14px', color: '#888888' }}>26-04-2024</p>
        </h2>

        <LaunchText />
      </main>
    </div>
  )
}
