import React from 'react'
import './particulars-list.css'

export default function ParticularsList({ particulars = [] }) {
  const filteredParticularsList = particulars.filter(
    particular => particular !== 'Volle eigendom',
  )

  return (
    filteredParticularsList.length > 0 && (
      <>
        <ul className="particulars-list">
          {filteredParticularsList.map((particular, index) => {
            return (
              <li key={index} className="particulars-list__item body">
                {particular}
              </li>
            )
          })}
        </ul>
        <p className="particulars-list__body body-small text-dim">
          Indien bij aanmelding het Tiara-veld ‘Informatieplicht’ is ingevuld of
          wanneer bij aanmelding de eigendomsverhouding anders was dan ‘volle
          eigendom’, dan worden de kenmerken hier getoond.
        </p>
      </>
    )
  )
}
