import React from 'react'
import { Box, Typography } from './index'

export default function PandWalkscore({ debug, address, walkscore }) {
  const { straatnaam, huisnummer, woonplaats } = address
  const walkscoreDefinition =
    'De Walk Score meet de beloopbaarheid van elk adres op basis van de afstand tot nabijgelegen voorzieningen en de voetgangersvriendelijkheid. Hier vindt u een duiding van de score:'
  const walkscoreDescription = [
    {
      items: [
        {
          scoreRange: '90-100',
          label: 'Zeer goed beloopbaar',
          description: 'Alle noodzakelijke voorzieningen zijn nabijgelegen.',
        },
        {
          scoreRange: '70-89',
          label: 'Goed beloopbaar',
          description:
            'De meeste noodzakelijke voorzieningen zijn nabijgelegen.',
        },
        {
          scoreRange: '50-69',
          label: 'Redelijk beloopbaar',
          description: 'Sommige voorzieningen zijn nabijgelegen.',
        },

        {
          scoreRange: '25-49',
          label: 'Meestal afhankelijk van de fiets of auto',
          description:
            'De meeste voorzieningen zijn niet nabijgelegen en vereisen een fiets of auto.',
        },
        {
          scoreRange: '0-24',
          label: 'Altijd afhankelijk van de fiets of auto',
          description:
            ' Bijna alle voorzieningen zijn niet nabijgelegen en vereisen een fiets of auto.',
        },
      ],
    },
  ]

  return (
    <Box
      style={{
        display: 'flex',
      }}
    >
      <Typography
        style={{
          fontSize: 10,
          color: '#888888',
          fontWeight: 'bold',
          marginBottom: 4,
        }}
      >
        Bereikbaarheidsscore (0-100)
      </Typography>
      <Typography style={{ fontWeight: 'bold', marginBottom: 4 }}>
        {`${huisnummer} ${straatnaam}, ${woonplaats}`}
      </Typography>

      <Typography
        style={{
          fontSize: 12,
          fontWeight: 'bold',
        }}
      >
        Walk Score:
      </Typography>
      <Typography
        style={{
          color: '#0099bc',
          padding: 4,
          fontWeight: 'bold',
          fontSize: 22,
        }}
      >
        {walkscore}
      </Typography>

      <Typography>{walkscoreDefinition}</Typography>

      {walkscoreDescription?.map((description, index) => {
        return (
          <Box
            key={index}
            style={{
              paddingBottom: 10,
              marginTop: 10,
            }}
            debug={debug}
          >
            {description?.items.map((text, index) => {
              return (
                <Box
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: 10,
                    paddingTop: 6,
                    paddingBottom: 2,
                    borderBottom: '1px solid #dddddd',
                  }}
                  debug={debug}
                >
                  <Typography
                    style={{
                      minWidth: 150,
                      fontSize: 11,
                      fontWeight: 'bold',
                    }}
                    debug={debug}
                  >
                    {text.scoreRange}
                  </Typography>
                  <Box>
                    <Typography
                      style={{
                        width: '100%',
                        fontSize: 11,
                        fontWeight: 'bold',
                      }}
                      debug={debug}
                    >
                      {text.label}
                    </Typography>
                    <Typography
                      style={{
                        width: '100%',
                        fontSize: 10,
                      }}
                      debug={debug}
                    >
                      {text.description}
                    </Typography>
                  </Box>
                </Box>
              )
            })}
          </Box>
        )
      })}
    </Box>
  )
}
