import React, { useState, useEffect, useRef, useMemo } from 'react'
import slugify from '../../utils/slugify'
import slugifyWithCounter from '../../utils/slugifyWithCounter'
import Status from './status'
import './autocomplete.css'

/**
 * This component is inspired by the accessibleAutocomplete component from
 * alphagov: https://github.com/alphagov/accessible-autocomplete
 */

export default function Autocomplete({
  id = 'autocomplete',
  minQueryLength = 2,
  value = '',
  options,
  onChange = () => {},
  onClick = () => {},
  onChangeSelectedIndex = () => {},
  optionsAreLoading = false,
  defaultSelectedOption = -1,
  itemRenderer,
  noResultsMessage,
  className,
  subLabel,
  slugifyWithCount = false,
  ...restProps
}) {
  const rootRef = useRef(null)
  const inputRef = useRef(null)
  const [hasFocus, setHasFocus] = useState(false)
  const [userHasSelected, setUserHasSelected] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(defaultSelectedOption)
  const [blurTimeout, setBlurTimeout] = useState(null)

  const visibleOptions = useMemo(() => {
    return value && userHasSelected === false ? options : []
  }, [options, value, userHasSelected])

  const slugifyWithCounterOption = slugifyWithCounter()

  useEffect(() => {
    const showNoResultsMessage =
      noResultsMessage &&
      !optionsAreLoading &&
      hasFocus &&
      value.length >= minQueryLength &&
      options.length === 0

    setIsExpanded(visibleOptions.length > 0 || showNoResultsMessage)
  }, [
    visibleOptions,
    options,
    optionsAreLoading,
    hasFocus,
    value,
    minQueryLength,
    noResultsMessage,
  ])

  useEffect(() => {
    close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => {
      clearTimeout(blurTimeout)
    }
  }, [blurTimeout])

  useEffect(() => {
    onChangeSelectedIndex(selectedIndex)
    // We do not want to fire this on setting the onChangeSelectedProp
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex])

  function increaseIndex() {
    const totalOptions = visibleOptions.length
    selectedIndex === totalOptions - 1 || selectedIndex < 0
      ? setSelectedIndex(0)
      : setSelectedIndex(selectedIndex + 1)
  }

  function decreaseIndex() {
    const totalOptions = visibleOptions.length
    selectedIndex === 0 || selectedIndex < 0
      ? setSelectedIndex(totalOptions - 1)
      : setSelectedIndex(selectedIndex - 1)
  }

  function selectCurrentOption(chosenIndex) {
    let index = selectedIndex
    if (chosenIndex !== undefined) {
      index = chosenIndex
      setSelectedIndex(chosenIndex)
    }
    const currentOption = visibleOptions[index]
    if (currentOption) {
      setUserHasSelected(true)
      setIsExpanded(false)
      onChange(currentOption.label)
      onClick(currentOption)
    }
  }

  function open() {
    setUserHasSelected(false)
    setSelectedIndex(defaultSelectedOption)
    setHasFocus(true)
  }

  function close() {
    setUserHasSelected(true)
    setSelectedIndex(-1)
    setHasFocus(false)
  }

  function handleOptionMouseClick(index) {
    selectCurrentOption(index)
    setTimeout(() => {
      if (inputRef?.current) {
        inputRef.current.focus()
        close()
      }
    }, 5)
  }

  function handleBlur(event) {
    event.preventDefault()
    const blurTimeout = setTimeout(() => {
      const { activeElement } = document
      if (activeElement !== inputRef.current) {
        close()
      }
    }, 50)
    setBlurTimeout(blurTimeout)
  }

  function handleEscape() {
    inputRef.current.select()
    close()
  }

  function handleKeyUp(event) {
    if (
      event.key !== 'Tab' &&
      event.key !== 'Shift' &&
      event.key !== 'ArrowLeft' &&
      event.key !== 'ArrowRight'
    ) {
      setUserHasSelected(false)
    }

    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault()
        return increaseIndex()
      case 'ArrowUp':
        event.preventDefault()
        return decreaseIndex()
      case 'Enter':
        if (selectedIndex > -1 && visibleOptions.length) {
          event.preventDefault()
          return selectCurrentOption()
        }
        return
      case 'Escape':
        return handleEscape()
      default:
        return
    }
  }

  return (
    <div className={`autocomplete ${className}`.trim()} ref={rootRef}>
      <p className="sr-only" id={`${id}__description`}>
        Na {minQueryLength} karakters zijn resultaten beschikbaar. Gebruik
        pijltje omhoog en naar beneden om resultaten te markeren.
      </p>
      <Status
        id={id}
        isInFocus={hasFocus}
        visibleOptions={visibleOptions}
        options={options}
        selectedIndex={selectedIndex}
        query={value}
        optionsAreLoading={optionsAreLoading}
      />
      <input
        id={id}
        ref={inputRef}
        type="text"
        value={value}
        onChange={event => onChange(event.target.value)}
        onKeyDown={handleKeyUp}
        onBlur={handleBlur}
        onFocus={open}
        {...restProps}
        role="combobox"
        aria-label="Zoeksuggesties"
        aria-describedby={value.length === 0 ? `${id}__description` : ''}
        aria-autocomplete="list"
        aria-expanded={isExpanded ? 'true' : 'false'}
        aria-controls={`${id}__listbox`}
        aria-owns={`${id}__listbox`}
        aria-activedescendant={`${id}__option-${selectedIndex}`}
        autoComplete="off"
      />
      {value && subLabel && (
        <span className="autocomplete__sub-label">{subLabel}</span>
      )}
      <ul id={`${id}__listbox`} className="autocomplete__list" role="listbox">
        {visibleOptions.map((item, index, list) => (
          <li
            key={
              slugifyWithCount
                ? slugifyWithCounterOption(item.id || item.label)
                : slugify(item.id || item.label)
            }
            id={`${id}__option-${index}`}
            className="autocomplete__option"
            role="option"
            aria-posinset={index + 1}
            aria-setsize={visibleOptions.length}
            aria-selected={selectedIndex === index ? 'true' : 'false'}
            aria-label={item.label}
            onMouseDown={() => handleOptionMouseClick(index)}
          >
            {typeof itemRenderer === 'function' ? (
              itemRenderer(selectedIndex === index, item, index, list)
            ) : (
              <span
                id={`${id}__option-${index}-label`}
                className="autocomplete__list-item body"
              >
                {item.label}
              </span>
            )}
          </li>
        ))}

        {visibleOptions.length === 0 &&
          !optionsAreLoading &&
          noResultsMessage && (
            <li className="autocomplete__option" tabIndex="-1">
              <span className="autocomplete__list-item body">
                {noResultsMessage}
              </span>
            </li>
          )}
      </ul>
      <div
        className={`autocomplete__loading-indicator autocomplete__loading-indicator--${
          optionsAreLoading ? 'show' : ''
        }`}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  )
}
