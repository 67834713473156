import React from 'react'
import formatValue from '@brainbay/components/utils/format-value'
import { Document } from './pdf/components'
import FrontPage from './pdf/pages/front-page'
import PandDetailsPage from './pdf/pages/pand-details-page'
import PandBetrokkenAddressPage from './pdf/pages/pand-betrokken-address-page'
import PandHistoryPage from './pdf/pages/pand-historie-page'
import PandVerblijfsobjectenPage from './pdf/pages/pand-verblijfsobjecten-page'
import PandWalkscorePage from './pdf/pages/pand-walkscore-page'
import PandBodemLoketPage from './pdf/pages/pand-bodemloket-page'
import DisclaimerPage from './pdf/pages/disclaimer-page'
import markerImage from '@brainbay/components/_assets/svg/icon-business-primary.png'
import SectorDescriptionPage from './pdf/pages/sector-description-page'
import KvkUsersPage from './pdf/pages/kvk-users-page'
import LokaleBekendmakingen from './pdf/pages/lokale-bekendmakingen'
import RuimtelijkePlannenPage from './pdf/pages/ruimtelijke-plannen-page'
import PandObjectDescriptionPage from './pdf/pages/pand-object-description-page'
import PandLuchtFotoAndOmgevinskaartenPage from './pdf/pages/pand-lucht-foto-omgevings-kaarten-page'

export default function PandDetailPDF({
  user,
  building,
  businessRegistratieHistory,
  exportSettings,
  debug,
  walkscore,
  kvkUsers,
  omgevingskaartenMapImage,
  luchtfotoMapImage,
  bodemloketMapImage,
  ruimtelijkePlannenImage,
  localAnnouncements,
}) {
  const userName = user?.user?.name || user?.user?.sub || '---'
  const date = formatValue(new Date().toString(), { format: 'date' })

  const { geselecteerdeAdres } = building || {}

  return (
    <Document>
      <FrontPage
        item={building}
        userName={userName}
        date={date}
        debug={debug}
        isGebouwpaspoort={true}
        gebouwpaspoortImage={true}
      />
      {!exportSettings.includes('object-description') && (
        <PandObjectDescriptionPage
          item={building}
          userName={userName}
          date={date}
          debug={debug}
          isGebouwpaspoort={true}
        />
      )}
      {!exportSettings.includes('pand-details') && (
        <PandDetailsPage
          item={building}
          userName={userName}
          date={date}
          debug={debug}
          isGebouwpaspoort={true}
          gebouwpaspoortImage={true}
          address={geselecteerdeAdres}
        />
      )}
      {!exportSettings.includes('pand-history') &&
        businessRegistratieHistory &&
        businessRegistratieHistory.length > 0 && (
          <PandHistoryPage
            businessRegistratieHistory={businessRegistratieHistory}
            userName={userName}
            date={date}
            debug={debug}
            isGebouwpaspoort={true}
          />
        )}
      {!exportSettings.includes('betrokken-adressen') && (
        <PandBetrokkenAddressPage
          item={building}
          userName={userName}
          date={date}
          debug={debug}
          isGebouwpaspoort={true}
        />
      )}
      {!exportSettings.includes('verblijfsobjecten') && (
        <PandVerblijfsobjectenPage
          item={building}
          userName={userName}
          date={date}
          debug={debug}
          isGebouwpaspoort={true}
        />
      )}
      {!exportSettings.includes('gebruikers') && kvkUsers?.total > 0 && (
        <KvkUsersPage
          item={building}
          users={kvkUsers}
          userName={userName}
          date={date}
          debug={debug}
          isGebouwpaspoort={true}
        />
      )}
      {(luchtfotoMapImage || omgevingskaartenMapImage) &&
        (!exportSettings.includes('luchtfoto') ||
          !exportSettings.includes('omgevingskaarten')) && (
          <PandLuchtFotoAndOmgevinskaartenPage
            userName={userName}
            date={date}
            debug={debug}
            isGebouwpaspoort={true}
            exportSettings={exportSettings}
            luchtfotoMapImage={luchtfotoMapImage}
            omgevingskaartenMapImage={omgevingskaartenMapImage}
          />
        )}
      {ruimtelijkePlannenImage &&
        !exportSettings.includes('ruimtelijke-plannen') && (
          <RuimtelijkePlannenPage
            item={building}
            userName={userName}
            date={date}
            isGebouwpaspoort={true}
            debug={debug}
            ruimtelijkePlannenImage={ruimtelijkePlannenImage}
          />
        )}
      {!exportSettings.includes('lokale-bekendmakingen') && (
        <LokaleBekendmakingen
          userName={userName}
          date={date}
          debug={debug}
          isGebouwpaspoort={true}
          localAnnouncements={localAnnouncements}
        />
      )}
      {bodemloketMapImage && !exportSettings.includes('bodemloket') && (
        <PandBodemLoketPage
          item={building}
          userName={userName}
          date={date}
          debug={debug}
          isGebouwpaspoort={true}
          bodemloketMapImage={bodemloketMapImage}
          markerImage={markerImage}
        />
      )}
      {!exportSettings.includes('walkscore') && (
        <PandWalkscorePage
          item={building}
          userName={userName}
          date={date}
          debug={debug}
          isGebouwpaspoort={true}
          address={geselecteerdeAdres}
          walkscore={walkscore}
        />
      )}
      {!exportSettings.includes('sector-description') && (
        <SectorDescriptionPage
          item={building}
          userName={userName}
          date={date}
          debug={debug}
          isGebouwpaspoort={true}
        />
      )}
      <DisclaimerPage
        userName={userName}
        date={date}
        debug={debug}
        isGebouwpaspoort={true}
      />
    </Document>
  )
}
