import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import ReactDOM from 'react-dom'
import {
  mapboxToken,
  mapboxStyle,
  apiHost,
} from '@brainbay/components/utils/environment-vars'
import MapGL, {
  Source,
  Layer,
  Marker as _Marker,
  NavigationControl,
} from 'react-map-gl'
import { useDispatch, useSelector } from 'react-redux'
import { objectsToCompareSelector } from '../../store/comparison'
import {
  setLatestSelectedResult,
  setLatestSelectedResultGeometry,
} from '../../store/search-result-latest-selected'
import 'mapbox-gl/dist/mapbox-gl.css'

import './mapbox.css'
import MapboxPopup from './mapbox-popup'
import useMediaQuery from '../../utils/use-media-query'
import MapMarkerPin from '@brainbay/components/components/map-marker-pin'

import IconBusinessPrimary from '@brainbay/components/_assets/svg/icon-business-primary.svg'
import IconBusinessSecondary from '@brainbay/components/_assets/svg/icon-business-secondary.svg'

export const mapDefaults = {
  LATITUDE: 52.1326,
  LONGITUDE: 5.2913,
  ZOOM_LEVEL_ZOOM_IN: 16,
  ZOOM_LEVEL_ZOOM_WIDE: 7,
  ZOOM: 5,
  TRANSITION_DURATION: 750,
}

const layerStyle = {
  id: 'point',
  type: 'symbol',
  source: 'points',
  'source-layer': 'object-marker',
  layout: {
    'icon-image': 'business-primary',
    'icon-allow-overlap': true,
    'icon-size': 0.5,
  },
}

const layerStyleClusterBackground = {
  id: 'cluster-background',
  type: 'circle',
  source: 'points',
  'source-layer': 'cluster-marker',
  paint: {
    'circle-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      '#ffd400',
      '#0099bc',
    ],
    'circle-radius': 15,
  },
}

const layerStyleClusterNumber = {
  id: 'cluster-number',
  type: 'symbol',
  source: 'points',
  'source-layer': 'cluster-marker',
  layout: {
    'text-field': '{objectCount}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 14,
    'text-allow-overlap': true,
  },
  paint: {
    'text-color': '#ffffff',
  },
}

export const Marker = _Marker

export default function Mapbox({
  className = '',
  onChangedHighlightedFeature = () => {},
  highlightedFeatures,
  tileQuery,
  apiAccessToken = '',
  onViewportChange = () => {},
  latitude,
  longitude,
  zoom,
  initialBounds,
}) {
  const [coordinatesLoaded, setCoordinatesLoaded] = useState(false)
  const [mapStyle, setMapStyle] = useState(mapboxStyle)
  const [visibleLayerStyle, setVisibleLayerStyle] = useState(false)
  const dispatch = useDispatch()
  const mapRef = useRef(null)
  const { hoveredFeatures, onHover, clearHovered } = useHover(mapRef)
  const popup = useHoverPopup(hoveredFeatures)
  useImages(mapRef)
  const objectsToCompare = useSelector(objectsToCompareSelector)
  const latestSelectedResultGeometry = useSelector(
    state => state.searchResultLatestSelected,
  )

  const [viewport, setViewport] = useState(() => ({
    bearing: 0,
    pitch: 0,
    longitude: Number(longitude) || mapDefaults.LONGITUDE,
    latitude: Number(latitude) || mapDefaults.LATITUDE,
    minZoom: mapDefaults.ZOOM_LEVEL_ZOOM_WIDE,
    zoom: Number(zoom) || mapDefaults.ZOOM,
  }))

  const activeMarkerCoords = useMemo(() => {
    const features = highlightedFeatures.length
      ? highlightedFeatures
      : hoveredFeatures
    const feature = features?.[0]
    return feature?.geometry?.coordinates
  }, [highlightedFeatures, hoveredFeatures])

  function onClick(event) {
    if (event.features.length > 0) {
      setViewport({
        ...viewport,
        longitude: Number(event.features[0].geometry.coordinates[0]),
        latitude: Number(event.features[0].geometry.coordinates[1]),
      })
      setTimeout(() => {
        const geometry = {
          zoom: viewport.zoom,
          longitude: Number(event.features[0].geometry.coordinates[0]),
          latitude: Number(event.features[0].geometry.coordinates[1]),
        }

        clearHovered()
        onChangedHighlightedFeature(event.features)
        dispatch(setLatestSelectedResultGeometry(geometry))
      }, mapDefaults.TRANSITION_DURATION + 100)
    } else {
      dispatch(setLatestSelectedResult(undefined))
      dispatch(setLatestSelectedResultGeometry({}))
      onChangedHighlightedFeature([])
    }
  }

  const applyInitialBounds = useCallback(() => {
    if (!mapRef.current) {
      return
    }

    const mapboxApi = mapRef.current.getMap()

    const { northEast, southWest } = initialBounds
    const { longitude, latitude, zoom } = latestSelectedResultGeometry

    const camera = mapboxApi.cameraForBounds([
      [southWest.longitude, southWest.latitude],
      [northEast.longitude, northEast.latitude],
    ])

    const nextViewport = {
      ...viewport,
      zoom: camera.zoom,
      latitude: camera.center.lat,
      longitude: camera.center.lng,
    }

    if (longitude && latitude && zoom) {
      setViewport({
        ...viewport,
        longitude,
        latitude,
        zoom,
      })
    } else {
      setViewport(nextViewport)
    }

    onViewportChange(nextViewport)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialBounds, onViewportChange, viewport, latestSelectedResultGeometry])

  function onLoad(event) {
    const map = event.target
    map.setPaintProperty(
      'building-number-label',
      'text-color',
      'hsl(0, 0%, 13%)',
    )
    map.setLayoutProperty('building-number-label', 'text-size', 12)

    if (initialBounds) {
      setTimeout(() => {
        setCoordinatesLoaded(true)
        applyInitialBounds()
      }, 100)
    }
  }

  function handleCheckboxChange(e) {
    setVisibleLayerStyle(e.target.checked)
  }

  function notInCompareList(id) {
    return !objectsToCompare.some(comparedObject => comparedObject?.id === id)
  }

  function interActiveLayerIds() {
    const ids = ['point', 'cluster-background']
    if (!coordinatesLoaded) {
      return []
    }
    return ids
  }

  useEffect(() => {
    if (initialBounds) {
      setTimeout(() => {
        setCoordinatesLoaded(true)
        applyInitialBounds()
      }, 100)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [initialBounds])

  return (
    <div className={className}>
      <MapGL
        ref={mapRef}
        {...viewport}
        width="100%"
        height="100%"
        mapStyle={mapStyle}
        onViewportChange={nextViewport => {
          onViewportChange(nextViewport)
          setViewport(nextViewport)
        }}
        mapboxApiAccessToken={mapboxToken}
        onClick={onClick}
        onHover={onHover}
        onLoad={onLoad}
        interactiveLayerIds={interActiveLayerIds()}
        transformRequest={(url, resourceType) => {
          if (resourceType === 'Tile' && url.includes(apiHost())) {
            return {
              url: url,
              headers: { Authorization: `Bearer ${apiAccessToken}` },
            }
          }
        }}
      >
        <MapControlsPortal>
          <MapStyleSelect mapStyle={mapStyle} onMapStyleChange={setMapStyle} />
          <LayerStyleCheckBox
            checked={visibleLayerStyle}
            onLayerStyleChange={handleCheckboxChange}
          />
          <NavigationControl className="mapbox__navigation-control" />
        </MapControlsPortal>

        <Source
          id="percelen"
          type="raster"
          tiles={[
            'https://geodata.nationaalgeoregister.nl/kadastralekaart/wmts/v4_0?layer=Kadastralekaart&style=default&tilematrixset=EPSG:3857&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TileMatrix=EPSG:3857:{z}&TileCol={x}&TileRow={y}',
          ]}
          tileSize={512}
          maxzoom={19}
        >
          <Layer
            id="percelen"
            type="raster"
            source="percelen"
            source-layer="kadastralekaart"
            layout={{ visibility: visibleLayerStyle ? 'visible' : 'none' }}
            paint={{ 'raster-opacity': 0.5 }}
          />
        </Source>

        {coordinatesLoaded && (
          <Source
            id="my-data"
            type="vector"
            tiles={[
              `${apiHost()}/tile/{x}/{y}/{z}?query=${tileQuery}&overlap=9`,
            ]}
            tileSize={512}
            promoteId="objectGuids"
          >
            <Layer {...layerStyleClusterBackground} />
            <Layer {...layerStyleClusterNumber} />
            <Layer {...layerStyle} />
          </Source>
        )}

        {activeMarkerCoords && !latestSelectedResultGeometry && (
          <MapMarkerPin
            longitude={Number(activeMarkerCoords?.[0]) || 0}
            latitude={Number(activeMarkerCoords?.[1]) || 0}
          />
        )}

        {latestSelectedResultGeometry &&
          notInCompareList(latestSelectedResultGeometry.guid) && (
            <MapMarkerPin
              longitude={Number(latestSelectedResultGeometry?.longitude) || 0}
              latitude={Number(latestSelectedResultGeometry?.latitude) || 0}
            />
          )}

        {objectsToCompare &&
          objectsToCompare.map(comparedObject => {
            return (
              <MapMarkerPin
                key={comparedObject?.id}
                longitude={Number(comparedObject?.longitude)}
                latitude={Number(comparedObject?.latitude)}
                comparisonAdded={true}
              />
            )
          })}

        {popup}
      </MapGL>
    </div>
  )
}

const MapControlsPortal = ({ children }) => {
  return ReactDOM.createPortal(
    children,
    document.getElementById('map-controls'),
  )
}

const MapStyleSelect = ({ mapStyle, onMapStyleChange }) => {
  return (
    <label className="mapbox__layer-switcher">
      <span className="sr-only">Soort onderlaag</span>
      <select
        value={mapStyle}
        onChange={event => onMapStyleChange(event.target.value)}
      >
        <option value={mapboxStyle}>Kaart</option>
        <option value="mapbox://styles/mapbox/satellite-streets-v11">
          Satellietfoto
        </option>
      </select>
    </label>
  )
}

const LayerStyleCheckBox = ({ checked, onLayerStyleChange }) => {
  return (
    <fieldset className="mapbox__layer-style-checkbox">
      <legend className="sr-only">Kaartlagen</legend>
      <input
        id="layer-style-checkbox"
        type="checkbox"
        name="layer-style-checkbox"
        defaultChecked={checked}
        onChange={onLayerStyleChange}
      />
      <label
        htmlFor="layer-style-checkbox"
        className="mapbox__layer-switcher-second"
      >
        <span className="sr-only">Soort layer</span>
        Kadastrale grenzen
      </label>
    </fieldset>
  )
}

const useImages = mapRef => {
  useEffect(() => {
    if (!mapRef.current) {
      return
    }

    const map = mapRef.current.getMap()

    function addSvgImage(id, image) {
      if (map.hasImage(id)) return

      let img = new Image(64, 64)
      img.onload = () => {
        // Do this check again as .onload is asynchronous
        if (!map.hasImage(id)) {
          map.addImage(id, img)
        }
      }
      img.src = image
    }

    addSvgImage('business-primary', IconBusinessPrimary)
    addSvgImage('business-secondary', IconBusinessSecondary)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef.current])
}

function useHover(mapRef) {
  const [hoveredFeatures, setHoveredFeatures] = useState([])
  const [hoveredClusters, setHoveredClusters] = useState([])

  const onHover = useCallback(
    event => {
      const map = mapRef.current.getMap()
      const objects = event.features.filter(
        feature => feature.sourceLayer === 'object-marker',
      )
      const clusters = event.features.filter(
        feature => feature.sourceLayer === 'cluster-marker',
      )

      function setMarkerHoveredState(id, hover) {
        map.setFeatureState(
          {
            source: 'my-data',
            sourceLayer: 'cluster-marker',
            id,
          },
          { hover },
        )
      }

      if (clusters.length === 0) {
        hoveredClusters.forEach(({ id }) => {
          setMarkerHoveredState(id, false)
        })
        setHoveredClusters([])
      }

      if (objects.length > 0) {
        setHoveredFeatures(event.features)
      } else if (clusters.length > 0) {
        const hoveringClusterIds = clusters.map(({ id }) => id)
        const oldHoveringClusterIds = hoveredClusters
          .map(({ id }) => id)
          .filter(id => hoveringClusterIds.includes(id) === false)

        hoveringClusterIds.forEach(id => {
          setMarkerHoveredState(id, true)
        })
        oldHoveringClusterIds.forEach(id => {
          setMarkerHoveredState(id, false)
        })

        setHoveredClusters(clusters)
      } else {
        setHoveredFeatures([])
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hoveredClusters],
  )

  const clearHovered = useCallback(() => {
    setHoveredFeatures([])
    setHoveredClusters([])
  }, [])

  return {
    onHover,
    clearHovered,
    hoveredClusters,
    hoveredFeatures,
  }
}

function useHoverPopup(hoveredFeatures) {
  const supportsHover = useMediaQuery('(hover: hover)')
  const lastState = useRef(null)

  /* when hovering out we just hide the previous popup using css. This means that a re-hover is instant */
  if (hoveredFeatures.length) {
    lastState.current = hoveredFeatures[0]
  } else if (!lastState.current) {
    return null
  }

  if (!supportsHover) {
    return null
  }

  const { geometry, properties } = lastState.current

  return (
    <MapboxPopup
      {...properties}
      hide={hoveredFeatures.length === 0}
      geometry={geometry}
    />
  )
}
