import React, { useEffect, useState, useMemo } from 'react'
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  useHistory,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setSearchResult as setPageTitle } from '../store/page-title'
import { resetSearchBar, showSearchBar } from '../store/display-search-bar'
import {
  setAutoCompleteOption,
  setNewFilterValue,
  setNewSearchValue,
  setSortBy,
  setSortDirection,
} from '../store/comparison'
import { SkiplinkTarget } from '../components/skiplinks'
import isSSR from '@brainbay/components/utils/is-ssr'
import Paginaton from '@brainbay/components/components/pagination'
import SearchResultList from '@brainbay/components/components/search-result-list'
import FilterBar from '@brainbay/components/components/filters/filter-bar'
import SortSelect from '@brainbay/components/components/sort-select'
import useResizeObserver from '@brainbay/components/utils/use-resize-observer'
// import viewportHack from '@brainbay/components/utils/viewport-hack'
import { get } from '../utils/api-data'

import './search-results.css'
import useApiCall from '../utils/use-api-call'
import { defaultSearchOption } from '../utils/constants'
import { getFormattedSearchQueryResultTitle } from '../utils/get-formatted-search-query'
import { useDebouncedEffect } from '../utils/use-debounced-effect'
import SearchResultItemGebouwpaspoort from '../components/search-result-item-gebouwpaspoort'
import './search-results-gebouwpaspoort.css'
import { gebouwpaspoortApiHost } from '../utils/business-environment-vars'
import formatFilterRequestData from '../utils/format-filter-request-data'
import { setFilterCatalog } from '../store/filtercatalog'
import useOnPopState from '../utils/use-on-pop-state'
import { removeExportButton } from '../store/export-button'

const searchParamsToObject = params =>
  [...params.keys()].reduce((list, key) => {
    const allValues = params.getAll(key)
    const value = allValues.length === 1 ? allValues[0] : allValues

    return { ...list, [key]: value }
  }, {})

const NoQueryProvided = () =>
  isSSR ? (
    <SearchResultList query={' '} />
  ) : (
    <p className="body-big">Voer een adres in het zoekveld in</p>
  )

const loadingData = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(nr => ({
  id: `${nr}`,
}))

/**
 * Hook to determine the search text based on the given query
 * @param {*} query
 * @returns
 */
const useSearchQueryResolver = query => {
  const dispatch = useDispatch()
  const autocompleteOption = useSelector(
    state => state.comparison?.autocompleteOption,
  )

  const { post } = useApiCall()

  useEffect(() => {
    post({
      path: `suggestion/resolve`,
      body: {
        query,
      },
    }).then(suggestions => {
      if (suggestions) {
        const { boundingBox, ...newAutocompleteOption } = suggestions

        dispatch(setAutoCompleteOption(newAutocompleteOption))
      }
      dispatch(setNewSearchValue(query))
    })
  }, [dispatch, post, query])

  return getFormattedSearchQueryResultTitle(autocompleteOption)
}

export default function SearchResultsGebouwpassport() {
  const [resultSize, setResultSize] = useState('large')
  const [totalPages, setTotalPages] = useState(0)
  const [totalResults, setTotalResults] = useState(undefined)
  const [dataIsLoading, setDataIsLoading] = useState(true)
  const [data, setData] = useState(loadingData)
  const [facets, setFacets] = useState({})
  const [dataError, setDataError] = useState(false)
  const [pagination, setPagination] = useState({})
  const [searchQueryParams, setSearchQueryParams] = useState({})
  const { currentRoute, previousRoute } = useSelector(
    state => state.routeHistory,
  )

  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()
  const query = match.params.query

  const page = match.params.page
  const hasQuery = Boolean(query)

  const pageValue = page === undefined ? 1 : page
  const rootUri = `/gebouwpaspoort/search-results/${query}`
  const numberOfItems = 20

  const nameTypeMap = useSelector(state => state?.filtercatalog?.nameTypeMap)
  const filterCatalog = useSelector(state => state.filtercatalog)
  const { filter, sortBy, sortDirection } = useSelector(
    state => state.comparison,
  )

  const filterValues = useMemo(() => {
    const params = new URLSearchParams(filter)
    params.delete('sortBy')
    params.delete('sortDirection')
    return searchParamsToObject(params)
  }, [filter])

  const { type: searchObjType, label: searchObjLabel } =
    useSearchQueryResolver(query)

  useEffect(() => {
    dispatch(removeExportButton())
  }, [dispatch])

  /** Set the page title and display search bar */
  useEffect(() => {
    dispatch(setPageTitle({ pageValue, query, totalPages }))
    dispatch(showSearchBar())
    return () => resetSearchBar()
  }, [dispatch, query, pageValue, totalPages])

  const { post } = useApiCall()

  /** Load new search results */
  useDebouncedEffect(
    () => {
      if (query) {
        setDataIsLoading(true)
        setData(loadingData)

        const location = {
          q: query === defaultSearchOption.id ? '' : query,
        }
        const filter = {
          ...formatFilterRequestData(filterValues, nameTypeMap),
          sortDirection,
          sortBy,
        }

        post({
          path: 'gebouwpaspoort/by-search',
          apiHostUrl: gebouwpaspoortApiHost(),
          body: {
            location,
            skip: pageValue * numberOfItems - numberOfItems,
            take: numberOfItems,
            filter,
          },
        })
          .then(response => {
            const { data, facets, ...pagination } = response
            const totalPages = Math.ceil(pagination.count / pagination.pageSize)
            const totalResults = response.count
            setData(data)
            setFacets(facets)
            setDataError(false)
            setPagination(pagination)
            setTotalPages(totalPages)
            setTotalResults(totalResults)
            setSearchQueryParams({ type: searchObjType, label: searchObjLabel })
          })
          .then(() => {
            setDataIsLoading(false)
          })
          .catch(e => {
            if (e.name !== 'AbortError') {
              setData([])
              setPagination({})
              setTotalPages(null)
              setDataIsLoading(false)
            }
          })
      }
    },
    [
      pageValue,
      post,
      query,
      searchObjType,
      searchObjLabel,
      filterValues,
      sortBy,
      sortDirection,
    ],
    300,
  )
  // as user navigates the session history, event in this hook handles the update of the filter data
  useOnPopState(() => {
    const params = new URLSearchParams(window.location.search)

    if (params.has('sortBy')) {
      dispatch(setSortBy(params.get('sortBy')))
    }
    if (params.has('sortDirection')) {
      dispatch(setSortDirection(params.get('sortDirection')))
    }
  }, true)

  useEffect(() => {
    // viewportHack()
    get('filtercatalog', { apiHostUrl: gebouwpaspoortApiHost() })
      .then(data => {
        const search = new URLSearchParams(window.location.search)
        const sortOption = data.sortOptions.find(({ selected }) => selected)
        const sortBy = defaultSearchOption.sortBy
        const sortDirection =
          sortOption?.defaultDirection || defaultSearchOption.sortDirection
        dispatch(setFilterCatalog(data))
        dispatch(setSortBy(search.get('sortBy') || sortBy))
        dispatch(setSortDirection(search.get('sortDirection') || sortDirection))
      })
      .catch(error => setFilterCatalog({ error }))
  }, [dispatch])

  const { ref } = useResizeObserver({
    onResize: ({ width }) => {
      window.requestAnimationFrame(() => {
        if (width >= 1250) {
          setResultSize('large')
        } else {
          setResultSize('small')
        }
      })
    },
  })

  /** Focus page title on route change */
  useEffect(() => {
    if (currentRoute && previousRoute) {
      if (currentRoute !== previousRoute) {
        const pageTitleElement = document.querySelector('h1')
        if (pageTitleElement) {
          pageTitleElement.tabIndex = '-1'
          pageTitleElement.focus()
        }
      }
    }
  }, [currentRoute, previousRoute])

  function handleFormChange(searchParams) {
    if (sortBy) {
      searchParams.append('sortBy', sortBy)
    }
    if (sortDirection) {
      searchParams.append('sortDirection', sortDirection)
    }

    history.push(`${rootUri}?${searchParams.toString()}`)
    dispatch(setNewFilterValue(searchParams.toString()))
  }

  function handleSortChange({ sortBy, sortDirection } = {}) {
    const params = new URLSearchParams(window.location.search)
    if (sortBy) {
      dispatch(setSortBy(sortBy))
      params.set('sortBy', sortBy)
    }
    if (sortDirection) {
      dispatch(setSortDirection(sortDirection))
      params.set('sortDirection', sortDirection)
    }
    history.push(`${rootUri}?${params}`)
  }

  return (
    <>
      <main ref={ref} className="search-results" data-result-size={resultSize}>
        <SkiplinkTarget
          id="search-results__filters"
          skiplinkLabel="Snel naar filters"
          className={`search-results__sidebar search-results__sidebar--position-left`}
        >
          <FilterBar
            filterCatalog={filterCatalog}
            filters={filterValues}
            filterSectionIsOpen={false}
            facets={facets}
            onSubmit={handleFormChange}
          />
        </SkiplinkTarget>
        <SkiplinkTarget
          id="search-results__content"
          className="search-results__content"
          skiplinkLabel="Snel naar zoekresultaten"
        >
          <Switch>
            <Route path="/gebouwpaspoort/search-results/:query">
              {hasQuery ? (
                dataError ? (
                  <p className="body-big">
                    We kunnen de data voor <q>{query}</q>{' '}
                    {pageValue > 1 && `op pagina ${pageValue}`} niet ophalen
                  </p>
                ) : (
                  <div className="gebouwpaspoort__search-results__content">
                    <h2 className="sr-only">Zoekresultaten</h2>
                    <SearchResultList
                      query={
                        searchQueryParams?.label || defaultSearchOption.label
                      }
                      type={searchQueryParams?.type}
                      hasResults={data?.length}
                      isLoading={dataIsLoading}
                      error={data?.error}
                      totalResults={totalResults}
                      filterOptions={
                        <SortSelect
                          sortBy={sortBy}
                          sortDirection={sortDirection}
                          sortOptions={filterCatalog.sortOptions}
                          onChangeSort={sortBy => handleSortChange({ sortBy })}
                          onChangeDirection={sortDirection =>
                            handleSortChange({ sortDirection })
                          }
                        />
                      }
                      autoSizedRows
                    >
                      {data.map(item => (
                        <SearchResultItemGebouwpaspoort
                          key={item.id}
                          item={item}
                          isLoading={dataIsLoading}
                        />
                      ))}
                    </SearchResultList>
                    <Paginaton
                      page={Number(pageValue)}
                      count={pagination?.count}
                      pageSize={numberOfItems}
                      rootUri={rootUri}
                      queryParams={location.search}
                    />
                  </div>
                )
              ) : (
                <NoQueryProvided />
              )}
            </Route>
          </Switch>
        </SkiplinkTarget>
        <div></div>
      </main>
    </>
  )
}
