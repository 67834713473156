import { createSlice } from '@reduxjs/toolkit'

const mapBig = createSlice({
  name: 'mapBig',
  initialState: {
    longitude: undefined,
    latitude: undefined,
    zoom: undefined,
    initialBounds: undefined,
  },
  reducers: {
    setViewport: (state, { longitude, latitude, zoom }) => {
      Object.assign(state, { longitude, latitude, zoom })
    },
    setInitialBounds: (state, { payload }) => {
      state.initialBounds = payload
    },
    retriggerInitialBounds: state => {
      state.initialBounds = { ...state.initialBounds }
    },
  },
})

export const { setViewport, setInitialBounds, retriggerInitialBounds } =
  mapBig.actions

export default mapBig.reducer
