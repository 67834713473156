import React from 'react'
import { Link as PDFLink } from '@react-pdf/renderer'

export default function Link({ style, fixed, children, debug, src }) {
  return (
    <PDFLink
      src={src}
      fixed={fixed}
      debug={debug}
      style={[{ lineHeight: 1.5, fontSize: 11, color: '#000' }, { ...style }]}
    >
      {children}
    </PDFLink>
  )
}
