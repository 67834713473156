import React from 'react'
import { Box, Typography } from './index'

/** EnergyLabel pdf component
 * @typedef {Object} EnergyLabelProps
 * @param label
 * @param fontSize
 * @param backgroundColor
 * @param debug
 * @returns {JSX.Element}
 * @constructor
 */
export default function EnergyLabel({
  label = '---',
  fontSize = 11,
  backgroundColor = '#ffffff',
  debug,
}) {
  const maxLabelLength = 10
  let labelLength, labelColor

  switch (label) {
    case 'A++++':
      labelLength = 0
      labelColor = '#15862e'
      break
    case 'A+++':
      labelLength = 1
      labelColor = '#15862e'
      break
    case 'A++':
      labelLength = 2
      labelColor = '#15862e'
      break
    case 'A+':
      labelLength = 3
      labelColor = '#15862e'
      break
    case 'A':
      labelLength = 4
      labelColor = '#15862e'
      break
    case 'B':
      labelLength = 5
      labelColor = '#4f9e2b'
      break
    case 'C':
      labelLength = 6
      labelColor = '#a6b707'
      break
    case 'D':
      labelLength = 7
      labelColor = '#ffe700'
      break
    case 'E':
      labelLength = 8
      labelColor = '#e99f00'
      break
    case 'F':
      labelLength = 9
      labelColor = '#e65d19'
      break
    case 'G':
      labelLength = 10
      labelColor = '#d62b21'
      break
    default:
      labelLength = 2
      labelColor = '#dddddd'
      break
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
      debug={debug}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingHorizontal: fontSize / 2,
          paddingTop: fontSize / 4,
          paddingBottom: 0,
          backgroundColor: labelColor,
        }}
        debug={debug}
      >
        <Typography
          style={{
            fontSize: fontSize,
            color: 'white',
            fontWeight: '600',
          }}
          debug={debug}
        >
          {label}
        </Typography>
      </Box>
      <Box
        style={{
          height: '100%',
          width: `${(70 / maxLabelLength) * labelLength}%`,
          transform: 'translateX(-0.25px)', // Fix for half pixel gaps
          backgroundColor: labelColor,
        }}
        debug={debug}
      ></Box>
      <Box
        style={{
          height: '100%',
          transform: 'translateX(-0.5px)',
          border: `${fontSize * 0.9} solid ${backgroundColor}`,
          borderLeftColor: labelColor,
        }}
        debug={debug}
      ></Box>
    </Box>
  )
}
