import React, { forwardRef, useEffect, useState } from 'react'
import Panel from '@brainbay/components/components/panel'
import DataMap from '@brainbay/components/components/data-map'
import CharacteristicsList from '@brainbay/components/components/characteristics-list'
import formatValue from '@brainbay/components/utils/format-value'
import NotificationPanel from '@brainbay/components/components/notification-panel'
import titleCase from '../../utils/title-case'
import './building-ruimtelijkeplannen-map.css'

function valueRenderer(value, index, item) {
  switch (item.type) {
    case 'url':
      return (
        <a href={value} target="_blank" rel="noopener noreferrer">
          {item.hrefLabel}
        </a>
      )
    case 'date':
      return formatValue(value, {
        format: 'date',
      })
    case 'hoofdgroep':
      const valueAsClassName = value.replace(/\s+/g, '-')
      return (
        <div className={`ruimtelijke-plannen__hoofdgroep ${valueAsClassName}`}>
          {value}
        </div>
      )
    default:
      return titleCase(value)
  }
}

const BuildingRuimtelijkePlannenMap = forwardRef(function BuildingWalkScore(
  { data, formattedAddress },
  ref,
) {
  const [address, setAddress] = useState({})
  const [hasLoaded, setHasLoaded] = useState(false)

  const ruimtelijkePlannenFromStore = data?.pand?.ruimtelijkePlannen
  const ruimtelijkePlannen =
    ruimtelijkePlannenFromStore?.length > 0
      ? ruimtelijkePlannenFromStore.map((ruimtelijkePlan, index) => ({
          items: [
            {
              label: 'Bestemmings hoofdgroep',
              type: 'hoofdgroep',
              value: ruimtelijkePlan.bestemmingshoofdgroep,
            },
            {
              label: 'Plan status',
              type: 'text',
              value: ruimtelijkePlan.planstatus,
            },
            {
              label: 'Type plan',
              type: 'text',
              value: ruimtelijkePlan.typeplan,
            },
            {
              label: 'Datum',
              type: 'date',
              value: ruimtelijkePlan.datum,
            },
            {
              label: 'Planregels',
              type: 'url',
              hrefLabel: 'Bekijk bestemmingsplan',
              value: ruimtelijkePlan.url,
            },
          ],
          title: `< ${index + 1} van ${ruimtelijkePlannenFromStore?.length} >`,
        }))
      : undefined

  useEffect(() => {
    if (data && !data.loading) {
      setAddress(data?.geselecteerdeAdres)
      setHasLoaded(true)
    }
  }, [data])
  const mapboxConfig = {
    scrollZoom: false,
  }
  return (
    <div ref={ref}>
      <Panel
        title="Ruimtelijke Plannen"
        tag="section"
        collapsable
        divider
        isOpenByDefault
      >
        <NotificationPanel
          tag="p"
          notification={`<span>
          Let op, de informatie van Ruimtelijke Plannen is mogelijk niet volledig en verouderd. Maak gebruik van het
          <a
            href="https://omgevingswet.overheid.nl/regels-op-de-kaart/documenten/locatie?regelsandere=regels&locatie=${formattedAddress}"
            target="_blank"
            rel="noopener noreferrer"
          >
          Omgevingsloket 
          </a>
          voor een volledig beeld van de geldende regels.
        </span>`}
          className="bidding-graph__notification"
        />
        {hasLoaded && (
          <DataMap
            longitude={address?.longitude}
            latitude={address?.latitude}
            mapboxConfig={mapboxConfig}
            width="100%"
            height="450px"
            zoom={17}
            mapBoxStyle="mapbox://styles/mapbox/light-v11"
            title="Enkelbestemming overzichtskaart"
            showMarker
            showRuimtelijkePlannenMap
            showKadaster
            showBAGPand
            showControls
            className="map-building"
            mapId="ruimtelijkePlannenMap"
          />
        )}
        {ruimtelijkePlannen && (
          <>
            <h3 className="bestemmingplannen-title text-bold">
              Geldende bestemmingplannen
            </h3>
            <CharacteristicsList
              subHeadingClassName="bestemmingplannen-title__subtitle text-bold"
              data={ruimtelijkePlannen}
              loading={data?.loading}
              valueRenderer={valueRenderer}
            />
          </>
        )}
      </Panel>
    </div>
  )
})

export default BuildingRuimtelijkePlannenMap
