import React from 'react'
import './source-label.css'

export default function SourceLabel({ prefixSource, source, isSmall = false }) {
  return (
    <div className="source-label">
      <span
        className={`source-label__label body text-color--dim-text loading-state ${
          isSmall ? 'body-small' : ''
        }`}
      >
        {source && prefixSource && `${prefixSource}: `}
        {source}
      </span>
    </div>
  )
}
