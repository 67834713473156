import React from 'react'
import { View as PDFView } from '@react-pdf/renderer'

export default function Box({ style, children, fixed, wrap }) {
  return (
    <PDFView style={style} wrap={wrap} fixed={fixed}>
      {children}
    </PDFView>
  )
}
