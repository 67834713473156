import React from 'react'
import { Image } from '@react-pdf/renderer'
import { Box, PageHeader } from '../components'
import DefaultLayout from '../layouts/default'

export default function PandLuchtFotoAndOmgevinskaartenPage({
  debug,
  isGebouwpaspoort,
  userName,
  date,
  exportSettings,
  luchtfotoMapImage,
  omgevingskaartenMapImage,
}) {
  const isShowLuchtFoto =
    !exportSettings.includes('luchtfoto') && luchtfotoMapImage
  const isShowOmgevinskaarteenFoto =
    !exportSettings.includes('omgevingskaarten') && omgevingskaartenMapImage

  return (
    <>
      <DefaultLayout
        hideHeader={true}
        userName={userName}
        date={date}
        debug={debug}
        isGebouwpaspoort={isGebouwpaspoort}
      >
        {isShowLuchtFoto && (
          <>
            <PageHeader pageName="Luchtfoto" />
            <Box
              style={{
                width: '100%',
                position: 'relative',
              }}
            >
              <Image
                style={{
                  width: '100%',
                  height: 'auto',
                  position: 'relative',
                  padding: 6,
                }}
                src={luchtfotoMapImage}
                allowDangerousPaths
                break
              ></Image>
            </Box>
          </>
        )}
        {isShowOmgevinskaarteenFoto && (
          <>
            <PageHeader
              pageName="Omgevingskaarten"
              top={isShowLuchtFoto ? 320 : 20}
            />
            <Box>
              <Box
                style={{
                  width: '100%',
                  position: 'relative',
                  marginTop: isShowLuchtFoto ? 100 : 10,
                }}
              >
                <Image
                  style={{
                    width: '100%',
                    height: 'auto',
                    position: 'relative',
                    padding: 6,
                  }}
                  src={omgevingskaartenMapImage}
                  allowDangerousPaths
                  break
                ></Image>
              </Box>
            </Box>
          </>
        )}
      </DefaultLayout>
    </>
  )
}
