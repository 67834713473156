import React, { useEffect, useState } from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import ExpandableCharacteristicsList from '../expandable-characteristics-list/expandable-characteristics-list'
import Panel from '@brainbay/components/components/panel'
import EnergieLabel from '@brainbay/components/components/energy-label'
import StatusLabel from '@brainbay/components/components/status-label'
import Modal from '@brainbay/components/components/modal'

import './object-characteristics.css'

function valueRenderer(value, index, item) {
  switch (item.type) {
    case 'energielabel':
      return <EnergieLabel label={value} />
    case 'status':
      return <StatusLabel status={value} />
    case 'text':
    default:
      return value
  }
}

export default function ObjectCharacteristics({ data, baseUrl, onCloseModal }) {
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    if (data && !data.loading) {
      setHasLoaded(true)
    }
  }, [data])

  function modalRenderer(item) {
    if (!item.moreInfo) return null

    return (
      <>
        <Link
          className="object-characteristics__more-info print-excluded"
          to={`${baseUrl}/info/${item.moreInfo.slug}`}
        >
          Meer info
        </Link>
        <Switch>
          <Route path={`${baseUrl}/info/${item.moreInfo.slug}`}>
            <Modal title={item.moreInfo.title} onClose={onCloseModal}>
              {item.moreInfo.description}
            </Modal>
          </Route>
        </Switch>
      </>
    )
  }

  return (
    <Panel title="Details" className="object-characteristics">
      {hasLoaded ? (
        <ExpandableCharacteristicsList
          headingLevel={2}
          items={data?.kenmerken}
          loading={data?.loading}
          valueRenderer={valueRenderer}
          modalRenderer={modalRenderer}
        />
      ) : (
        <p>Er kunnen geen kenmerken opgehaald worden op dit moment</p>
      )}
    </Panel>
  )
}
