import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Panel from '@brainbay/components/components/panel'
import ModalImageViewer from '@brainbay/components/components/modal-image-viewer'
import ObjectDetails from '@brainbay/components/components/object-details'
import ObjectDetailsDefinitionList from '@brainbay/components/components/object-details-definition-list'
import ObjectDetailLink from '@brainbay/components/components/object-detail-link'
import EnergyLabel from '@brainbay/components/components/energy-label'
import AddButton from '@brainbay/components/components/add-button'
import formatAddressBusiness from '../../utils/format-address-business'
import formatValue from '@brainbay/components/utils/format-value'
import useObjectPrices from '../../utils/use-object-prices'
import useObjectDate from '../../utils/use-object-date'
import useObjectSurface from '../../utils/use-object-surface'
import useUserData from '../../utils/use-user-data'
import formatObjectFunction from '../../utils/format-object-function'
import setTitleForObjectDetails from '../../utils/set-title-object-details'
import Icon from '@brainbay/components/components/icon'
import { ReactComponent as IconInfo } from '@brainbay/components/_assets/svg/icon-info.svg'
import PriceCorrectionPopup from '../../components/price-correction-popup'
import StatusRegistration from '../status-registration/status-registration'

import './object-object-detail.css'
import FluxModalComponent from '../flux-connection-modal/flux-connection-modal'

const loadingItems = [
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  <EnergyLabel isLoading={true} />,
]

export default function ObjectObjectDetail({
  data,
  baseUrl,
  onCloseModal,
  hasBeenAdded,
  toggleComparison = () => {},
}) {
  const [address, setAddress] = useState('')
  const [hasLoaded, setHasLoaded] = useState(false)
  const [savedRecentVisits, setSavedRecentVisits] = useState(false)
  const baseImagesUrl = `${baseUrl}/images`
  const [primaryPrice, secondaryPrice] = useObjectPrices(data)
  const date = useObjectDate(data)
  const surface = useObjectSurface(data)
  const { setUserData } = useUserData()
  const routeHistory = useSelector(state => state.routeHistory)
  const compareList = useSelector(state => state.comparison.compareList)
  const fromSearch = routeHistory?.previousRoute?.indexOf('search-results')
  const isKadasterPortfolioTransaction =
    data?.bron === 'Kadaster' && data?.isPortefeuille

  const recentVisitsFromStore = useSelector(
    state => state.user?.data?.recentVisits,
  )

  const recentVisits = useMemo(
    () => (Array.isArray(recentVisitsFromStore) ? recentVisitsFromStore : []),
    [recentVisitsFromStore],
  )

  const saveRecentVisits = useCallback(
    (id, objectGuid, mediaGuid, address, addressId) => {
      const currentArray = recentVisits
      const currentObject = currentArray.find(item =>
        item.id ? item.id === id : item.objectGuid === objectGuid,
      )

      if (fromSearch !== 1 && !currentObject) {
        return
      }

      const date = new Date()

      const newObject = {
        id,
        objectGuid,
        address,
        mediaGuid,
        addressId,
        date: String(date),
      }

      const newArray = [
        newObject,
        ...currentArray.filter(item =>
          item.id ? item.id !== id : item.objectGuid !== objectGuid,
        ),
      ].slice(0, 3)

      setUserData('recent-visits', newArray)
    },
    [fromSearch, recentVisits, setUserData],
  )

  useEffect(() => {
    const address = formatAddressBusiness({
      street: data?.straatnaam,
      houseNumber: data?.huisnummer,
      houseNumberSuffix: data?.huisnummertoevoeging,
      postalCode: data?.postcode,
      city: data?.woonplaats,
      municipality: data?.gemeente,
      noStreetReturnValue:
        data?.bron === 'Kadaster'
          ? `Een perceel of appartementsrecht in de gemeente ${data?.gemeente}`
          : '',
    })
    if (address !== '---') setAddress(address)

    if (data && !data.loading) {
      setHasLoaded(true)
    }
  }, [data])

  useEffect(() => {
    if (
      address &&
      (recentVisits.length || fromSearch > -1) &&
      !savedRecentVisits
    ) {
      setSavedRecentVisits(true)

      saveRecentVisits(
        data?.id,
        data?.objectGuid,
        data?.mediaGuid,
        address,
        data?.adres,
      )
    }
  }, [
    recentVisits,
    savedRecentVisits,
    fromSearch,
    recentVisitsFromStore,
    saveRecentVisits,
    data,
    address,
  ])

  const objectFunctions = formatObjectFunction(data)
  const overstukId = data?.overstukId
  //extract parts of overstukId to construct kadaster url
  let kadasterUrl = null
  if (overstukId) {
    const match = overstukId.match(/NL(\d+)\s+(\d{5})(\d+)/)
    if (match) {
      const register = match[1]
      const deel = match[2]
      let nummer = match[3]
      nummer = nummer.replace(/^00/, '')
      kadasterUrl = `https://kadaster-on-line.kadaster.nl/bronsel.asp?deel=${deel}&nummer=${nummer}&register=${register}&z=HYPERLINK%20STUK`
    }
  }
  const KadasterPortefeuilleRemark = data => {
    if (data?.bron === 'Kadaster' && data?.isPortefeuille) {
      return (
        <div className="text-color--dim-text">
          {'Let op: meer onroerend goed betrokken, zie betrokken objecten.'}
        </div>
      )
    }
  }

  return (
    <Panel
      title={setTitleForObjectDetails(data?.status)}
      tag="section"
      fullBleed
      divider={false}
      action={
        <AddButton
          hideLabel
          labelAdd="Voeg to aan vergelijking"
          labelRemove="Verwijder uit vergelijking"
          onClick={() => toggleComparison(data?.objectGuid)}
          isAdded={compareList.includes(data?.objectGuid)}
        />
      }
    >
      <Switch>
        <Route path={`${baseImagesUrl}/:mediaGuid?`}>
          <ModalImageViewer
            onClose={onCloseModal}
            imagesBaseUrl={baseImagesUrl}
            mediaGuids={data?.mediaGuids}
            isLoading={data?.loading}
          />
        </Route>
        <FluxModalComponent />
      </Switch>

      <ObjectDetails
        imagesBaseUrl={baseImagesUrl}
        address={address}
        housingTypes={objectFunctions ? [objectFunctions] : []}
        mediaGuids={data?.mediaGuids}
        status={data?.status}
        isLoading={data?.loading}
        linkSlot={
          data?.nummeraanduidingId && (
            <ObjectDetailLink
              guid={data?.objectGuid}
              url={`/gebouwpaspoort/${data?.nummeraanduidingId}`}
              type="gebouwpaspoort"
            >
              Bekijk gebouwpaspoort
            </ObjectDetailLink>
          )
        }
        duration={data?.transactie?.looptijd ?? data?.looptijd}
        prefixSource="Bron"
        source={data?.bron}
        registrationStatus={
          data?.controleStatus ? (
            <StatusRegistration
              controleStatus={data.controleStatus}
              prefixControleStatus="Controlestatus"
              data={data}
            />
          ) : (
            KadasterPortefeuilleRemark(data)
          )
        }
      >
        <span
          className="sr-only"
          id="object-object-details__object-kenmerken-description"
        >
          Object kenmerken
        </span>

        {!hasLoaded && (
          <ObjectDetailsDefinitionList>
            <dl>
              {loadingItems.map((loadingItem, index) => (
                <React.Fragment key={index}>
                  <dt></dt>
                  <dd>{loadingItem}</dd>
                </React.Fragment>
              ))}
            </dl>
          </ObjectDetailsDefinitionList>
        )}

        {hasLoaded && (
          <ObjectDetailsDefinitionList>
            <dl aria-labelledby="object-object-details__object-kenmerken-description">
              {primaryPrice && <dt>{primaryPrice.term}</dt>}
              {primaryPrice &&
                (data?.transactie?.originalPrijs ? (
                  <dd>
                    <div className="price-definition-popup">
                      <PriceCorrectionPopup data={data}>
                        {primaryPrice.definition}
                        <Icon circle size="nano" className="info__icon">
                          <IconInfo />
                        </Icon>
                      </PriceCorrectionPopup>
                    </div>

                    {primaryPrice.perM2 && (
                      <span className="body text-dim">
                        {primaryPrice.perM2}
                      </span>
                    )}
                  </dd>
                ) : (
                  <dd>
                    {primaryPrice.definition}
                    <br />
                    {kadasterUrl && (
                      <a
                        className="object-details__kadaster__link"
                        href={kadasterUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Koop akte
                      </a>
                    )}
                    {primaryPrice.perM2 && (
                      <span className="body text-dim">
                        {primaryPrice.perM2}
                      </span>
                    )}
                  </dd>
                ))}

              {secondaryPrice && <dt>{secondaryPrice.term}</dt>}
              {secondaryPrice && (
                <dd>
                  {secondaryPrice.definition}
                  <br />
                  {secondaryPrice.perM2 && (
                    <span className="body text-dim">
                      {secondaryPrice.perM2}
                    </span>
                  )}
                </dd>
              )}
              <dt>{surface.term}</dt>
              <dd>{surface.definition}</dd>
              <dt>Perceeloppervlakte</dt>
              <dd>{formatValue(data?.perceelOppervlakte, 'meter-squared')}</dd>
              <dt>{date.term}</dt>
              <dd>{date.definition}</dd>
              <dt>
                {isKadasterPortfolioTransaction ? 'Bouwjaar adres' : 'Bouwjaar'}
              </dt>
              <dd>{data?.bouwjaar || '---'}</dd>
              <dt>
                {isKadasterPortfolioTransaction
                  ? 'Energielabel adres'
                  : 'Energie label'}
              </dt>
              <dd>
                <EnergyLabel
                  isLoading={!hasLoaded}
                  label={data?.energielabel}
                />
              </dd>
            </dl>
          </ObjectDetailsDefinitionList>
        )}
      </ObjectDetails>
    </Panel>
  )
}
