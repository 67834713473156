import React from 'react'
import { Image } from '@react-pdf/renderer'
import { Box, Typography } from './index'

import brainbayLogo from '../../../_assets/images/pdf/brainbay-logo.jpg'
import nvmLogo from '../../../_assets/images/pdf/nvm-logo.jpg'

export default function PageFooter({
  userName,
  date,
  hideText,
  hasTransaction,
  debug,
  isGebouwpaspoort,
}) {
  return (
    <Box
      style={{
        position: 'absolute',
        bottom: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 596,
        height: 60,
        paddingHorizontal: 35,
      }}
      debug={debug}
      fixed
    >
      <Image source={brainbayLogo} style={{ height: 20 }} debug={debug} />

      {!hideText && (
        <Typography style={{ fontSize: 10, color: '#888888' }} debug={debug}>
          {hasTransaction
            ? 'Transactierapport'
            : isGebouwpaspoort
            ? 'Gebouwpaspoortrapport'
            : 'Stamkaart'}
          . Gemaakt door {userName}, {date}
        </Typography>
      )}

      <Image source={nvmLogo} style={{ height: 20 }} debug={debug} />
    </Box>
  )
}
