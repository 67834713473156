import React, { lazy, Suspense, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setViewport } from '../../store/map-big'

import './map-big.css'
import { defaultSearchOption } from '../../utils/constants'

const Map = lazy(() => import('../mapbox/mapbox'))

export default function MapBig({
  filters = {},
  query = '',
  onChangedHighlightedFeature,
  highlightedFeatures,
  onViewportChange = () => {},
}) {
  const mapState = useSelector(state => state.mapBig)
  const params = useParams()
  const dispatch = useDispatch()
  const idToken = useSelector(state => state?.user?.idToken)

  const tileQuery = useMemo(() => {
    const q = query === defaultSearchOption.id ? '' : 'query'
    const object = { filter: filters, q }
    const json = JSON.stringify(object)
    return window.btoa(json)
  }, [filters, query])

  function handleViewportChange(newViewport, ...rest) {
    const { longitude, latitude, zoom } = newViewport
    if (
      mapState.longitude !== longitude &&
      mapState.latitude !== latitude &&
      mapState.zoom !== zoom
    ) {
      dispatch(setViewport({ longitude, latitude, zoom }))
    }
    onViewportChange(newViewport, ...rest)
  }

  return (
    <Suspense fallback={<></>}>
      <Map
        className="map-big"
        tileQuery={tileQuery}
        apiAccessToken={idToken}
        onChangedHighlightedFeature={onChangedHighlightedFeature}
        highlightedFeatures={highlightedFeatures}
        onViewportChange={handleViewportChange}
        initialBounds={mapState.initialBounds}
        latitude={Number(mapState.latitude) || Number(params.lat)}
        longitude={Number(mapState.longitude) || Number(params.lng)}
        zoom={Number(mapState.zoom) || Number(params.zoom)}
      ></Map>
    </Suspense>
  )
}
