import { useCallback, useEffect, useRef } from 'react'
import {
  createAbortController,
  post as apiPost,
  get as apiGet,
} from './api-data'

export default function useApiCall() {
  const lastAbortController = useRef(null)

  const updateAbortController = useCallback(() => {
    if (lastAbortController.current) {
      lastAbortController.current.abort()
    }
    const abortController = createAbortController()
    lastAbortController.current = abortController
    return abortController
  }, [])

  const post = useCallback(
    (...args) => {
      return apiPost(...args, updateAbortController())
    },
    [updateAbortController],
  )

  const get = useCallback(
    (...args) => {
      return apiGet(...args, updateAbortController())
    },
    [updateAbortController],
  )

  const cancel = useCallback(() => {
    if (lastAbortController.current) {
      lastAbortController.current.abort()
    }
  }, [])

  useEffect(() => {
    return () => {
      /* eslint-disable */
      lastAbortController.current?.abort()
    }
  }, [])

  return {
    post,
    get,
    cancel,
  }
}
