const debugLogLevel = 4
const debugApiHost =
  process.env.REACT_APP_API_HOST || 'http://localhost:4000/api'
const debugAuthDomain = process.env.REACT_APP_DEBUG_AUTH_DOMAIN
const debugAuthClientId = process.env.REACT_APP_DEBUG_AUTH_CLIENT_ID
const debugMapboxToken = process.env.REACT_APP_DEBUG_MAPBOX_TOKEN
const debugMapboxStyle = process.env.REACT_APP_DEBUG_MAPBOX_STYLE
const debugDeployEnv = process.env.REACT_APP_DEBUG_DEPLOY_ENV || 'dev'
const debugApmServiceName = process.env.REACT_APP_APM_SERVICE_NAME
const debugApmServiceUrl = process.env.REACT_APP_APM_SERVICE_URL

export const logLevel =
  window.log_level === '__LOG_LEVEL__'
    ? debugLogLevel
    : Number(window.log_level)

export const apiHost = () => {
  const storedApiHost = window.sessionStorage.getItem('api_host')
  if (storedApiHost) {
    return storedApiHost
  }

  return window.api_host === '__API_HOST__' ? debugApiHost : window.api_host
}

export const mediaHost = `https://media.nvm.nl`

export const authDomain =
  window.auth_domain === '__AUTH_DOMAIN__'
    ? debugAuthDomain
    : window.auth_domain

export const authClientId =
  window.auth_client_id === '__AUTH_CLIENT_ID__'
    ? debugAuthClientId
    : window.auth_client_id

export const mapboxToken =
  window.mapbox_token === '__MAPBOX_TOKEN__'
    ? debugMapboxToken
    : window.mapbox_token

export const mapboxStyle =
  window.mapbox_style === '__MAPBOX_STYLE__'
    ? debugMapboxStyle
    : window.mapbox_style

export const deployEnv =
  window.deploy_env === '__DEPLOY_ENV__' ? debugDeployEnv : window.deploy_env

export const apmServiceName =
  window.apm_service_name === '__APM_SERVICE_NAME__'
    ? debugApmServiceName
    : window.apm_service_name

export const apmServiceUrl =
  window.apm_service_url === '__APM_SERVICE_URL__'
    ? debugApmServiceUrl
    : window.apm_service_url
