import React from 'react'
import DefaultLayout from '../layouts/default'
import { Typography, Box } from '../components'
import { StyleSheet } from '@react-pdf/renderer'
import formatAddressBusiness from '../../../utils/format-address-business'
import sortByProperties from '../../../utils/sort-by-properties'
import titleCase from '../../../utils/title-case'

import formatValue from '@brainbay/components/utils/format-value'

export default function ObjectBetrokkenPage({
  item,
  userName,
  date,
  hasTransaction,
  debug,
}) {
  const styles = StyleSheet.create({
    tableContainer: {
      display: 'flex',
      width: '100%',
    },
    tableHeader: {
      fontWeight: 'bold',
      borderBottomWidth: 1,
      borderColor: '#0099bc',
      padding: 6,
      textAlign: 'left',
      fontSize: 10,
      flex: 1,
    },
    tableRow: {
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottom: '1px solid #dddddd',
    },

    tableCell: {
      padding: 6,
      textAlign: 'left',
      fontSize: 9,
      flex: 1,
      wordWrap: 'break-word',
    },
  })
  function oppervlakteValueByLabel(object, label) {
    return object?.kenmerken
      ?.find(section => section?.title === 'Oppervlakten')
      ?.items?.find(item => item?.label === label)?.value
  }
  function breakLongPriceRange(priceRange) {
    if (priceRange !== undefined && priceRange !== null) {
      const parts = priceRange.split('-')
      if (parts.length === 2) {
        return parts.join('-\n')
      }
      return priceRange
    }
  }

  const sortedBetrokkenAddressList = [...item].sort((a, b) =>
    sortByProperties(a, b, [
      'straatnaam',
      'huisnummer',
      'huisnummertoevoeging',
    ]),
  )

  return (
    <DefaultLayout
      pageName="Betrokken objecten"
      userName={userName}
      date={date}
      hasTransaction={hasTransaction}
      debug={debug}
    >
      <Box style={styles.tableContainer}>
        <Box style={styles.tableRow}>
          <Typography
            style={{ ...styles.tableHeader, flex: 1.25 }}
            debug={debug}
          >
            Type
          </Typography>

          <Typography
            style={{ ...styles.tableHeader, flex: 2.25 }}
            debug={debug}
          >
            Adres
          </Typography>

          <Typography
            style={{ ...styles.tableHeader, flex: 1.25 }}
            debug={debug}
          >
            Oppervlakte verblijfsobject
          </Typography>

          <Typography style={styles.tableHeader} debug={debug}>
            Oppervlakte perceel
          </Typography>

          <Typography
            style={{ ...styles.tableHeader, flex: 1.25 }}
            debug={debug}
          >
            Prijs
          </Typography>

          <Typography
            style={{ ...styles.tableHeader, flex: 0.75 }}
            debug={debug}
          >
            Bouwjaar
          </Typography>

          <Typography style={styles.tableHeader} debug={debug}>
            Energie label
          </Typography>
        </Box>

        {sortedBetrokkenAddressList?.map((objectData, rowIndex) => {
          const verblijfsobjectOppervlakte = oppervlakteValueByLabel(
            objectData,
            'Verblijfsobject oppervlakte',
          )

          const perceelOppervlakte = oppervlakteValueByLabel(
            objectData,
            'Perceeloppervlakte',
          )

          return (
            <Box style={styles.tableRow} key={rowIndex} wrap={false}>
              <Typography
                style={{ ...styles.tableCell, flex: 1.25 }}
                debug={debug}
              >
                {objectData?.hoofdfunctie && titleCase(objectData.hoofdfunctie)}
              </Typography>

              <Typography
                style={{ ...styles.tableCell, flex: 2.25 }}
                debug={debug}
              >
                {!objectData?.straatnaam
                  ? 'Perceel of appartementsrecht'
                  : formatAddressBusiness({
                      street: objectData?.straatnaam,
                      houseNumber: objectData?.huisnummer,
                      houseNumberSuffix: objectData?.huisnummertoevoeging,
                    })}
              </Typography>

              <Typography
                style={{ ...styles.tableCell, flex: 1.25 }}
                debug={debug}
              >
                {verblijfsobjectOppervlakte
                  ? verblijfsobjectOppervlakte
                      .split('bron:BAG')
                      .map((part, index) => (
                        <React.Fragment key={index}>
                          {formatValue(part)}
                          {index === 0 ? <br /> : 'bron:BAG'.trim()}
                        </React.Fragment>
                      ))
                  : '---'}
              </Typography>

              <Typography style={styles.tableCell} debug={debug}>
                {perceelOppervlakte && formatValue(perceelOppervlakte)}
              </Typography>

              <Typography
                style={{ ...styles.tableCell, flex: 1.25 }}
                debug={debug}
              >
                {breakLongPriceRange(
                  objectData?.transactie?.koopprijs?.kadasterPrijsRange ||
                    '---',
                )}
              </Typography>

              <Typography
                style={{ ...styles.tableCell, flex: 0.75 }}
                debug={debug}
              >
                {objectData?.bouwjaar || '---'}
              </Typography>

              <Typography style={styles.tableCell} debug={debug}>
                {objectData?.energielabel || '---'}
              </Typography>
            </Box>
          )
        })}
      </Box>
    </DefaultLayout>
  )
}
