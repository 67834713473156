import React from 'react'
import formatValue from '@brainbay/components/utils/format-value'
import { Document } from './pdf/components'
import FrontPage from './pdf/pages/front-page'
import BuildingDetailPage from './pdf/pages/building-details-page'
import MediaPage from './pdf/pages/media-page'
import OfferTextPage from './pdf/pages/offer-text-page'
import CharacteristicsPage from './pdf/pages/characteristics-page'
import DisclaimerPage from './pdf/pages/disclaimer-page'
import PriceIndexPage from './pdf/pages/price-index-page'
import ObjectBetrokkenPage from './pdf/pages/object-betrokken-page'

export default function ObjectDetailPDF({
  user,
  object,
  exportSettings,
  priceIndex,
  debug,
  graphSVG,
  betrokkenObjectData,
}) {
  const userName = user?.user?.name || user?.user?.sub || '---'
  const date = formatValue(new Date().toString(), { format: 'date' })
  const hasTransaction =
    object?.transactie && Object.keys(object.transactie).length > 0
  const isKadasterPortfolioTransaction =
    object?.bron === 'Kadaster' && object?.isPortefeuille

  return (
    <Document>
      <FrontPage
        item={object}
        userName={userName}
        date={date}
        debug={debug}
        hasTransaction={hasTransaction}
        isGebouwpaspoort={false}
        gebouwpaspoortImage={false}
      />
      {!exportSettings.includes('object-details') && (
        <BuildingDetailPage
          item={object}
          userName={userName}
          date={date}
          hasTransaction={hasTransaction}
          debug={debug}
          isKadasterPortfolioTransaction={isKadasterPortfolioTransaction}
        />
      )}
      {!exportSettings.includes('object-betrokken') &&
        isKadasterPortfolioTransaction && (
          <ObjectBetrokkenPage
            item={betrokkenObjectData}
            userName={userName}
            date={date}
            hasTransaction={hasTransaction}
            debug={debug}
          />
        )}
      {!exportSettings.includes('media') && (
        <MediaPage
          item={object}
          userName={userName}
          date={date}
          hasTransaction={hasTransaction}
          debug={debug}
        />
      )}
      {!exportSettings.includes('price-index') && (
        <PriceIndexPage
          item={object}
          priceIndex={priceIndex}
          userName={userName}
          date={date}
          debug={debug}
          hasTransaction={hasTransaction}
          graphSVG={graphSVG}
        />
      )}
      {!exportSettings.includes('aanbiedings-text') && (
        <OfferTextPage
          item={object}
          userName={userName}
          date={date}
          hasTransaction={hasTransaction}
          debug={debug}
        />
      )}
      {!exportSettings.includes('kenmerken') && (
        <CharacteristicsPage
          item={object}
          userName={userName}
          date={date}
          hasTransaction={hasTransaction}
          debug={debug}
        />
      )}
      <DisclaimerPage
        item={object}
        userName={userName}
        date={date}
        hasTransaction={hasTransaction}
        debug={debug}
      />
    </Document>
  )
}
