const debugApiHost =
  process.env.REACT_APP_GEBOUWPASPOORT_API_HOST ||
  'http://localhost:4000/gebouwpaspoort-api'

const debugApiHostConsent =
  process.env.REACT_APP_CONSENT_API_HOST || 'http://localhost:4000/consent-api'

export const gebouwpaspoortApiHost = () => {
  const storedApiHost = window.sessionStorage.getItem('gebouwpaspoort_host_api')
  if (storedApiHost) {
    return storedApiHost
  }

  return window.gebouwpaspoort_host_api === '__GEBOUWPASPOORT_API_HOST__'
    ? debugApiHost
    : window.gebouwpaspoort_host_api
}

export const consentApiHost = () => {
  const storedApiHost = window.sessionStorage.getItem('consent_host_api')
  if (storedApiHost) {
    return storedApiHost
  }

  return window.consent_host_api === '__CONSENT_API_HOST__'
    ? debugApiHostConsent
    : window.consent_host_api
}
