import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get } from '../utils/api-data'
import { gebouwpaspoortApiHost } from '../utils/business-environment-vars'

const load = createAsyncThunk(
  'buildings/load',
  nummeraanduidingId =>
    get(`gebouwpaspoort/by-nummeraanduidingid/${nummeraanduidingId}`, {
      apiHostUrl: gebouwpaspoortApiHost(),
    }),
  {
    condition: (nummeraanduidingId, { getState, extra }) => {
      const { buildings } = getState()
      if (buildings[nummeraanduidingId]) {
        return false
      }
    },
  },
)

const buildings = createSlice({
  name: 'buildings',
  initialState: {},
  reducers: {},
  extraReducers: {
    [load.pending]: (state, { meta }) => {
      const nummeraanduidingId = meta.arg
      const building = state[nummeraanduidingId]
      if (building) return state
      state[nummeraanduidingId] = { nummeraanduidingId, loading: true }
    },
    [load.fulfilled]: (state, { payload, meta }) => {
      state[meta.arg] = payload
    },
    [load.rejected]: (state, { payload, meta }) => {
      const nummeraanduidingId = meta.arg
      state[meta.arg] = {
        nummeraanduidingId,
        error: 'We konden het gebouw niet vinden',
      }
    },
  },
})

export const loadBuildingByNummeraanduidingId = load

export default buildings.reducer
