import React from 'react'
import Label from '../label'
import './select.css'

export function getActiveFilterLabels(control, filters) {
  const options = control.options

  const selectedOption = options.find(
    option =>
      option.value === filters[control.name] && !option.hideInActiveFilters,
  )

  if (!selectedOption) {
    return []
  }

  return [{ label: selectedOption.label, control }]
}

export function removeFilterValues(params, control, _optionValue) {
  delete params[control.name]
}

export default function Select({ control, filters, groupLabel, onChange }) {
  const filterValue = filters?.[control.name] || ''
  return (
    <>
      <Label
        htmlFor={`search-filters__control--${control.name}`}
        groupLabel={groupLabel}
        control={control}
      />
      <select
        id={`search-filters__control--${control.name}`}
        className="select__select"
        name={control.name}
        value={filterValue}
        onChange={onChange}
      >
        {control.options.map(option => (
          <option
            key={option.value || 'empty'}
            value={option.value}
            aria-label={option['sr-label']}
          >
            {option.label || option.value}
          </option>
        ))}
      </select>
    </>
  )
}
