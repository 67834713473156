import React from 'react'
import Icon from '../../icon'
import { ReactComponent as IconClose } from '../../../_assets/svg/icon-close.svg'
import { ReactComponent as IconPlus } from '../../../_assets/svg/icon-plus.svg'
import { ReactComponent as IconMinus } from '../../../_assets/svg/icon-minus.svg'
import './active-filter-list.css'

export default function ActiveFilterList({
  id,
  activeFilters,
  onRemove = () => {},
}) {
  return (
    <details id={id} className="active-filter-list body" open>
      <summary
        className="active-filter-list__summary"
        data-testid="active-filter-count"
      >
        Actieve filters ({activeFilters.length})
        {activeFilters.length ? (
          <Icon size="nano">
            <IconPlus className="active-filter-list__summary-icon--plus" />
            <IconMinus className="active-filter-list__summary-icon--minus" />
          </Icon>
        ) : null}
      </summary>
      <ul className="active-filter-list__list">
        {activeFilters.map(item => (
          <li key={item.label} className="active-filter-list__list-item">
            <button onClick={() => onRemove(item)}>
              <span className="sr-only">Verwijder filter </span>
              {item.label}
              &nbsp;
              <Icon size="nano">
                <IconClose />
              </Icon>
            </button>
          </li>
        ))}
      </ul>
    </details>
  )
}
