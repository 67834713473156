import React from 'react'
import { Page, Box, PageFooter, Typography, ImageBox } from '../components'
import { getImageUrl, getObjectAddress } from '../helpers'

export default function FrontPage({
  item,
  userName,
  date,
  hasTransaction,
  debug,
  isGebouwpaspoort = false,
  gebouwpaspoortImage = false,
}) {
  const address = getObjectAddress(item, isGebouwpaspoort)

  const imageDataUrl = getImageUrl(item, gebouwpaspoortImage)

  return (
    <Page debug={debug}>
      <Typography
        style={{
          fontSize: 18,
          color: '#000',
          fontWeight: 700,
          textAlign: 'center',
          marginBottom: 20,
        }}
        debug={debug}
      >
        {hasTransaction
          ? 'Transactierapport'
          : isGebouwpaspoort
          ? 'Gebouwpaspoort'
          : 'Stamkaart'}
      </Typography>
      <Box
        style={{
          position: 'absolute',
          top: 170,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 596,
          height: 120,
          backgroundColor: '#0099bc',
          marginBottom: 30,
        }}
        debug={debug}
      >
        <Typography
          style={{
            fontSize: 20,
            color: '#fff',
            fontWeight: 700,
            textAlign: 'center',
          }}
          debug={debug}
        >
          {address}
        </Typography>
        <Typography
          style={{
            fontSize: 16,
            color: '#fff',
            textAlign: 'center',
          }}
          debug={debug}
        >
          Gemaakt door {userName}, {date}
        </Typography>
      </Box>
      <Box
        debug={debug}
        style={{
          position: 'absolute',
          bottom: 120,
          width: 500,
          left: 48,
        }}
      >
        <ImageBox
          src={imageDataUrl}
          style={{
            width: '100%',
            height: 380,
            display: 'flex',
            margin: '0 auto',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          allowDangerousPaths
          break
        />
      </Box>
      <PageFooter hideText />
    </Page>
  )
}
