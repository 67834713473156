import React, { forwardRef, useEffect, useState } from 'react'
import ExpandableCharacteristicsList from '../expandable-characteristics-list/expandable-characteristics-list'
import Panel from '@brainbay/components/components/panel'
import SourceLabel from '@brainbay/components/components/source-label'
import formatAddressBusiness from '../../utils/format-address-business'
import sortByProperties from '../../utils/sort-by-properties'

import './building-addresses.css'

function valueRenderer(value, index, item) {
  if (item.isNevenAdres) {
    return <span className="building-addresses__value">{value}</span>
  }

  return value
}

function labelRenderer(label, index, item) {
  if (item.isNevenAdres) {
    return <span className="building-addresses__label">{label}</span>
  }

  return label
}

const BuildingAddresses = forwardRef(function BuildingAddresses({ data }, ref) {
  const [building, setBuilding] = useState({})
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    if (data && !data.loading) {
      const { pand } = data || {}
      setBuilding(pand)
      setHasLoaded(true)
    }
  }, [data])

  const addresses =
    building?.verblijfsobjecten?.length &&
    building?.verblijfsobjecten
      .flatMap(verblijfsobject => {
        if (verblijfsobject.nevenAdressen)
          return [verblijfsobject.hoofdAdres, ...verblijfsobject.nevenAdressen]

        return [verblijfsobject.hoofdAdres]
      })
      .sort((a, b) =>
        sortByProperties(a, b, [
          'straatnaam',
          'huisnummer',
          'huisnummertoevoeging',
        ]),
      )
      .map(address => {
        return {
          label: `${address.isNevenAdres ? 'Nevenadres' : 'Hoofdadres'}`,
          isNevenAdres: address.isNevenAdres,
          type: 'text',
          value: formatAddressBusiness({
            street: address.straatnaam,
            houseNumber: address.huisnummer,
            houseNumberSuffix: address.huisnummertoevoeging,
            postalCode: address.postcode,
            city: address.woonplaats,
            municipality: address.gemeente,
          }),
        }
      })

  const addressesData = [
    {
      title: `${addresses?.length > 1 ? 'Adressen' : 'Adres'}`,
      items: addresses,
    },
  ]

  return (
    <div ref={ref}>
      <Panel
        title={`Betrokken adressen (${addresses?.length ?? 0})`}
        className="building-addresses"
        tag="section"
        fullBleed
        collapsable
        divider
        isOpenByDefault
      >
        {hasLoaded && (
          <div className={`building-addresses__content`}>
            <ExpandableCharacteristicsList
              loading={data?.loading}
              items={addressesData}
              heightLevel={addresses?.length === 1 ? 1 : null}
              valueRenderer={valueRenderer}
              labelRenderer={labelRenderer}
            />
          </div>
        )}
        <div className="building-addresses__source-label">
          <SourceLabel source="BAG" prefixSource="Bron" />
        </div>
      </Panel>
    </div>
  )
})
export default BuildingAddresses
