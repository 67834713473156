import React, { useEffect, useState, useMemo, useRef } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { generateObjectDetailPDFDocument } from '../utils/generate-pdf-document'
import { get } from '../utils/api-data'
import useReferences from '../utils/use-references'
import useOnPopState from '../utils/use-on-pop-state'
import includesOneOf from '../utils/string-helpers'
import setTitleForObjectDetails from '../utils/set-title-object-details'

import ObjectActionBar from '../components/object/object-action-bar'
import ExportObjectDetails from '../components/export-object-details'
import ObjectBroker from '../components/object/object-broker'
import ObjectCharacteristics from '../components/object/object-characteristics'
import ObjectDetails from '../components/object/object-object-detail'
import ObjectDescription from '../components/object/object-description'
import ObjectReferences from '../components/object/object-references'
import ObjectSurroundings from '../components/object/object-surroundings'
import ObjectTransferTax from '../components/object/object-transfer-tax'
import ObjectPartialTransactions from '../components/object/object-partial-transactions'
import ObjectPriceIndex from '../components/object/object-price-index'
import ObjectBetrokkenAdresses from '../components/object/object-betrokken-address'

import { setObjectDetails as setPageTitle } from '../store/page-title'
import { resetSearchBar, showSearchBar } from '../store/display-search-bar'
import { loadObjectByObjectGuid } from '../store/objects'
import { showExportButton } from '../store/export-button'
import { setPriceIndex } from '../store/price-index'
import {
  deleteAllExcludedItems,
  deleteExcludedItem,
  setExcludedItem,
} from '../store/print-settings'
import {
  addObject,
  removeObject,
  objectsToCompareSelector,
  expandComparePanel,
  setSortReferencesBy,
  enableComparePanel,
} from '../store/comparison'
import { fetchBetrokkenObjectData } from '../store/betrokken-object'

import formatAddressBusiness from '../utils/format-address-business'
import PrintSettingsForm from '@brainbay/components/components/print-settings-form'
import Graph from '@brainbay/components/components/graph'

import './object.css'
import useReturnToSearchResultsPage from '../utils/use-return-to-search-results-page'

export default function ObjectView(props) {
  const history = useHistory()
  const objectsToCompare = useSelector(objectsToCompareSelector)
  const comparisonState = useSelector(state => state.comparison.comparePanel)
  const match = useRouteMatch()
  const objectGuid = match?.params?.objectGuid
  const dispatch = useDispatch()
  const userObject = useSelector(state => state?.user)
  const objectData = useSelector(state => state.objects[objectGuid])
  const compareList = useSelector(state => state.comparison.compareList)
  const priceIndex = useSelector(state => state.priceIndex)
  const baseUrl = `/${objectGuid}`
  const { referencesAreLoading, referencesData, referencesError } =
    useReferences(objectGuid)
  const searchResultsUrl = useReturnToSearchResultsPage(baseUrl)
  const [expandPartialTransactions, setExpandPartialTransactions] =
    useState(false)
  const graphRef = useRef(null)
  const betrokkenObjectData = useSelector(fetchBetrokkenObjectData)

  const address = formatAddressBusiness({
    street: objectData?.straatnaam,
    houseNumber: objectData?.huisnummer,
    houseNumberSuffix: objectData?.huisnummertoevoeging,
    city: objectData?.woonplaats,
    postalCode: objectData?.postcode,
    municipality: objectData?.gemeente,
  })

  const excludedPrintItems = useSelector(state => state.printSettings)
  const hasTransaction =
    objectData?.transactie && Object.keys(objectData.transactie).length > 0

  const isValidHoofdfunctie = objectData =>
    includesOneOf(objectData?.hoofdfunctie, ['bedrijfs', 'kantoor', 'winkel'])

  const isKadasterPortfolioTransaction =
    objectData?.bron === 'Kadaster' && objectData?.isPortefeuille

  const showPriceIndex =
    objectData?.transactie?.status &&
    isValidHoofdfunctie(objectData) &&
    objectData?.bron !== 'Kadaster'

  async function handleExportPDF() {
    await generateObjectDetailPDFDocument(
      userObject,
      objectData,
      excludedPrintItems,
      `${hasTransaction ? 'Transactierapport' : 'Stamkaart'} ${address}.pdf`,
      priceIndex,
      graphRef,
      betrokkenObjectData,
    )
  }

  let exportObjectSections = useMemo(() => {
    const settings = [
      {
        key: 'object-details',
        name: setTitleForObjectDetails(objectData?.status),
      },
      ...(isKadasterPortfolioTransaction
        ? [
            {
              key: 'object-betrokken',
              name: 'Betrokken objecten',
            },
          ]
        : []),

      {
        key: 'media',
        name: 'Media',
      },
      {
        key: 'aanbiedings-text',
        name: 'Aanbiedingstekst',
      },
      {
        key: 'kenmerken',
        name: 'Details',
      },
    ]

    if (showPriceIndex) {
      dispatch(deleteExcludedItem('price-index'))
      settings.splice(2, 0, {
        key: 'price-index',
        name: 'Geïndexeerde koopsom',
      })
    } else {
      dispatch(setExcludedItem('price-index'))
    }

    return settings
  }, [
    dispatch,
    showPriceIndex,
    objectData?.status,
    isKadasterPortfolioTransaction,
  ])

  const hasBeenAdded = useMemo(() => {
    const objectsToCompareIds = objectsToCompare.map(
      ({ objectGuid }) => objectGuid,
    )
    return objectsToCompareIds.includes(objectGuid)
  }, [objectGuid, objectsToCompare])

  useOnPopState(() => {
    const params = new URLSearchParams(window.location.search)

    if (params.has('sortReferenceBy')) {
      dispatch(setSortReferencesBy(params.get('sortReferenceBy')))
    }
  }, true)

  const shortAddress = useMemo(
    () => {
      const address = formatAddressBusiness({
        postalCode: objectData?.postcode,
        street: objectData?.straatnaam,
        houseNumber: objectData?.huisnummer,
        houseNumberSuffix: objectData?.huisnummertoevoeging,
        municipality: objectData?.gemeente,
      })

      return address === '---' ? address : ''
    },
    /* eslint-disable react-hooks/exhaustive-deps  */ [
      objectData?.postcode,
      objectData?.huisnummer,
      objectData?.huisnummertoevoeging,
      objectData?.straatnaam,
      objectData?.gemeente,
    ],
  )

  function closeModal() {
    history.push(baseUrl)
  }

  function printPdf() {
    setExpandPartialTransactions(true)

    setTimeout(() => {
      // wait for the partial transactions to be expanded
      window.print()
    }, 500)
  }

  function onExportReset() {
    dispatch(deleteAllExcludedItems())
    closeModal()
  }

  function printSettingChange(event, setting) {
    event.target.checked
      ? dispatch(deleteExcludedItem(setting))
      : dispatch(setExcludedItem(setting))
  }

  function handleAddButton(guid) {
    dispatch(expandComparePanel())
    if (compareList.includes(guid)) {
      dispatch(removeObject(guid))
    } else {
      dispatch(addObject(guid))
    }
  }

  useEffect(() => {
    if (!objectData && objectGuid) {
      dispatch(loadObjectByObjectGuid(objectGuid))
    }
  }, [dispatch, objectData, objectGuid])

  useEffect(() => {
    dispatch(setPageTitle(shortAddress))
    dispatch(showSearchBar())

    return () => resetSearchBar()
  }, [dispatch, shortAddress])

  useEffect(() => {
    dispatch(showExportButton())
    dispatch(enableComparePanel())
  }, [dispatch])

  useEffect(() => {
    showPriceIndex &&
      get(`price-index/${objectGuid}`)
        .then(response => {
          if (response.data) {
            dispatch(setPriceIndex(response.data))
          } else {
            dispatch(setPriceIndex({}))
          }
        })
        .catch(() => {
          dispatch(setPriceIndex({}))
        })
  }, [dispatch, objectGuid, showPriceIndex])

  return (
    <>
      <main
        className={`object layout__grid ${
          comparisonState === 'expand' ? 'object--comparison-shown' : ''
        }`}
      >
        <ObjectActionBar
          searchResultsUrl={searchResultsUrl}
          data={objectData}
          baseUrl={baseUrl}
          onCloseModal={closeModal}
        />

        <ObjectDetails
          baseUrl={baseUrl}
          data={objectData}
          onCloseModal={closeModal}
          toggleComparison={handleAddButton}
          hasBeenAdded={hasBeenAdded}
        />

        {objectData && (
          <ObjectPartialTransactions
            data={objectData}
            expandPartialTransactions={expandPartialTransactions}
            showBuyerTenantName
          />
        )}
        {objectData?.isPortefeuille && (
          <ObjectBetrokkenAdresses data={objectData} />
        )}
        <div className="object__two-columns">
          <div className="object__spec-panels">
            {showPriceIndex && (
              <>
                <ObjectPriceIndex
                  data={objectData}
                  baseUrl={baseUrl}
                  priceIndex={priceIndex}
                  onCloseModal={closeModal}
                />

                {priceIndex && (
                  <div className="sr-only">
                    <Graph
                      innerRef={graphRef}
                      className="valuation-modal__graph-object-price"
                      valuationData={priceIndex?.prijsOntwikkelingsGrafiek}
                    />
                  </div>
                )}
              </>
            )}

            <ObjectDescription data={objectData} />
            <ObjectCharacteristics
              data={objectData}
              baseUrl={baseUrl}
              onCloseModal={closeModal}
            />
          </div>
          <div className="object__spec-panels">
            {objectData?.bron !== 'Kadaster' && (
              <ObjectBroker data={objectData} />
            )}
            <ObjectTransferTax
              data={objectData}
              baseUrl={baseUrl}
              onCloseModal={closeModal}
            />
            <ObjectSurroundings data={objectData} />
          </div>
        </div>

        <ObjectReferences
          className="print-excluded"
          isLoading={referencesAreLoading}
          data={referencesData}
          error={referencesError}
          objectGuid={objectGuid}
          onAddToggle={handleAddButton}
          compareList={compareList}
        />

        <ExportObjectDetails
          onClose={onExportReset}
          settings={
            <PrintSettingsForm
              onPrintPdf={printPdf}
              onFormSubmit={handleExportPDF}
              excludedPrintItems={excludedPrintItems}
              onChangeCheckbox={printSettingChange}
              objectSections={exportObjectSections}
              hideRemarks
            />
          }
        />
      </main>
    </>
  )
}
