import React from 'react'
import DefaultLayout from '../layouts/default'
import { Typography } from '../components'

export default function OfferTextPage({
  item,
  userName,
  date,
  hasTransaction,
  debug,
}) {
  return (
    <DefaultLayout
      pageName="Aanbiedingstekst"
      userName={userName}
      date={date}
      hasTransaction={hasTransaction}
      debug={debug}
    >
      <Typography
        style={{
          fontSize: 11,
          color: '#000',
        }}
        debug={debug}
      >
        {item.aanbiedingstekst}
      </Typography>
    </DefaultLayout>
  )
}
