import React, { useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setCompare as setPageTitle } from '../store/page-title'
import {
  addObject,
  removeObject,
  objectsToCompareSelector,
} from '../store/comparison'
import { resetSearchBar, hideSearchBar } from '../store/display-search-bar'
import CompareListItem from '../components/compare-list-item'
import SaveButton from '../components/save-button'
import Table from '@brainbay/components/components/table'
import Icon from '@brainbay/components/components/icon'
import AddButton from '@brainbay/components/components/add-button'
import Panel from '@brainbay/components/components/panel'
import IconLegend from '@brainbay/components/components/icon-legend'
import EnergyLabel from '@brainbay/components/components/energy-label'
import { ReactComponent as IconBouwjaar } from '@brainbay/components/_assets/svg/icon-bouwjaar.svg'
import { ReactComponent as IconWoonoppervlak } from '@brainbay/components/_assets/svg/icon-woonoppervlak.svg'
import { ReactComponent as IconPerceeloppervlak } from '@brainbay/components/_assets/svg/icon-perceeloppervlak.svg'

const CellWithLabel = ({
  label,
  value,
  className = '',
  isLoading,
  hideLabel = false,
  ariaLabel,
  icon,
}) => (
  <div className={`compare__table-cell-wrapper ${className}`.trim()}>
    <span
      className={`compare__table-cell-label body-small text-bold loading-state ${
        hideLabel ? 'sr-only' : ''
      }`.trim()}
      aria-label={ariaLabel}
    >
      {label}:
    </span>
    <span className="compare__table-cell-value body loading-state">
      {isLoading
        ? null
        : (
            <>
              {icon && <Icon size="tiny">{icon}</Icon>} {value}
            </>
          ) || <span>{icon && <Icon size="tiny">{icon}</Icon>}&nbsp;</span>}
    </span>
  </div>
)

export default function Landing() {
  const dispatch = useDispatch()
  const objectsToCompare = useSelector(objectsToCompareSelector)
  const previousUrl = useSelector(state => state?.routeHistory?.previousRoute)
  const filterString = useSelector(state => state.comparison.filter)
  const compareList = useSelector(state => state.comparison.compareList)

  const returnUrl = useMemo(() => {
    return /search-results/.test(previousUrl)
      ? `${previousUrl}?${filterString}`
      : '/'
  }, [filterString, previousUrl])

  function handleAddButton(guid) {
    if (compareList.includes(guid)) {
      dispatch(removeObject(guid))
    } else {
      dispatch(addObject(guid))
    }
  }

  useEffect(() => {
    dispatch(setPageTitle())
    dispatch(hideSearchBar())

    return () => dispatch(resetSearchBar())
  }, [dispatch])

  return (
    <>
      <SaveButton />
      <main className="compare layout__grid">
        {objectsToCompare.length === 0 ? (
          <div className="compare__no-entries">
            <p className="body-big">
              Er zijn nog geen objecten geselecteerd om te vergelijken.
            </p>
            <Link to={returnUrl} className="button button--secondary  ">
              Maak uw object selectie hier
            </Link>
          </div>
        ) : (
          <>
            <Link to={returnUrl} className="compare__link-back">
              &lt; Terug naar zoekresultaten
            </Link>
            <Panel titleTag="span" title="Vergelijking" action={<IconLegend />}>
              <div className="compare__table-wrapper">
                <Table
                  className="compare__table"
                  caption="Vergelijk objecten"
                  hideCaption
                  columns={objectsToCompare}
                  columnHeaders={objectsToCompare.map(
                    (item, index) => `Referentie #${index + 1}`,
                  )}
                  rows={[
                    {
                      columnProperty: 'summary',
                      render: (object, index, array) => (
                        <CompareListItem
                          className="compare__table-header-image"
                          style={{ zIndex: array.length - index }}
                          key={`${object.objectGuid}`}
                          isLoading={object.loading}
                          city={object.woonplaats}
                          houseNumber={object.huisnummer}
                          houseNumberSuffix={object.huisnummertoevoeging}
                          postalCode={object.postcode}
                          mediaGuid={object?.mediaGuid}
                          objectGuid={object.objectGuid}
                          status={object.status}
                          duration={object.looptijd}
                          street={object.straatnaam}
                          municipality={object.gemeente}
                          removeHandler={() =>
                            handleAddButton(object.objectGuid)
                          }
                        />
                      ),
                    },
                    {
                      columnProperty: 'hoofdfunctie',
                      render: object => (
                        <CellWithLabel
                          isLoading={object.loading}
                          label="Hoofdfunctie"
                          value={object.hoofdfunctie}
                        />
                      ),
                    },
                    {
                      columnProperty: 'pand-oppervlakte',
                      render: object => (
                        <CellWithLabel
                          isLoading={object.loading}
                          ariaLabel="Pand oppervlakte"
                          label="Oppervlakte"
                          icon={<IconWoonoppervlak />}
                          value={
                            object.pandOppervlakte &&
                            `${object.pandOppervlakte}m2`
                          }
                        />
                      ),
                    },
                    {
                      columnProperty: 'terrein-oppervlakte',
                      render: object => (
                        <CellWithLabel
                          isLoading={object.loading}
                          hideLabel
                          label="Terrein oppervlakte"
                          icon={<IconPerceeloppervlak />}
                          value={
                            object.perceelOppervlakte &&
                            `${object.perceelOppervlakte}m2`
                          }
                        />
                      ),
                    },
                    {
                      columnProperty: 'bouwjaar',
                      render: object => (
                        <CellWithLabel
                          isLoading={object.loading}
                          label="Bouwjaar"
                          value={object.bouwjaar}
                          icon={<IconBouwjaar />}
                        />
                      ),
                    },
                    {
                      columnProperty: 'energielabel',
                      render: object => (
                        <CellWithLabel
                          label="Energielabel"
                          value={
                            <EnergyLabel
                              isLoading={object.loading}
                              label={object.energielabel}
                            />
                          }
                        />
                      ),
                    },
                  ]}
                />
              </div>
              <div className="compare__add-button-wrapper">
                <AddButton
                  hideLabel
                  linkTo={returnUrl}
                  iconSize="small"
                  labelAdd="Andere referenties toevoegen"
                />
              </div>
            </Panel>
          </>
        )}
      </main>
    </>
  )
}
