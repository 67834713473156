import React, { useEffect, useMemo, useState } from 'react'
import { useDebouncedCallback } from '../../../../utils/use-debounce'
import Label from '../label'
import './text-input.css'

export function getActiveFilterLabels(control, filters) {
  if (!filters[control.name]) {
    return []
  }

  return [{ label: filters[control.name], control }]
}

export function removeFilterValues(params, control, _optionValue) {
  delete params[control.name]
}

export default function TextInput({
  control,
  filters,
  groupLabel,
  onChange,
  onKeyDown = () => {},
  debounceDelay = 250,
}) {
  const [internalValue, setInternalValue] = useState('')
  const onChangeDebounced = useDebouncedCallback(event => {
    onChange(event)
  }, debounceDelay)

  const externalValue = useMemo(() => {
    return filters?.[control.name] || ''
  }, [control.name, filters])

  useEffect(() => {
    setInternalValue(externalValue)
  }, [externalValue])

  function handleOnChange(event) {
    event.persist()
    setInternalValue(event.target.value)
    onChangeDebounced(event)
  }

  const { label, ...restControl } = control
  const controlWithoutLabel =
    control.label === groupLabel ? restControl : control

  return (
    <>
      <Label
        htmlFor={`search-filters__control--${control.name}`}
        groupLabel={groupLabel}
        control={controlWithoutLabel}
      />
      <input
        id={`search-filters__control--${control.name}`}
        className="text-input__input"
        name={control.name}
        type="text"
        value={internalValue}
        onChange={handleOnChange}
        onKeyDown={onKeyDown}
      />
    </>
  )
}
