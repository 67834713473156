import React, { forwardRef, useEffect, useState } from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import ExpandableCharacteristicsList from '../expandable-characteristics-list/expandable-characteristics-list'
import Panel from '@brainbay/components/components/panel'
import Modal from '@brainbay/components/components/modal'
import EnergieLabel from '@brainbay/components/components/energy-label'
import formatValue from '@brainbay/components/utils/format-value'
import SourceLabel from '@brainbay/components/components/source-label'

import './building-characteristics.css'

const BuildingSurfaceTable = ({ item }) => {
  return (
    <>
      <span>{item.value}</span>
      <Link
        className="building-surface__more-info print-excluded"
        to={`${item.baseUrl}/info/${item.moreInfo.slug}`}
      >
        Meer info
      </Link>
      <Switch>
        <Route path={`${item.baseUrl}/info/${item.moreInfo.slug}`}>
          <Modal title={item.moreInfo.title} onClose={item.onCloseModal}>
            {item.moreInfo.description}
          </Modal>
        </Route>
      </Switch>
      <table className="building-surface__table">
        <thead>
          <tr>
            <th className="building-surface__table__column-header">
              Gebruiksdoel
            </th>
            <th className="building-surface__table__column-header">
              Oppervlakte
            </th>
            <th className="building-surface__table__column-header">
              Verblijfsobjecten
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(item.gebruiksdoelen)
            .sort(([key]) => (key === 'totaal' ? 1 : -1))
            .map(([key, value]) => (
              <tr
                key={key}
                className={`building-surface__table__row ${
                  key === 'totaal' ? 'text-bold' : ''
                }`}
              >
                <td className="building-surface__table__cell">{key}</td>
                <td className="building-surface__table__cell">
                  {formatValue(value.oppervlakte, 'meter-squared')}
                </td>
                <td className="building-surface__table__cell">{value.count}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  )
}

function valueRenderer(value, index, item) {
  switch (item.type) {
    case 'gebruikersoppervlakte':
      return <BuildingSurfaceTable item={item} />
    case 'energielabel':
      return <EnergieLabel label={value} />
    case 'url':
      return (
        <a href={value} target="_blank" rel="noopener noreferrer">
          {item.hrefLabel}
        </a>
      )
    default:
      return value
  }
}

const BuildingCharacteristics = forwardRef(function BuildingCharacteristics(
  { data, baseUrl, onCloseModal },
  ref,
) {
  const HEIGHT_LEVEL = 5

  const [characteristics, setCharacteristics] = useState({})
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    if (data && !data.loading) {
      const kenmerken = data.kenmerken?.map(category => {
        const items = category.items.map(kenmerk => {
          if (kenmerk.type === 'gebruikersoppervlakte') {
            return { ...kenmerk, baseUrl, onCloseModal }
          }
          return kenmerk
        })
        return { ...category, items }
      })
      setCharacteristics(kenmerken)
      setHasLoaded(true)
    }
  }, [data, baseUrl, onCloseModal])

  return (
    <div ref={ref}>
      <Panel
        title="Kenmerken"
        tag="section"
        collapsable
        divider
        isOpenByDefault
        className="building-characteristics"
        style={{ '--height-level': HEIGHT_LEVEL }}
      >
        {hasLoaded && (
          <ExpandableCharacteristicsList
            loading={data?.loading}
            items={characteristics}
            valueRenderer={valueRenderer}
            heightLevel={HEIGHT_LEVEL}
          />
        )}
        <div className="building-characteristics_source-label">
          <SourceLabel
            source="BAG / RVO / CBS / Brainbay"
            prefixSource="Bron"
          />
        </div>
      </Panel>
    </div>
  )
})

export default BuildingCharacteristics
