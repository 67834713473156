import React, { forwardRef, useEffect, useState } from 'react'
import Panel from '@brainbay/components/components/panel'
import DataMap from '@brainbay/components/components/data-map'
import SourceLabel from '@brainbay/components/components/source-label'
import WalkScoreBadge from './building-walkscore-badge'
import './building-walkscore.css'

const BuildingWalkScore = forwardRef(function BuildingWalkScore({ data }, ref) {
  const [address, setAddress] = useState({})
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    if (data && !data.loading) {
      setAddress(data.geselecteerdeAdres)
      setHasLoaded(true)
    }
  }, [data])
  const mapboxConfig = {
    scrollZoom: false,
  }

  return (
    <div ref={ref}>
      <Panel
        title="Walkscore"
        tag="section"
        collapsable
        divider
        isOpenByDefault
        className="building-walk-score"
      >
        <div className="building-walk-score__content">
          {hasLoaded && (
            <>
              <WalkScoreBadge address={address} />
              <DataMap
                longitude={address?.longitude}
                latitude={address?.latitude}
                mapboxConfig={mapboxConfig}
                mapboxStyle="mapbox://styles/mapbox/outdoors-v11"
                width="100%"
                height="530px"
                zoom={16}
                showWalkScore
                showControls
              />
            </>
          )}
        </div>
        <div className="building-walk-score_source-label">
          <SourceLabel source="Walk Score" prefixSource="Bron" />
        </div>
      </Panel>
    </div>
  )
})
export default BuildingWalkScore
