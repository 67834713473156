import React from 'react'
import DefaultLayout from '../layouts/default'
import { Typography, Box } from '../components'

export default function CharacteristicsPage({
  item,
  userName,
  date,
  hasTransaction,
  debug,
}) {
  return (
    <DefaultLayout
      pageName="Details"
      userName={userName}
      date={date}
      hasTransaction={hasTransaction}
      debug={debug}
    >
      {item.kenmerken ? (
        item.kenmerken.map((characteristic, index) => {
          return (
            <Box
              key={index}
              style={{
                paddingBottom: 10,
                marginTop: 10,
              }}
              debug={debug}
            >
              <Typography
                style={{
                  fontSize: 12,
                  fontWeight: '700',
                  paddingTop: 6,
                  paddingBottom: 2,
                  borderBottom: '1px solid #dddddd',
                }}
                debug={debug}
              >
                {characteristic.title}
              </Typography>
              {characteristic.items.map((characteristicItem, index) => {
                return (
                  <Box
                    key={index}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      fontSize: 10,
                      paddingTop: 6,
                      paddingBottom: 2,
                      borderBottom: '1px solid #dddddd',
                    }}
                    debug={debug}
                  >
                    <Typography
                      style={{
                        minWidth: 270,
                        fontSize: 10,
                        paddingRight: 10,
                      }}
                      debug={debug}
                    >
                      {characteristicItem.label}
                    </Typography>
                    <Typography
                      style={{
                        width: '100%',
                        fontSize: 10,
                      }}
                      debug={debug}
                    >
                      {characteristicItem.value}
                    </Typography>
                  </Box>
                )
              })}
            </Box>
          )
        })
      ) : (
        <Typography>Geen kenmerken.</Typography>
      )}
    </DefaultLayout>
  )
}
