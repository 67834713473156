import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Panel from '@brainbay/components/components/panel'
import Modal from '@brainbay/components/components/modal'

import './object-transfer-tax.css'

const getTransferTaxPercentage = date => {
  if (date >= '2023-01-01') {
    return `10,4`
  } else if (date >= '2021-01-01' && date < '2023-01-01') {
    return 8
  } else if (date < '2021-01-01') {
    return 6
  } else {
    return null
  }
}

export default function ObjectTransferTax({
  data = {},
  onCloseModal,
  baseUrl,
}) {
  const { datumTransport, transactie, status, afmelddatum } = data

  const date =
    datumTransport ||
    ((transactie?.status === 'verkocht' ||
      transactie?.status === 'verkocht_bijInschrijving' ||
      transactie?.status === 'geveild') &&
      transactie?.transactieDatum) ||
    (status === 'ingetrokken' && afmelddatum)
  const percentage = getTransferTaxPercentage(date)

  if (!date || !percentage) {
    return null
  }

  return (
    <Panel
      title="Overdrachtsbelasting"
      moreInfoUrl={`${baseUrl}/info/overdrachtsbelasting`}
    >
      <Switch>
        <Route path={`${baseUrl}/info/overdrachtsbelasting`}>
          <Modal
            title="Welke overdrachtsbelasting is van toepassing?"
            onClose={onCloseModal}
          >
            De overheid heeft de overdrachtsbelasting voor commercieel vastgoed
            (inclusief grond bestemd voor woningbouw en de aankoop van een
            woning als belegging) de laatste jaren aangepast:
            <ul className="object-transfer-tax__list">
              <li>
                Vanaf 1 januari 2023 geldt een overdrachtsbelasting van: 10,4%
                <sup>*</sup>
              </li>
              <li>
                Vanaf 1 januari 2021 tot 1 januari 2023 gold een
                overdrachtsbelasting van: 8%
              </li>
              <li>Voor 1 januari 2021 gold een overdrachtsbelasting van: 6%</li>
            </ul>
            Bij aankoop van nieuwbouw commercieel vastgoed wordt geen
            overdrachtsbelasting gerekend, maar betaalt de koper BTW. <br />
            <br />
            Als peildatum voor de bepaling van de overdrachtsbelasting die op de
            kooptransactie van toepassing is, hanteren we de transportdatum,
            mits deze door de makelaar is ingevuld. Is de transportdatum niet
            bekend, dan wordt de transactiedatum als peildatum gehanteerd. Het
            getoonde percentage is derhalve indicatief en kan in sommige
            gevallen afwijken van het werkelijk gehanteerde tarief. <br />
            <br />
            <sup>*</sup>Voor een bedrijfspand waarin ook een (bedrijfs)woning
            staat waar de koper zelf gaat wonen, geldt een
            overdrachtsbelastingtarief van 2%, of de startersvrijstelling. Voor
            de bedrijfsruimte geldt het tarief van 10,4%. Is de oppervlakte van
            de bedrijfsruimte 10% of minder van het pand. dan geldt voor het
            hele pand het tarief van 2% of de startersvrijstelling. <br />
            <br />
            <span className="body-small">Bron: Belastingdienst.</span>
          </Modal>
        </Route>
      </Switch>
      <p>
        Ten tijde van aankoop/verkoop:{' '}
        <span className="text-bold">{percentage}%</span>
      </p>
    </Panel>
  )
}
