import React, { useEffect } from 'react'
import { Switch, Route, useLocation, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentRoute, setPreviousRoute } from './store/route-history'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import Landing from './views/landing'
import SearchResults from './views/search-results'
import AuthCallback from './views/auth-callback'
import Compare from './views/compare'
import ObjectView from './views/object'
import Building from './views/building'
import SavedComparisons from './views/saved-comparisons'
import AppSearchBar from './components/app-search-bar/app-search-bar'
import AppNav from './components/app-nav'
import ComparisonPanel from './components/comparison-panel'
import PrivateRoute from './components/private-route'
import Skiplinks from './components/skiplinks'
import ApmProvider from './utils/apm'
import useFeatureFlags from './utils/use-feature-flags'
import GoogleAnalytics from '@brainbay/components/components/google-analytics'
import Feedback from '@brainbay/components/components/feedback'
import AppHeader from '@brainbay/components/components/app-header'
import isSSR from '@brainbay/components/utils/is-ssr'
import log from '@brainbay/components/utils/log'
import { trackEvent } from '@brainbay/components/utils/ga-events'
import { gaCategories } from '@brainbay/components/utils/constants'
import SearchResultsGebouwpassport from './views/search-results-gebouwpaspoort'
import { RequireConsent } from './components/consent'

import './App.css'

HelmetProvider.canUseDOM = isSSR === false

export default function App(props) {
  const { getEnabledFeatures } = useFeatureFlags()
  const searchType = useSelector(state => state.search.mode)
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const isLanding =
    location.pathname === '/' || location.pathname === '/gebouwpaspoort'
  const displaySearchBar = useSelector(state => state?.displaySearchBar)
  const hasExportButton = useSelector(state => state.exportButton)
  const pageTitle = useSelector(state => state.pageTitle)
  const user = useSelector(state => state?.user)
  const { currentRoute, previousRoute } = useSelector(
    state => state.routeHistory,
  )
  const isCompactMode = useSelector(
    state => state.searchResultMode.isCompactMode,
  )
  function handleOnExport() {
    trackEvent(
      'clicked_open_modal_export',
      gaCategories.EXPORT,
      'Export modal opened',
    )

    const { pathname, search } = location
    history.push(`${pathname + search}#export`)
  }

  useEffect(() => {
    dispatch(setCurrentRoute(location.pathname))

    return () => {
      dispatch(setPreviousRoute(location.pathname))
    }
  }, [dispatch, location])

  useEffect(() => {
    if (currentRoute && previousRoute) {
      const currentPage = currentRoute.split('/')?.[1]
      const previousPage = previousRoute.split('/')?.[1]
      if (currentPage !== previousPage) {
        const pageTitleElement = document.querySelector('h1')
        if (pageTitleElement) {
          pageTitleElement.tabIndex = '-1'
          pageTitleElement.focus()
        } else {
          log.warning(
            'Page title could not be announced to screenreader.',
            'No <h1> has been found on the page',
          )
        }

        window.scrollTo(0, 0)
      }
    }
  }, [currentRoute, previousRoute])

  return (
    <HelmetProvider>
      <ApmProvider>
        <Helmet>
          <html data-enabled-features={getEnabledFeatures().join(' ')} />
          <title>
            {pageTitle === 'Home' ? '' : `${pageTitle} | `}Business Referenties
            - Brainbay
          </title>
        </Helmet>

        <GoogleAnalytics accountNumber={user?.user?.TiaraAccountNumber} />

        <h1 tabIndex={-1} className="sr-only">
          {pageTitle}
        </h1>

        <Skiplinks>
          <AppNav
            className={pageTitle === 'Home' ? 'app__app-nav--home' : ''}
          />

          <div id="map-root"></div>

          {!isLanding && (
            <AppHeader
              searchbar={
                displaySearchBar && <AppSearchBar isLanding={isLanding} />
              }
              hasExport={hasExportButton}
              onExport={handleOnExport}
            />
          )}

          <Switch>
            <Route path="/callback">
              <AuthCallback />
            </Route>
            <PrivateRoute
              path={
                isCompactMode
                  ? '/search-results/:query/compact-lijst/:page?/:map?'
                  : '/search-results/:query/:page?/:map?'
              }
            >
              <SearchResults onExport={handleOnExport} />
            </PrivateRoute>
            <PrivateRoute path={'/gebouwpaspoort/search-results/:query/:page?'}>
              <SearchResultsGebouwpassport />
            </PrivateRoute>
            <PrivateRoute path="/compare">
              <Compare />
            </PrivateRoute>
            <PrivateRoute path="/saved-comparisons">
              <SavedComparisons />
            </PrivateRoute>
            <PrivateRoute path="/gebouwpaspoort" exact>
              <RequireConsent contextName="gebouwpaspoort">
                <Landing
                  searchBarComponent={
                    <AppSearchBar
                      isLanding={true}
                      defaultSearchMode="switch-gebouwpaspoort"
                    />
                  }
                />
              </RequireConsent>
            </PrivateRoute>
            <PrivateRoute path="/gebouwpaspoort/:nummeraanduidingId">
              <RequireConsent contextName="gebouwpaspoort">
                <Building />
              </RequireConsent>
            </PrivateRoute>
            <PrivateRoute path="/:objectGuid">
              <ObjectView onExport={handleOnExport} />
            </PrivateRoute>
            <PrivateRoute path="/" exact>
              <Landing
                searchBarComponent={
                  displaySearchBar && <AppSearchBar isLanding={isLanding} />
                }
              />
            </PrivateRoute>
          </Switch>

          <ComparisonPanel />
        </Skiplinks>
        <Feedback
          className="app__feedback print-excluded"
          src={`https://ratings.brainbay.nl/widget?appName=${
            searchType === 'switch-gebouwpaspoort'
              ? 'Gebouwpaspoort'
              : 'Businessrefentiemodule'
          }&widgetToken=394f6480-c8a3-4a33-9c6f-2826f9b431fc`}
        />
      </ApmProvider>
    </HelmetProvider>
  )
}
