import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get } from '../utils/api-data'

export const load = createAsyncThunk(
  'businessRegistratiesHistory/load',
  async (pandId, thunkAPI) => {
    if (pandId) {
      try {
        const response = await get(
          `business-registratie-history/by-pand-id/${pandId}`,
        )
        return response
      } catch (error) {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  },
)

const businessRegistratieHistory = createSlice({
  name: 'businessRegistratieHistory',
  initialState: {
    value: null,
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(load.pending, state => {
        state.status = 'loading'
      })
      .addCase(load.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.value = action.payload
      })
      .addCase(load.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })
  },
})

export const loadBusinessRegistratiesHistoryByPandId = load

export default businessRegistratieHistory.reducer
