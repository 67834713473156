import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get, post } from '../utils/api-data'

const load = createAsyncThunk(
  'objects/load',
  objectGuid => get(`objects/by-businessguid/${objectGuid}`),
  {
    condition: (objectGuid, { getState, extra }) => {
      const { objects } = getState()
      if (objects[objectGuid]) {
        return false
      }
    },
  },
)

const bulkLoad = createAsyncThunk('objects/bulk-load', objectGuids =>
  post({ path: `objects/by-businessguids`, body: objectGuids }),
)

const objects = createSlice({
  name: 'objects',
  initialState: {},
  reducers: {},
  extraReducers: {
    [load.pending]: (state, { meta }) => {
      const objectGuid = meta.arg
      const object = state[objectGuid]
      if (object) return state
      state[objectGuid] = { objectGuid, loading: true }
    },
    [load.fulfilled]: (state, { payload, meta }) => {
      state[meta.arg] = payload
    },
    [load.rejected]: (state, { payload, meta }) => {
      const objectGuid = meta.arg
      state[objectGuid] = {
        objectGuid,
        error: 'We konden het object niet vinden',
      }
    },
    [bulkLoad.pending]: (state, { meta }) => {
      const objectGuidList = meta.arg
      objectGuidList.forEach(objectGuid => {
        if (!state[objectGuid]) {
          state[objectGuid] = { objectGuid, loading: true }
        }
      })
    },
    [bulkLoad.fulfilled]: (state, { payload: bulkObjectsArray }) => {
      bulkObjectsArray.forEach(item => (state[item.objectGuid] = item))
    },
    [bulkLoad.rejected]: (state, { payload, meta }) => {
      const objectGuidList = meta.arg
      objectGuidList.forEach(objectGuid => {
        state[objectGuid] = {
          objectGuid,
          error: 'We konden het object niet vinden',
        }
      })
    },
  },
})

export const loadObjectByObjectGuid = load
export const loadBulkObjectsByObjectGuid = bulkLoad

export default objects.reducer
