import React from 'react'
import './tooltip.css'

export default function Tooltip({ children, className, ...props }) {
  return (
    <div
      className={`tooltip${className ? ` ${className}` : ''}`.trim()}
      role="tooltip"
      {...props}
    >
      {children}
    </div>
  )
}
