import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Icon from '@brainbay/components/components/icon'
import { ReactComponent as IconChevronBack } from '@brainbay/components/_assets/svg/icon-chevron-back.svg'
import SearchResultItem from '../../components/search-result-item'
import { loadObjectByObjectGuid } from '../../store/objects'
import { setLatestSelectedResult } from '../../store/search-result-latest-selected'

import './map-highlighted-objects.css'

export default function MapHighlightedObjects({
  features,
  loadedObjects = {},
  onAddToggle = () => {},
}) {
  const dispatch = useDispatch()
  const [activeIndex, setActiveIndex] = useState(0)
  const [activeObjectGuid, setActiveObjectGuid] = useState(undefined)
  const latestSelectedResult = useSelector(
    state => state.searchResultLatestSelected,
  )

  const objectGuids = useMemo(() => {
    return features
      .map(feature => feature?.properties?.objectGuids?.split(','))
      .flat()
  }, [features])

  const totalItems = objectGuids.length

  /** Set initial values */
  useEffect(() => {
    setActiveIndex(0)
  }, [features])

  /** Find active object guid based on active index */
  useEffect(() => {
    const newActiveObjectGuid = objectGuids?.[activeIndex]
    const isLatestSelectedResult = latestSelectedResult.guid !== undefined

    if (!newActiveObjectGuid && isLatestSelectedResult) {
      setActiveObjectGuid(latestSelectedResult?.guid)
    } else {
      setActiveObjectGuid(newActiveObjectGuid)
    }

    if (!loadedObjects[newActiveObjectGuid] && newActiveObjectGuid) {
      dispatch(loadObjectByObjectGuid(newActiveObjectGuid))
      dispatch(setLatestSelectedResult(newActiveObjectGuid))
    }
  }, [activeIndex, dispatch, latestSelectedResult, loadedObjects, objectGuids])

  function increaseIndex() {
    let newIndex = activeIndex + 1

    if (newIndex >= totalItems) {
      newIndex = 0
    }

    setActiveIndex(newIndex)
  }

  function decreaseIndex() {
    let newIndex = activeIndex - 1

    if (newIndex < 0) {
      newIndex = totalItems - 1
    }

    setActiveIndex(newIndex)
  }

  return objectGuids ? (
    <aside className="map-highlighted-objects">
      {totalItems > 1 && (
        <header className="map-highlighted-objects__action-buttons">
          <button className="button button--x-small" onClick={decreaseIndex}>
            <Icon size="nano">
              <IconChevronBack />
            </Icon>
          </button>
          <button className="button button--x-small" onClick={increaseIndex}>
            <Icon size="nano">
              <IconChevronBack />
            </Icon>
          </button>
          <span className="map-highlighted-objects__total-indicator body">
            {activeIndex + 1} / {totalItems}
          </span>
        </header>
      )}

      {activeObjectGuid && (
        <SearchResultItem
          standalone
          item={loadedObjects[activeObjectGuid] || {}}
          isLoading={
            !loadedObjects[activeObjectGuid] ||
            loadedObjects[activeObjectGuid].isLoading
          }
          onAddToggle={onAddToggle}
        />
      )}
    </aside>
  ) : null
}
