import React from 'react'
import DefaultLayout from '../layouts/default'
import PandWalkscore from '../components/pand-walkscore'

export default function PandWalkscorePage({
  userName,
  date,
  debug,
  isGebouwpaspoort,
  walkscore,
  address,
}) {
  return (
    <DefaultLayout
      pageName="Walkscore"
      userName={userName}
      date={date}
      debug={debug}
      isGebouwpaspoort={isGebouwpaspoort}
    >
      <PandWalkscore debug={debug} address={address} walkscore={walkscore} />
    </DefaultLayout>
  )
}
