import { createSlice } from '@reduxjs/toolkit'

const initialState = true

const displaySearchBar = createSlice({
  name: 'displaySearchBar',
  initialState,
  reducers: {
    hideSearchBar: _ => false,
    showSearchBar: _ => true,
    resetSearchBar: _ => initialState,
  },
})

export const { hideSearchBar, showSearchBar, resetSearchBar } =
  displaySearchBar.actions

export default displaySearchBar.reducer
