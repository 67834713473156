import React from 'react'
import './notification-bar.css'

export default function NotificationBar({ notification }) {
  return (
    <div
      className="notification-bar body-small"
      dangerouslySetInnerHTML={{ __html: notification }}
    />
  )
}
