/**
 * Sorts two objects based on specified nested property paths.
 * This function is for sorting objects in an array based on multiple properties in a hierarchical structure.
 **/

export default function sortByProperties(a, b, propertyPaths) {
  const getNestedPropertyValue = (obj, path) =>
    path
      .split('.')
      .reduce(
        (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
        obj,
      )

  for (const propertyPath of propertyPaths) {
    const valueA = getNestedPropertyValue(a, propertyPath)
    const valueB = getNestedPropertyValue(b, propertyPath)

    // Handle null or undefined values
    if (valueA === null || valueA === undefined) {
      return -1
    }
    if (valueB === null || valueB === undefined) {
      return 1
    }
    const isNumeric = value => !isNaN(value) && value !== ''
    if (isNumeric(valueA) && isNumeric(valueB)) {
      const numA = parseFloat(valueA)
      const numB = parseFloat(valueB)

      if (numA < numB) {
        return -1
      }
      if (numA > numB) {
        return 1
      }
    } else {
      if (valueA < valueB) {
        return -1
      }
      if (valueA > valueB) {
        return 1
      }
    }
  }
  return 0
}
