import React, { isValidElement } from 'react'
import { Link } from 'react-router-dom'
import slugify from '../../utils/slugify'
import Icon from '../icon'
import { ReactComponent as IconPlus } from '../../_assets/svg/icon-plus.svg'
import formatValue from '../../utils/format-value'
import './table.css'

export default function ComparisonList({
  rows = [],
  columns = [],
  columnHeaders = [],
  hideCaption = false,
  hideRowHeaders = false,
  hideColumnHeaders = false,
  highlightFirstColumn = false,
  caption = '',
  className = '',
  referencesLink = false,
  onReferencesModalOpen = () => {},
  onResetReferences = () => {},
  referencesUpdatedAt = '',
}) {
  let _columnHeaders = columnHeaders
  if (typeof columnHeaders === 'function') {
    _columnHeaders = columns.map(columnHeaders)
  }

  const rowHeadersAvailable = rows.find(row => row?.header)

  return (
    <div className="comparison-list__table-wrapper">
      <table className={className}>
        {caption && (
          <caption className={`table-caption${hideCaption ? ' sr-only' : ''}`}>
            {caption}
          </caption>
        )}
        {_columnHeaders && (
          <thead className="table-head">
            <tr className="table-row">
              {rowHeadersAvailable && <th scope="row"></th>}
              {_columnHeaders.map((columnHeader, index) => (
                <th
                  scope="col"
                  aria-sort={columnHeader?.ariaSort || null}
                  key={`${columnHeader?.key || columnHeader}-${index}`}
                  className={`table-column-header${
                    hideColumnHeaders ? ' sr-only' : ''
                  }`}
                >
                  {columnHeader?.header || columnHeader}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody className="table-body">
          {rows.map(row => {
            const rowValueIsString = typeof row == 'string'
            const columnProperty = rowValueIsString ? row : row?.columnProperty
            const rowClassname = row?.className ? row?.className : ''
            const rowHeader = row?.header
            const cellRenderer = row?.render
              ? (item, index, array) => row.render(item, index, array)
              : item => item[columnProperty]

            const tableRowClass = columnProperty
              ? `table-row--${columnProperty}`
              : ''

            return (
              <tr
                key={columnProperty}
                className={`table-row ${tableRowClass} ${rowClassname}`.trim()}
              >
                {rowHeader && (
                  <th
                    scope="row"
                    className={`table-row-header${
                      hideRowHeaders ? ' sr-only' : ''
                    }`}
                  >
                    {rowHeader}
                  </th>
                )}

                {columns.map((item, itemIndex) => {
                  const columnHeaderSlug =
                    _columnHeaders[itemIndex]?.header ||
                    _columnHeaders[itemIndex]
                  const isElement = isValidElement(columnHeaderSlug)
                  let tableColumnClass = ''

                  if (isElement && columnHeaderSlug.key) {
                    tableColumnClass = `table-column--${columnHeaderSlug.key}`
                  } else if (columnHeaderSlug && !isElement) {
                    tableColumnClass = `table-column--${slugify(
                      columnHeaderSlug,
                    )}`
                  }

                  const highlightFirstColumnClass =
                    highlightFirstColumn && itemIndex === 0
                      ? 'table-column--highlighted'
                      : ''

                  return (
                    <td
                      key={`${columnHeaderSlug}-${itemIndex}`}
                      className={`table-cell ${tableColumnClass} ${highlightFirstColumnClass}`.trim()}
                    >
                      {cellRenderer(item, itemIndex, columns)}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {referencesLink && (
        <div className="comparison-list__modify-reference__wrapper print-excluded">
          <Link
            className="comparison-list__modify-reference__link-button"
            to={referencesLink}
            onClick={onReferencesModalOpen}
          >
            <Icon circle>
              <IconPlus />
            </Icon>
            <p className="body-big text-dim">Wijzig referenties</p>
          </Link>

          {onResetReferences && referencesUpdatedAt && (
            <>
              <button
                className="comparison-list__modify-reference__reset-button button"
                onClick={onResetReferences}
              >
                Vernieuw referenties
              </button>

              {referencesUpdatedAt && (
                <p className="comparison-list__modify-reference__reset-button--meta body-small text-dim">
                  Laatst gewijzigd op{' '}
                  {formatValue(referencesUpdatedAt, {
                    format: 'date',
                  })}
                </p>
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}
