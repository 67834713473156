import React, { useMemo, useRef, useState } from 'react'

import ActiveFilterList from '../active-filter-list'
import Panel from '../../panel'
import { Control, FormGroup, removeFilterValues } from '../controls'

import './filter-bar.css'
import {
  forEachControl,
  useActiveFilters,
  useFilterDefaults,
  useFilterTree,
  useOpenFormGroups,
} from './filter-bar-hooks'

export default function FilterBar({
  filters = {},
  filterCatalog = {},
  topMostFilter = 'aanbod',
  facets = {},
  onSubmit = () => {},
  onClearFilters = () => {},
}) {
  const [expanded, setExpanded] = useState(true)
  const formRef = useRef(null)

  const defaultFilterValues = useFilterDefaults(filterCatalog, filters)
  const filtersWithDefaults = useMemo(
    () => ({ ...defaultFilterValues, ...filters }),
    [defaultFilterValues, filters],
  )
  const { getVisibleFilters, getDescendantFilters } =
    useFilterTree(filterCatalog)
  const visibleFilters = useMemo(
    () => getVisibleFilters(filtersWithDefaults),
    [filtersWithDefaults, getVisibleFilters],
  )
  const openFormGroups = useOpenFormGroups(
    filterCatalog,
    filters,
    getDescendantFilters,
  )

  const activeFilters = useActiveFilters(
    filterCatalog,
    filters,
    getDescendantFilters,
  )

  function handleFormSubmit(
    event = {
      preventDefault: () => {},
    },
  ) {
    if (event.stopPropagation) {
      event.stopPropagation()
    }
    const newFilters = {}
    const formData = new FormData(formRef.current)

    for (let key of formData.keys()) {
      if (
        formData.getAll(key)[0] &&
        !newFilters[key] &&
        /^ignore-/.test(key) === false
      ) {
        newFilters[key] = formData.getAll(key).join(',')
      }
    }

    // values which are in visibleFilters, but no longer in newVisibleFilters
    const newVisibleFilters = new Set(getVisibleFilters(newFilters))
    const removedFilters = new Set(
      visibleFilters.filter(filter => !newVisibleFilters.has(filter)),
    )

    /*
      Apply removeFilterValues logic so that we correctly remove the filter values from the query for each control
      We do this because there is no one-to-one mapping from filter to query. 
      Range inputs have at most 2 query params and checkboxes can have multiple params (if they also contain subControls)
    */
    forEachControl(filterCatalog, control => {
      if (removedFilters.has(control.name)) {
        removeFilterValues(newFilters, control)
      }
    })

    const params = new URLSearchParams(Object.entries(newFilters))
    onSubmit(params)
  }

  function handleRemoveFilter({ control, value }) {
    const params = { ...filters }

    removeFilterValues(params, control, value)

    onSubmit(new URLSearchParams(params))
  }

  function handleFormReset(event) {
    event.stopPropagation()

    const params = new URLSearchParams(defaultFilterValues)
    onSubmit(params)
  }

  function handleClearFilters(event) {
    event.preventDefault()

    handleFormReset(event)

    onClearFilters()
  }

  return (
    <Panel
      divider={false}
      title={`Filters`}
      tag="aside"
      className={`filter-bar ${expanded ? 'filter-bar--expanded-mobile' : ''}`}
      action={
        <div className="filter-bar__panel-actions">
          {activeFilters.length > 0 && (
            <button
              className="button button--x-small search-filters__remove-button body"
              onClick={handleClearFilters}
            >
              Reset filters
            </button>
          )}
          <button
            className="filter-bar__toggle-button button button--x-small body"
            aria-expanded={expanded ? 'true' : 'false'}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? 'Inklappen' : 'Uitklappen'}
          </button>
        </div>
      }
    >
      <ActiveFilterList
        id="active-filter-list"
        activeFilters={activeFilters}
        onRemove={handleRemoveFilter}
      />
      <form
        className="filter-bar__form"
        onSubmit={handleFormSubmit}
        ref={formRef}
      >
        {filterCatalog?.filters?.map(({ controls, ...group }) => {
          // If no controls are visible for the group then don't render the group
          if (
            !controls.some(control => visibleFilters.includes(control.name))
          ) {
            return null
          }

          return (
            <FormGroup
              key={group.id}
              {...group}
              isOpen={openFormGroups.has(group.id)}
            >
              {(controls || []).map((control, index) => {
                if (!visibleFilters.includes(control.name)) {
                  return null
                }

                const callback =
                  control.name === topMostFilter
                    ? handleFormReset
                    : handleFormSubmit

                return (
                  <Control
                    control={control}
                    filters={filters}
                    facet={facets}
                    key={`${group.id}-${index}`}
                    groupLabel={group.label}
                    onChange={callback}
                  />
                )
              })}
            </FormGroup>
          )
        })}
        {filterCatalog?.error && (
          <p>Er gaat iets mis met het ophalen van de filters</p>
        )}
      </form>
    </Panel>
  )
}
