import React, { useState } from 'react'
import Modal from '@brainbay/components/components/modal'

function PriceCorrectionPopup({ children, data }) {
  const [showModal, setShowModal] = useState(false)

  const handleClick = () => {
    setShowModal(!showModal)
  }
  const onCloseModal = () => {
    setShowModal(!showModal)
  }
  const originalPrijs = data?.transactie?.originalPrijs
  const popupText = {
    title: 'Let op!',
    description: `De door de makelaar afgemelde transactieprijs is door brainbay gecorrigeerd. De originele, door de makelaar, ingevoerde prijs was: € ${originalPrijs} per jaar.`,
  }

  return (
    <div onClick={handleClick}>
      {children}
      {showModal && (
        <Modal className="popup" title={popupText.title} onClose={onCloseModal}>
          {popupText.description}
        </Modal>
      )}
    </div>
  )
}

export default PriceCorrectionPopup
