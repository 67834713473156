import React, { useEffect, useState } from 'react'
import './sort-select.css'

export default function SortSelect({
  tag = 'fieldset',
  onChangeDirection = () => {},
  onChangeSort = () => {},
  sortOptions = [],
  ascendingKey = 'ascending',
  descendingKey = 'descending',
  defaultDirection = '',
  sortBy = '',
  sortDirection: _sortDirection = '',
}) {
  const Tag = tag
  const [sortDirection, setSortDirection] = useState(
    defaultDirection || descendingKey,
  )

  function setSelect(e) {
    onChangeSort(e.target.value)
  }

  function changeDirection() {
    if (sortDirection === ascendingKey) {
      setSortDirection(descendingKey)
      onChangeDirection(descendingKey)
    } else {
      setSortDirection(ascendingKey)
      onChangeDirection(ascendingKey)
    }
  }

  useEffect(() => {
    setSortDirection(_sortDirection)
  }, [_sortDirection])

  return (
    <Tag className="sort-select">
      <legend className="sr-only">Sorteren</legend>
      <label className="sr-only" htmlFor="sort-select__select">
        Sorteer opties
      </label>
      <select
        id="sort-select__select"
        className="sort-select__select select--small"
        onChange={setSelect}
        value={sortBy}
      >
        {sortOptions.map?.(option => {
          return (
            <option key={option.key} value={option.key}>
              {option.label}
            </option>
          )
        })}
      </select>

      <button onClick={changeDirection}>
        <span className="sr-only">
          {sortDirection === descendingKey ? 'Aflopend' : 'Oplopend'}{' '}
          geselecteerd. Verander naar{' '}
          {sortDirection === descendingKey ? 'Oplopend' : 'Aflopend'}
        </span>
        <span
          className={
            sortDirection === descendingKey ? 'sort-select--active' : ''
          }
          aria-hidden="true"
        >
          ↓
        </span>{' '}
        <span
          className={
            sortDirection === ascendingKey ? 'sort-select--active' : ''
          }
          aria-hidden="true"
        >
          ↑
        </span>
      </button>
    </Tag>
  )
}
