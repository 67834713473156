import React from 'react'
import DefaultLayout from '../layouts/default'
import { Typography, Box } from '../components'
import formatAddressBusiness from '../../format-address-business'
import formatValue from '@brainbay/components/utils/format-value'
import sortByProperties from '../../sort-by-properties'

import { StyleSheet } from '@react-pdf/renderer'

export default function PandVerblijfsobjectenPage({
  item,
  userName,
  date,
  debug,
  isGebouwpaspoort,
}) {
  const { pand } = item || {}
  const sortedAccommodationObjects = pand?.verblijfsobjecten
    ? [...pand.verblijfsobjecten].sort((a, b) =>
        sortByProperties(a, b, [
          'hoofdAdres.straatnaam',
          'hoofdAdres.huisnummer',
          'hoofdAdres.huisnummertoevoeging',
        ]),
      )
    : []
  const accommodationObjectsCount = sortedAccommodationObjects?.length || 0
  const styles = StyleSheet.create({
    tableContainer: {
      display: 'table',
      width: '100%',
    },
    tableHeader: {
      fontWeight: 'bold',
      fontSize: 12,
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderColor: '#0099bc',
    },
    tableRow: {
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottom: '1px solid #dddddd',
    },
    tableCell: {
      flex: 1,
      padding: 6,
      textAlign: 'left',
      fontSize: 10,
    },
  })

  return (
    <>
      <DefaultLayout
        pageName={`Verblijfsobject${
          accommodationObjectsCount === 1 ? '' : 'en'
        }`}
        userName={userName}
        date={date}
        debug={debug}
        isGebouwpaspoort={isGebouwpaspoort}
      >
        <Typography
          style={{
            fontSize: 18,
            color: '#000',
            fontWeight: 700,
            marginBottom: 10,
          }}
          debug={debug}
        >
          {`Verblijfsobject${accommodationObjectsCount === 1 ? '' : 'en'}`}
        </Typography>

        <Box style={styles.tableContainer}>
          {sortedAccommodationObjects?.length > 0 && (
            <Box style={styles.tableHeader}>
              <Typography
                style={{ ...styles.tableCell, flex: 1.5 }}
                debug={debug}
              >
                Adres
              </Typography>

              <Typography style={styles.tableCell} debug={debug}>
                Energie label
              </Typography>

              <Typography style={styles.tableCell} debug={debug}>
                Oppervlakte
              </Typography>

              <Typography style={styles.tableCell} debug={debug}>
                Status
              </Typography>

              <Typography style={styles.tableCell} debug={debug}>
                Gebruiksdoel(en)
              </Typography>
            </Box>
          )}
          {sortedAccommodationObjects?.map((objectData, rowIndex) => (
            <Box style={styles.tableRow} key={rowIndex} wrap={false}>
              {
                <Typography
                  style={{ ...styles.tableCell, flex: 1.5 }}
                  debug={debug}
                >
                  {formatAddressBusiness({
                    street: objectData?.hoofdAdres?.straatnaam,
                    houseNumber: objectData?.hoofdAdres?.huisnummer,
                    houseNumberSuffix:
                      objectData?.hoofdAdres?.huisnummertoevoeging,
                    postalCode: objectData?.hoofdAdres?.postcode,
                    city: objectData?.hoofdAdres?.woonplaats,
                    municipality: objectData?.hoofdAdres?.gemeente,
                  })}
                </Typography>
              }

              <Typography style={styles.tableCell} debug={debug}>
                {objectData?.energieklasse ? objectData.energieklasse : '---'}
              </Typography>

              <Typography style={styles.tableCell} debug={debug}>
                {formatValue(objectData?.oppervlakte, 'meter-squared')}
              </Typography>

              <Typography style={styles.tableCell} debug={debug}>
                {formatValue(objectData?.verblijfsobjectStatus)}
              </Typography>

              <Typography style={styles.tableCell} debug={debug}>
                {formatValue(
                  objectData?.gebruiksdoelen,
                  'comma-separated-list',
                )}
              </Typography>
            </Box>
          ))}
        </Box>
      </DefaultLayout>
    </>
  )
}
