import React from 'react'

import './compare-list.css'

export default function SearchCompareList({ children }) {
  return (
    <ul className="compare-list__list">
      {children.map(child => (
        <li className="compare-list__item" key={child.key}>
          {child}
        </li>
      ))}
    </ul>
  )
}
