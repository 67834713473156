import React from 'react'
import Icon from '../icon'
import { ReactComponent as IconInfo } from '../../_assets/svg/icon-info.svg'
import './notification-panel.css'

export default function NotificationPanel({
  tag = 'section',
  inline = false,
  notification,
  className,
}) {
  const PanelTag = tag

  return (
    <PanelTag
      className={`notification-panel body ${
        inline && 'notification-panel--inline body-small'
      } ${className}`.trim()}
    >
      <Icon
        circle
        size={inline ? 'pico' : 'nano'}
        className="notification-panel__icon"
      >
        <IconInfo />
      </Icon>
      <span dangerouslySetInnerHTML={{ __html: notification }} />
    </PanelTag>
  )
}
