import { createSlice } from '@reduxjs/toolkit'
const betrokkenObject = createSlice({
  name: 'betrokkenObject',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    setBetrokkenObjectData: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setBetrokkenObjectData } = betrokkenObject.actions
export const fetchBetrokkenObjectData = state => state.betrokkenObject.data
export default betrokkenObject.reducer
