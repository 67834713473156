import React from 'react'
/**
 * Check if a word of an array of words is in a string
 * @param stringOrArray
 * @param arrayOfStrings
 * @returns {*}
 */
export default function includesOneOf(stringOrArray, arrayOfStrings) {
  if (!stringOrArray || !arrayOfStrings.length) return false

  const string = Array.isArray(stringOrArray)
    ? stringOrArray.join(' ')
    : stringOrArray

  return arrayOfStrings.some(word => string.toLowerCase().includes(word))
}

export const convertNextLineToHtmlBreakEl = stringToBreak =>
  stringToBreak?.split('\n\n').reduce((acc, val, index) =>
    [].concat(
      acc,
      <React.Fragment key={index}>
        <br />
        <br />
      </React.Fragment>,
      val,
    ),
  )
