import React from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import Modal from '@brainbay/components/components/modal'

import './object-action-bar.css'

export default function ObjectActionBar({
  searchResultsUrl,
  onCloseModal,
  data,
  baseUrl,
}) {
  const defaultLocation = new URL(window.location).origin

  return (
    <>
      <Switch>
        <Route path={`${baseUrl}/fout/`}>
          <Modal title="Meld een fout" onClose={onCloseModal}>
            Via de onderstaande knop kunt u brainbay wijzen op fouten in de
            getoonde data. U kunt correcties melden op bijvoorbeeld de volgende
            punten:
            <ol className="object-action-bar__list">
              <li className="object-action-bar__list-item">
                Oppervlaktegegevens
              </li>
              <li className="object-action-bar__list-item">
                Transactieprijsgegevens
              </li>
              <li className="object-action-bar__list-item">Gebouwtype</li>
              <li className="object-action-bar__list-item">
                Huurder-/kopernaam
              </li>
              <li className="object-action-bar__list-item">etc.</li>
            </ol>
            Brainbay zal de door u geconstateerde onjuistheid controleren en
            indien noodzakelijk aanpassen in de database. <br /> <br />
            Wilt u een fout melden? Klik dan op onderstaande knop en stuur uw
            correctie naar brainbay.
            <br />
            {data?.objectGuid && (
              <a
                className="button button--primary button--small object-action-bar__button"
                href={`mailto:support@brainbay.nl?cc=g.raven@brainbay.nl&subject=Meld een fout, referentie: ${
                  data.objectGuid
                }&body=U wilt een fout melden over het volgende object:%0D%0A%0D%0A ${
                  defaultLocation + '/' + data.objectGuid
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Stuur een e-mail
              </a>
            )}
          </Modal>
        </Route>
      </Switch>
      <div className="object-action-bar print-excluded">
        {searchResultsUrl && (
          <div className="object-action-bar__left">
            <Link to={searchResultsUrl}>&lt; Terug naar Zoekresultaten</Link>
          </div>
        )}

        <div className="object-action-bar__right">
          <Link
            className="button button--primary button--small"
            to={`${baseUrl}/fout/`}
          >
            Meld een fout
          </Link>
        </div>
      </div>
    </>
  )
}
