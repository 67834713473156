import React from 'react'

import './gebouwpaspoort-launch-text.css'

const LaunchText = () => {
  return (
    <>
      <div className="gebouwpaspoort-launch-text body text-normal">
        <div className="gebouwpaspoort-launch-text__copy">
          <p>
            Met het gebouwenpaspoort heb je alle relevante objectkenmerken onder
            één dak. Dat scheelt tijd, geld en onnodige zorgen. We zijn
            ontzettend blij met de financiële steun van de NVM voor het
            Gebouwpaspoort afgelopen jaar. Hierdoor hebben alle leden er
            kosteloos gebruik van kunnen maken. Binnenkort komt aan deze mooie
            geste een einde. Wil je ook na 1 mei makkelijk en snel inzicht
            krijgen in bijvoorbeeld de bestemming, transactiehistorie, actuele
            gebruikers, bodemloket, Walkscore en andere gebouwkenmerken van een
            vastgoedobject, zoals een geautomatiseerde objectbeschrijving? Sluit
            dan vanaf 1 mei een abonnement af. Kijk op onze{' '}
            <a
              href="https://brainbay.nl/producten/gebouwpaspoort"
              rel="noopener noreferrer"
              target="_blank"
            >
              website
            </a>{' '}
            voor meer informatie.
          </p>
        </div>
      </div>
      <h2 className="h4 text-bold">
        Kadastertransacties beschikbaar!
        <p style={{ fontSize: '14px', color: '#888888' }}>4-10-2023</p>
      </h2>
      <div className="gebouwpaspoort-launch-text body text-normal">
        <div className="gebouwpaspoort-launch-text__copy">
          <p>
            Vanaf 4 oktober 2023 zijn ook kadastertransacties op het gebied van
            commercieel vastgoed zichtbaar in de Business Referentiemodule en
            het Gebouwpaspoort! De kadastertransacties zijn beschikbaar vanaf
            2019 en kan je vinden door in de filters onder de tab 'Bron', links
            op de overzichtpagina, de bron 'Kadaster' te selecteren. Via de
            detailpagina van het object kunt u de akte bij het kadaster
            opvragen. De kosten hiervoor bedragen 2,90 euro per opvraging.
          </p>
          <h2 className="gebouwpaspoort-launch-text__title h5 text-color--primary">
            Deel van de extra diensten
          </h2>
          <p>
            Het zichtbaar maken van de kadastertransacties zit op dit moment nog
            in de testfase, en u kunt daarom deze service gratis uitproberen. U
            beslist zelf of u gebruik wilt maken van deze feature als deze
            functie op aanvraag wordt. We zullen u uiteraard tijdig informeren
            als het tarief en de ingangsdatum bekend is.
          </p>
        </div>
        <div className="gebouwpaspoort-launch-text__copy">
          <h2 className="gebouwpaspoort-launch-text__title h5 text-color--primary">
            Hoe zorg ik ervoor dat kadastertransacties te zien zijn in mijn
            overzicht?
          </h2>
          <p>
            De kadastertransacties worden niet standaard weergegeven in u
            overzicht, dit om zoeken overzichtelijk te houden.
            kadastertransacties kun je inzien door de bron 'Kadaster' aan te
            vinken onder de tab 'Bron' in het filtermenu welke je links vindt op
            de overzichtpagina.
          </p>
        </div>
        <div className="gebouwpaspoort-launch-text__copy">
          <h2 className="gebouwpaspoort-launch-text__title h5 text-color--primary">
            Waarom zie ik een prijsrange bij de kadastertransacties en niet de
            exacte transactieprijs?
          </h2>
          <p>
            Door het tonen van een prijsrange kunt u als gebruiker een beeld
            krijgen van de koopsom en inschatten of de transactie voor u
            bruikbaar is zonder dat u meteen de kosten maakt voor het kopen van
            de exacte prijs.
          </p>
        </div>

        <div className="gebouwpaspoort-launch-text__copy">
          <h2 className="gebouwpaspoort-launch-text__title h5 text-color--primary">
            Hoe kan ik de daadwerkelijke prijs inzien?
          </h2>
          <p>
            Als u in het zoekresultaat op het adres van een kadastertransactie
            klikt, gaat u naar de detailpagina van de transactie. Hier staat
            onder de prijsrange de tekst “Koop akte”. Als u op die link klikt,
            wordt u automatisch doorgelinkt naar de pagina van het kadaster waar
            u de koopakte bij het kadaster kunt kopen. De kosten bedragen 2,90
            euro per akte. U betaalt dit bedrag aan het kadaster en kunt de akte
            dan als PDF downloaden op de site van het Kadaster.
          </p>
        </div>
        <div className="gebouwpaspoort-launch-text__copy">
          <h2 className="gebouwpaspoort-launch-text__title h5 text-color--primary">
            Kan ik de exacte koopsom ook bij brainbay kopen?
          </h2>
          <p>
            Wij werken eraan om dit mogelijk te maken, zodat we in de toekomst
            een nog vollediger beeld kunnen geven binnen de tool zonder dat u de
            akte hoeft op te vragen. Zodra dit mogelijk is, zullen we u daarover
            informeren.
          </p>
        </div>
        <div className="gebouwpaspoort-launch-text__copy">
          <h2 className="gebouwpaspoort-launch-text__title h5 text-color--primary">
            Waarom staan er veel woonobjecten tussen de transacties van het
            kadaster?
          </h2>
          <p>
            Het Kadaster bestempelt transacties waarbij de koper geen natuurlijk
            persoon is, ook als een commercieel vastgoed transactie. Vandaar dat
            ze in de tool staan opgenomen. De woningtransacties die in de tool
            getoond worden, hebben als koper veelal een B.V. of stichting.
          </p>
        </div>

        <div className="gebouwpaspoort-launch-text__copy">
          <h2 className="gebouwpaspoort-launch-text__title h5 text-color--primary">
            Waarom staan transacties er soms dubbel in, maar dan met een ander
            adres?
          </h2>
          <p>
            De data van het kadaster is gebaseerd op de betrokken kadastrale
            objecten (percelen of appartementsrechten). Om de data van het
            kadaster doorzoekbaar te maken registeren wij de adressen die bij
            een transactie horen. Indien meerdere adressen bij een transactie
            betrokken zijn tonen we die individueel in de tool als
            zoekresultaat, zodat u de transactie op elk betrokken adres kunt
            vinden. Op de detailpagina van de kadastertransacties ziet u in het
            informatieblok “Betrokken adressen” een overzicht van betrokken
            adressen, indien bij de transacties meer adressen betrokken zijn.
          </p>
        </div>
      </div>
      <h2 className="h4 text-bold">
        Gebouwpaspoort (bèta)
        <p style={{ fontSize: '14px', color: '#888888' }}>31-05-2023</p>
      </h2>

      <div className="gebouwpaspoort-launch-text body text-normal">
        <div className="gebouwpaspoort-launch-text__copy">
          <p>
            Brainbay heeft tijdens de Ledenvergadering Business van 31 mei jl.
            het Gebouwpaspoort officieel gelanceerd. Met het Gebouwpaspoort kun
            je elk vastgoedobject in Nederland opvragen en inzicht krijgen in de
            gebouwkenmerken. Het betreft een bèta (test) versie van de tool,
            zodat brainbay al in een vroeg stadium van de ontwikkeling feedback
            bij gebruikers kan ophalen. Het Gebouwpaspoort is onderdeel van de
            Business Referentiemodule. De bèta (test) versie van het
            Gebouwpaspoort is nu beschikbaar voor alle NVM leden!
          </p>
          <h2 className="gebouwpaspoort-launch-text__title h5 text-color--primary">
            Welke data bevat de bèta versie van het Gebouwpaspoort?
          </h2>
          <p>De bèta versie bevat momenteel de volgende gebouwinformatie:</p>

          <ol className="ordered-list ">
            <li>
              BAG data (gebouwoppervlakte, -bouwjaar, -functie en -status)
            </li>
            <li>Betrokken adressen</li>
            <li>Actuele energielabels</li>
            <li>Objecthistorie in brainbay database</li>
            <li>Bodemloketdata (bodemverontreiniging)</li>
            <li>Luchtfoto</li>
            <li>Kadastrale kaart</li>
            <li>Omgevingskaarten</li>
            <li>Walkscore</li>
            <li>Marktanalyse</li>
          </ol>
          <p>
            Het gebouwpaspoort is gebaseerd op de BAG (Basisregistratie Adressen
            en Gebouwen). Brainbay voegt hier allerlei extra datasets aan toe om
            u een zo goed en volledige beeld van het vastgoedobject te geven.
          </p>
        </div>
        <div className="gebouwpaspoort-launch-text__copy">
          <h2 className="gebouwpaspoort-launch-text__title h5 text-color--primary">
            Waarom is het een bèta versie?
          </h2>
          <p>
            Brainbay werkt momenteel aan de ontwikkeling van het Gebouwpaspoort.
            Om een zo goed mogelijke tool te ontwikkelen hebben we in een vroeg
            stadium van de ontwikkeling input en feedback van gebruikers nodig.
            Daarom stellen we nu al een bèta (test) versie van de tool
            beschikbaar aan gebruikers.
          </p>
        </div>
        <div className="gebouwpaspoort-launch-text__copy">
          <h2 className="gebouwpaspoort-launch-text__title h5 text-color--primary">
            Hoe werkt het gebouwpaspoort?
          </h2>
          <p>
            Op de landingspagina van de business Referentiemodule ziet u een
            zoekbalk met daarboven twee tabbladen. Via het eerste tabblad kunt u
            in de brainbay database zoeken naar referenties. In het tweede
            tabblad kunt u een adres of postcode en huisnummer invoeren om een
            gebouwpaspoort op te vragen. <br /> <br />
            Het Gebouwpaspoort gaat uit van gehele gebouwen (BAG panden). Een
            gebouw kan bestaan uit meerdere verblijfsobjecten met een eigen
            adres. Indien een object uit meerdere adressen (verblijfsobjecten)
            bestaat, hebben al deze adressen hetzelfde gebouwpaspoort.
          </p>
        </div>
        <div className="gebouwpaspoort-launch-text__copy">
          <h2 className="gebouwpaspoort-launch-text__title h5 text-color--primary">
            Welke features komen er nog aan?
          </h2>
          <p>
            Brainbay werkt hard om het gebouwpaspoort verder uit te breiden met
            relevante gebouwinformatie. De komende tijd zullen de volgende data
            worden toegevoegd:
          </p>
          <ol className="ordered-list">
            <li>Ruimtelijke plannen</li>
            <li>Reistijden</li>
            <li>Officiële bekendmakingen</li>
            <li>Referentiesuggesties (koop, huur, belegging)</li>
            <li>KvK data (gebruikers)</li>
            <li>Zoeken op kaart</li>
            <li>Printen naar PDF</li>
          </ol>
        </div>
        <div className="gebouwpaspoort-launch-text__copy">
          <h2 className="gebouwpaspoort-launch-text__title h5 text-color--primary">
            Kan ik de tool ook na de bèta periode gebruiken?
          </h2>
          <p>
            Ja, dat kan, maar als het gebouwpaspoort uit de bèta fase is, zal de
            tool een betaalde dienst worden. U beslist zelf of u wel of geen
            gebruik maakt van de tool. Naar verwachting wordt vanaf Q3 het
            tarief voor gebruik van het Gebouwpaspoort bekend gemaakt. Uiteraard
            mag u van ons verwachten dat er ruim van tevoren informatie en
            uitleg over de gebruiksvoorwaarden wordt gegeven.
          </p>
        </div>
        <div className="gebouwpaspoort-launch-text__copy">
          <h2 className="gebouwpaspoort-launch-text__title h5 text-color--primary">
            Hoe kan ik brainbay feedback geven over het gebruik van de tool?
          </h2>
          <p>
            U kunt brainbay feedback geven via de ‘feedback’ knop aan de
            rechterzijde van het scherm. U kunt hier de tool beoordelen en een
            reactie sturen over het gebruik via de tekstvak in het
            feedbackscherm. Uw beoordeling en feedback wordt zeer gewaardeerd.
          </p>
        </div>
      </div>
    </>
  )
}
export default LaunchText
