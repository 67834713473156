import React, { useEffect, useRef, useState } from 'react'
import {
  useHistory,
  useRouteMatch,
  Switch,
  Route,
  Link,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { generatePandDetailPDFDocument } from '../utils/generate-pdf-document'
import { loadBuildingByNummeraanduidingId } from '../store/buildings'
import { loadBusinessRegistratiesHistoryByPandId } from '../store/business-registraties-history'
import { setSearchMode } from '../store/search'
import { resetSearchBar, showSearchBar } from '../store/display-search-bar'
import { setBuildingDetails as setPageTitle } from '../store/page-title'
import { showExportButton } from '../store/export-button'
import useReferences from '../utils/use-references'
import useOnPopState from '../utils/use-on-pop-state'

import BuildingDetail from '../components/building/building-detail'
import BuildingHistory from '../components/building/building-history'
import BuildingAccommodationObjects from '../components/building/building-accommodation-objects'
import BuildingAddresses from '../components/building/building-addresses'
import BuildingCharacteristics from '../components/building/building-characteristics'
import ObjectReferences from '../components/object/object-references'
import BuildingMaps from '../components/building/building-maps'
import BuildingSoilInvestigation from '../components/building/building-soil-investigation'
import BuildingAerialPicture from '../components/building/building-aerial-picture'
import BuildingRuimtelijkePlannenMap from '../components/building/building-ruimtelijkeplannen-map'
import BuildingMarktAnalyse from '../components/building/building-marktanalyse-reports'
import BuildingWalkScore from '../components/building/building-walkscore'
import BuildingBekendmakingen from '../components/building/building-bekendmakingen'
import ExportPandDetails from '../components/export-pand-details/export-pand-details'

import PrintSettingsForm from '@brainbay/components/components/print-settings-form'
import Modal from '@brainbay/components/components/portal'
import formatAddressBusiness from '../utils/format-address-business'
import captureElementToCanvas from '../utils/capture-element-to-canvas'

import { selectWalkscore } from '../store/walkscore'
import { fetchWalkScoreData } from '../store/walkscore'
import {
  addObject,
  removeObject,
  expandComparePanel,
  setSortReferencesBy,
  enableComparePanel,
} from '../store/comparison'
import {
  deleteAllExcludedItems,
  deleteExcludedItem,
  setExcludedItem,
} from '../store/print-settings'

import './building.css'
import BuildingDescription from '../components/building/building-description'
import { fetchKvkUsers, selectKvkUsers } from '../store/kvk-users'
import KvkUsersTable from '../components/building/kvk-users'
import SectorDescription from '../components/building/building-sector-description'
import { selectLocalAnnouncements } from '../store/lokale-bekendmakingen'
import { gebouwpaspoortSources } from '../utils/constants'
import { isSafari } from '../utils/get-browser-info'
import useReturnToSearchResultsPage from '../utils/use-return-to-search-results-page'
import { ReactComponent as NoDataFound } from './no-data-found.svg'

export default function BuildingPassportView(props) {
  const match = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()
  const nummeraanduidingId = match?.params?.nummeraanduidingId
  const building = useSelector(state => state.buildings[nummeraanduidingId])
  const walkscore = useSelector(selectWalkscore)
  const kvkUsers = useSelector(selectKvkUsers)
  const localAnnouncements = useSelector(selectLocalAnnouncements)
  const businessRegistratieHistory = useSelector(
    state => state.businessRegistratiesHistories?.value,
  )

  const userObject = useSelector(state => state?.user)
  const searchType = useSelector(state => state.search.mode)
  const compareList = useSelector(state => state.comparison.compareList)
  const baseUrl = `/gebouwpaspoort/${nummeraanduidingId}`
  const { referencesAreLoading, referencesData, referencesError } =
    useReferences(nummeraanduidingId)
  const [address, setAddress] = useState({})
  const [formattedAddress, setFormattedAddress] = useState('')
  const searchResultsUrl = useReturnToSearchResultsPage(baseUrl)

  const PandRef = useRef(null)
  const BuildingDetailRef = useRef(null)
  const HistorieRef = useRef(null)
  const KenmerkenRef = useRef(null)
  const BetrokkenRef = useRef(null)
  const VerblijfsobjectenRef = useRef(null)
  const GebruikersRef = useRef(null)
  const LuchtfotoRef = useRef(null)
  const WalkscoreRef = useRef(null)
  const OmgevingskaartenRef = useRef(null)
  const BodemloketRef = useRef(null)
  const MarktAnalyseRef = useRef(null)
  const RuimtelijkeplannenRef = useRef(null)
  const BekendmakingenRef = useRef(null)
  const ReferentiesRef = useRef(null)

  function closeModal() {
    history.push(baseUrl)
  }
  useEffect(() => {
    if (!building && nummeraanduidingId) {
      dispatch(loadBuildingByNummeraanduidingId(nummeraanduidingId))
    }
  }, [dispatch, building, nummeraanduidingId])

  useEffect(() => {
    const { pand } = building || {}
    if (pand?.pandId) {
      // Fetch gebouwpaspoort history
      dispatch(loadBusinessRegistratiesHistoryByPandId(pand.pandId))
    }
  }, [dispatch, building])

  useEffect(() => {
    const { geselecteerdeAdres } = building || {}
    setAddress(geselecteerdeAdres)
  }, [building])

  useEffect(() => {
    if (address) {
      dispatch(fetchWalkScoreData(address))
      dispatch(fetchKvkUsers({ address, page: 1 }))
    }
  }, [address, dispatch])

  useEffect(() => {
    const formattedAddressFromHelper = formatAddressBusiness({
      street: address?.straatnaam,
      houseNumber: address?.huisnummer,
      houseNumberSuffix: address?.huisnummertoevoeging,
      postalCode: address?.postcode,
      city: address?.woonplaats,
      municipality: address?.gemeente,
    })
    setFormattedAddress(formattedAddressFromHelper)
  }, [address])

  useEffect(() => {
    dispatch(setPageTitle())
    dispatch(showSearchBar())
    dispatch(enableComparePanel())
    dispatch(showExportButton())

    if (searchType !== 'switch-gebouwpaspoort') {
      dispatch(setSearchMode('switch-gebouwpaspoort'))
    }

    return () => {
      resetSearchBar()
    }
  }, [dispatch, searchType])

  useOnPopState(() => {
    const params = new URLSearchParams(window.location.search)

    if (params.has('sortReferenceBy')) {
      dispatch(setSortReferencesBy(params.get('sortReferenceBy')))
    }
  }, true)

  function handleAddButton(guid) {
    dispatch(expandComparePanel())
    if (compareList.includes(guid)) {
      dispatch(removeObject(guid))
    } else {
      dispatch(addObject(guid))
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleTabClick = ref => {
    window.scrollTo({ top: ref.current.offsetTop - 48, behavior: 'smooth' })
  }

  const excludedPrintItems = useSelector(state => state.printSettings)
  const isGebouwpaspoort =
    building?.pand && Object.keys(building.pand).length > 0

  async function handleExportPDF() {
    const omgevingskaartenMapElement = document.getElementById(
      'omgevingskaartenMapElement',
    )
    const bodemloketMapElement = document.getElementById('bodemloketMapElement')
    const ruimtelijkePlannenMapElement = document.getElementById(
      'ruimtelijkePlannenMap',
    )
    const luchtfotoMapElement = document.getElementById('luchtFotoMapElement')

    const omgevingskaartenMapImage = await captureElementToCanvas(
      omgevingskaartenMapElement,
    )

    const luchtfotoMapImage = await captureElementToCanvas(luchtfotoMapElement)

    const bodemloketMapImage =
      await captureElementToCanvas(bodemloketMapElement)

    const ruimtelijkePlannenMapImage = await captureElementToCanvas(
      ruimtelijkePlannenMapElement,
    )

    await generatePandDetailPDFDocument(
      userObject,
      building,
      businessRegistratieHistory,
      excludedPrintItems,
      `${isGebouwpaspoort ? 'Gebouwpaspoort' : ''} ${formattedAddress}.pdf`,
      walkscore,
      kvkUsers,
      omgevingskaartenMapImage,
      luchtfotoMapImage,
      bodemloketMapImage,
      ruimtelijkePlannenMapImage,
      localAnnouncements,
    )
  }

  const settings = [
    {
      key: 'object-description',
      name: 'Objectbeschrijving',
    },
    {
      key: 'pand-details',
      name: 'Pand details',
    },
    ...(businessRegistratieHistory && businessRegistratieHistory.length > 0
      ? [
          {
            key: 'pand-history',
            name: 'Historie in Brainbay Business database',
          },
        ]
      : []),
    {
      key: 'betrokken-adressen',
      name: 'Betrokken adressen',
    },
    {
      key: 'verblijfsobjecten',
      name: 'Verblijfsobjecten',
    },
    {
      key: 'gebruikers',
      name: 'Actuele Gebruikers',
    },
    {
      key: 'luchtfoto',
      name: 'Luchtfoto',
    },
    ...(!isSafari
      ? [
          {
            key: 'omgevingskaarten',
            name: 'Omgevingskaarten',
          },
        ]
      : []),
    ...(!isSafari
      ? [
          {
            key: 'ruimtelijke-plannen',
            name: 'Ruimtelijke Plannen',
          },
        ]
      : []),
    {
      key: 'lokale-bekendmakingen',
      name: 'Lokale bekendmakingen',
    },
    ...(!isSafari
      ? [
          {
            key: 'bodemloket',
            name: 'Bodemloket',
          },
        ]
      : []),
    {
      key: 'walkscore',
      name: 'Walkscore',
    },
    {
      key: 'sector-description',
      name: 'Sectorbeschrijving',
    },
  ]
  function printPdf() {
    setTimeout(() => {
      // wait for the partial transactions to be expanded
      window.print()
    }, 500)
  }
  function onExportReset() {
    dispatch(deleteAllExcludedItems())
    closeModal()
  }
  function printSettingChange(event, setting) {
    event.target.checked
      ? dispatch(deleteExcludedItem(setting))
      : dispatch(setExcludedItem(setting))
  }
  const navButtons = [
    {
      label: 'Overzicht',
      ref: PandRef,
    },
    {
      label: 'Beschrijving',
      ref: BuildingDetailRef,
    },
    {
      label: 'Historie',
      ref: HistorieRef,
    },
    {
      label: 'Kenmerken',
      ref: KenmerkenRef,
    },
    {
      label: ' Betrokken adressen',
      ref: BetrokkenRef,
    },
    {
      label: 'Verblijfsobjecten',
      ref: VerblijfsobjectenRef,
    },
    {
      label: 'Gebruikers',
      ref: GebruikersRef,
    },
    {
      label: 'Luchtfoto',
      ref: LuchtfotoRef,
    },
    {
      label: 'Omgevingskaarten',
      ref: OmgevingskaartenRef,
    },
    {
      label: 'Ruimtelijke plannen',
      ref: RuimtelijkeplannenRef,
    },
    {
      label: 'Bekendmakingen',
      ref: BekendmakingenRef,
    },
    {
      label: 'Bodemloket',
      ref: BodemloketRef,
    },
    {
      label: 'Walkscore',
      ref: WalkscoreRef,
    },

    {
      label: 'Marktanalyse',
      ref: MarktAnalyseRef,
    },
    {
      label: 'Referenties',
      ref: ReferentiesRef,
    },
  ]

  if (building?.error)
    return (
      <main className="object layout__grid">
        <div className="no-data-found">
          <NoDataFound />
          <p className="no-data-found__message">
            De gegevens voor het door u opgevraagde adres zijn niet beschikbaar
            in het gebouwpaspoort. Houd er rekening mee dat het gezochte adres
            een verblijfsobject moet zijn en geen lig- of standplaats.
          </p>
        </div>
      </main>
    )
  return (
    <>
      <main className="object layout__grid">
        <Switch>
          <Route path={`${baseUrl}/bronnen`}>
            <Modal
              key="modal-home-improvement"
              onClose={() => history.push(baseUrl)}
            >
              <h3 className="h5 gebouwpaspoort__sources__table-title">
                Metadata
              </h3>
              <p className="body">
                Hieronder vind je een overzicht van de bronnen en datasets die
                in het Gebouwpaspoort worden gebruikt met daarbij de updatedatum
                of -frequentie.
              </p>
              <table className="gebouwpaspoort__sources__table body">
                <thead>
                  <tr>
                    <th className="gebouwpaspoort__sources__table-header"></th>
                    <th className="gebouwpaspoort__sources__table-header">
                      Bron
                    </th>
                    <th className="gebouwpaspoort__sources__table-header">
                      Dataset
                    </th>
                    <th className="gebouwpaspoort__sources__table-header">
                      Updates
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {gebouwpaspoortSources.map((bron, index) => (
                    <tr key={index}>
                      <td>{`${index + 1}.`}</td>
                      <td className="gebouwpaspoort__sources__table-data">
                        {bron.source}
                      </td>
                      <td className="gebouwpaspoort__sources__table-data">
                        {bron.dataset}
                      </td>
                      <td className="gebouwpaspoort__sources__table-data">
                        {bron.updates}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Modal>
          </Route>
        </Switch>
        <div className="nav-tabs">
          <nav className="nav-buttons-container">
            {navButtons.map((button, index) => (
              <button
                key={index}
                className="tab-button"
                onClick={() => handleTabClick(button.ref)}
              >
                {button.label}
              </button>
            ))}
          </nav>
        </div>
        <div className="gebouwpaspoort__navigation-bar print-excluded">
          {searchResultsUrl && (
            <div className="gebouwpaspoort__navigation__search-results">
              <Link to={searchResultsUrl}>&lt; Terug naar Zoekresultaten</Link>
            </div>
          )}
          <div className="gebouwpaspoort__navigation__sources">
            <Link
              className="panel__header-link print-excluded"
              to={`${baseUrl}/bronnen`}
            >
              {'Meer informatie over de bronnen die we gebruiken'}
            </Link>
          </div>
        </div>
        <BuildingDetail
          baseUrl={baseUrl}
          data={building}
          onCloseModal={closeModal}
          ref={PandRef}
        />
        <BuildingDescription data={building} ref={BuildingDetailRef} />
        <BuildingHistory
          building={{
            businessRegistratieHistories: businessRegistratieHistory,
          }}
          ref={HistorieRef}
        />
        <BuildingCharacteristics
          data={building}
          baseUrl={baseUrl}
          onCloseModal={closeModal}
          ref={KenmerkenRef}
        />
        <BuildingAddresses data={building} ref={BetrokkenRef} />
        <BuildingAccommodationObjects
          data={building}
          ref={VerblijfsobjectenRef}
        />
        <KvkUsersTable
          data={kvkUsers}
          address={address}
          formattedAddress={formattedAddress}
          ref={GebruikersRef}
        />
        <BuildingAerialPicture data={building} ref={LuchtfotoRef} />
        <BuildingMaps data={building} ref={OmgevingskaartenRef} />
        <BuildingRuimtelijkePlannenMap
          data={building}
          formattedAddress={formattedAddress}
          ref={RuimtelijkeplannenRef}
        />
        <BuildingBekendmakingen data={building} ref={BekendmakingenRef} />
        <BuildingSoilInvestigation data={building} ref={BodemloketRef} />
        <BuildingWalkScore data={building} ref={WalkscoreRef} />
        <SectorDescription data={building} />
        <BuildingMarktAnalyse ref={MarktAnalyseRef} />
        <ObjectReferences
          className="print-excluded"
          isLoading={referencesAreLoading}
          data={referencesData}
          error={referencesError}
          nummeraanduidingId={nummeraanduidingId}
          onAddToggle={handleAddButton}
          compareList={compareList}
          ref={ReferentiesRef}
        />
        <ExportPandDetails
          onClose={onExportReset}
          settings={
            <PrintSettingsForm
              onPrintPdf={printPdf}
              onFormSubmit={handleExportPDF}
              excludedPrintItems={excludedPrintItems}
              onChangeCheckbox={printSettingChange}
              objectSections={settings}
              warningMessage={
                isSafari &&
                'Het printen naar PDF werkt niet goed in de Safari-browser. Bepaalde rapportonderdelen worden niet geprint. Als je de volledige PDF wilt verkrijgen, maak dan gebruik van de Chrome-browser.'
              }
              showSmallerSections
              hideRemarks
            />
          }
        />
      </main>
    </>
  )
}
