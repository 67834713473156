import { createSlice } from '@reduxjs/toolkit'

const searchResultMode = createSlice({
  name: 'searchResultMode',
  initialState: {
    isMapMode: window.location.pathname.includes('/map'),
    isCompactMode: window.location.pathname.includes('/compact-lijst'),
    loadingState: true,
  },
  reducers: {
    setMapMode: state => {
      state.isMapMode = true
      state.isCompactMode = false
    },
    setListMode: state => {
      state.isMapMode = false
      state.isCompactMode = false
    },
    setCompactMode: state => {
      state.isCompactMode = true
      state.isMapMode = false
    },
    toggleMode: state => {
      state.isMapMode = !state.isMapMode
      state.isCompactMode = !state.isCompactMode
    },
  },
})

export const { setMapMode, setListMode, setCompactMode, toggleMode } =
  searchResultMode.actions

export default searchResultMode.reducer
