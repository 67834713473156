let hasSupport = undefined

export default function supportsInputDate() {
  if (hasSupport !== undefined) return hasSupport

  const input = document.createElement('input')
  input.setAttribute('type', 'date')

  if (input.type === 'date') {
    hasSupport = true
  } else {
    hasSupport = false
  }

  return hasSupport
}
