import React, { useEffect, useMemo, useState } from 'react'
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  useHistory,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { showExportButton } from '../store/export-button'
import { setSearchResult as setPageTitle } from '../store/page-title'
import { resetSearchBar, showSearchBar } from '../store/display-search-bar'
import { loadObjectByObjectGuid } from '../store/objects'
import {
  setMapMode,
  setListMode,
  setCompactMode,
} from '../store/search-result-mode'
import {
  addObject,
  removeObject,
  setSortBy,
  setSortDirection,
  setNewFilterValue,
  setAutoCompleteOption,
  setNewSearchValue,
  expandComparePanel,
  setRadius,
  enableComparePanel,
} from '../store/comparison'
import formatFilterRequestData from '../utils/format-filter-request-data'
import ExportSearchResults from '../components/export-search-results'
import SearchResultItem from '../components/search-result-item'
import SearchResultsCompact from '../components/search-result-compact-list/search-result-compact-list'
import { SkiplinkTarget } from '../components/skiplinks'
import SaveButton from '../components/save-button'
import MapPortal from '../components/map-portal'
import MapBig from '../components/map-big'
import MapListSwitch from '../components/map-list-switch'
import MapHighlightedObjects from '../components/map-highlighted-objects'
import isSSR from '@brainbay/components/utils/is-ssr'
import Pagination from '@brainbay/components/components/pagination'
import SearchResultList from '@brainbay/components/components/search-result-list'
import SortSelect from '@brainbay/components/components/sort-select'
import useResizeObserver from '@brainbay/components/utils/use-resize-observer'
import { useDebouncedCallback } from '@brainbay/components/utils/use-debounce'
import viewportHack from '@brainbay/components/utils/viewport-hack'
import FilterBar from '@brainbay/components/components/filters/filter-bar'
import NotificationPanel from '@brainbay/components/components/notification-panel'
import { get } from '../utils/api-data'

import './search-results.css'
import useFeatureFlags from '../utils/use-feature-flags'
import useOnPopState from '../utils/use-on-pop-state'
import useApiCall from '../utils/use-api-call'
import { setInitialBounds } from '../store/map-big'
import { defaultSearchOption } from '../utils/constants'
import { getFormattedSearchQueryResultTitle } from '../utils/get-formatted-search-query'
import { setSearchQuery } from '../store/search'
import { useDebouncedEffect } from '../utils/use-debounced-effect'
import { setFilterCatalog } from '../store/filtercatalog'
import FluxModalComponent from '../components/flux-connection-modal/flux-connection-modal'
import { fluxParams } from '../utils/flux-params'

const searchParamsToObject = params =>
  [...params.keys()].reduce((list, key) => {
    const allValues = params.getAll(key)
    const value = allValues.length === 1 ? allValues[0] : allValues

    return { ...list, [key]: value }
  }, {})

const NoQueryProvided = () =>
  isSSR ? (
    <SearchResultList query={' '} />
  ) : (
    <p className="body-big">Voer een adres in het zoekveld in</p>
  )

const loadingData = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(nr => ({
  id: `${nr}`,
}))

/**
 * Hook to determine the search text based on the given query
 * @param {*} query
 * @returns
 */
const useSearchQueryResolver = query => {
  const dispatch = useDispatch()
  const autocompleteOption = useSelector(
    state => state.comparison?.autocompleteOption,
  )

  const { post } = useApiCall()

  useEffect(() => {
    post({
      path: `suggestion/resolve`,
      body: {
        query,
      },
    }).then(geoSuggestion => {
      if (geoSuggestion) {
        const { boundingBox, ...newAutocompleteOption } = geoSuggestion

        dispatch(setAutoCompleteOption(newAutocompleteOption))
        dispatch(setInitialBounds(boundingBox))
      }
      dispatch(setNewSearchValue(query))
    })
  }, [dispatch, post, query])

  return getFormattedSearchQueryResultTitle(autocompleteOption)
}

/**
 * Hook to manage highlighted features on the map
 * @param {*} loadedObjects
 * @returns
 */
const useHighlightedFeatures = loadedObjects => {
  const dispatch = useDispatch()
  const [highlightedFeaturesOnMap, setHighlightedFeaturesOnMap] = useState([])

  const highlightedObjects = useMemo(() => {
    return highlightedFeaturesOnMap
      .map(feature => feature?.properties?.objectGuid)
      .map(guid => loadedObjects[guid])
      .filter(x => x)
  }, [highlightedFeaturesOnMap, loadedObjects])

  /** Load highlighted features data */
  useEffect(() => {
    highlightedFeaturesOnMap.forEach(feature => {
      if (
        feature?.properties?.objectGuid &&
        Object.keys(loadedObjects).includes(feature?.properties?.objectGuid) ===
          false
      ) {
        dispatch(loadObjectByObjectGuid(feature?.properties?.objectGuid))
      }
    })
  }, [dispatch, highlightedFeaturesOnMap, loadedObjects])

  return {
    highlightedFeaturesOnMap,
    highlightedObjects,
    setHighlightedFeaturesOnMap,
  }
}

export default function SearchResults({ onExport }) {
  const { isEnabled } = useFeatureFlags()

  const [resultSize, setResultSize] = useState('large')
  const [totalPages, setTotalPages] = useState(0)
  const [totalResults, setTotalResults] = useState(undefined)
  const [dataIsLoading, setDataIsLoading] = useState(true)
  const [data, setData] = useState(loadingData)
  const [facets, setFacets] = useState({})
  const [dataError, setDataError] = useState(false)
  const [pagination, setPagination] = useState({})
  const [searchQueryParams, setSearchQueryParams] = useState({})
  const [locationObject, setLocationObject] = useState({})
  const [requestFiltersObject, setRequestFiltersObject] = useState({})
  const { currentRoute, previousRoute } = useSelector(
    state => state.routeHistory,
  )
  const nameTypeMap = useSelector(state => state?.filtercatalog?.nameTypeMap)
  const filterCatalog = useSelector(state => state.filtercatalog)
  const isMapMode = useSelector(state => state.searchResultMode.isMapMode)
  const isCompactMode = useSelector(
    state => state.searchResultMode.isCompactMode,
  )
  const radiusValue = useSelector(state => state.comparison.radius)
  const expandedComparison = useSelector(
    state => state.comparison.comparePanel === 'expand',
  )
  const compareList = useSelector(state => state.comparison.compareList)
  const loadedObjects = useSelector(state => state.objects)

  const dispatch = useDispatch()
  const location = useLocation()
  const {
    isAllFluxParamsExist: isShowSendFluxButton,
    fluxId,
    fluxSessionContext,
    fluxAddress,
  } = fluxParams()

  const { filter, sortBy, sortDirection, radius } = useSelector(
    state => state.comparison,
  )

  const filterValues = useMemo(() => {
    const params = new URLSearchParams(filter)
    params.delete('radius')
    params.delete('sortBy')
    params.delete('sortDirection')
    return searchParamsToObject(params)
  }, [filter])

  const history = useHistory()
  const match = useRouteMatch()
  const query = match.params.query

  const q = query === defaultSearchOption.id ? '' : query
  const page = match.params.page
  const pageValue =
    page === undefined || page === 'compact-lijst'
      ? 1
      : page === 'map'
      ? 'map'
      : page
  const rootUri = `/search-results/${query}`
  const numberOfItems = 20
  const hasQuery = Boolean(query)
  const { type, label } = useSearchQueryResolver(query)

  const {
    highlightedFeaturesOnMap,
    highlightedObjects,
    setHighlightedFeaturesOnMap,
  } = useHighlightedFeatures(loadedObjects)

  useEffect(() => {
    setHighlightedFeaturesOnMap([])
  }, [label, setHighlightedFeaturesOnMap])

  useOnPopState(() => {
    const params = new URLSearchParams(window.location.search)

    if (params.has('sortBy')) {
      dispatch(setSortBy(params.get('sortBy')))
    }
    if (params.has('sortDirection')) {
      dispatch(setSortDirection(params.get('sortDirection')))
    }
    if (params.has('radius')) {
      dispatch(setRadius(params.get('radius')))
    }
    if (
      params.has('fluxid') &&
      params.has('sessionContext') & params.has('address')
    ) {
      // do not include flux related params to filterValue
      params.delete('fluxid')
      params.delete('sessionContext')
      params.delete('address')
    }

    dispatch(setNewFilterValue(params.toString()))

    const urlIncludesMapPath = window.location.pathname.includes('/map')
    const urlIncludesCompactPath =
      window.location.pathname.includes('/compact-lijst')

    if (urlIncludesMapPath) {
      dispatch(setMapMode())
    } else if (urlIncludesCompactPath) {
      dispatch(setCompactMode())
    } else {
      dispatch(setListMode())
    }
  }, true)

  useEffect(() => {
    viewportHack()
    get('filtercatalog')
      .then(data => {
        const search = new URLSearchParams(window.location.search)
        const sortOption = data.sortOptions.find(({ selected }) => selected)
        const sortBy = defaultSearchOption.sortBy
        const sortDirection =
          sortOption?.defaultDirection || defaultSearchOption.sortDirection
        dispatch(setFilterCatalog(data))
        dispatch(setSortBy(search.get('sortBy') || sortBy))
        dispatch(setSortDirection(search.get('sortDirection') || sortDirection))
      })
      .catch(error => setFilterCatalog({ error }))
  }, [dispatch])
  const { ref } = useResizeObserver({
    onResize: ({ width }) => {
      window.requestAnimationFrame(() => {
        if (width >= 1250) {
          setResultSize('large')
        } else {
          setResultSize('small')
        }
      })
    },
  })

  const handleMapViewportUpdate = useDebouncedCallback(newViewport => {
    const lat = newViewport.latitude
    const lng = newViewport.longitude
    const zoom = newViewport.zoom

    if (isMapMode) {
      history.replace(`${rootUri}/map/${lat}/${lng}/${zoom}${location.search}`)
    }
  }, 100)

  function handleFormChange(searchParams) {
    const currentUri = isCompactMode
      ? `${rootUri}/compact-lijst`
      : isMapMode
      ? `${rootUri}/map`
      : rootUri

    if (sortBy) {
      searchParams.append('sortBy', sortBy)
    }
    if (sortDirection) {
      searchParams.append('sortDirection', sortDirection)
    }

    if (fluxId && fluxSessionContext && fluxAddress) {
      history.push(
        `${currentUri}?${searchParams.toString()}&fluxid=${fluxId}&sessionContext=${fluxSessionContext}&address=${fluxAddress}`,
      )
    } else {
      history.push(`${currentUri}?${searchParams.toString()}`)
    }

    dispatch(setNewFilterValue(searchParams.toString()))
  }

  function handleSortChange(
    { sortBy, sortDirection } = {},
    isCompactMode = false,
  ) {
    const params = new URLSearchParams(window.location.search)
    if (sortBy) {
      dispatch(setSortBy(sortBy))
      params.set('sortBy', sortBy)
    }
    if (sortDirection) {
      dispatch(setSortDirection(sortDirection))
      params.set('sortDirection', sortDirection)
    }
    dispatch(setNewFilterValue(params.toString()))

    if (isCompactMode) {
      history.push(`${rootUri}/compact-lijst?${params}`)
    } else {
      history.push(`${rootUri}?${params}`)
    }
  }

  function handleAddButton(guid) {
    dispatch(expandComparePanel())
    if (compareList.includes(guid)) {
      dispatch(removeObject(guid))
    } else {
      dispatch(addObject(guid))
    }
  }

  function handleMapListSwitch(mode) {
    let url = rootUri
    setDataIsLoading(true)

    if (mode === 'map') {
      dispatch(setMapMode())
      url += '/map' + location.search
    } else if (mode === 'compact') {
      dispatch(setCompactMode())
      url += '/compact-lijst' + location.search
    } else {
      // Default to the list mode
      dispatch(setListMode())
      url += location.search
    }
    dispatch(setSearchQuery(url))
    history.push(url)
    setTimeout(() => viewportHack(), 10)
  }

  /** Focus page title on route change */
  useEffect(() => {
    if (currentRoute && previousRoute) {
      if (currentRoute !== previousRoute) {
        const pageTitleElement = document.querySelector('h1')
        if (pageTitleElement) {
          pageTitleElement.tabIndex = '-1'
          pageTitleElement.focus()
        }
      }
    }
  }, [currentRoute, previousRoute])

  /** Set the page title and display search bar */
  useEffect(() => {
    dispatch(setPageTitle({ pageValue, query, totalPages }))
    dispatch(showSearchBar())
    dispatch(showExportButton())
    dispatch(enableComparePanel())
    return () => resetSearchBar()
  }, [dispatch, query, pageValue, totalPages])

  const { post } = useApiCall()

  /** Load new search results */
  useDebouncedEffect(
    () => {
      if (query && nameTypeMap) {
        setDataIsLoading(true)
        setData(loadingData)
        setDataError(false)

        const location = {
          q: query === defaultSearchOption.id ? '' : query,
          radius: !!radius ? parseInt(radius) : undefined,
        }

        const filter = {
          ...formatFilterRequestData(filterValues, nameTypeMap),
          sortDirection,
          sortBy,
        }

        setRequestFiltersObject(filter)
        setLocationObject(location)

        post({
          path: 'objects/by-search',
          body: {
            location,
            skip: pageValue * numberOfItems - numberOfItems,
            take: numberOfItems,
            filter,
          },
        })
          .then(response => {
            const { data, facets, ...pagination } = response
            const totalPages = Math.ceil(pagination.count / pagination.pageSize)
            const totalResults = response.count
            setData(data)
            setFacets(facets)
            setDataError(false)
            setPagination(pagination)
            setTotalPages(totalPages)
            setTotalResults(totalResults)
            setSearchQueryParams({ type, label })
            setDataIsLoading(false)
          })
          .catch(e => {
            if (e.name !== 'AbortError') {
              setDataError(true)
              setData([])
              setPagination({})
              setTotalPages(null)
              setDataIsLoading(false)
            }
          })
      }
    },
    [
      nameTypeMap,
      pageValue,
      isCompactMode,
      isMapMode,
      post,
      query,
      radius,
      filterValues,
      sortBy,
      sortDirection,
      type,
      label,
    ],
    300,
  )

  return (
    <>
      {isEnabled('myComparisons') && <SaveButton />}
      <main
        ref={ref}
        className={`search-results ${isMapMode ? 'search-results--map' : ''} ${
          expandedComparison ? 'search-results--comparison' : ''
        }`.trim()}
        data-result-size={resultSize}
      >
        <SkiplinkTarget
          id="search-results__filters"
          skiplinkLabel="Snel naar filters"
          className={`search-results__sidebar search-results__sidebar--position-${
            isMapMode ? 'left' : 'right'
          } search-results__filters`}
        >
          <FilterBar
            filterCatalog={filterCatalog}
            filters={filterValues}
            filterSectionIsOpen={false}
            facets={facets}
            onSubmit={handleFormChange}
          />
        </SkiplinkTarget>
        <SkiplinkTarget
          id="search-results__content"
          className="search-results__content"
          skiplinkLabel="Snel naar zoekresultaten"
        >
          {isShowSendFluxButton && !isMapMode && (
            <NotificationPanel
              notification={`Selecteer referentieobjecten voor de taxatie met Flux-ID ${fluxId} en adres ${fluxAddress}`}
              className="search-results__flux-notification"
            />
          )}
          <Switch>
            <Route path="/search-results/:query/:page?/map/:lat?/:lng?/:zoom?">
              <>
                <MapPortal>
                  <MapBig
                    filters={filterValues}
                    query={query}
                    highlightedFeatures={highlightedFeaturesOnMap}
                    onChangedHighlightedFeature={setHighlightedFeaturesOnMap}
                    onViewportChange={handleMapViewportUpdate}
                  />
                </MapPortal>
                <div className="search-results__map-controls" id="map-controls">
                  <MapListSwitch
                    className="search-results__map-list-switch--map"
                    current={isMapMode ? 'map' : 'list'}
                    onChange={handleMapListSwitch}
                  />
                  <MapHighlightedObjects
                    loadedObjects={loadedObjects}
                    features={highlightedFeaturesOnMap}
                    onAddToggle={handleAddButton}
                  />
                  {highlightedObjects
                    .filter((item, index) => index === 0)
                    .map(item => (
                      <SearchResultItem
                        key={item.objectGuid}
                        standalone
                        item={item}
                        isAdded={compareList.includes(item.objectGuid)}
                        isLoading={item.loading}
                        onAddToggle={handleAddButton}
                      />
                    ))}
                </div>
              </>
            </Route>
            <Route path="/search-results/:query/compact-lijst">
              <MapListSwitch
                className="search-results__map-list-switch--list"
                current={isMapMode ? 'map' : 'compact'}
                onChange={handleMapListSwitch}
              />
              <FluxModalComponent />
              {hasQuery ? (
                dataError ? (
                  <p className="body-big">
                    We kunnen de data voor <q>{query}</q>{' '}
                    {pageValue > 1 && `op pagina ${pageValue}`} niet ophalen
                  </p>
                ) : (
                  <>
                    <h2 className="sr-only">Zoekresultaten</h2>
                    <SearchResultList
                      query={
                        searchQueryParams?.label || defaultSearchOption.label
                      }
                      radius={radiusValue}
                      type={searchQueryParams?.type}
                      hasResults={data?.length}
                      isLoading={dataIsLoading}
                      error={data?.error}
                      totalResults={totalResults}
                      filterOptions={
                        <SortSelect
                          sortBy={sortBy}
                          sortDirection={sortDirection}
                          sortOptions={filterCatalog.sortOptions}
                          onChangeSort={sortBy =>
                            handleSortChange({ sortBy }, true)
                          }
                          onChangeDirection={sortDirection =>
                            handleSortChange({ sortDirection }, true)
                          }
                        />
                      }
                      autoSizedRows
                    />
                    <SearchResultsCompact
                      data={data}
                      isLoading={dataIsLoading}
                    />
                    <Pagination
                      page={Number(pageValue)}
                      count={pagination?.count}
                      pageSize={numberOfItems}
                      rootUri={rootUri}
                      queryParams={location.search}
                      isCompactMode={isCompactMode}
                    />
                    <ExportSearchResults
                      query={q}
                      filterValues={requestFiltersObject}
                      parentLocation={locationObject}
                    />
                  </>
                )
              ) : (
                <NoQueryProvided />
              )}
              <FluxModalComponent />
            </Route>
            <Route path="/search-results/:query">
              <MapListSwitch
                className="search-results__map-list-switch--list"
                current={isMapMode ? 'map' : 'list'}
                onChange={handleMapListSwitch}
              />
              <FluxModalComponent />
              {hasQuery ? (
                dataError ? (
                  <p className="body-big">
                    We kunnen de data voor <q>{query}</q>{' '}
                    {pageValue > 1 && `op pagina ${pageValue}`} niet ophalen
                  </p>
                ) : (
                  <>
                    <h2 className="sr-only">Zoekresultaten</h2>
                    <SearchResultList
                      query={
                        searchQueryParams?.label || defaultSearchOption.label
                      }
                      radius={radiusValue}
                      type={searchQueryParams?.type}
                      hasResults={data?.length}
                      isLoading={dataIsLoading}
                      error={data?.error}
                      totalResults={totalResults}
                      filterOptions={
                        <SortSelect
                          sortBy={sortBy}
                          sortDirection={sortDirection}
                          sortOptions={filterCatalog.sortOptions}
                          onChangeSort={sortBy => handleSortChange({ sortBy })}
                          onChangeDirection={sortDirection =>
                            handleSortChange({ sortDirection })
                          }
                        />
                      }
                      autoSizedRows
                    >
                      {data.map(item => (
                        <SearchResultItem
                          key={item.id}
                          item={item}
                          isAdded={compareList.includes(item.objectGuid)}
                          isLoading={dataIsLoading}
                          onAddToggle={handleAddButton}
                        />
                      ))}
                    </SearchResultList>
                    <Pagination
                      page={Number(pageValue)}
                      count={pagination?.count}
                      pageSize={numberOfItems}
                      rootUri={rootUri}
                      queryParams={location.search}
                    />
                    <ExportSearchResults
                      query={q}
                      filterValues={requestFiltersObject}
                      parentLocation={locationObject}
                    />
                  </>
                )
              ) : (
                <NoQueryProvided />
              )}
            </Route>
          </Switch>
        </SkiplinkTarget>
        <div></div>
      </main>
    </>
  )
}
