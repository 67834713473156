import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { fluxParams } from './flux-params'

/**
 * Hook to determine if we should show the 'back to search results' button.
 * This should only be shown when we enter the object page and should not be hidden when we enter a subpage of the object page.
 * @param {string} baseUrl
 * @returns {string | null}
 */
const useReturnToSearchResultsPage = baseUrl => {
  const [returnUrl, setReturnUrl] = useState('/')
  const filterString = useSelector(state => state.comparison.filter)
  const sortBy = useSelector(state => state.comparison.sortBy)
  const sortDirection = useSelector(state => state.comparison.sortDirection)
  const previousUrl = useSelector(state => state?.routeHistory?.previousRoute)
  const location = useLocation()

  useEffect(() => {
    if (
      previousUrl &&
      !previousUrl.startsWith(baseUrl) && // when 'returning' to the object after visiting the images
      location.pathname === baseUrl
    ) {
      if (/search-results/.test(previousUrl)) {
        const {
          isAllFluxParamsExist,
          fluxId,
          fluxSessionContext,
          fluxAddress,
        } = fluxParams()

        const newReturnUrl = !isAllFluxParamsExist
          ? `${previousUrl}?${filterString}`
          : `${previousUrl}?${filterString}&sessionContext=${fluxSessionContext}&address=${fluxAddress}&fluxid=${fluxId}`

        setReturnUrl(newReturnUrl)
      } else {
        setReturnUrl(null)
      }
    }
  }, [
    baseUrl,
    filterString,
    location.pathname,
    previousUrl,
    sortBy,
    sortDirection,
  ])

  return returnUrl
}

export default useReturnToSearchResultsPage
