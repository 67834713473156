import { createSlice } from '@reduxjs/toolkit'

const priceIndex = createSlice({
  name: 'price-index',
  initialState: {},
  reducers: {
    setPriceIndex: (_, { payload }) => payload,
  },
})

export const { setPriceIndex } = priceIndex.actions

export default priceIndex.reducer
