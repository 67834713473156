import React from 'react'
import ObjectDetailsDefinitionList from '@brainbay/components/components/object-details-definition-list'
import formatValue from '@brainbay/components/utils/format-value'
import EnergyLabel from '@brainbay/components/components/energy-label'

import './search-result-item-details.css'
import useObjectPrices from '../../utils/use-object-prices'
import useObjectDate from '../../utils/use-object-date'
import useObjectSurface from '../../utils/use-object-surface'

const LoadingItems = [1, 2, 3, 4, 5].map(item => (
  <React.Fragment key={item}>
    <dt></dt>
    <dd></dd>
  </React.Fragment>
))

export default function SearchResultItemDetails({
  isLoading,
  data,
  verblijfsobject,
  className = '',
  isGebouwpaspoort = false,
}) {
  const [primaryPrice, secondaryPrice] = useObjectPrices(data)
  const date = useObjectDate(data)
  const surface = useObjectSurface(data)

  const slots = {
    prijs: primaryPrice,
    prijsAlt: secondaryPrice,
    oppervlakte: surface,
    perceelOppervlakte: {
      term: 'Perceeloppervlakte',
      definition: formatValue(data?.perceelOppervlakte, 'meter-squared'),
    },
    aanmelddatum: date,
    bouwjaar: {
      term: 'Bouwjaar',
      definition: data?.bouwjaar || '---',
    },
    energielabel: {
      term: 'Energielabel',
      definition: (
        <EnergyLabel isLoading={isLoading} label={data?.energielabel} isSmall />
      ),
    },
  }

  const slotsGebouwpaspoort = {
    verblijfsobject: data?.verblijfsobject,
    oppervlakteVerblijfsobject: {
      term: 'Oppervlakte verblijfsobject',
      definition: formatValue(verblijfsobject?.oppervlakte, 'meter-squared'),
    },
    oppervlaktePand: {
      term: 'Oppervlakte pand',
      definition: formatValue(data?.oppervlakte, 'meter-squared'),
    },
    oppervlaktePerceel: {
      term: 'Oppervlakte perceel',
      definition: formatValue(data?.perceeloppervlakte, 'meter-squared'),
    },
    bouwjaar: {
      term: 'Bouwjaar',
      definition: data?.bouwjaar || '---',
    },
    energielabel: {
      term: 'Energielabel',
      definition: (
        <EnergyLabel
          isLoading={isLoading}
          label={verblijfsobject?.energielabel}
          isSmall
        />
      ),
    },
    pandStatus: {
      term: 'Pand Status',
      definition: data?.pandStatus,
    },
  }
  const slotOrder = [
    'prijs',
    'prijsAlt',
    'oppervlakte',
    'perceelOppervlakte',
    'aanmelddatum',
    'bouwjaar',
    'energielabel',
  ]

  const slotOrderGebouwpaspoort = [
    'verblijfsobject',
    'oppervlakteVerblijfsobject',
    'oppervlaktePand',
    'oppervlaktePerceel',
    'bouwjaar',
    'energielabel',
    'pandStatus',
  ]

  if (slots.prijs && slots.prijsAlt) {
    className += ' search-result-item-details--extra-price'
  }

  const slotElements = isGebouwpaspoort
    ? slotOrderGebouwpaspoort
        .map(name => slotsGebouwpaspoort[name])
        .filter(Boolean)
    : slotOrder.map(name => slots[name]).filter(Boolean)

  return (
    <ObjectDetailsDefinitionList
      termClassName="body-small text-normal text-dim"
      definitionClassName="body text-bold text-color--black"
      iconSize="tiny"
      className={`search-result-item-details ${className}`.trim()}
    >
      {isLoading ? (
        <dl>{LoadingItems}</dl>
      ) : (
        <dl>
          {slotElements.map(({ term, definition, perM2 }) => (
            <React.Fragment key={term}>
              <dt>{term}</dt>
              <dd>
                {definition}
                {perM2 && (
                  <>
                    <br />
                    <span className="body-small text-dim">{perM2}</span>
                  </>
                )}
              </dd>
            </React.Fragment>
          ))}
        </dl>
      )}
    </ObjectDetailsDefinitionList>
  )
}
