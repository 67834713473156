import React, { useState, useRef, useEffect } from 'react'
import { types, gaCategories } from '../../utils/constants'
import { trackEvent } from '../../utils/ga-events'
import './housing-types-select.css'

export default function HousingTypesSelect({
  housingTypesFromApi = [],
  propertyName = 'soortenEnType',
  isBAGObject = false,
  onHousingTypeChange,
  onHousingInputChange,
}) {
  const fieldsetRef = useRef()

  const [selectedHousing, setSelectedHousing] = useState()
  const [selectedType, setSelectedType] = useState()
  const [selectedVariant, setSelectedVariant] = useState()

  useEffect(() => {
    const [housing, type, variant] = housingTypesFromApi

    setSelectedHousing(housing)
    setSelectedType(type)
    setSelectedVariant(variant ? variant : '')
  }, [housingTypesFromApi])

  useEffect(() => {
    // When `selectedHousing`, `selectedType` or `selectedVariant` is changing
    // we set the value to the correct array
    const housingFromUser = [selectedHousing, selectedType]

    // If selectedVariant is undefined we don't want it to be added to the array
    // selectedVariant can be an empty string, so we check for `undefined`
    if (selectedVariant !== undefined) {
      housingFromUser.push(selectedVariant)
    }

    fieldsetRef.current.value = housingFromUser
  }, [selectedHousing, selectedType, selectedVariant])

  function setHousing(housing) {
    // When changing the housing the `housingType` and `housingVariant` should be
    // set to the first options in the array
    setSelectedHousing(housing)

    if (!isBAGObject) {
      setSelectedType(types?.[housing]?.['housingType']?.[0])
      setSelectedVariant(types?.[housing]?.['housingVariant']?.[0])
    }

    if (housing === 'appartement') {
      setSelectedVariant(undefined)
    }
  }

  function handleChangeHousing(e) {
    if (onHousingInputChange) {
      let data = [selectedHousing, selectedType, selectedVariant]

      if (e.target.name === 'housing') {
        if (e.target.value === 'appartement') {
          data = [
            e.target.value,
            types[e.target.value]['housingType'][0],
            undefined,
          ]
        } else {
          data = [
            e.target.value,
            types[e.target.value]['housingType'][0],
            types[e.target.value]['housingVariant'][0],
          ]
        }
      }

      if (e.target.name === 'housing-type') {
        data[1] = e.target.value
      }

      if (e.target.name === 'housing-variant') {
        data[2] = e.target.value
      }

      onHousingInputChange(data)
    }

    trackChangeHousing(e)
  }

  function trackChangeHousing(e) {
    if (
      e.target.name === 'housing' ||
      (e.target.name === 'housing-type' && selectedType) ||
      (e.target.name === 'housing-variant' && selectedVariant)
    ) {
      trackEvent(
        `changed_${e.target.name}`,
        gaCategories.EDIT,
        `Changed ${e.target.name} to ${e.target.value}`,
      )
    } else {
      trackEvent(
        `added_${e.target.name}`,
        gaCategories.EDIT,
        `Added ${e.target.name} to ${e.target.value}`,
      )
    }
  }

  return (
    <fieldset
      ref={fieldsetRef}
      className="housing-types-select text-normal"
      name={propertyName}
      onChange={handleChangeHousing}
    >
      <select
        className="select--small housing-types-select__select"
        value={selectedHousing}
        name="housing"
        onChange={e => {
          setHousing(e.target.value)
          onHousingTypeChange && onHousingTypeChange(e.target.value)
        }}
      >
        {Object.keys(types).map(housingKey => {
          return (
            <option key={housingKey} value={housingKey}>
              {housingKey}
            </option>
          )
        })}
      </select>
      {types?.[selectedHousing]?.['housingType'].length > 0 && (
        <select
          className="select--small housing-types-select__select"
          value={selectedType}
          name="housing-type"
          onChange={e => setSelectedType(e.target.value)}
          autoFocus={isBAGObject}
          required
        >
          {
            // If the option doesn't exist we render the option as last value
            types[selectedHousing]['housingType'].indexOf(selectedType) ===
              -1 && <option value="" />
          }
          {types[selectedHousing]['housingType'].map(housingType => {
            return (
              <option key={housingType} value={housingType}>
                {housingType}
              </option>
            )
          })}
        </select>
      )}
      {types?.[selectedHousing]?.['housingVariant'].length > 0 && (
        <select
          className="select--small housing-types-select__select"
          value={selectedVariant}
          name="housing-variant"
          onChange={e => setSelectedVariant(e.target.value)}
          required
        >
          {
            // If the option doesn't exist we render the option as last value
            types[selectedHousing]['housingVariant'].indexOf(
              selectedVariant,
            ) === -1 && <option value="" />
          }
          {types[selectedHousing]['housingVariant'].map(housingVariant => {
            return (
              <option key={housingVariant} value={housingVariant}>
                {housingVariant}
              </option>
            )
          })}
        </select>
      )}
    </fieldset>
  )
}
