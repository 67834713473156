import React, { useEffect, useContext, useState } from 'react'
import { Context } from './skiplink-context'

export default function SkiplinkTarget({
  tag = 'div',
  children,
  className,
  skiplinkLabel,
  id,
  ...props
}) {
  const [linkObj] = useState({ id, label: skiplinkLabel })
  const { addSkiplink, removeSkiplink } = useContext(Context)
  const TagElement = tag

  useEffect(() => {
    addSkiplink(linkObj)
    return () => removeSkiplink(linkObj)
    // We need this disabled, otherwhise we end up in an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TagElement
      {...props}
      id={id}
      tabIndex={-1}
      className={`${className} skiplink-target`}
    >
      {children}
    </TagElement>
  )
}
