import React, { useState } from 'react'
import { Context } from './skiplink-context'

import './skiplinks.css'

function handleClick(link) {
  document.getElementById(link.id).focus()
  window.scrollTo(0, 0)
}

function handleToTop() {
  document.querySelector('h1').focus()
}

export default function Skiplinks({ children }) {
  const [skipLinks, setSkipLinks] = useState([])

  const addSkiplink = linkObj => {
    setSkipLinks(prevState => {
      const existing = prevState.find(item => item.id === linkObj.id)
      return !existing ? [...prevState, linkObj] : prevState
    })
  }

  const removeSkiplink = linkObj => {
    setSkipLinks(prevState => prevState.filter(item => item.id !== linkObj.id))
  }

  return (
    <Context.Provider value={{ skipLinks, addSkiplink, removeSkiplink }}>
      <Context.Consumer>
        {({ skipLinks: links }) =>
          links.map(link => (
            <button
              className="skiplink body text-bold"
              key={link.id}
              onClick={() => handleClick(link)}
            >
              {link.label}
            </button>
          ))
        }
      </Context.Consumer>
      {children}
      <button
        className="skiplink skiplink--to-top body text-bold"
        onClick={handleToTop}
      >
        Terug naar boven
      </button>
    </Context.Provider>
  )
}
