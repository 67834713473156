import React from 'react'
import ObjectListItemThumb from '@brainbay/components/components/object-list-item-thumb'
import Icon from '@brainbay/components/components/icon'
import { ReactComponent as IconClose } from '@brainbay/components/_assets/svg/icon-close.svg'
import formatAddressBusiness from '../../utils/format-address-business'
import { fluxParams } from '../../utils/flux-params'

import './compare-list-item.css'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { expandComparePanel } from '../../store/comparison'

export default function SearchCompareListItem({
  className,
  isLoading,
  city,
  houseNumber,
  houseNumberSuffix,
  mediaGuid,
  objectGuid,
  status,
  duration,
  street,
  municipality,
  postalCode,
  removeHandler = () => {},
  style = {},
  displayAddress = false,
}) {
  const dispatch = useDispatch()

  function onRemoveClick(e) {
    e.preventDefault()
    removeHandler()
  }
  const { isAllFluxParamsExist, fluxId, fluxSessionContext, fluxAddress } =
    fluxParams()

  const redirectUrl = !isAllFluxParamsExist
    ? `/${objectGuid}`
    : `/${objectGuid}?sessionContext=${fluxSessionContext}&address=${fluxAddress}&fluxid=${fluxId}`

  return (
    <Link
      to={redirectUrl}
      className={`compare-list-item ${className}`.trim()}
      style={style}
      onClick={() => dispatch(expandComparePanel())}
    >
      {!isLoading && (
        <>
          <h3
            className={`compare-list-item__title ${
              displayAddress ? '' : 'sr-only'
            }`}
          >
            {formatAddressBusiness({
              city,
              street,
              postalCode,
              houseNumber,
              houseNumberSuffix,
              municipality,
            })}
          </h3>
          <button
            className="compare-list-item__remove-button"
            onClick={onRemoveClick}
          >
            <span className="sr-only">
              Verwijder{' '}
              {formatAddressBusiness({
                city,
                street,
                postalCode,
                houseNumber,
                houseNumberSuffix,
                municipality,
              })}{' '}
              uit vergelijking
            </span>
            <Icon size="tiny">
              <IconClose />
            </Icon>
          </button>
          <ObjectListItemThumb
            address={formatAddressBusiness({
              city,
              postalCode,
              houseNumber,
              houseNumberSuffix,
              street,
              municipality,
            })}
            mediaGuid={mediaGuid}
            status={status}
          />
        </>
      )}
    </Link>
  )
}
