import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useApiCall from '../../utils/use-api-call'
import formatAddressBusiness from '../../utils/format-address-business'
import log from '@brainbay/components/utils/log'
import Panel from '@brainbay/components/components/panel'
import formatValue from '@brainbay/components/utils/format-value'
import ObjectDetailLink from '@brainbay/components/components/object-detail-link'
import EnergyLabel from '@brainbay/components/components/energy-label'
import { setBetrokkenObjectData } from '../../store/betrokken-object'
import sortByProperties from '../../utils/sort-by-properties'

import './object-betrokken-address.css'

const ObjectBetrokkenAdresses = ({ data }) => {
  const [hasLoaded, setHasLoaded] = useState(false)
  const [betrokkenAddressList, setBetrokkenAddressList] = useState([])
  const [showFooter, setShowFooter] = useState(false)
  const [showMoreVisible, setShowMoreVisible] = useState(true)
  const numberOfRowsToBeShown = 50
  const betrokkenAddressCount = data?.relatedPortfolioTransactions?.length
  const [fetchedData, setFetchedData] = useState([])
  const [endIndexForRender, setEndIndexForRender] = useState(50)
  const currentBatchSize = 50
  const relatedPortfolioTransactions = data?.relatedPortfolioTransactions
  const { post } = useApiCall()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!data?.loading) {
      setHasLoaded(true)
    }
  }, [data])

  useEffect(() => {
    if (data?.isPortefeuille) {
      const totalGuids = relatedPortfolioTransactions?.length

      if (totalGuids > numberOfRowsToBeShown) {
        setShowFooter(true)
      }

      if (totalGuids > 0 && betrokkenAddressList?.length === 0) {
        fetchRelatedPortfolioTransactions(relatedPortfolioTransactions, 0, 50)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    betrokkenAddressList,
    currentBatchSize,
    relatedPortfolioTransactions,
    numberOfRowsToBeShown,
  ])

  const fetchRelatedPortfolioTransactions = async (
    relatedPortfolioTransactions,
    start,
    end,
  ) => {
    const batchGuids = relatedPortfolioTransactions?.slice(start, end)

    if (batchGuids?.length === 0) {
      return // No more data to fetch
    }

    // Check if the data for this batch has already been fetched

    const batchData = fetchedData?.slice(start, end)

    if (batchData?.length === batchGuids?.length) {
      // Data for this batch is already fetched, no need for an API call
      setBetrokkenAddressList(prevAddress => [...prevAddress, ...batchData])
    } else {
      try {
        const response = await post({
          path: 'objects/by-businessguids',
          body: batchGuids,
        })
        dispatch(setBetrokkenObjectData(response))
        // Update the fetched data state with the new data
        setFetchedData(prevFetchedData => [
          ...prevFetchedData.slice(0, start),
          ...response,
          ...prevFetchedData.slice(end),
        ])

        if (fetchedData?.length === relatedPortfolioTransactions?.length) {
          // All data has been fetched
          setShowFooter(false)
        }
        setBetrokkenAddressList(prevAddress => [...prevAddress, ...response])
      } catch (error) {
        log.error('Error fetching related portfolio transactions:', error)
      }
    }
  }

  const handleShowMore = () => {
    const start = betrokkenAddressList?.length
    const end = start + currentBatchSize

    if (end > betrokkenAddressCount) {
      setShowMoreVisible(false)
    } else {
      setShowMoreVisible(true)
    }
    fetchRelatedPortfolioTransactions(relatedPortfolioTransactions, start, end)
  }

  const handleShowLess = () => {
    // Calculate the new start and end indices for rendering
    const newEndIndex = endIndexForRender - currentBatchSize
    const newStartIndex = Math.max(newEndIndex - numberOfRowsToBeShown, 0)

    // Get the data to display from the original data
    const newDisplayData = fetchedData.slice(newStartIndex, newEndIndex)

    // Update the start and end indices for rendering
    setEndIndexForRender(newEndIndex)

    // Set the data to be displayed
    setBetrokkenAddressList(newDisplayData)

    // If we're back at the initial data size, show the "Show More" button again
    if (newEndIndex < betrokkenAddressCount) {
      setShowMoreVisible(true)
    }

    // If we're back at the initial data size, hide the "Show Less" button
    if (newEndIndex === 0) {
      setShowFooter(true)
    }
  }

  function oppervlakteValueByLabel(object, label) {
    return object?.kenmerken
      .find(section => section?.title === 'Oppervlakten')
      ?.items.find(item => item?.label === label)?.value
  }
  const sortedBetrokkenAddressList = [...betrokkenAddressList].sort((a, b) =>
    sortByProperties(a, b, [
      'straatnaam',
      'huisnummer',
      'huisnummertoevoeging',
    ]),
  )

  return (
    <Panel
      title={`Betrokken objecten (${betrokkenAddressCount ?? 0})`}
      className="object-betrokken-addresses"
      tag="section"
      fullBleed
      {...(betrokkenAddressCount > 0
        ? { collapsable: true, divider: true }
        : { collapsable: false, divider: false })}
      isOpenByDefault
    >
      {hasLoaded && betrokkenAddressCount > 0 && (
        <div className="object-betrokken-addresses__content">
          <table className="object-betrokken-addresses__table">
            <thead className="object-betrokken-addresses__head">
              <tr className="object-betrokken-addresses-table__row">
                <th
                  scope="col"
                  className="object-betrokken-addresses-table__column-header"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="object-betrokken-addresses-table__column-header"
                >
                  Adres
                </th>
                <th
                  scope="col"
                  className="object-betrokken-addresses-table__column-header"
                  style={{ whiteSpace: 'pre-line' }}
                >
                  Oppervlakte verblijfsobject
                </th>
                <th
                  scope="col"
                  className="object-betrokken-addresses-table__column-header"
                  style={{ whiteSpace: 'pre-line' }}
                >
                  Oppervlakte perceel
                </th>
                <th
                  scope="col"
                  className="object-betrokken-addresses-table__column-header"
                >
                  Prijs
                </th>
                <th
                  scope="col"
                  className="object-betrokken-addresses-table__column-header"
                >
                  Bouwjaar
                </th>
                <th
                  scope="col"
                  className="object-betrokken-addresses-table__column-header"
                >
                  Energie label
                </th>
              </tr>
            </thead>

            <tbody className="object-betrokken-addresses-table__body">
              {sortedBetrokkenAddressList?.map((object, index) => {
                const verblijfsobjectOppervlakte = oppervlakteValueByLabel(
                  object,
                  'Verblijfsobject oppervlakte',
                )
                const perceelOppervlakte = oppervlakteValueByLabel(
                  object,
                  'Perceeloppervlakte',
                )
                return (
                  <tr key={index}>
                    <td className="object-betrokken-addresses-table__data">
                      {object?.hoofdfunctie
                        ? object.hoofdfunctie.charAt(0).toUpperCase() +
                          object.hoofdfunctie.slice(1).toLowerCase()
                        : ''}
                    </td>
                    <td className="object-betrokken-addresses-table__data">
                      {!object?.straatnaam
                        ? 'Perceel of appartementsrecht'
                        : formatAddressBusiness({
                            street: object?.straatnaam,
                            houseNumber: object?.huisnummer,
                            houseNumberSuffix: object?.huisnummertoevoeging,
                          })}
                    </td>
                    <td className="object-betrokken-addresses-table__data">
                      {verblijfsobjectOppervlakte
                        ? formatValue(verblijfsobjectOppervlakte)
                        : '---'}
                    </td>
                    <td className="object-betrokken-addresses-table__data">
                      {perceelOppervlakte
                        ? formatValue(perceelOppervlakte)
                        : '---'}
                    </td>
                    <td className="object-betrokken-addresses-table__data">
                      {object?.transactie?.koopprijs?.kadasterPrijsRange ||
                        '---'}
                    </td>
                    <td className="object-betrokken-addresses-table__data">
                      {object?.bouwjaar || '---'}
                    </td>
                    <td className="object-betrokken-addresses-table__data">
                      <EnergyLabel label={object?.energielabel} />
                    </td>
                    <td className="object-betrokken-addresses-table__data">
                      <ObjectDetailLink
                        guid={object?.objectGuid}
                        url={`/${object?.objectGuid}`}
                        type="object_details"
                        page="object_details"
                      >
                        Details
                      </ObjectDetailLink>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          {showFooter && (
            <footer className="object-betrokken-addresses__expandable">
              <div className="object-betrokken-addresses__expandable--rows">
                {currentBatchSize &&
                  betrokkenAddressList?.length > numberOfRowsToBeShown && (
                    <button
                      className="button button--x-small expandable--rows__toggle"
                      onClick={handleShowLess}
                    >
                      Toon minder
                    </button>
                  )}
                {showMoreVisible && (
                  <button
                    className="button button--x-small expandable--rows__toggle"
                    onClick={handleShowMore}
                  >
                    Toon meer
                  </button>
                )}
              </div>
            </footer>
          )}
        </div>
      )}
    </Panel>
  )
}
export default ObjectBetrokkenAdresses
