import React from 'react'

import './characteristics-list.css'

export default function CharacteristicsList({
  title = '',
  data = [],
  headingLevel = 3,
  headingClassName = 'h4',
  subHeadingClassName = 'text-bold',
  loading = false,
  error,
  valueRenderer,
  labelRenderer,
  modalRenderer,
}) {
  const TitleTag = `h${headingLevel}`
  const SubTitleTag = `h${headingLevel + 1}`

  const loadingData = [{ items: [[], [], []] }, { items: [[], [], []] }]

  return (
    <section className="characteristics-list">
      {title && (
        <TitleTag className={`characteristics-list__title ${headingClassName}`}>
          {title}
        </TitleTag>
      )}
      {(loading ? loadingData : data).map((section, index) => (
        <React.Fragment key={index}>
          {(loading || section.title) && (
            <SubTitleTag
              className={`characteristics-list__sub-title loading-state ${subHeadingClassName}`}
            >
              {section.title}
            </SubTitleTag>
          )}
          {section?.items?.length > 0 && (
            <dl className="characteristics-list__list body">
              {section.items.map((item, index) => (
                <div
                  key={`${index}-key`}
                  className="characteristics-list__pair-wrapper"
                >
                  <dt className="loading-state">
                    {typeof labelRenderer === 'function'
                      ? labelRenderer(item.label, index, item)
                      : item.label}
                  </dt>
                  <dd className="loading-state">
                    {typeof valueRenderer === 'function'
                      ? valueRenderer(item.value, index, item)
                      : item.value}
                    {typeof modalRenderer === 'function' && modalRenderer(item)}
                  </dd>
                </div>
              ))}
            </dl>
          )}
        </React.Fragment>
      ))}
      {error && (
        <p className="body characteristics-list__error-text">
          Er gaat iets mis met het ophalen van de data
        </p>
      )}
    </section>
  )
}
