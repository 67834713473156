import React, { useState, useEffect, Suspense, lazy } from 'react'
import { mapboxToken, mapboxStyle } from '../../utils/environment-vars'
import 'mapbox-gl/dist/mapbox-gl.css'

const MapGL = lazy(() => import('react-map-gl'))

export const mapDefaults = {
  LATITUDE: 52.1326,
  LONGITUDE: 5.2913,
  ZOOM_LEVEL_ZOOM_IN: 16,
  ZOOM_LEVEL_ZOOM_WIDE: 7,
}

export default function MapboxMap({
  children,
  centeredLongitude,
  centeredLatitude,
  zoom = mapDefaults.ZOOM_LEVEL_ZOOM_IN,
}) {
  const [viewport, setViewport] = useState({
    bearing: 0,
    pitch: 0,
    longitude: mapDefaults.LONGITUDE,
    latitude: mapDefaults.LATITUDE,
    minZoom: mapDefaults.ZOOM_LEVEL_ZOOM_WIDE,
  })

  useEffect(() => {
    if (centeredLongitude && centeredLatitude) {
      async function centerMarker() {
        const { FlyToInterpolator } = await import('react-map-gl')
        try {
          setViewport(oldViewport => ({
            ...oldViewport,
            latitude: centeredLatitude,
            longitude: centeredLongitude,
            zoom: zoom,
            transitionDuration: 500,
            transitionInterpolator: new FlyToInterpolator(),
          }))
        } catch {
          setViewport(oldViewport => ({
            ...oldViewport,
            latitude: centeredLatitude,
            longitude: centeredLongitude,
            zoom: zoom,
          }))
        }
      }

      centerMarker()
    }
  }, [centeredLatitude, centeredLongitude, zoom])

  return (
    <Suspense fallback={<></>}>
      <MapGL
        {...viewport}
        width="100%"
        height="100%"
        mapStyle={mapboxStyle}
        onViewportChange={nextViewport => setViewport(nextViewport)}
        mapboxApiAccessToken={mapboxToken}
      >
        {children}
      </MapGL>
    </Suspense>
  )
}
