export const gaCategories = {
  EXPORT: 'export',
  REFERENCES: 'references',
  MAP: 'map',
  PAGES: 'pages',
  NOTES: 'notes',
  CREATE: 'create',
  EDIT: 'edit',
  AVM: 'avm',
  HOME_IMPROVEMENTS: 'home-improvements',
  EXTERNAL_PAGES: 'external_pages',
  SEARCH: 'search',
  BIDDING_STRATEGY: 'bidding-strategy',
  NAVIGATION: 'navigation',
}

export const status = {
  VERKOCHT: 'verkocht',
  VERHUURD: 'verhuurd',
  TE_KOOP: 'te koop',
  TE_HUUR: 'te huur',
  TE_KOOP_EN_TE_HUUR: 'te koop en te huur',
  ONDER_OPTIE: 'onder optie',
  ONDER_BOD: 'onder bod',
  BESCHIKBAAR: 'beschikbaar',
  VERKOCHT_ONDER_VOORBEHOUD: 'verkocht onder voorbehoud',
  VERHUURD_ONDER_VOORBEHOUD: 'verhuurd onder voorbehoud',
  VERKOCHT_BIJ_INSCHRIJVING: 'verkocht bij inschrijving',
  VERKOOP_BIJ_INSCHRIJVING: 'verkoop bij inschrijving',
  INGETROKKEN: 'ingetrokken',
  ONBEKEND: 'onbekend',
  BAG: 'BAG',
  VEILING: 'veiling',
  GEVEILD: 'geveild',
  VERLENGING: 'verlenging',
}

export const statusText = {
  [status.VERKOCHT]: 'verkocht',
  [status.VERHUURD]: 'verhuurd',
  [status.TE_KOOP]: 'te koop',
  [status.TE_HUUR]: 'te huur',
  [status.TE_KOOP_EN_TE_HUUR]: 'te koop / huur',
  [status.ONDER_OPTIE]: 'onder optie',
  [status.ONDER_BOD]: 'onder bod',
  [status.BESCHIKBAAR]: 'beschikbaar',
  [status.VERKOCHT_ONDER_VOORBEHOUD]: 'verkocht o.v.',
  [status.VERHUURD_ONDER_VOORBEHOUD]: 'verhuurd o.v.',
  [status.VERKOCHT_BIJ_INSCHRIJVING]: 'verkocht bij inschrijving',
  [status.VERKOOP_BIJ_INSCHRIJVING]: 'verkoop bij inschrijving',
  [status.INGETROKKEN]: 'ingetrokken',
  [status.ONBEKEND]: 'onbekend',
  [status.BAG]: 'BAG',
  [status.VEILING]: 'veiling',
  [status.GEVEILD]: 'geveild',
  [status.VERLENGING]: 'verlenging',
}

export const statusSubClass = {
  [status.VERKOCHT]: '--sold',
  [status.VERHUURD]: '--rented',
  [status.TE_KOOP]: '--for-sale',
  [status.TE_HUUR]: '--for-rent',
  [status.TE_KOOP_EN_TE_HUUR]: '--for-sale-and-rent',
  [status.ONDER_OPTIE]: '--under-offer',
  [status.ONDER_BOD]: '--under-option',
  [status.BESCHIKBAAR]: '--available',
  [status.VERKOCHT_ONDER_VOORBEHOUD]: '--sold-with-reservation',
  [status.VERHUURD_ONDER_VOORBEHOUD]: '--rented-with-reservation',
  [status.VERKOCHT_BIJ_INSCHRIJVING]: '--sold-at-registration',
  [status.VERKOOP_BIJ_INSCHRIJVING]: '--sale-at-registration',
  [status.INGETROKKEN]: '--withdrawn',
  [status.ONBEKEND]: '--unknown',
  [status.BAG]: '--bag',
  [status.VEILING]: '--auction',
  [status.GEVEILD]: '--auctioned',
  [status.VERLENGING]: '--extension',
}

export const condition = {
  KOSTEN_KOPER: 'kosten koper',
  VRIJ_OP_NAAM: 'vrij op naam',
  PER_JAAR: 'per jaar',
  PER_MAAND: 'per maand',
  PER_VIERKANTE_METER_PER_JAAR: 'per vierkante meter per jaar',
  VERHUURD_PER_JAAR: 'verhuurd per jaar',
  VERHUURD_PER_MAAND: 'verhuurd per maand',
  VERHUURD_PER_M2_PER_JAAR: 'verhuurd per vierkante meter per jaar',
}

export const conditionAcronyms = {
  [condition.KOSTEN_KOPER]: 'k.k.',
  [condition.VRIJ_OP_NAAM]: 'v.o.n.',
  [condition.PER_JAAR]: 'per jr',
  [condition.PER_MAAND]: 'per mnd',
  [condition.PER_VIERKANTE_METER_PER_JAAR]: 'per m\u00B2/jr',
  [condition.VERHUURD_PER_JAAR]: 'per jr',
  [condition.VERHUURD_PER_MAAND]: 'per mnd',
  [condition.VERHUURD_PER_M2_PER_JAAR]: 'per m\u00B2/jr',
}

export const housingTypes = {
  APPARTEMENT: 'appartement',
  WOONHUIS: 'woonhuis',
}

export const housing = {
  WOONHUIS_TYPE: [
    'bungalow',
    'eengezinswoning',
    'grachtenpand',
    'herenhuis',
    'landgoed',
    'landhuis',
    'stacaravan',
    'villa',
    'woonboerderij',
    'woonboot',
    'woonwagen',
  ],
  WOONHUIS_VARIANT: [
    '2-onder-1-kapwoning',
    'eindwoning',
    'geschakelde 2-onder-1-kapwoning',
    'geschakelde woning',
    'halfvrijstaande woning',
    'hoekwoning',
    'tussenwoning',
    'verspringende woning',
    'vrijstaande woning',
  ],
  APPARTEMENT_TYPE: [
    'benedenwoning',
    'beneden + bovenwoning',
    'bovenwoning',
    'dubbel benedenhuis',
    'galerijflat',
    'maisonnette',
    'penthouse',
    'portiekflat',
    'portiekwoning',
    'studentenkamer',
    'tussenverdieping',
  ],
}

export const types = {
  woonhuis: {
    housingType: housing.WOONHUIS_TYPE,
    housingVariant: housing.WOONHUIS_VARIANT,
  },
  appartement: {
    housingType: housing.APPARTEMENT_TYPE,
    housingVariant: [],
  },
}

export const qualityScoreTypes = {
  1: 'Slecht',
  2: 'Matig',
  3: 'Redelijk',
  4: 'Goed',
  5: 'Zeer goed',
  6: 'Uitstekend',
}

export const sizeValues = {
  LARGE: 'large',
  SMALL: 'small',
}

export const sizeValuesExtended = {
  PICO: 'pico',
  NANO: 'nano',
  tiny: 'tiny',
  small: 'small',
  medium: 'medium',
  large: 'large',
}

export const defaultQualityScore = 4
export const defaultNumberOfReferences = 8

export const toolName = {
  INVERKOOPNAME: 'inverkoopname',
  AANKOOP: 'aankoop',
  WAARDEREN: 'waarderen',
  PRIJSONTWIKKELING: 'prijsontwikkeling',
  BUSINESSAPPLICATION: 'business-application',
}
