import { post, get } from '../../utils/api-data'
import { consentApiHost } from '../../utils/business-environment-vars'

export const consentApi = {
  acceptConsents({ ids }) {
    return post({
      path: 'consent/accept',
      body: {
        consentIds: ids,
      },
      apiHostUrl: consentApiHost(),
    })
  },

  checkConsents({ contextName }) {
    return get(`consent/check/${contextName}`, { apiHostUrl: consentApiHost() })
  },

  getConsents({ ids }) {
    return get(`consent/get`, { apiHostUrl: consentApiHost(), body: ids })
  },
}
