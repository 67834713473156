import React from 'react'
import formatValue from '@brainbay/components/utils/format-value'
import './object-partial-transactions-table.css'

export function ObjectPartialTransactionsTable({
  transactions = [],
  transactionCount = 0,
  caption,
  hideCaption = false,
  expanded = false,
  showBuyerTenantName = false,
}) {
  if (expanded) {
    transactionCount = transactions.length
  }

  return (
    <table className="object-partial-transactions-table">
      {caption && (
        <caption className={`table-caption${hideCaption ? ' sr-only' : ''}`}>
          {caption}
        </caption>
      )}
      <thead className="object-partial-transactions-table__head">
        <tr className="object-partial-transactions-table__row">
          <th
            scope="col"
            className="object-partial-transactions-table__column-header"
          >
            Status
          </th>
          <th
            scope="col"
            className="object-partial-transactions-table__column-header"
          >
            Datum
          </th>
          <th
            scope="col"
            className="object-partial-transactions-table__column-header"
          >
            Oppervlakte
          </th>
          <th
            scope="col"
            className="object-partial-transactions-table__column-header"
          >
            Prijs
          </th>
          <th
            scope="col"
            className="object-partial-transactions-table__column-header"
          >
            Prijs per m²
          </th>
          <th
            scope="col"
            className="object-partial-transactions-table__column-header"
          >
            Huurkorting
          </th>
          {showBuyerTenantName && (
            <th
              scope="col"
              className="object-partial-transactions-table__column-header"
            >
              Koper-/huurdernaam
            </th>
          )}
          <th
            scope="col"
            className="object-partial-transactions-table__column-header"
          >
            Ingang contract
          </th>
          <th
            scope="col"
            className="object-partial-transactions-table__column-header"
          >
            Duur contract
          </th>
          <th
            scope="col"
            className="object-partial-transactions-table__column-header"
          >
            Looptijd
          </th>
        </tr>
      </thead>
      <tbody className="object-partial-transactions-table__body">
        {transactions &&
          transactions.slice(0, transactionCount).map((transaction, index) => {
            const {
              status,
              koperHuurderNaam,
              huurovereenkomst,
              transactieDatum,
              transactiePrijs,
              transactieConditie,
              transactieOppervlakte,
              looptijd,
              koopprijs,
              huurprijs,
            } = transaction

            return (
              <tr
                key={index}
                className="object-partial-transactions-table__row"
              >
                <td className="object-partial-transactions-table__cell">
                  {status ? status : '---'}
                </td>
                <td className="object-partial-transactions-table__cell">
                  {formatValue(transactieDatum, {
                    format: 'date',
                  })}
                </td>
                <td className="object-partial-transactions-table__cell">
                  {formatValue(transactieOppervlakte, {
                    format: 'meter-squared',
                  })}
                </td>
                <td className="object-partial-transactions-table__cell">
                  {formatValue(transactiePrijs, {
                    format: 'costs',
                    condition: transactieConditie,
                  })}
                </td>
                <td className="object-partial-transactions-table__cell">
                  {koopprijs &&
                    Object.keys(koopprijs).length > 0 &&
                    koopprijs?.perM2 &&
                    formatValue(koopprijs.perM2, {
                      format: 'currency',
                    })}

                  {huurprijs &&
                    Object.keys(huurprijs).length > 0 &&
                    huurprijs?.perM2Jaar &&
                    formatValue(huurprijs.perM2Jaar, {
                      format: 'currency',
                    })}

                  {huurprijs &&
                    Object.keys(huurprijs).length > 0 &&
                    koopprijs &&
                    Object.keys(koopprijs).length > 0 &&
                    '---'}
                </td>
                <td className="object-partial-transactions-table__cell">
                  {formatValue(huurovereenkomst?.aantalMaandenIncentives, {
                    format: 'months',
                  })}
                </td>
                {showBuyerTenantName && (
                  <td className="object-partial-transactions-table__cell">
                    {koperHuurderNaam ?? '---'}
                  </td>
                )}
                <td className="object-partial-transactions-table__cell">
                  {formatValue(huurovereenkomst?.datumIngang, {
                    format: 'date',
                  })}
                </td>
                <td className="object-partial-transactions-table__cell">
                  {formatValue(huurovereenkomst?.duurcontractMaanden, {
                    format: 'months',
                  })}
                </td>
                <td className="object-partial-transactions-table__cell">
                  {formatValue(looptijd, { format: 'days' })}
                </td>
              </tr>
            )
          })}
      </tbody>
    </table>
  )
}
