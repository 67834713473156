export default function formatFilterRequestData(filterObject, nameTypeMap) {
  return Object.entries(filterObject).reduce((collection, entry) => {
    const [key, value] = entry
    let values = typeof value === 'string' ? value.split(',') : value

    if (nameTypeMap[key] === 'date-range') {
      values = values.map(value => {
        const [day, month, year] = value.split('-')
        if (day.length === 4) return value

        // Value comes from input type date in the format of `dd-mm-yyyy`.
        // Reformat to yyyy-mm-dd
        return `${year}-${month}-${day}`
      })
    }
    collection[key] = values.join(',')
    return collection
  }, {})
}
