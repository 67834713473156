import React from 'react'
import { Text as PDFText } from '@react-pdf/renderer'
import { Font } from '@react-pdf/renderer'

Font.registerHyphenationCallback(word => {
  return [word]
})

export default function Typography({ style, fixed, children, debug }) {
  return (
    <PDFText
      fixed={fixed}
      debug={debug}
      style={[{ lineHeight: 1.5, fontSize: 11, color: '#000' }, { ...style }]}
    >
      {children}
    </PDFText>
  )
}
