import { createSlice } from '@reduxjs/toolkit'

const searchResultMapMode = createSlice({
  name: 'searchResultMapMode',
  initialState: window.location.pathname.includes('/map'),
  reducers: {
    map: () => true,
    list: () => false,
    toggle: state => !state,
  },
})

export const { map, list, toggle } = searchResultMapMode.actions

export default searchResultMapMode.reducer
