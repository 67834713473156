import { logLevel } from './environment-vars'

const noop = () => () => {}

function log(label, color1, color2) {
  return function ({ isGroup = false } = {}) {
    return function (...values) {
      const [title, ...vals] = values
      if (values.length > 1) {
        console.groupCollapsed(`%c ${label} %c ${title} `, color1, color2)
        if (isGroup === false) {
          vals.forEach(value => {
            if (typeof value === 'function') {
              value()
            } else {
              console.log(value)
            }
          })
          console.groupEnd()
        }
      } else {
        if (isGroup) {
          console.groupCollapsed(`%c ${label} %c ${title} `, color1, color2)
        } else {
          console.groupCollapsed(`%c ${label} %c ${title} `, color1, color2)
          console.trace()
          console.groupEnd()
        }
      }
    }
  }
}

const methods = {
  error:
    logLevel >= 1
      ? log(
          'Error:',
          'background: #F44336; color: #fff; border-radius: 3px 0 0 3px;',
          'background: #263238; color: #eeffff; border-radius: 0 3px 3px 0; font-weight: 400;',
        )
      : noop,
  warning:
    logLevel >= 2
      ? log(
          'Warning:',
          'background: #c6a700; color: #fff; border-radius: 3px 0 0 3px;',
          'background: #263238; color: #eeffff; border-radius: 0 3px 3px 0; font-weight: 400;',
        )
      : noop,
  info:
    logLevel >= 3
      ? log(
          'Info:',
          'background: #0069c0; color: #fff; border-radius: 3px 0 0 3px;',
          'background: #263238; color: #eeffff; border-radius: 0 3px 3px 0; font-weight: 400;',
        )
      : noop,
  debug:
    logLevel >= 4
      ? log(
          'Debug:',
          'background: #d6a123; color: #000; border-radius: 3px 0 0 3px;',
          'background: #263238; color: #eeffff; border-radius: 0 3px 3px 0; font-weight: 400;',
        )
      : noop,
}

const logObject = {
  error: methods.error(),
  warning: methods.warning(),
  info: methods.info(),
  debug: methods.debug(),

  groupStart: {
    error: methods.error({ isGroup: true }),
    warning: methods.warning({ isGroup: true }),
    info: methods.info({ isGroup: true }),
    debug: methods.debug({ isGroup: true }),
  },

  groupEnd: console.groupEnd,
}

export default logObject
