import React from 'react'
import { Box, Typography } from './index'

export default function ObjectBroker({ item, debug }) {
  const {
    vestigingnummer,
    naam,
    straatnaam,
    huisnummer,
    huisnummertoevoeging,
    postcode,
    plaats,
    telefoonnummer,
    website,
  } = item.hoofdAanbieder ?? {}

  return (
    <>
      <Typography
        style={{
          fontSize: 18,
          color: '#000',
          fontWeight: 700,
        }}
        debug={debug}
      >
        Makelaar
      </Typography>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
        }}
        debug={debug}
        break
      >
        {/* Object item details */}
        <Box
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          <Box
            style={{
              flex: '1 1 100%',
              flexDirection: 'row',
              width: '100%',
              marginBottom: 3,
            }}
          >
            <Typography
              style={{ fontSize: 10, color: '#888888' }}
              debug={debug}
            >
              Naam:{' '}
            </Typography>
            <Typography
              style={{ fontSize: 10, color: '#000', fontWeight: 700 }}
              debug={debug}
            >
              {naam}
            </Typography>
          </Box>

          <Box
            style={{
              flex: '1 1 15%',
              width: '15%',
              marginTop: 3,
            }}
          >
            <Typography
              style={{ fontSize: 10, color: '#888888' }}
              debug={debug}
            >
              Vestigingnummer
            </Typography>
            <Typography
              style={{ fontSize: 10, color: '#000', fontWeight: 700 }}
              debug={debug}
            >
              {vestigingnummer}
            </Typography>
          </Box>

          <Box
            style={{
              flex: '1 1 22%',
              width: '22%',
              marginTop: 3,
            }}
          >
            <Typography
              style={{ fontSize: 10, color: '#888888' }}
              debug={debug}
            >
              Locatie
            </Typography>
            <Typography
              style={{ fontSize: 10, color: '#000', fontWeight: 700 }}
              debug={debug}
            >
              {(straatnaam || huisnummer || huisnummertoevoeging) &&
                `${straatnaam ?? ''} ${huisnummer ?? ''} ${
                  huisnummertoevoeging ?? ''
                }`}
            </Typography>
            <Typography
              style={{ fontSize: 10, color: '#000', fontWeight: 700 }}
              debug={debug}
            >
              {(postcode || plaats) &&
                `${postcode ?? ''} ${plaats ?? ''}
           `}
            </Typography>
          </Box>
          <Box
            style={{
              flex: '1 1 22%',
              width: '22%',
              marginTop: 3,
            }}
          >
            <Typography
              style={{ fontSize: 10, color: '#888888' }}
              debug={debug}
            >
              Contact
            </Typography>
            <Typography
              style={{ fontSize: 10, color: '#000', fontWeight: 700 }}
              debug={debug}
            >
              {telefoonnummer ?? ''}
            </Typography>
            <Typography
              style={{ fontSize: 10, color: '#000', fontWeight: 700 }}
              debug={debug}
            >
              {website ?? ''}
            </Typography>
          </Box>
          {item?.medeAanbieders?.length > 0 && (
            <Box
              style={{
                flex: '1 1 100%',
                flexDirection: 'row',
                width: '100%',
              }}
            >
              <Typography
                style={{ fontSize: 8, color: '#888888' }}
                debug={debug}
              >
                Betrokken makelaar(s):{' '}
              </Typography>
              {item.medeAanbieders.map((medeAanbieder, index) => (
                <Typography
                  style={{ fontSize: 8, color: '#000' }}
                  debug={debug}
                  key={index}
                >
                  {medeAanbieder.naam}, {medeAanbieder.plaats}
                </Typography>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}
