import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { get } from '../utils/api-data'
import { gebouwpaspoortApiHost } from '../utils/business-environment-vars'

export const fetchWalkScoreData = createAsyncThunk(
  'walkscore/fetchWalkScoreData',
  async (address, thunkAPI) => {
    if (address && address.latitude && address.longitude) {
      try {
        const response = await get(
          `proxies/walkscore?latitude=${address.latitude}&longitude=${address.longitude}`,
          { apiHostUrl: gebouwpaspoortApiHost() },
        )
        const data = JSON.parse(response)
        return data.walkscore
      } catch (error) {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  },
)

const walkscore = createSlice({
  name: 'walkscore',
  initialState: {
    walkscore: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    setWalkscoreData: (state, action) => {
      state.walkscore = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchWalkScoreData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchWalkScoreData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.walkscore = action.payload
      })
      .addCase(fetchWalkScoreData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })
  },
})

export const selectWalkscore = state => state.walkscore.walkscore

export default walkscore.reducer
