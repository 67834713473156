import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import {
  calculateImageWidth,
  createImageSrc,
} from '../../utils/responsive-image-helpers'

import './responsive-image.css'

export default function ResponsiveImage({
  style,
  className,
  imageId,
  url = '',
  alt = '',
  isLoading = false,
  host,
  loading = 'lazy',
  customWidth,
}) {
  const [highResImageLoaded, setHighResImageLoaded] = useState(false)
  const [highResImageError, setHighResImageError] = useState(false)

  // Not using useRef() because in theory 'useRef().current' could be changed
  // when you unobserve(seRef().current) on destroy
  const [imageRef, setImageRef] = useState()
  const [highResImageSrc, setHighResImageSrc] = useState('')
  const [lowResImageSrc, setLowResImageSrc] = useState('')

  useEffect(() => {
    if (imageRef) {
      let highResSrc
      let lowResSrc

      if (imageId && !isLoading) {
        const width = calculateImageWidth({ ref: imageRef })

        highResSrc = createImageSrc({ host, id: imageId, width })
        lowResSrc = createImageSrc({ host, id: imageId, width: 10 })
      }

      function setImageSrc() {
        setHighResImageError(false)
        setHighResImageLoaded(false)

        if (imageId) {
          setLowResImageSrc(lowResSrc)
          setHighResImageSrc(highResSrc)
        } else if (url) {
          setHighResImageSrc(url)
        } else if (!isLoading) {
          setHighResImageError(true)
        }
      }

      setImageSrc()
    }
  }, [imageRef, imageId, url, isLoading, host, customWidth])

  function onhighResImageLoaded() {
    setHighResImageLoaded(true)
  }

  function onHighResImageError() {
    setHighResImageError(true)
  }

  function onHighResImageTransitionEnd() {
    setLowResImageSrc()
  }

  const imageClassNames = classNames({
    'responsive-image': true,
    'responsive-image--loaded': highResImageLoaded,
    'responsive-image--error': highResImageError,
    [className]: Boolean(className),
  })

  return (
    <div className={imageClassNames} style={style} ref={setImageRef}>
      {lowResImageSrc && !highResImageError && (
        <img
          className="responsive-image__img responsive-image__img--low-res"
          src={lowResImageSrc}
          alt=""
          loading={loading}
        />
      )}
      {highResImageSrc && !highResImageError && (
        <img
          key={highResImageLoaded}
          className="responsive-image__img responsive-image__img--high-res"
          src={highResImageSrc}
          alt={alt}
          loading={loading}
          onLoad={onhighResImageLoaded}
          onError={onHighResImageError}
          onTransitionEnd={onHighResImageTransitionEnd}
        />
      )}
      {highResImageError && (
        <div className="responsive-image__img">
          <span className="sr-only">Afbeelding niet gevonden</span>
        </div>
      )}
    </div>
  )
}
