import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'

import './map-portal.css'

const mapElement = document.createElement('div')
mapElement.classList.add('map-portal')

export default function MapPortal({ children }) {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    document.getElementById('map-root').appendChild(mapElement)
    return () => {
      const root = document.getElementById('map-root')
      root && root.removeChild(mapElement)
    }
  }, [])

  useEffect(() => {
    setMounted(true)
  }, [])

  return mounted && createPortal(children, mapElement)
}
