import { deployEnv } from '@brainbay/components/utils/environment-vars'

export default function useFeatureFlags() {
  const config = {
    myComparisons: deployEnv !== 'prd',
    export: deployEnv !== 'prd',
  }

  return {
    isEnabled(featureName) {
      return config[featureName]
    },
    getEnabledFeatures() {
      return Object.keys(config).filter(key => config[key])
    },
  }
}
