import React from 'react'
import DefaultLayout from '../layouts/default'
import { Typography, Box } from '../components'
import formatAddressBusiness from '../../format-address-business'
import sortByProperties from '../../sort-by-properties'

export default function PandBetrokkenAddressPage({
  item,
  userName,
  date,
  debug,
  isGebouwpaspoort,
}) {
  const { pand } = item || {}
  const addresses =
    pand?.verblijfsobjecten?.length &&
    pand?.verblijfsobjecten
      .flatMap(verblijfsobject => {
        if (verblijfsobject.nevenAdressen)
          return [verblijfsobject.hoofdAdres, ...verblijfsobject.nevenAdressen]
        return [verblijfsobject.hoofdAdres]
      })
      .sort((a, b) =>
        sortByProperties(a, b, [
          'straatnaam',
          'huisnummer',
          'huisnummertoevoeging',
        ]),
      )
      .map(address => {
        return {
          label: `${address.isNevenAdres ? 'Nevenadres' : 'Hoofdadres'}`,
          isNevenAdres: address.isNevenAdres,
          type: 'text',
          value: formatAddressBusiness({
            street: address.straatnaam,
            houseNumber: address.huisnummer,
            houseNumberSuffix: address.huisnummertoevoeging,
            postalCode: address.postcode,
            city: address.woonplaats,
            municipality: address.gemeente,
          }),
        }
      })

  return (
    <DefaultLayout
      pageName={`Betrokken adres${addresses?.length === 1 ? '' : 'sen'}`}
      userName={userName}
      date={date}
      debug={debug}
      isGebouwpaspoort={isGebouwpaspoort}
    >
      <Typography
        style={{
          fontSize: 18,
          fontWeight: '700',
          paddingTop: 6,
          paddingBottom: 2,
          borderBottom: '1px solid #dddddd',
        }}
        debug={debug}
      >
        {`Betrokken adres${addresses?.length === 1 ? '' : 'sen'}`}
      </Typography>
      {addresses ? (
        addresses.map((adress, index) => {
          return (
            <Box
              key={index}
              style={{
                paddingBottom: 3,
                marginTop: 3,
              }}
              debug={debug}
            >
              <Box
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontSize: 10,
                  paddingTop: 6,
                  paddingBottom: 2,
                  borderBottom: '1px solid #dddddd',
                }}
                debug={debug}
              >
                <Typography
                  style={{
                    fontSize: 11,
                    paddingRight: 10,
                    flex: 1,
                  }}
                  debug={debug}
                >
                  {adress.label}
                </Typography>
                <Typography
                  style={{
                    width: '100%',
                    fontSize: 11,
                    flex: 2,
                  }}
                  debug={debug}
                >
                  {adress.value}
                </Typography>
              </Box>
            </Box>
          )
        })
      ) : (
        <Typography>Geen kenmerken.</Typography>
      )}
    </DefaultLayout>
  )
}
