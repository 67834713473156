import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import SearchBar from '@brainbay/components/components/search-bar'
import AutocompleteItem from '../autocomplete-item/autocomplete-item'
import {
  setAutoCompleteOption,
  setNewSearchValue,
} from '../../store/comparison'
import useAutocompleteOptions from '../../utils/use-autocomplete-options'
import {
  retriggerInitialState,
  setLatestSelectedResult,
} from '../../store/search-result-latest-selected'
import { setSearchQuery } from '../../store/search'
import getFormattedSearchQuery, {
  getFormattedSearchQueryResultTitle,
} from '../../utils/get-formatted-search-query'
import { defaultSearchOption } from '../../utils/constants'

export default function AppSearchBarGebouwpaspoort({ isLanding }) {
  const [inputQuery, setInputQuery] = useState('')
  const [option, setOption] = useState({})
  const [subLabel, setSubLabel] = useState('') // for showing gemente, straat etc. right under search input in the bar

  const history = useHistory()
  const dispatch = useDispatch()

  const searchQuery = useSelector(state => state.search.query)
  const existingAutocompleteOption = useSelector(
    state => state.comparison.autocompleteOption,
  )

  const { autocompleteOptions, autocompleteOptionsAreLoading } =
    useAutocompleteOptions(inputQuery, true)

  const itemRenderer = (isSelected, item) => {
    return <AutocompleteItem isSelected={isSelected} {...item} />
  }

  function handleOnSearchSubmit(event, inputValue) {
    if (event) {
      event.preventDefault()
    }

    // this might happen when you press 'enter' too soon. The api call is still in-flight
    // we might want to refine this a bit, but for now this seems ok.
    if (autocompleteOptionsAreLoading) {
      return
    }

    if (inputValue === '') {
      const { id } = defaultSearchOption
      let url = `/gebouwpaspoort/search-results/${id}`

      const offerParam = getOfferParam(url)
      dispatch(setSearchQuery(offerParam))
      history.push(offerParam)
    } else {
      const label = option.parent?.parent?.label
        ? `${option.label}, ${option.parent.parent.label}`
        : option.label

      if (label !== inputValue) {
        if (autocompleteOptions) {
          const [firstOption] = autocompleteOptions
          dispatch(setAutoCompleteOption(firstOption))
          const { url } = selectOption(firstOption)
          history.push(url)
        }
      } else if (searchQuery && Object.keys(option).length !== 0) {
        history.push(searchQuery)
      }
    }
  }

  const resetLastestSelectedResult = () => {
    dispatch(retriggerInitialState())
    dispatch(setLatestSelectedResult(undefined))
  }

  const getOfferParam = url => {
    resetLastestSelectedResult()
    return url
  }

  /* makes sure the input query is set to the correct value on load */
  useEffect(() => {
    setInputQuery(getFormattedSearchQuery(existingAutocompleteOption))
  }, [existingAutocompleteOption])

  useEffect(() => {
    if (inputQuery === '') {
      setOption({})
    }

    if (inputQuery === existingAutocompleteOption?.label) {
      setOption(existingAutocompleteOption)
    }
  }, [inputQuery, existingAutocompleteOption])

  useEffect(() => {
    if (option) {
      const { type } = getFormattedSearchQueryResultTitle(option)
      setSubLabel(type)
    }
  }, [option])

  function selectOption(autocompleteOption) {
    let query = autocompleteOption?.id
    let url

    if (query.includes('nummeraanduidingid-')) {
      query = query.split('nummeraanduidingid-')[1]
      url = `/gebouwpaspoort/${query}`
    } else {
      url = `/gebouwpaspoort/search-results/${query}/1`
    }

    setOption(autocompleteOption)

    dispatch(setNewSearchValue(autocompleteOption?.label))
    setInputQuery(getFormattedSearchQuery(autocompleteOption))
    dispatch(setSearchQuery(getOfferParam(url)))

    if (!isLanding) {
      dispatch(setAutoCompleteOption(autocompleteOption))
      history.push(getOfferParam(url))
    }

    return {
      url: getOfferParam(url),
    }
  }

  return (
    <SearchBar
      autocomplete
      autocompleteOptions={autocompleteOptions}
      autocompleteOptionsAreLoading={autocompleteOptionsAreLoading}
      autocompleteDefaultSelectedOption={0}
      onSubmit={handleOnSearchSubmit}
      itemRenderer={itemRenderer}
      onChange={setInputQuery}
      placeholder="Zoek op adres, postcode, plaats, gemeente of provincie"
      noResultsMessage="Geen resultaten, geef een andere zoekterm in."
      onClick={selectOption}
      searchValue={inputQuery}
      primaryAction={isLanding}
      separated={isLanding}
      subLabel={subLabel}
    />
  )
}
