import React from 'react'
import './status-registration.css'

export default function StatusRegistration({
  controleStatus,
  prefixControleStatus,
  isSmall = false,
}) {
  return (
    <div className="status-registration">
      <span
        className={`status-registration body text-color--dim-text loading-state ${
          isSmall ? 'body-small' : 'body-big'
        }`}
      >
        {controleStatus && prefixControleStatus && `${prefixControleStatus}: `}
        {controleStatus}
      </span>
    </div>
  )
}
