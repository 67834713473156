import React, { useState } from 'react'
import Icon from '../icon'
import { ReactComponent as IconClose } from '../../_assets/svg/icon-close.svg'
import './feedback.css'

export default function Feedback({ src, className = '' }) {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className={`feedback ${className}`.trim()}>
      <button
        className="feedback__open-button button button--small button--primary"
        onClick={() => setExpanded(true)}
      >
        Feedback
      </button>
      <div
        className={`feedback__window ${
          expanded ? 'feedback__window--show' : ''
        }`.trim()}
      >
        {expanded && (
          <>
            <button
              className="feedback__close-button"
              onClick={() => setExpanded(false)}
            >
              <span className="sr-only">Sluit feedback</span>
              <Icon>
                <IconClose />
              </Icon>
            </button>
            <iframe
              title="feedback window"
              width="100%"
              height="100%"
              src={src}
            ></iframe>
          </>
        )}
      </div>
    </div>
  )
}
