import React from 'react'
import formatValue from '../../../../utils/format-value'
import DateInput from '../date-input'
import './date-range-input.css'

const formatter = value => formatValue(value, 'date')

export function hasValue(control, filters) {
  return (
    filters[control.min.name] !== undefined ||
    filters[control.max.name] !== undefined
  )
}

export function getActiveFilterLabels(control, filters) {
  const minValue = filters[control.min.name]
  const maxValue = filters[control.max.name]

  if (maxValue && minValue) {
    return [
      {
        label: `${formatter(minValue)} - ${formatter(maxValue)} (${
          control.label
        })`,
        control,
      },
    ]
  }

  if (maxValue) {
    return [{ label: `tot ${formatter(maxValue)} (${control.label})`, control }]
  }

  if (minValue) {
    return [
      { label: `vanaf ${formatter(minValue)} (${control.label})`, control },
    ]
  }

  return []
}

export function removeFilterValues(params, control, _optionValue) {
  delete params[control.min.name]
  delete params[control.max.name]
}

export default function DateRangeInput({
  control,
  filters,
  groupLabel,
  onChange,
}) {
  const min = control.min
  const max = control.max

  return (
    <>
      <fieldset
        className="date-range-input__fieldset filters__fieldset"
        name={control.name}
      >
        <legend aria-label={control['sr-label'] || control.label}>
          {control.label !== groupLabel && control.label}
        </legend>
        <div className="date-range-input__wrapper">
          <div className="date-range-input__input-group">
            <DateInput
              control={{ ...control, ...min }}
              filters={filters}
              groupLabel={groupLabel}
              onChange={onChange}
            />
          </div>
          <div className="date-range-input__input-group">
            <DateInput
              control={{ ...control, ...max }}
              filters={filters}
              groupLabel={groupLabel}
              onChange={onChange}
            />
          </div>
        </div>
      </fieldset>
    </>
  )
}
