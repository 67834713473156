export const fluxParams = () => {
  const params = new URLSearchParams(window.location.search)

  const fluxId = params.get('fluxid')
  const fluxSessionContext = params.get('sessionContext')
  const fluxAddress = params.get('address')
  const isAllFluxParamsExist = fluxId && fluxSessionContext && fluxAddress

  return {
    isAllFluxParamsExist,
    fluxId,
    fluxSessionContext,
    fluxAddress,
  }
}
