import React from 'react'
import formatValue from '../../../../utils/format-value'
import './option-control.css'

export function getActiveFilterLabels(control, filters) {
  const options = control.options

  const selectedOption = options.find(
    option =>
      option.value === filters[control.name] && !option.hideInActiveFilters,
  )

  if (!selectedOption) {
    return []
  }

  return [{ label: selectedOption.label, control }]
}

export function removeFilterValues(params, control, _optionValue) {
  delete params[control.name]
}

export default function OptionControl({
  control,
  filters,
  facet,
  groupLabel,
  onChange,
  labelContent,
}) {
  const isButtonGroup = control.type.startsWith('button-group-')

  const type = control.type.replace('button-group-', '')

  const filterValueForControl = Array.isArray(filters?.[control.name])
    ? filters?.[control.name][0]
    : filters?.[control.name]
  const filterValues = (filterValueForControl || '').split(',')
  const buttonGroup = isButtonGroup ? { 'data-button-group': '' } : {}

  return (
    <fieldset className="option-control filters__fieldset" {...buttonGroup}>
      <legend className={`${groupLabel === control.label ? 'sr-only' : ''}`}>
        {control.label || groupLabel}
      </legend>
      {control?.options.map(option => {
        const facetValue = formatValue(
          facet?.[control.name]?.[option.value || option.label],
          'decimal',
        )
        const labelTextContent =
          typeof labelContent === 'function'
            ? labelContent(option.label || option.value)
            : option.label || option.value

        return (
          <React.Fragment key={option.value}>
            <input
              id={`search-filters__control--${control.name}-${option.value}`}
              type={type}
              value={option.value}
              onChange={onChange}
              name={control.name}
              checked={Boolean(
                (filterValues &&
                  filterValues.length > 0 &&
                  filterValues.includes(option.value)) ||
                  (!filterValueForControl && option.selected),
              )}
            />
            <label
              htmlFor={`search-filters__control--${control.name}-${option.value}`}
              aria-label={option['sr-label']}
            >
              {isButtonGroup === false ? (
                <span className="option-control__label-text">
                  {typeof labelContent === 'function' ? (
                    labelTextContent
                  ) : (
                    <span
                      className={`${
                        facetValue === '0' || facetValue === '---'
                          ? 'option-control__facet-0'
                          : ''
                      }`}
                    >
                      {labelTextContent}
                    </span>
                  )}

                  <span className="option-control__facet">{facetValue}</span>
                </span>
              ) : (
                <span className="option-control__label-text--radio-group">
                  {labelTextContent}{' '}
                  {facetValue !== '---' || facetValue !== '0' ? (
                    <span>({facetValue})</span>
                  ) : (
                    <span>(0)</span>
                  )}
                </span>
              )}
            </label>
          </React.Fragment>
        )
      })}
    </fieldset>
  )
}
