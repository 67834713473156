import React, { useEffect, useState } from 'react'
import Panel from '@brainbay/components/components/panel'

import { ObjectPartialTransactionsTable } from './object-partial-transactions-table'
import './object-partial-transactions.css'

const buttonTexts = {
  expanded: ['Toon minder', 'Verberg deel van de deeltransacties'],
  collapsed: ['Toon meer', 'Toon alle deeltransactie'],
}

const srIntroText = {
  expanded: (
    <p className="sr-only">
      De deeltransacties zijn nu volledig beschikbaar. Gebruik de knop:
      <i>Verbeg deel van de deeltransacties</i>, om een deel van de
      deeltransacties te verbergen.
    </p>
  ),
  collapsed: (
    <p className="sr-only">
      De deeltransacties zijn deels visueel verborgen. Gebruik de knop:
      <i>Toon alle deeltransacties</i>, om alle deeltransacties te tonen.
    </p>
  ),
}

export default function ObjectPartialTransactions({
  className = '',
  fullBleed = false,
  hideFooter = false,
  showBuyerTenantName = false,
  expandPartialTransactions = false,
  data,
}) {
  const [hasLoaded, setHasLoaded] = useState(false)
  const [expanded, setExpanded] = useState(undefined)
  const [showFooter, setShowFooter] = useState(false)
  const [transactionCount, setTransactionCount] = useState(0)
  const [buttonText, setButtonText] = useState(buttonTexts.collapsed)

  useEffect(() => {
    if (data && !data.loading) {
      setHasLoaded(true)
    }
  }, [data])

  const partialTransactions = data?.deelTransacties
  const totalTransactionsCount = partialTransactions?.length
  const isExpanded = !expanded

  useEffect(() => {
    if (hasLoaded) {
      if (hideFooter) {
        setShowFooter(false)
        setExpanded(true)
        setTransactionCount(totalTransactionsCount)
      } else {
        if (totalTransactionsCount >= 1) {
          setTransactionCount(1)
        }
        if (totalTransactionsCount > 1) {
          setShowFooter(true)
        }
      }
    }
  }, [hasLoaded, hideFooter, totalTransactionsCount])

  useEffect(() => {
    if (expandPartialTransactions) {
      setExpanded(true)
      setTransactionCount(totalTransactionsCount)
      setButtonText(buttonTexts.expanded)
    }
  }, [expandPartialTransactions, totalTransactionsCount])

  function toggle() {
    setExpanded(isExpanded)
    setTransactionCount(isExpanded ? totalTransactionsCount : 1)
    setButtonText(isExpanded ? buttonTexts.expanded : buttonTexts.collapsed)
  }

  return (
    <>
      {hasLoaded && data?.deelTransacties?.length > 0 && (
        <Panel
          title={`Deeltransacties (${totalTransactionsCount})`}
          tag="section"
          className={className}
          fullBleed={fullBleed}
        >
          <div className="object-partial-transactions__content">
            {!hideFooter
              ? expanded
                ? srIntroText.expanded
                : srIntroText.collapsed
              : null}
            <ObjectPartialTransactionsTable
              transactions={partialTransactions}
              transactionCount={transactionCount}
              caption={`Deeltransacties (${totalTransactionsCount})`}
              expanded={expanded}
              hideCaption
              showBuyerTenantName={showBuyerTenantName}
            />
          </div>
          {showFooter && (
            <footer
              className={`object-description__footer ${
                data?.loading ? 'object-description__footer--loading' : ''
              }`}
            >
              <button
                aria-expanded={expanded ? 'true' : 'false'}
                className="object-description__toggle button button--x-small print-excluded"
                onClick={toggle}
                disabled={data?.loading}
              >
                <span aria-hidden="true">{buttonText[0]}</span>
                <span className="sr-only">{buttonText[1]}</span>
              </button>
            </footer>
          )}
        </Panel>
      )}
    </>
  )
}
