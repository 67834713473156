import { createSlice } from '@reduxjs/toolkit'

const searchResultLatestSelected = createSlice({
  name: 'searchResultMapMode',
  initialState: {
    guid: undefined,
    longitude: undefined,
    latitude: undefined,
    zoom: undefined,
  },
  reducers: {
    setLatestSelectedResult: (state, { payload }) => {
      state.guid = payload
    },
    setLatestSelectedResultGeometry: (state, { payload }) => {
      const { longitude, latitude, zoom } = payload
      Object.assign(state, { longitude, latitude, zoom })
    },
    retriggerInitialState: state => {
      state.initialState = { ...state.initialState }
    },
  },
})

export const {
  setLatestSelectedResult,
  setLatestSelectedResultGeometry,
  retriggerInitialState,
} = searchResultLatestSelected.actions

export default searchResultLatestSelected.reducer
