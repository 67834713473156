import React, { useState, useEffect, forwardRef } from 'react'
import Panel from '@brainbay/components/components/panel'
import './building-description.css'
import {
  fetchKvkUsers,
  resultPerPage as resultPerPageKvkData,
} from '../../store/kvk-users'
import SourceLabel from '@brainbay/components/components/source-label'
import { useDispatch } from 'react-redux'
import './kvk-users.css'

const KvkUsersTable = forwardRef(function KvkUsersTable(
  { data, address, formattedAddress },
  ref,
) {
  const [numberOfRowsToBeShown, setNumberOfRowsToBeShown] =
    useState(resultPerPageKvkData)
  const [showFooter, setShowFooter] = useState(false)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()

  const handleShowMore = () => {
    if (data?.total > data?.kvkUsers?.length) {
      dispatch(fetchKvkUsers({ address, page: page + 1 }))
      setPage(page => page + 1)
    }
    setNumberOfRowsToBeShown(
      Math.min(numberOfRowsToBeShown + resultPerPageKvkData, data?.total),
    )
  }
  useEffect(() => {
    setNumberOfRowsToBeShown(resultPerPageKvkData)
    setShowFooter(false)
    setPage(1)
  }, [address])

  const handleShowLess = () => {
    setNumberOfRowsToBeShown(
      Math.max(
        numberOfRowsToBeShown - resultPerPageKvkData,
        resultPerPageKvkData,
      ),
    )
  }

  // show the footer based on the number of rows
  useEffect(() => {
    if (data?.total > resultPerPageKvkData) {
      setShowFooter(true)
    }
  }, [data])

  return (
    <div ref={ref}>
      <Panel
        title={`Actuele gebruikers (KvK) (${data?.total})`}
        tag="article"
        divider
      >
        {data?.kvkUsers?.length > 0 && (
          <>
            <h3 className="kvk-users__address-title">
              <b>Adres:</b>
              {formattedAddress}
            </h3>
            <table className="kvk-users__table">
              <caption className="sr-only">Actuele gebruikers (kvk)</caption>
              <thead>
                <tr className="text-bold">
                  <th scope="col" className="kvk-users__table-header"></th>
                  <th scope="col" className="kvk-users__table-header">
                    KVK nummer
                  </th>
                  <th scope="col" className="kvk-users__table-header">
                    Handelsnaam
                  </th>
                  <th scope="col" className="kvk-users__table-header">
                    Adres type
                  </th>
                  <th scope="col" className="kvk-users__table-header">
                    Type
                  </th>
                </tr>
              </thead>

              <tbody>
                {data?.kvkUsers
                  ?.slice(0, numberOfRowsToBeShown)
                  .map((object, index) => {
                    const addressOfObject = object?.adres?.binnenlandsAdres
                    return (
                      <tr key={index}>
                        <td className="kvk-users__table-data">{index + 1}.</td>
                        <td className="kvk-users__table-data">
                          {object.kvkNummer}
                        </td>
                        <td className="kvk-users__table-data">{object.naam}</td>
                        <td className="kvk-users__table-data capitalize-first-letter">
                          {addressOfObject?.type}
                        </td>
                        <td className="kvk-users__table-data capitalize-first-letter">
                          {object.type}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
            {showFooter && (
              <footer className="object-betrokken-addresses__expandable">
                <div className="object-betrokken-addresses__expandable--rows">
                  {numberOfRowsToBeShown > resultPerPageKvkData && (
                    <button
                      className="button button--x-small expandable--rows__toggle"
                      onClick={handleShowLess}
                      disabled={data?.status === 'loading'}
                    >
                      Toon minder
                    </button>
                  )}
                  {numberOfRowsToBeShown < data?.total && (
                    <button
                      className="button button--x-small expandable--rows__toggle"
                      onClick={handleShowMore}
                      disabled={data?.status === 'loading'}
                    >
                      Toon meer
                    </button>
                  )}
                </div>
              </footer>
            )}
            <div className="kvk-users__source-label">
              <SourceLabel source="KvK" prefixSource="Bron" />
            </div>
          </>
        )}
      </Panel>
    </div>
  )
})
export default KvkUsersTable
