import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  query: '',
  mode: 'switch-referenties',
}

const search = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchQuery: (state, { payload }) => {
      state.query = payload
    },
    setSearchMode: (state, { payload }) => {
      state.mode = payload
    },
  },
})

export const { setSearchQuery, setSearchMode } = search.actions

export default search.reducer
