export const defaultSearchOption = {
  id: 'heel-nederland',
  label: 'Heel Nederland',
  type: 'land',
  sortDirection: 'descending',
  sortBy: 'transactiedatum',
}

export const gebouwpaspoortSources = [
  {
    source: 'BAG',
    dataset: 'adressen',
    updates: '1, 9, 15de van de maand',
  },
  {
    source: 'Ruimtelijke plannen',
    dataset: 'Enkelbestemming',
    updates: '1ste van de maand',
  },
  {
    source: 'Ruimtelijke plannen',
    dataset: 'Functiebeschrijving',
    updates: '1ste van de maand',
  },
  {
    source: 'Ruimtelijke plannen',
    dataset: 'Maatvoering',
    updates: '1ste van de maand',
  },
  {
    source: 'IBIS',
    dataset: 'kantoorgebieden',
    updates: '9-10de maand van het jaar',
  },
  {
    source: 'Locatus',
    dataset: 'winkelgebieden',
    updates: '2 keer per jaar, onregelmatig',
  },
  {
    source: 'BRP',
    dataset: 'gewaspercelen',
    updates: 'jaarlijks, september',
  },
  {
    source: 'RVO',
    dataset: 'energielabels',
    updates: 'dagelijks',
  },
  {
    source: 'Klimaatatlas',
    dataset: 'funderingsrisico',
    updates: 'onregelmatig',
  },
  {
    source: 'Brainbay',
    dataset: 'sector beschrijving',
    updates: 'maand na afloop kwartaal',
  },
  {
    source: 'Brainbay',
    dataset: 'registraties',
    updates: 'dagelijks',
  },
  {
    source: 'Brainbay',
    dataset: 'marktanalyses',
    updates: 'maand na afloop kwartaal',
  },
  {
    source: 'CBS',
    dataset: 'kerncijfers',
    updates: 'onregelmatig',
  },
  {
    source: 'CBS',
    dataset: 'nabijheidsstatistieke',
    updates: '3 maal per jaar',
  },
  {
    source: 'CBS',
    dataset: 'mate van verstedelijk',
    updates: 'onregelmatig',
  },
  {
    source: 'Kvk',
    dataset: 'zoekenapi',
    updates: 'live',
  },
  {
    source: 'Luchtfoto',
    dataset: 'Openstreetmap',
    updates: 'medio 2022',
  },
  {
    source: 'Kadaster',
    dataset: 'Kadastraal perceel',
    updates: '10de van de maand',
  },
  {
    source: 'Lokale bekendmakingen',
    dataset: 'Lokale bekendmakingen',
    updates: 'live',
  },
  {
    source: 'Bodemloket',
    dataset: 'Bodemloket',
    updates: 'live',
  },
  {
    source: 'Walkscore',
    dataset: 'Walkscore',
    updates: 'live',
  },
]
