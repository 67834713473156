import React from 'react'
import { Page as PDFPage } from '@react-pdf/renderer'

export default function Page({ children, smallPadding = false }) {
  return (
    <PDFPage
      size={'A4'}
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Rubik',
        paddingTop: smallPadding ? 35 : 100,
        paddingBottom: smallPadding ? 35 : 65,
        paddingHorizontal: 35,
      }}
    >
      {children}
    </PDFPage>
  )
}
