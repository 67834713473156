import React from 'react'
import DefaultLayout from '../layouts/default'
import PandDetails from '../components/pand-details'
import PandCharacteristics from '../components/pand-characteristics'

export default function PandDetailsPage({
  item,
  userName,
  date,
  debug,
  gebouwpaspoortImage,
  isGebouwpaspoort,
  address,
}) {
  return (
    <DefaultLayout
      pageName="Pand details"
      userName={userName}
      date={date}
      debug={debug}
      isGebouwpaspoort={isGebouwpaspoort}
    >
      <PandDetails
        item={item}
        debug={debug}
        gebouwpaspoortImage={gebouwpaspoortImage}
        isGebouwpaspoort={isGebouwpaspoort}
      />
      <PandCharacteristics item={item} debug={debug} address={address} />
    </DefaultLayout>
  )
}
