import { status } from '@brainbay/components/utils/constants'
import formatValue from '@brainbay/components/utils/format-value'

export default function useObjectDate(data) {
  switch (data?.status) {
    case status.VERKOCHT:
    case status.VERHUURD:
    case status.GEVEILD:
    case status.VERLENGING:
      return {
        term: 'Transactiedatum',
        definition: formatValue(data.transactie.transactieDatum, {
          format: 'date',
        }),
      }
    case status.TE_KOOP:
    case status.VERKOCHT_ONDER_VOORBEHOUD:
    case status.TE_HUUR:
    case status.VERHUURD_ONDER_VOORBEHOUD:
    case status.VERKOCHT_BIJ_INSCHRIJVING:
    case status.VERKOOP_BIJ_INSCHRIJVING:
    case status.VEILING:
    case status.TE_KOOP_EN_TE_HUUR:
    case status.ONDER_BOD:
    case status.ONDER_OPTIE:
      return {
        term: 'Aanmelddatum',
        definition: formatValue(data.aanmelddatum, {
          format: 'date',
        }),
      }
    case status.INGETROKKEN:
      return {
        term: 'Afmelddatum',
        definition: formatValue(data.afmelddatum, {
          format: 'date',
        }),
      }
    default:
      return {
        term: '',
        definition: '',
      }
  }
}
