import { conditionAcronyms } from './constants'

/**
 * Determines the rounding factor based on the value.
 *
 * @param {number} value - The value to determine the rounding factor for.
 * @returns {number} The rounding factor.
 */
function getRoundingFactor(value) {
  if (value <= 100) return 1
  else if (value <= 1000) return 10
  else if (value <= 10000) return 100
  else return 1000
}

export function roundNumber(value, round) {
  value = parseInt(value, 10)

  if (isNaN(value)) {
    return '---'
  } else {
    return !isNaN(round) ? Math.round(value / round) * round : value
  }
}

export function toDecimal(value, round) {
  const roundedValue = roundNumber(value, round)

  // After each third number it will create a new 'Capturing group' between the capturing groups we add a dot.
  // https://blog.abelotech.com/posts/number-currency-formatting-javascript/
  return roundedValue.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') // 1000 -> 1.000
}

export function toCurrency(value, round) {
  value = parseInt(value, 10)

  return isNaN(value) ? '---' : `\u20AC ${toDecimal(value, round)}` // € {value}
}

export default function formatValue(value, _options) {
  let options = _options
  if (typeof _options === 'string') {
    options = { format: _options }
  }

  if (options?.dynamicRound) {
    const round = getRoundingFactor(value)
    options = { ...options, round }
  }
  if (!options?.format && options?.round) {
    return roundNumber(value, options.round)
  }

  switch (options?.format) {
    case 'decimal': {
      return toDecimal(value, options.round)
    }

    case 'currency': {
      return toCurrency(value, options.round)
    }

    case 'currency-with-comparison-text': {
      value = parseInt(value, 10)

      return isNaN(value)
        ? '---'
        : value > 0
        ? `\u20AC ${toDecimal(value, options.round)} hoger`
        : `\u20AC ${toDecimal(-value, options.round)} lager`
    }

    case 'number-behind-comma': {
      return parseFloat(value)
        .toFixed(options.numberOfItems)
        .replace(/\./g, ',')
    }

    case 'costs': {
      const conditionText = conditionAcronyms[options.condition]
      const conditionRaw = options?.condition || ''

      if (conditionText) {
        return `${toCurrency(value, options.round)} ${conditionText}`
      } else if (conditionRaw.length && options.useUnsupportedCondition) {
        return `${toCurrency(value, options.round)} ${conditionRaw}`
      } else {
        return `${toCurrency(value, options.round)}`
      }
    }

    case 'meter-squared': {
      return `${toDecimal(value, options.round)} m\u00B2` // {value}m²
    }

    case 'per-meter-squared': {
      return `${toCurrency(value, options.round)} per m\u00B2` // {value} per m²
    }

    case 'cubic-meter': {
      return `${toDecimal(value, options.round)} m\u00B3` // {value}m³
    }

    case 'linear-measure': {
      const metric = options?.condition
      return `${toDecimal(value, options.round)}${metric}`
    }

    case 'days': {
      const days = toDecimal(value, options.round)

      if (days === '1') {
        return `${days} dag`
      } else {
        return `${days} dagen`
      }
    }

    case 'months': {
      if (!value) {
        return '---'
      }

      const months = toDecimal(value, options.round)

      if (months === '1') {
        return `${months} maand`
      } else {
        return `${months} maanden`
      }
    }

    case 'percent': {
      if (Number.isNaN(parseFloat(value))) {
        return '--- %'
      }
      return `${Math.round(value)}%`
    }

    case 'percent-positive': {
      if (Number.isNaN(parseFloat(value))) {
        return '--- %'
      }
      return Math.round(value) < 0
        ? `${Math.round(value)}%`
        : `+${Math.round(value)}%`
    }

    case 'percent-negative': {
      if (Number.isNaN(parseFloat(value))) {
        return '--- %'
      }
      return `-${Math.round(value)}%`
    }

    case 'percent-with-comparison-text': {
      if (Number.isNaN(parseFloat(value))) {
        return '--- %'
      }
      return Math.round(value) < 0
        ? `${-Math.round(value)}% gedaald`
        : `${Math.round(value)}% gestegen`
    }

    case 'period': {
      if (
        typeof value !== 'object' ||
        (value.from === undefined && value.to === undefined)
      ) {
        return '---'
      }

      const from = value.from !== undefined ? value.from : 'tot '
      const to =
        value.to !== undefined && value.from !== undefined
          ? `-${value.to}`
          : value.to
          ? value.to
          : ''

      return `${from}${to}`
    }

    // Example input: ['value1', 'value2']
    case 'comma-separated-list': {
      if (!value || !value.length || !Array.isArray(value)) {
        return '---'
      }
      return value.filter(item => item).join(', ')
    }

    // Example input: 2018-07-31T15:31:09.923Z (Date-Time ISO date) || 2018-07-31 (ISO date) || 1532995200 (unix timestamp)
    case 'date': {
      if (!value) {
        return '---'
      }

      const dateValue = isNaN(value) ? new Date(value) : new Date(value * 1000)

      let day = dateValue.getDate()
      let month = dateValue.getMonth() + 1
      const year = dateValue.getFullYear()

      if (day < 10) {
        day = `0${day}`
      }

      if (month < 10) {
        month = `0${month}`
      }
      return day && month && year ? `${day}-${month}-${year}` : '---'
    }

    // Example input: 2018-07-31T15:31:09.923Z (Date-Time ISO date) || 2018-07-31 (ISO date) || 1532995200 (unix timestamp)
    case 'short-date': {
      if (!value) {
        return '---'
      }

      const dateValue = isNaN(value) ? new Date(value) : new Date(value * 1000)

      let month = dateValue.getMonth() + 1
      const year = dateValue.getFullYear()

      if (month < 10) {
        month = `0${month}`
      }

      return month && year ? `${month}-${year}` : '---'
    }

    case 'quarterly-date': {
      if (!value) {
        return '---'
      }

      const dateValue = isNaN(value) ? new Date(value) : new Date(value * 1000)

      return `Q${Math.floor(
        (dateValue.getMonth() + 3) / 3,
      )}-${dateValue.getFullYear()}`
    }

    default: {
      return value
    }

    case 'date-time': {
      if (!value) {
        return '---'
      }

      const date = formatValue(value, 'date')
      const timeValue = isNaN(value) ? new Date(value) : new Date(value * 1000)

      return `${date} ${timeValue.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      })}`
    }
  }
}
