import formatValue from '@brainbay/components/utils/format-value'
import { csvOrderTemplate } from './csv-order-template'

/**
 * Escapes a string by wrapping it in double quotes.
 *
 * @param {string} string - The string to escape.
 * @returns {string} The escaped string, enclosed in double quotes.
 */
function escapeString(string) {
  return '"' + string + '"'
}

/**
 * Combines an array of strings into a single string, using the specified separator and escaping each string with double quotes.
 *
 * @param {Array<string>} array - The array of strings to combine.
 * @param {string} separator - The separator to use between each string.
 * @returns {string} The combined string, with each original string enclosed in double quotes and separated by the specified separator.
 */
function combineArrayToString(array, separator) {
  return escapeString(array.join(separator))
}

/**
 * Formats the given data based on the provided key, according to the specified rules.
 *
 * @param {string} key - The key representing the type of data being formatted.
 * @param {any} data - The data to be formatted.
 * @returns {string} The formatted data as a string.
 */
function formatCsvValues(key, data) {
  /**
   * Escapes and joins an array of strings.
   *
   * @param {string[]} strArray - The array of strings to be escaped and joined.
   * @returns {string} The escaped and joined string.
   */
  const escapeAndJoinStrings = strArray => strArray.map(escapeString).join('')

  /**
   * Formats the 'naam' and 'plaats' properties of an object.
   *
   * @param {Object} [obj] - The object containing 'naam' and 'plaats' properties.
   * @param {string} [obj.naam] - The 'naam' property of the object.
   * @param {string} [obj.plaats] - The 'plaats' property of the object.
   * @returns {string} The formatted string combining 'naam' and 'plaats'.
   */
  const formatNameAndPlace = ({ naam, plaats } = {}) =>
    naam || plaats ? escapeString(`${naam ?? ''} - ${plaats ?? ''}`) : ''

  switch (key) {
    case 'hoofdAanbieder':
      return formatNameAndPlace(data)
    case 'medeAanbieders':
      return data && data.length
        ? escapeAndJoinStrings(data.map(formatNameAndPlace))
        : ''
    case 'aanmelddatum':
    case 'afmelddatum':
    case 'transactieDatum':
      return data ? formatValue(data, { format: 'date' }) : ''
    case 'specialisaties':
    case 'alternatieveAanwendbaarheden':
      return data ? combineArrayToString(data, ', ') : ''
    case 'transactieVertrouwelijk':
      return data ? 'ja' : 'nee'
    case 'transactiePrijs':
    case 'huurprijs':
    case 'koopprijs':
      return typeof data !== 'object' ? data : ''
    case 'brutoAanvangsrendementAanbieding':
    case 'brutoAanvangsrendementTransactie':
      return data ? escapeString(`${data.toFixed(2).replace('.', ',')}%`) : ''
    default:
      return data
  }
}

/**
 * Escapes a string value if the key is not in the dateKeys array.
 *
 * @param {string} key - The key of the value.
 * @param {*} value - The value to be escaped if needed.
 * @returns {*} - The escaped value if conditions are met, otherwise the original value.
 */
function escapeStringIfNeeded(key, value) {
  const dateKeys = ['aanmelddatum', 'afmelddatum', 'transactieDatum']

  if (typeof value === 'string' && !dateKeys.includes(key)) {
    return escapeString(value)
  }

  return value
}

/**
 * Formats an object as a CSV line using the provided keys.
 *
 * @param {string[]} keys - The array of keys to be used for extracting and formatting the object values.
 * @param {Object} object - The object to be formatted as a CSV line.
 * @returns {string} - The formatted CSV line.
 */
function formatCsvLine(keys, object) {
  return keys
    .map(key => {
      const value = escapeStringIfNeeded(key, object[key])
      return formatCsvValues(key, value)
    })
    .join(',')
}

/**
 * Creates a function that takes an object and returns a CSV line.
 *
 * @param {string[]} keys - The array of keys to be used for extracting and formatting the object values.
 * @returns {function(Object): string} - A function that takes an object and returns a formatted CSV line.
 */
function getLineFromObject(keys) {
  return function execute(object) {
    return formatCsvLine(keys, object)
  }
}

/**
 *
 * Transform header names from camelCase to sentence case with lowercase letters
 *
 * @param {String[]} keys Keys of the object
 * @returns string[]
 */
function formatCsvHeaderNames(keys) {
  return keys.map(key => {
    return key.replace(/([A-Z])/g, ' $1').toLowerCase()
  })
}

/**
 * Converts an array of result objects into a CSV formatted string.
 * First, it converts the results into an array of ordered objects using the convertResultToOrderedObject function.
 * Then, it generates the CSV header names and the CSV data lines.
 * Finally, it joins the header and the data lines into a single string with newline characters.
 *
 * @function convertToCsv
 * @param {Array<Object>} results - An array of result objects to be converted.
 * @returns {string} - The CSV formatted string containing the headers and the data lines.
 */
export default function convertToCsv(results) {
  if (!results || results.length === 0) {
    return ''
  }

  /**
   * Convert a result object to an ordered object based on the csvOrderTemplate.
   * The ordered object will have a 'stamkaart' property with a URL constructed using the objectGuid of the result object.
   * If the ordered object has a 'transactie' property, it will be merged with the orderedResult object.
   *
   * @function convertResultToOrderedObject
   * @param {Object} result - The result object to be converted.
   * @returns {Object} - The ordered object derived from the result object.
   */
  const convertResultToOrderedObject = result => {
    const orderedResult = { ...csvOrderTemplate, ...result }
    orderedResult.stamkaart = `${window.location.origin}/${result.objectGuid}`

    if (orderedResult.transactie) {
      // TODO: Refactor this to use a more generic approach
      if (orderedResult.transactie.huurprijs) {
        delete orderedResult.transactie.huurprijs
      }

      // TODO: Refactor this to use a more generic approach
      if (orderedResult.transactie.koopprijs) {
        delete orderedResult.transactie.koopprijs
      }

      return { ...orderedResult, ...orderedResult.transactie }
    }
    return orderedResult
  }

  const convertedResults = results.map(convertResultToOrderedObject)
  const keys = Object.keys(csvOrderTemplate)
  const formattedKeys = formatCsvHeaderNames(keys)
  const objectLines = convertedResults.map(getLineFromObject(keys))
  const lines = [formattedKeys.join(','), ...objectLines]

  return lines.join('\n')
}
