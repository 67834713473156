import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '@brainbay/components/components/modal'
import { objectsToCompareSelector } from '../../store/comparison'
import {
  removeFluxModal,
  resetFluxConnectionState,
  sentRefIdsToFlux,
} from '../../store/flux-connection'
import './flux-connection-modal.css'
import { fluxParams } from '../../utils/flux-params'

export default function FluxModalComponent() {
  const dispatch = useDispatch()
  const { isFluxModalOpen, statusOfSentRefs } = useSelector(
    state => state.fluxConnection,
  )
  const objectsToCompare = useSelector(objectsToCompareSelector)

  const isFluxEndpointSucceeded = statusOfSentRefs === 'succeeded'
  const isFluxEndpointFailed = statusOfSentRefs === 'failed'
  const isFluxEndpointLoading = statusOfSentRefs === 'loading'

  const { fluxId, fluxSessionContext, fluxAddress } = fluxParams()

  const onClickSendFlux = async () => {
    const refIdsToSendFlux = objectsToCompare.map(obj => obj.id)

    dispatch(
      sentRefIdsToFlux({
        ids: refIdsToSendFlux,
        sessionContext: fluxSessionContext,
      }),
    )
  }
  return (
    isFluxModalOpen && (
      <Modal
        onClose={() => dispatch(resetFluxConnectionState())}
        className="flux__modal"
        title={`Gegevens verzenden naar Flux-ID ${fluxId}`}
        showFooter={false}
      >
        <>
          <p>
            Weet je zeker dat je de gegevens van onderstaande referentieobjecten
            aan jouw taxatie met Flux-ID {fluxId} en adres {fluxAddress} wilt
            toevoegen?
          </p>
          <ul>
            {Object.values(objectsToCompare).map((object, index) => (
              <li key={index}>{object.formattedAddress}</li>
            ))}
          </ul>
          <div className="flux__modal-buttons">
            <button
              className="modal-buttons__button button .button--destructive-hover"
              onClick={() => dispatch(removeFluxModal())}
              disabled={isFluxEndpointLoading || isFluxEndpointSucceeded}
            >
              {'Cancel'}
            </button>
            <button
              className={`modal-buttons__button button button--primary ${
                isFluxEndpointLoading && 'button--loading'
              }`}
              type="submit"
              disabled={isFluxEndpointLoading || isFluxEndpointSucceeded}
              onClick={onClickSendFlux}
            >
              {'Verzend naar Flux'}
            </button>
          </div>
          {isFluxEndpointSucceeded && (
            <p className="request-status request-status--succeeded">
              De referentieobjecten zijn verzonden naar jouw taxatie met Flux-ID{' '}
              {fluxId}. Ververs je taxatie in Flux om de verzonden
              referentieobjecten en gegevens zichtbaar te maken.
            </p>
          )}
          {isFluxEndpointFailed && (
            <p className="request-status request-status--failed">
              Gegevens verzenden mislukt. Mocht het probleem aanhouden, neem dan
              contact op met de helpdesk van Flux via 085-401 63 70 of per mail
              via helpdesk@flux.nl.
            </p>
          )}
        </>
      </Modal>
    )
  )
}
