import { mediaHost as defaultMediaHost } from './environment-vars'

/**
 * Calculate the optimal image width.
 * Using the device pixel ratio and the width of the image element. Optionally a
 * custom width can be provided.
 *
 * @param {object} imageRef - The image element reference
 * @param {number} width - The custom width
 * @returns {number} width
 */
export function calculateImageWidth({ ref, width }) {
  const pixelRatio = window.devicePixelRatio || 1
  const cssWidth = ref?.getBoundingClientRect().width || width
  return Math.ceil((cssWidth * pixelRatio) / 100) * 100
}

/**
 * Create an image source URL. Optionally a host can be provided, otherwise the
 * default media host is used.
 *
 * @param {string} host - The host URL
 * @param {string} id - The image ID
 * @param {number} width - The image width
 * @returns {string} src
 */
export function createImageSrc({ host, id, width }) {
  const mediaHost = host ? host : defaultMediaHost
  return `${mediaHost}/${width}x/${id}`
}
