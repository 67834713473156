import html2canvas from 'html2canvas'

async function captureElementToCanvas(element) {
  try {
    const canvas = await html2canvas(element, {
      useCORS: true,
      allowTaint: true,
    })
    return canvas.toDataURL('image/png', 1.0)
  } catch (error) {
    console.error('Error capturing map image:', error)
    return null
  }
}

export default captureElementToCanvas
