import React from 'react'
import Panel from '@brainbay/components/components/panel'

import './object-broker.css'
import {
  isValidUrl,
  prependProtocol,
} from '@brainbay/components/utils/url-helpers'

export default function ObjectBroker({ data = {} }) {
  let {
    vestigingnummer,
    naam,
    straatnaam,
    huisnummer,
    huisnummertoevoeging,
    postcode,
    plaats,
    telefoonnummer,
    website,
  } = data.hoofdAanbieder ?? {}

  website = prependProtocol(website)

  return (
    <Panel title="Makelaar">
      <dl className="object-broker__list">
        {(data.loading || vestigingnummer) && (
          <>
            <dt>Vestigingnummer</dt>
            <dd className="loading-state">{vestigingnummer}</dd>
          </>
        )}

        {(data.loading || naam) && (
          <>
            <dt>Naam</dt>
            <dd className="loading-state">{naam}</dd>
          </>
        )}

        {(data.loading ||
          straatnaam ||
          huisnummer ||
          huisnummertoevoeging ||
          plaats ||
          postcode) && (
          <>
            <dt>Locatie</dt>
            <dd className="loading-state">
              {(straatnaam || huisnummer || huisnummertoevoeging) && (
                <span>
                  {straatnaam} {huisnummer} {huisnummertoevoeging}
                </span>
              )}
              {(postcode || plaats) && (
                <span>
                  {postcode} {plaats}
                </span>
              )}
            </dd>
          </>
        )}

        {(data.loading || telefoonnummer || website) && (
          <>
            <dt>Contact</dt>
            <dd className="loading-state">
              {telefoonnummer && (
                <a href={`tel:${telefoonnummer}`}>{telefoonnummer}</a>
              )}
              {website &&
                (isValidUrl(website) ? (
                  <a href={website} target="_blank" rel="noopener noreferrer">
                    {website}
                  </a>
                ) : (
                  <p>{website}</p>
                ))}
            </dd>
          </>
        )}

        {data?.medeAanbieders?.length > 0 && (
          <>
            <dt>Betrokken makelaar(s)</dt>
            {data.medeAanbieders.map((medeAanbieder, index) => (
              <dd key={index}>
                {medeAanbieder.naam}, {medeAanbieder.plaats}
              </dd>
            ))}
          </>
        )}
      </dl>
    </Panel>
  )
}
