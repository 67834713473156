import React from 'react'
import { useState, useEffect } from 'react'
import log from '@brainbay/components/utils/log'
import store from '../store'
import { apiHost } from '@brainbay/components/utils/environment-vars'
import * as auth from './auth-service'

const noop = () => {}

function mergeProps(newProps) {
  return function (component) {
    const props = { ...component.props, ...newProps }
    return { ...component, props }
  }
}

async function throwOnStatusError(response) {
  if (response.status === 401) {
    auth.login()
    return Promise.reject(response)
  } else if (response.status >= 400 || response.ok === false) {
    return Promise.reject(response)
  } else {
    return Promise.resolve(response)
  }
}

function requestData({
  apiHostUrl = '',
  path,
  body = {},
  method = 'GET',
  abortController,
}) {
  const abortSignal = abortController?.signal
  const idToken = store.getState()?.user?.idToken
  const authHeader = idToken ? { Authorization: `Bearer ${idToken}` } : {}

  const accessToken = store.getState()?.user?.accessToken
  const accessTokenHeader = accessToken ? { AccessToken: accessToken } : {}

  const bodyStr = JSON.stringify(body)
  const options = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...authHeader,
      ...accessTokenHeader,
    },
    signal: abortSignal,
  }

  if (method === 'POST') {
    options.body = bodyStr
  }

  return fetch(`${apiHostUrl ? apiHostUrl : apiHost()}/${path}`, options)
    .then(throwOnStatusError)
    .then(response => {
      if (response.status === 204) {
        return null
      }
      return response.json()
    })
    .catch(error =>
      Promise.reject(
        error.message ? { name: error.name, error: error.message } : error,
      ),
    )
}

export function createAbortController() {
  return window.AbortController
    ? new window.AbortController()
    : { signal: () => {} }
}

export function get(path, { apiHostUrl, body } = {}, abortController) {
  return requestData({ apiHostUrl, path, body, abortController }).catch(
    error => {
      return Promise.reject(error)
    },
  )
}

export function post({ path, apiHostUrl, body }, abortController) {
  return requestData({
    apiHostUrl,
    path,
    body,
    method: 'POST',
    abortController,
  }).catch(async error => {
    return Promise.reject(error)
  })
}

export function deleteRequest(path) {
  const body = {}
  return requestData({ path, body, method: 'DELETE' })
}

export default function ApiData({
  path,
  body,
  children,
  render = noop,
  error = noop,
}) {
  const [data, setData] = useState({ loading: true })

  useEffect(() => {
    let isCanceled = false

    async function getData() {
      let data
      let error

      try {
        if (body) {
          data = await post({ path, body })
        } else {
          data = await get(path)
        }
      } catch (err) {
        error = err
        log.error(`<ApiData path="${path}" />`, error)
      }

      !isCanceled &&
        setData({
          ...(data || { error }),
          loading: false,
        })
    }

    !isCanceled && setData({ loading: true })
    getData()

    return () => {
      isCanceled = true
    }
  }, [body, path])

  if (children) {
    return (children.length ? children : [children]).map(
      mergeProps({ response: data }),
    )
  } else {
    if (data.error === undefined) {
      return render({ response: data })
    } else {
      if (error === noop) {
        return render({ response: data })
      } else {
        return error({ response: data })
      }
    }
  }
}

export function withApiData({ path }, Component) {
  return props => (
    <ApiData path={path}>
      <Component {...props} />
    </ApiData>
  )
}
