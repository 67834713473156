import React from 'react'
import Icon from '../../../icon'
import { ReactComponent as IconPlus } from '../../../../_assets/svg/icon-plus.svg'
import { ReactComponent as IconMinus } from '../../../../_assets/svg/icon-minus.svg'
import './form-group.css'

export default function FormGroup({
  hideLabel = false,
  isOpen = true,
  children,
  ...group
}) {
  return (
    <section className={`form-group form-group--${group.id}`}>
      {hideLabel === false ? (
        <details
          className="form-group__details"
          open={isOpen}
          data-testid="form-group"
        >
          <summary className="form-group__summary">
            <h3 className="form-group__title text-bold">
              {group.label}
              <Icon className="form-group__plus-icon" size="tiny">
                <IconPlus />
              </Icon>
              <Icon className="form-group__minus-icon" size="tiny">
                <IconMinus />
              </Icon>
            </h3>
          </summary>
          {children}
        </details>
      ) : (
        <>
          <h3 className="form-group__title sr-only">{group.label}</h3>
          {children}
        </>
      )}
    </section>
  )
}
