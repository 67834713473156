import { useEffect, useRef } from 'react'

export default function useOnPopState(callback, initial = false) {
  const callBackRef = useRef()
  callBackRef.current = callback

  useEffect(() => {
    function onPopState() {
      callBackRef.current()
    }

    if (initial) {
      callBackRef.current()
    }

    window.addEventListener('popstate', onPopState, false)

    return () => {
      window.removeEventListener('popstate', onPopState, false)
    }
  }, [initial])
}
