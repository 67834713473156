import { status } from '@brainbay/components/utils/constants'
const setTitleForObjectDetails = data => {
  switch (data) {
    case status.VERKOCHT:
    case status.VERHUURD:
    case status.GEVEILD:
    case status.VERLENGING:
    case status.VERKOCHT_BIJ_INSCHRIJVING:
      return 'Transactie kenmerken'
    case status.TE_KOOP:
    case status.VERKOCHT_ONDER_VOORBEHOUD:
    case status.TE_HUUR:
    case status.VERHUURD_ONDER_VOORBEHOUD:
    case status.VERKOOP_BIJ_INSCHRIJVING:
    case status.VEILING:
    case status.TE_KOOP_EN_TE_HUUR:
    case status.ONDER_BOD:
    case status.ONDER_OPTIE:
    case status.INGETROKKEN:
      return 'Aanbod kenmerken'
    default: {
      return 'Object Details'
    }
  }
}
export default setTitleForObjectDetails
