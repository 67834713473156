import { createSlice } from '@reduxjs/toolkit'

function findNameValuesIn(obj) {
  const names = []
  const foundKey = Object.keys(obj).find(key => key === 'name')
  if (foundKey) {
    names.push(obj[foundKey])
  }

  Object.values(obj).forEach(value => {
    if (typeof value == 'object') {
      findNameValuesIn(value).forEach(nestedName => names.push(nestedName))
    }
  })

  return names
}

const filtercatalog = createSlice({
  name: 'filtercatalog',
  initialState: { loading: true },
  reducers: {
    setFilterCatalog: (_, { payload }) => {
      const nameTypeMap = {}
      const { filters, ...rest } = payload
      filters.forEach(filter =>
        filter?.controls?.forEach(control => {
          const { type } = control
          findNameValuesIn(control).forEach(name => (nameTypeMap[name] = type))
        }),
      )

      return { filters, ...rest, nameTypeMap }
    },
  },
})

export const { setFilterCatalog } = filtercatalog.actions

export default filtercatalog.reducer
