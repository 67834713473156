import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import HousingTypesSelect from '../housing-types-select'
import ImageViewer from '../image-viewer'
import Icon from '../icon'
import ParticularsList from '../particulars-list'
import ObjectLabel from '../object-label'
import SourceLabel from '../source-label'
import { ReactComponent as IconScale } from '../../_assets/svg/icon-scale.svg'
import formatValue from '../../utils/format-value'
import './object-details.css'

const ObjectDetailsForm = ({
  children,
  isBAGObject,
  isSaving,
  address,
  housingTypes,
  particulars = [],
  qualityScore,
  objectDetailsHasChanged,
  imagesBaseUrl,
  mediaGuids = [],
  status,
  duration,
  onHousingTypeChange,
  onFormSubmit,
  onFormReset,
  onFormClose,
  referenceCheckboxDefault = true,
  onChangeReferencesCheckbox,
}) => {
  const [selectedMediaGuid, setSelectedMediaGuid] = useState(0)
  const [fetchNewReferencesCheckbox, setFetchNewReferencesCheckbox] = useState(
    referenceCheckboxDefault && true,
  )

  return (
    <form onSubmit={onFormSubmit} onReset={onFormReset}>
      <div className="object-details">
        <ObjectLabel
          className="object-details__img-spacer"
          status={status}
          duration={duration}
        >
          {mediaGuids.length > 0 && (
            <Link
              className="object-details__button-scale print-excluded"
              to={`${imagesBaseUrl}/${
                selectedMediaGuid ? selectedMediaGuid : mediaGuids[0]
              }`}
            >
              <span className="sr-only">Bekijk foto groter</span>
              <Icon>
                <IconScale />
              </Icon>
            </Link>
          )}
          <ImageViewer
            mediaGuids={mediaGuids}
            mediaSourceId={address}
            onMediaGuidChange={setSelectedMediaGuid}
          />
        </ObjectLabel>

        <div className="object-details__content">
          <header className="object-details__header">
            <address className="object-details__address body-big text-bold">
              {address}
            </address>
            <div className="object-details__housing-type--select">
              <HousingTypesSelect
                isBAGObject={isBAGObject}
                housingTypesFromApi={housingTypes}
                onHousingTypeChange={onHousingTypeChange}
              />
            </div>
          </header>

          {children}

          <footer className="object-details__footer">
            <ParticularsList particulars={particulars} />
          </footer>
        </div>
      </div>

      {qualityScore && (
        <div className="object-details__quality-score">{qualityScore}</div>
      )}
      <div className="object-details__edit-buttons-and-checkbox">
        <div className="object-details__edit-buttons-and-checkbox__buttons print-excluded">
          <button
            className="object-details__edit-button button button--small"
            type="button"
            onClick={onFormClose}
          >
            Sluiten
          </button>
          {objectDetailsHasChanged && (
            <button
              className="object-details__edit-button button button--small"
              onClick={onFormReset}
            >
              Herstel naar beginwaarden
            </button>
          )}
          <button
            className={`object-details__edit-button button button--primary button--small ${
              isSaving ? 'button--loading' : null
            }`}
            type="submit"
          >
            Opslaan
          </button>
        </div>
        {onChangeReferencesCheckbox && (
          <fieldset>
            <input
              type="checkbox"
              id="checkbox-refs"
              checked={fetchNewReferencesCheckbox}
              onChange={() => {
                setFetchNewReferencesCheckbox(!fetchNewReferencesCheckbox)
                onChangeReferencesCheckbox &&
                  onChangeReferencesCheckbox(!fetchNewReferencesCheckbox)
              }}
            />
            <label htmlFor="checkbox-refs">Haal nieuwe referenties op</label>
          </fieldset>
        )}
      </div>
    </form>
  )
}
const ObjectDetailsContainer = ({
  isLoading,
  children,
  address,
  housingTypes,
  particulars = [],
  renderReferencesNotToExport,
  qualityScore,
  imagesBaseUrl,
  linkSlot,
  mediaGuids = [],
  status,
  duration,
  objectDetailsHasChanged,
  onEditObjectDetails,
  onFormSubmit,
  onFormReset,
  prefixSource,
  source,
  registrationStatus,
}) => {
  const [selectedMediaGuid, setSelectedMediaGuid] = useState(0)

  return (
    <>
      <div className="object-details">
        <ObjectLabel
          className="object-details__img-spacer"
          status={status}
          duration={duration}
        >
          {!isLoading && mediaGuids.length > 0 && (
            <Link
              className="object-details__button-scale print-excluded"
              to={`${imagesBaseUrl}/${
                selectedMediaGuid ? selectedMediaGuid : mediaGuids?.[0]
              }`}
            >
              <span className="sr-only">Bekijk foto groter</span>
              <Icon>
                <IconScale />
              </Icon>
            </Link>
          )}
          <ImageViewer
            isLoading={isLoading}
            mediaGuids={mediaGuids}
            onMediaGuidChange={setSelectedMediaGuid}
            mediaSourceId={address}
          />
        </ObjectLabel>

        <div className="object-details__content">
          <header className="object-details__header">
            <address className="object-details__address body-big text-bold loading-state">
              {address}
            </address>
            <div className="object-details__detail-info">
              {isLoading ? (
                <p className="object-details__housing-type loading-state" />
              ) : (
                <p className="object-details__housing-type capitalize-first-letter body loading-state">
                  <span className="sr-only">Object soort en type, </span>
                  {formatValue(housingTypes, {
                    format: 'comma-separated-list',
                  })}
                </p>
              )}
              {!isLoading && linkSlot}
            </div>
          </header>

          {children}

          {source || particulars?.length ? (
            <footer className="object-details__footer">
              {source && (
                <SourceLabel prefixSource={prefixSource} source={source} />
              )}
              {registrationStatus}
              <span
                className={
                  renderReferencesNotToExport ? 'particulars-not-print' : ''
                }
              >
                {particulars && <ParticularsList particulars={particulars} />}
              </span>
            </footer>
          ) : (
            ''
          )}
        </div>
      </div>

      {qualityScore && (
        <div className="object-details__quality-score">{qualityScore}</div>
      )}

      {!isLoading && onFormSubmit && (
        <div className="object-details__edit-buttons print-excluded">
          {objectDetailsHasChanged && (
            <button
              className="object-details__edit-button button button--small"
              onClick={onFormReset}
            >
              Herstel naar beginwaarden
            </button>
          )}
          <button
            className="object-details__edit-button button button--primary button--small"
            type="button"
            onClick={onEditObjectDetails}
          >
            Wijzig woningdetails
          </button>
        </div>
      )}
    </>
  )
}

export default function ObjectDetails({
  children,
  housingTypes,
  particulars,
  renderReferencesNotToExport,
  qualityScore,
  onHousingTypeChange,
  onFormClose,
  onFormSubmit,
  onFormReset,
  onEditObjectDetails,
  objectDetailsHasChanged,
  imagesBaseUrl,
  isLoading,
  isSaving,
  isBAGObject,
  linkSlot,
  address,
  mediaGuids,
  status,
  duration,
  canEdit,
  source,
  prefixSource,
  referenceCheckboxDefault,
  onChangeReferencesCheckbox,
  registrationStatus,
}) {
  return (
    <>
      {canEdit ? (
        <ObjectDetailsForm
          isBAGObject={isBAGObject}
          isSaving={isSaving}
          address={address}
          housingTypes={housingTypes}
          particulars={particulars}
          qualityScore={qualityScore}
          imagesBaseUrl={imagesBaseUrl}
          mediaGuids={mediaGuids}
          status={status}
          duration={duration}
          objectDetailsHasChanged={objectDetailsHasChanged}
          onHousingTypeChange={onHousingTypeChange}
          onFormSubmit={onFormSubmit}
          onFormReset={onFormReset}
          onFormClose={onFormClose}
          onChangeReferencesCheckbox={onChangeReferencesCheckbox}
          referenceCheckboxDefault={referenceCheckboxDefault}
        >
          {children}
        </ObjectDetailsForm>
      ) : (
        <ObjectDetailsContainer
          isLoading={isLoading}
          address={address}
          housingTypes={housingTypes}
          particulars={particulars}
          renderReferencesNotToExport={renderReferencesNotToExport}
          qualityScore={qualityScore}
          imagesBaseUrl={imagesBaseUrl}
          mediaGuids={mediaGuids}
          status={status}
          duration={duration}
          objectDetailsHasChanged={objectDetailsHasChanged}
          onEditObjectDetails={onEditObjectDetails}
          onFormSubmit={onFormSubmit}
          onFormReset={onFormReset}
          source={source}
          prefixSource={prefixSource}
          linkSlot={linkSlot}
          registrationStatus={registrationStatus}
        >
          {children}
        </ObjectDetailsContainer>
      )}
    </>
  )
}
