import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ToastBar from '../toast-bar'
import isSSR from '../../utils/is-ssr'
import log from '../../utils/log'
import './google-analytics.css'

const GA_TAG =
  isSSR === false && window.ga_tag !== '__GA_TAG__' ? window.ga_tag : undefined

let scriptLoaderElement

export default function GoogleAnalytics({ accountNumber }) {
  const [userDidConsent, setUserDidConsent] = useState(null)
  const [userDidNotConsent, setUserDidNotConsent] = useState(false)
  const [gaIsLoaded, setGaIsLoaded] = useState(false)
  const location = useLocation()

  const showConsentBar =
    userDidConsent === false && userDidNotConsent === false && isSSR === false

  function userGivesConsent() {
    window.localStorage.setItem('cookieconsent', true)
    setUserDidConsent(true)
    scriptLoaderElement = document.createElement('script')
    scriptLoaderElement.async = true
    scriptLoaderElement.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TAG}`
    scriptLoaderElement.onload = () => {
      setGaIsLoaded(true)
    }

    const script = document.createElement('script')
    script.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${GA_TAG}');
    `

    if (process.env.NODE_ENV === 'production' && GA_TAG) {
      document.body.appendChild(scriptLoaderElement)
      document.body.appendChild(script)
    }
  }

  function userGivesNonConsent() {
    setUserDidNotConsent(true)
  }

  useEffect(() => {
    const cookieConsent = window.localStorage.getItem('cookieconsent')
    if (cookieConsent === 'true') {
      userGivesConsent()
    } else {
      setUserDidConsent(false)
    }
  }, [])

  useEffect(() => {
    if (
      userDidConsent &&
      gaIsLoaded &&
      process.env.NODE_ENV === 'production' &&
      GA_TAG
    ) {
      window.gtag('config', GA_TAG, {
        page_path: location.pathname,
        accountNumber,
      })

      if (accountNumber) {
        window.gtag('set', 'user_properties', {
          accountNumber,
        })
      }
    }
  }, [gaIsLoaded, location, userDidConsent, accountNumber])

  useEffect(() => {
    if (!GA_TAG) {
      log.info('GA_TAG', GA_TAG)
    }
  }, [])

  return (
    <>
      {showConsentBar && (
        <ToastBar title="Cookie notificatie">
          <p className="google-analytics__text body">
            Op de website(s) van brainbay worden algemene bezoekersgegevens
            bijgehouden. Brainbay gebruikt cookies nooit om informatie uit uw
            computer op te halen die niet oorspronkelijk in een cookie is
            verzonden, zoals uw naam of e-mailadres. U kunt uw browser alle
            cookies laten weigeren of laten waarschuwen wanneer er een cookie
            wordt verzonden. Het kan echter gebeuren dat sommige opties of
            diensten van de website niet goed functioneren zonder cookies.
          </p>
          <div className="google-analytics__buttons">
            <button
              className="button button--primary"
              onClick={userGivesConsent}
              data-analytics-consent-button="yes"
            >
              Akkoord
            </button>
            <button
              className="button button--primary"
              onClick={userGivesNonConsent}
              data-analytics-consent-button="no"
            >
              Niet akkoord
            </button>
            <a
              className="button button--primary"
              href="https://www.brainbay.nl/privacyverklaring/"
            >
              Privacyverklaring
            </a>
          </div>
        </ToastBar>
      )}
    </>
  )
}
