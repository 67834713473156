import pkg from '../package.json'
import { browser } from './utils/sniff-browser'
import log from './utils/log'
import isSSR from './utils/is-ssr'
import './_global-styles/styles.css'
import viewportHack from './utils/viewport-hack'
import 'what-input'

export default function components({ appName } = {}) {
  if (appName) {
    console.log(
      `%c Brainbay %c ${appName} `,
      'background: #0099BC; color: #fff; border-radius: 3px 0 0 3px;',
      'background: #16113e; color: #eeffff; border-radius: 0 3px 3px 0; font-weight: 400;',
    )
  }

  console.log(
    `%c @brainbay/components %c v${pkg.version} %c ${
      process.env.COMPONENTS_ARE_LINKED ? '[LINKED]' : ''
    }`,
    'background: #0099BC; color: #fff; border-radius: 3px 0 0 3px;',
    'background: #16113e; color: #eeffff; border-radius: 0 3px 3px 0; font-weight: 400;',
    '',
  )

  if (!isSSR) {
    log.info(
      browser.vendor,
      `${browser.major}${browser.minor ? `.${browser.minor}` : ''}`,
    )

    viewportHack()
  }
}
