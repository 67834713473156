import { useEffect, useRef } from 'react'

/**
 * Debounces a use effect. So that it is only triggered at most once per {debounceTime}
 * @param {*} cb
 * @param {*} deps
 * @param {*} debounceTime
 */
export const useDebouncedEffect = (cb, deps, debounceTime) => {
  // save the callback in a ref so that we don't have to include it in the dependency list
  // as it is likely that the cb constantly changes between renders
  const cbRef = useRef()
  cbRef.current = cb

  useEffect(() => {
    const timeout = setTimeout(() => {
      cbRef.current()
    }, debounceTime)

    return () => {
      clearTimeout(timeout)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [...deps, debounceTime])
}
