import React, { Suspense, lazy } from 'react'
import Icon from '../icon'
import { ReactComponent as IconBoldHouse } from '../../_assets/svg/icon-bold-house.svg'
import { ReactComponent as IconCheck } from '../../_assets/svg/icon-check.svg'
import { statusSubClass } from '../../utils/constants'
import './map-marker.css'

// React lazy only supports default exports and no named exports. This way we make sure we get the Marker module.
const Marker = lazy(() =>
  import('react-map-gl').then(module => ({ default: module.Marker })),
)

export default function MapMarker({
  longitude,
  latitude,
  text,
  houseStatus,
  onMarkerClick = () => {},
  active,
  referenceAdded,
}) {
  function getSubClass() {
    let subClass = ''

    if (houseStatus) {
      subClass += ` map-marker${statusSubClass[houseStatus]}`
    }

    if (active) {
      subClass += ' map-marker--active'
    }

    if (referenceAdded) {
      subClass += ' map-marker--added'
    }

    return subClass
  }

  return (
    <Suspense fallback={<></>}>
      <Marker
        className={`map-marker${getSubClass()}`.trim()}
        longitude={longitude}
        latitude={latitude}
      >
        <button onClick={onMarkerClick} className="map-marker__button">
          <div className="map-marker__icon">
            <Icon circle>
              <IconBoldHouse />
            </Icon>
          </div>

          {text && <span className="map-marker__text body-small">{text}</span>}

          <div className="map-marker__icon--check">
            <Icon circle size="nano">
              <IconCheck />
            </Icon>
          </div>
        </button>
      </Marker>
    </Suspense>
  )
}
