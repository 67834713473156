import React, { forwardRef, useEffect, useState } from 'react'
import Panel from '@brainbay/components/components/panel'
import DataMap from '@brainbay/components/components/data-map'
import './building-aerial-picture.css'

const BuildingAerialPicture = forwardRef(function BuildingAerialPicture(
  { data },
  ref,
) {
  const [address, setAddress] = useState({})
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    if (data && !data.loading) {
      setAddress(data.geselecteerdeAdres)
      setHasLoaded(true)
    }
  }, [data])

  const mapboxConfig = {
    scrollZoom: false,
  }

  return (
    <div ref={ref}>
      <Panel
        title="Luchtfoto"
        tag="section"
        collapsable
        divider
        isOpenByDefault
        className="building-aerial-picture"
      >
        <div className="building-aerial-picture__content">
          {hasLoaded && (
            <DataMap
              longitude={address?.longitude}
              latitude={address?.latitude}
              mapboxConfig={mapboxConfig}
              width="100%"
              height="400px"
              zoom={16}
              showMarker
              showControls
              showAerial
              mapId="luchtFotoMapElement"
            />
          )}
        </div>
      </Panel>
    </div>
  )
})

export default BuildingAerialPicture
