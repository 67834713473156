/**
 * Returns true/false if a url is valid
 *
 * @example
 * isValidUrl('www.invalid.com') -> false
 * isValidUrl('http://www.valid.com') -> true
 *
 * @param {string} url
 * @returns {boolean}
 */
export function isValidUrl(url) {
  try {
    new URL(url)
    return true
  } catch (err) {
    return false
  }
}

/**
 * Prepends https:// to urls with missing protocol.
 *
 * Very primitive; only checks if url starts with www.
 *
 * @example
 * prependProtocol('www.invalid.com') -> 'https://www.invalid.com'
 * prependProtocol('http://www.valid.com') -> 'http://www.valid.com'
 *
 * @param {string} url
 * @returns {string}
 */
export function prependProtocol(url) {
  if (url?.startsWith('www.')) return `https://${url}`
  return url
}
