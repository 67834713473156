import React, { useState, useEffect, useMemo } from 'react'
import { useRouteMatch, Link, useHistory } from 'react-router-dom'
import ResponsiveImage from '../responsive-image'
import ImageViewer from '../image-viewer'
import Modal from '../portal'
import './modal-image-viewer.css'

export default function ModalImageViewer({
  onClose,
  imagesBaseUrl,
  mediaGuids,
  isLoading,
}) {
  const modalMediaGuids = useMemo(
    () => (isLoading ? ['', '', '', '', ''] : mediaGuids),
    [isLoading, mediaGuids],
  )
  const match = useRouteMatch()
  const mediaGuid = match.params.mediaGuid
  const [mediaGuidIndex, setMediaGuidIndex] = useState(0)
  const history = useHistory()

  useEffect(() => {
    let newIndex = (modalMediaGuids || []).indexOf(mediaGuid)

    if (newIndex === -1) {
      newIndex = 0
    }

    setMediaGuidIndex(newIndex)
  }, [mediaGuid, modalMediaGuids])

  function mediaGuidChange(mediaGuid) {
    history.push(`${imagesBaseUrl}/${mediaGuid}`)
  }

  const ShowMediaGuids = () => {
    return (
      <section className="modal-image-viewer">
        <h3 className="text-bold body-big">
          Foto's{' '}
          {!isLoading && (
            <span className="modal-image-viewer__span text-normal text-dim">
              {modalMediaGuids?.length}
            </span>
          )}
        </h3>
        <ul className="modal-image-viewer__gallery">
          {modalMediaGuids.map((mediaGuid, index) => {
            return (
              <li className="modal-image-viewer__gallery-item" key={index}>
                <Link
                  className="panel__header-link print-excluded"
                  to={`${imagesBaseUrl}/${mediaGuid}`}
                  onClick={() => setMediaGuidIndex(index)}
                >
                  <ResponsiveImage
                    className="modal-image-viewer__gallery-item-image"
                    imageId={mediaGuid}
                    isLoading={isLoading}
                    loading="eager"
                  />
                </Link>
              </li>
            )
          })}
        </ul>
      </section>
    )
  }

  const ErrorMessage = () => {
    return (
      <p className="body modal-image-viewer__error">
        Geen afbeeldingen gevonden
      </p>
    )
  }

  const ShowMediaGuid = () => {
    return (
      <section className="modal-image-viewer__image-wrapper">
        <ImageViewer
          mediaGuids={mediaGuids}
          selectedMediaGuidIndex={mediaGuidIndex}
          isLoading={isLoading}
          smallButtons
          onMediaGuidChange={mediaGuidChange}
        />
      </section>
    )
  }

  if (mediaGuid) {
    return (
      <Modal
        className="modal-image-viewer modal-image-viewer__active-mediaguid"
        onClose={onClose}
      >
        <ShowMediaGuid />
        {!isLoading && (
          <Link
            className="modal-image-viewer__link button buttom-small"
            to={imagesBaseUrl}
          >
            Alle foto's
          </Link>
        )}
      </Modal>
    )
  }

  return (
    <Modal className="modal-image-viewer" onClose={onClose}>
      {modalMediaGuids?.length ? <ShowMediaGuids /> : <ErrorMessage />}
    </Modal>
  )
}
