import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getActiveFilterLabels, hasValue } from '../controls'

export function forEachControl(filterCatalog, callback) {
  const filters = filterCatalog.filters ?? []

  filters.forEach(group => {
    group.controls.forEach((control, index) => callback(control, index, group))
  })
}

export function useActiveFilters(filterCatalog, filters, getDescendantFilters) {
  return useMemo(() => {
    const activeFilters = []

    if (filterCatalog.loading) {
      return []
    }

    filterCatalog.filters.forEach(({ controls }) => {
      controls.forEach(control => {
        /*
          If the only purpose of this control is to show other controls then don't include it in the active filters
          (fixed transactionPrijsType from appearing in filters)
        */
        if (getDescendantFilters(control.name, 'show').length) {
          return
        }

        const labels = getActiveFilterLabels(control, filters)
        if (labels.length) {
          activeFilters.push(...labels)
        }
      })
    })
    return activeFilters
  }, [filters, filterCatalog, getDescendantFilters])
}

export function useOpenFormGroups(
  filterCatalog,
  filters,
  getDescendantFilters,
) {
  const [openFormGroups, setOpenFormGroups] = useState(() => new Set())
  const initialized = useRef(false)

  useEffect(() => {
    if (
      initialized.current ||
      filterCatalog.loading ||
      !Object.keys(filters).length
    ) {
      return
    }

    const groups = new Set()
    forEachControl(filterCatalog, (control, _index, group) => {
      if (
        hasValue(control, filters) &&
        !getDescendantFilters(control.name, 'show').length
      ) {
        groups.add(group.id)
      }
    })

    setOpenFormGroups(groups)
    initialized.current = true
  }, [filters, filterCatalog, getDescendantFilters])

  return openFormGroups
}

export function useFilterDefaults(filterCatalog) {
  return useMemo(() => {
    const values = {}

    forEachControl(filterCatalog, ({ name, options }) => {
      if (!options) {
        return
      }

      const selectedOptions = options.filter(option => option.selected)
      if (selectedOptions.length < 1) {
        return
      }

      values[name] = selectedOptions.map(option => option.value).join(',')
    })

    return values
  }, [filterCatalog])
}

export function useFilterTree(filterCatalog) {
  const helpers = useMemo(() => {
    if (filterCatalog.loading) {
      return {
        defaultVisibleFilters: [],
        traverseTree: () => {},
      }
    }

    const defaultVisibleFilters = []

    const targetedConditionalControls = new Set()

    const groupedBySourceControl = new Map()
    filterCatalog.displayConfiguration.forEach(config => {
      const key = config.sourceControl
      let targetConfigs = groupedBySourceControl.get(key)
      if (!targetConfigs) {
        targetConfigs = []
        groupedBySourceControl.set(key, targetConfigs)
      }

      targetConfigs.push(config)

      if (config.behaviour === 'show') {
        targetedConditionalControls.add(config.targetControl)
      }
    })

    // all “normal” filters should be visible by default
    forEachControl(filterCatalog, ({ name }) => {
      if (!targetedConditionalControls.has(name)) {
        defaultVisibleFilters.push(name)
      }
    })

    const rootConfigs = []
    // find the topmost filters (“aanbod”)
    Array.from(groupedBySourceControl.entries()).forEach(([name, configs]) => {
      if (!targetedConditionalControls.has(name)) {
        // we know that this filter is the topmost filter because it is never targeted by another filter
        rootConfigs.push(...configs)
        defaultVisibleFilters.push(name)
      }
    })

    function traverseBranch(cb, configs) {
      configs.forEach(config => {
        let traverseChilds = false
        cb({
          traverseChilds() {
            traverseChilds = true
          },
          config,
        })

        if (traverseChilds) {
          const subConfigs = groupedBySourceControl.get(config.targetControl)

          if (subConfigs) {
            traverseBranch(cb, subConfigs)
          }
        }
      })
    }

    return {
      traverseTree(cb, from) {
        let initialConfigs = []
        if (from) {
          const key = from
          if (groupedBySourceControl.has(key)) {
            initialConfigs = groupedBySourceControl.get(key)
          }
        } else {
          initialConfigs = rootConfigs
        }
        traverseBranch(cb, initialConfigs)
      },
      getTargetedControls(name) {
        return groupedBySourceControl.get(name)
      },
      defaultVisibleFilters,
    }
  }, [filterCatalog])

  /**
   * Returns the filter elements which should be visible based on the given filter values
   */
  const getVisibleFilters = useCallback(
    filterValues => {
      const { traverseTree, defaultVisibleFilters } = helpers
      const visibleFilters = [...defaultVisibleFilters]

      traverseTree(({ config, traverseChilds }) => {
        if (
          config.behaviour === 'show' &&
          config.sourceOption === filterValues[config.sourceControl]
        ) {
          visibleFilters.push(config.targetControl)
          traverseChilds()
        }
      })

      return visibleFilters
    },
    [helpers],
  )

  /**
   * Traverses the filter tree and returns all the descendants of a filter
   */
  const getDescendantFilters = useCallback(
    (name, behaviour) => {
      const descendants = []
      helpers.traverseTree(({ config, traverseChilds }) => {
        if (config.behaviour === behaviour) {
          descendants.push(config.targetControl)
          traverseChilds()
        }
      }, name)
      return descendants
    },
    [helpers],
  )

  return {
    getVisibleFilters,
    getDescendantFilters,
  }
}
